import { Button, Col, Form, FormProps, Icons, Input, Row } from "@pankod/refine-antd";
import { useOne } from "@pankod/refine-core";
import { RenderSpinner } from "components/common";
import { IEvaluationModelMetadata } from "interfaces";
import { DATAPROVIDER_LOOKUP, RESOURCE_PATH, STALE_DURATION } from "scripts/site";

export const EvaluationModelFilter: React.FC<{ formProps: FormProps }> = (props) => {
    const { isLoading, data: metadata } = useOne<IEvaluationModelMetadata>({
        dataProviderName: DATAPROVIDER_LOOKUP,
        resource: RESOURCE_PATH.METADATA,
        id: RESOURCE_PATH.EVALUATIONMODEL,
        queryOptions: {
            enabled: true,
            staleTime: STALE_DURATION
        }
    });
    const metaConfig = metadata?.data.config;
    return (
        isLoading ? <RenderSpinner message="Loading..." ></RenderSpinner> :
            <Form layout="vertical" {...props.formProps} autoComplete="off">
                <Row gutter={[10, 0]} align="bottom">
                    <Col xs={24} xl={24} md={12}>
                        <Form.Item
                            label={"Search"}
                            name="q"
                            tooltip={metaConfig?.search?.textMessages?.searchTextPlaceholder || "Search Evaluaton Models"}
                        >
                            <Input
                                placeholder={metaConfig?.search?.textMessages?.searchTextPlaceholder || "Search Evaluaton Models"}
                                prefix={<Icons.SearchOutlined />}
                            />
                        </Form.Item>
                    </Col>
                    <Col xs={24} xl={24} md={8}>
                        <Form.Item>
                            <Button
                                style={{ width: "100%" }}
                                htmlType="submit"
                                type="primary"
                            >
                                Search
                            </Button>
                        </Form.Item>
                        <Form.Item>
                            <Button
                                style={{ width: "100%" }}
                                htmlType="submit"
                                onClick={() => { props.formProps.form?.resetFields() }}
                            >
                                Clear Search
                            </Button>
                        </Form.Item>
                    </Col>
                </Row>
            </Form>
    );
}