import { arrayExtensions, stringExtensions } from "@architecture-innovation-transformation/lib-common";
import { Button, CreateButton, Drawer, Icons, List, Table, Tag, TextField, Typography, getDefaultSortOrder, useTable } from "@pankod/refine-antd";
import { CanAccess, CrudFilters, HttpError, IResourceComponentsProps, useGetIdentity, useNavigation, useOne } from "@pankod/refine-core";
import { RenderWorkflowModal } from "components/common";
import { OfferingFilter } from "components/offering/offeringFilter";
import dayjs from "dayjs";
import { IState, IUser } from "interfaces";
import { IOffering, IOfferingFilterVariables, IOfferingMetadata } from "interfaces/article";
import { useState } from "react";
import { useLocation } from "react-router-dom";
import { DATAPROVIDER_CREATE, DATAPROVIDER_LOOKUP, DATAPROVIDER_READ, RESOURCE_PATH, STALE_DURATION, THINK_GEN_AI_LOGO_BLACK, THINK_GEN_AI_LOGO_WHITE, getQueryStringParams } from "scripts/site";
import { getAppTheme } from "scripts/theme";

export const OfferingList: React.FC<IResourceComponentsProps> = () => {
  const { data } = useGetIdentity();
  const user = data as IUser;

  const { show } = useNavigation();
  const [filterOpen, switchFilterForm] = useState(false);
  const [myOfferings, setMyOfferings] = useState(false);
  const { search } = useLocation();
  const { programId } = getQueryStringParams(search);
  const mode: "List" | "Program" = programId ? "Program" : "List";


  const listFilter: CrudFilters = [
    {
      field: "stateManager.state",
      operator: "eq",
      value: myOfferings ? "" : "active,archived"
    },
    {
      field: "authorName ARRAY_CONTAINS_ANY",
      operator: "eq",
      value: myOfferings ? user.id : ""
    },
    {
      field: "_arrayfields",
      operator: "eq",
      value: "serviceLineIds,authorName,valueDimensions,licensingmodel,offeringtype,state"
    }
  ];

  if (mode === "Program") {
    listFilter.push({
      field: "programId",
      operator: "eq",
      value: programId
    });
  }

  const { data: entityMetadata } = useOne<IOfferingMetadata>({
    dataProviderName: DATAPROVIDER_LOOKUP,
    resource: RESOURCE_PATH.METADATA,
    id: RESOURCE_PATH.OFFERING,
    queryOptions: {
      enabled: true,
      staleTime: STALE_DURATION
    }
  });
  const metaConfig = entityMetadata?.data?.config;
  const entityStates = entityMetadata?.data?.states as IState[] ?? [];

  const { tableProps, sorter, tableQueryResult, searchFormProps, filters } = useTable<IOffering, HttpError, IOfferingFilterVariables>({
    dataProviderName: DATAPROVIDER_READ,
    initialSorter: [
      {
        field: "updatedAt",
        order: "desc"
      },
    ],
    permanentFilter: listFilter,
    onSearch: (params) => {
      const filters: CrudFilters = [];
      const { q, serviceLineIds, offeringtype, valueDimensions, licensingmodel, state } = params;
      filters.push({
        field: "q",
        operator: "contains",
        value: q
      });
      filters.push({
        field: "serviceLineIds ARRAY_CONTAINS_ANY",
        operator: "eq",
        value: serviceLineIds
      });
      filters.push({
        field: "offeringtype ARRAY_CONTAINS_ANY",
        operator: "eq",
        value: offeringtype
      });
      filters.push({
        field: "licensingmodel ARRAY_CONTAINS_ANY",
        operator: "eq",
        value: licensingmodel
      });
      filters.push({
        field: "valueDimensions ARRAY_CONTAINS_ANY",
        operator: "eq",
        value: valueDimensions
      });
      filters.push({
        field: "state ARRAY_CONTAINS_ANY",
        operator: "eq",
        value: state
      });
      return filters;
    }
  });

  function rowClick(record: IOffering) {
    return {
      onClick: () => {
        show(RESOURCE_PATH.OFFERING, record.id);
      },
    };
  }
  const totalRequests = tableQueryResult?.data?.total ?? 0;
  let isFiltered = filters && filters.length > listFilter.length && filters.some(fltr => fltr.value !== undefined)

  const clearFilters = () => {
    searchFormProps.form?.resetFields();
    searchFormProps.form?.submit();
  }

  let pageTitle = (tableQueryResult.isLoading ? "Loading" : totalRequests) + " Offering" + (totalRequests !== 1 ? "s" : "");;
  let pageTag = "";

  return (
    <List
      headerProps={{
        title: pageTitle,
        tags: <>
          {mode === "Program" && <Tag color={"default"}>
            {pageTag}
          </Tag>}
          <Button key="filter" type="default"
            icon={isFiltered ? <Icons.FilterFilled /> : <Icons.FilterOutlined />}
            style={{ paddingRight: (isFiltered ? 4 : 15) }}
            title={isFiltered ? "You are viewing a filtered list" : (entityMetadata?.data?.config?.search?.textMessages?.buttonText || "Search & Filter")}
            onClick={() => switchFilterForm(true)}>
            Search
            {isFiltered &&
              <>
                <Button
                  type="text"
                  danger
                  title={"Clear Filters"}
                  size="small"
                  style={{ marginLeft: 5 }}
                  onClick={(e) => {
                    clearFilters();
                    e.stopPropagation();
                  }}
                  icon={<Icons.CloseOutlined />}
                >
                </Button>
              </>
            }
          </Button>
        </>,
        extra:
          <>
            <CanAccess resource={RESOURCE_PATH.OFFERING} action="create" params={{ id: "1", dataProviderName: DATAPROVIDER_CREATE }}>
              <CreateButton icon={<Icons.PlusOutlined />}>Add</CreateButton>
            </CanAccess>
            <Button
              title={myOfferings ? "Show All Offerings" : "Show My Offerings"}
              icon={myOfferings ? <Icons.BarsOutlined /> : <Icons.SolutionOutlined />}
              onClick={() => setMyOfferings(!myOfferings)}
            >
              {myOfferings ? "All Offerings" : "My Offerings"}
            </Button>
            {entityStates.length > 0 ? RenderWorkflowModal(entityStates) : <></>}
            {mode === "Program" && <>
              <img src={getAppTheme() === "dark" ? THINK_GEN_AI_LOGO_WHITE : THINK_GEN_AI_LOGO_BLACK} alt="Think GenAI" style={{
                height: 30,
                width: 150,
              }}></img>
            </>
            }
          </>
      }}
    >
      <Table {...tableProps}
        rowKey="id"
        pagination={{
          ...tableProps.pagination,
          position: ["bottomRight"],
          showTotal: (total => <Typography.Title level={4} style={{ marginRight: 10 }} >Total {total}</Typography.Title>)
        }}
      >
        <Table.Column
          dataIndex="title"
          key="title"
          title="Title"
          defaultSortOrder={getDefaultSortOrder("title", sorter)}
          sorter
          onCell={rowClick}
          className="mouseHand"
        />

        <Table.Column
          dataIndex={["stateManager", "state"]}
          key="stateManager.state"
          title="State"
          onCell={rowClick}
          className="mouseHand"
          sorter
          render={(value) => <TextField value={stringExtensions.capitalize(value)} />}
        />

        <Table.Column
          dataIndex="offeringtype"
          key="offeringtype"
          title="Offering Type"
          onCell={rowClick}
          className="mouseHand"
        />

        <Table.Column
          dataIndex="licensingmodel"
          key="licensingmodel"
          title="Licensing Model"
          onCell={rowClick}
          className="mouseHand"
        />

        {/* <Table.Column
          dataIndex="serviceLineIds"
          key="serviceLineIds"
          title="Service Lines"
          onCell={rowClick}
          className="mouseHand"
          render={(value: string[]) =>
            value && value.map((r, i) => (
              <div key={i} style={{ marginBottom: 5 }}>
                <LookupButton noTooltip id={r} resource={RESOURCE_PATH.SERVICELINE} noClick />
              </div>
            ))
          }
        /> */}
        <Table.Column
          dataIndex="serviceLineIds"
          key="serviceLineIds"
          title="Service Lines"
          onCell={rowClick}
          className="mouseHand"
          render={(value: string[]) =>
            value && <Typography.Title
              style={{
                fontWeight: "unset",
                fontSize: "14px",
              }}
              level={5}
              ellipsis={{
                rows: 1,
                tooltip: value.join(", "),
              }}
            >
              {arrayExtensions.validateArray(value ?? [])
                && value.map((e, i) =>
                  <div key={i} style={{ marginBottom: 5 }}>
                    <Tag
                      color={"blue"}
                      key={`tag-${i}`}>{stringExtensions.capitalize(e)}</Tag>
                  </div>
                )}
            </Typography.Title>
          }
        />
        <Table.Column
          dataIndex="valueDimensions"
          key="valueDimensions"
          title="Value Dimensions"
          onCell={rowClick}
          className="mouseHand"
          render={(value: string[]) =>
            value && <Typography.Title
              style={{
                fontWeight: "unset",
                fontSize: "14px",
              }}
              level={5}
              ellipsis={{
                rows: 2,
                tooltip: value.join(", "),
              }}
            >
              {arrayExtensions.validateArray(value ?? [])
                && value.map((e, i) =>
                  <div key={i} style={{ marginBottom: 5 }}>
                    <Tag //style={{ marginBottom: 7 }}
                      color={"blue"}
                      key={`tag-${i}`}>{e}</Tag>
                  </div>
                )}
            </Typography.Title>
          }
        />

        <Table.Column
          dataIndex="publishedDate"
          key="publishedDate"
          title="Published Date"
          render={(value: string) => <>
            {value &&
              <Typography.Text style={{ color: "GrayText" }}>{dayjs(value).format('LLLL')}</Typography.Text>
            }
          </>}
          defaultSortOrder={getDefaultSortOrder("publishedDate", sorter)}
          sorter
          onCell={rowClick}
          className="mouseHand"
        />

      </Table>
      <Drawer
        title={metaConfig?.search?.textMessages?.drawerFormHeader || "Search & Filter"}
        placement="right"
        size="default"
        open={filterOpen}
        onClose={() => { switchFilterForm(false) }}
      >
        <OfferingFilter formProps={searchFormProps}
          onApplyFilter={() => switchFilterForm(false)} />
      </Drawer>
    </List>
  );
};