import { Form } from "@pankod/refine-antd";
import { IGroup } from "interfaces";
import { RenderQuestion } from "./renderQuestion";

export const RenderGroup: React.FC<IGroup & {
    index: number,
}> = ({ index, questions }) => {

    return (
        <Form.List
            name={["evaluationModel", "groups"]}
        >
            {(fields) =>
                fields
                    .filter(f => f.key === index)
                    .map((group) => (
                        <Form.List
                            {...group}
                            name={[group.key, "questions"]}
                        >
                            {(fields) => (
                                fields.map((ques) => (
                                    <RenderQuestion
                                        key={`g-${index}-q-${ques.key}`}
                                        {...questions[ques.key]}
                                        index={ques.key} 
                                        groupIndex = {index}/>
                                ))
                            )}
                        </Form.List>
                    ))
            }
        </Form.List>
    );
};