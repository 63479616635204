import { IBaseProps, stringExtensions } from "@architecture-innovation-transformation/lib-common";
import { Button, Drawer, Icons, RefreshButton, Show, Table, Tabs, Tag, TextField, getDefaultSortOrder } from "@pankod/refine-antd";
import { IResourceComponentsProps, useNavigation, useOne } from "@pankod/refine-core";
import { StateButtons } from "components/common";
import { ShowUserCard } from "components/profile/showUserCard";
import { WorklistFilter } from "components/profile/worklistFilter";
import { displayRelativeDate } from "components/utils/displayRelativeDate";
import { IWorkListMetadata } from "interfaces";
import { useState } from "react";
import { DATAPROVIDER_LOOKUP, DATAPROVIDER_WORKLIST, RESOURCE_PATH, STALE_DURATION, useNotifications } from "scripts/site";

export const Request: React.FC<IResourceComponentsProps> = () => {
  const { tableProps, sorter, tableQueryResult, searchFormProps } = useNotifications(RESOURCE_PATH.REQUEST);
  const { show } = useNavigation();
  const { data: metadata, isLoading: metadataLoading } = useOne<IWorkListMetadata>({
    dataProviderName: DATAPROVIDER_LOOKUP,
    resource: RESOURCE_PATH.METADATA,
    id: RESOURCE_PATH.REQUEST,
    queryOptions: {
      enabled: true,
      staleTime: STALE_DURATION
    }
  });
  const [filterOpen, switchFilterForm] = useState(false);
  const totalActionItems = tableQueryResult?.data?.total ?? 0;
  const tableData = tableQueryResult?.data?.data;
  const worklistModules = metadata?.data.config?.worklistModules;

  function rowClick(record: any) {
    return {
      onClick: () => {
        show(record?.type, record?.id);
      },
    };
  }

  const getTabContent = () => {
    let tabs = [];
    worklistModules?.forEach((m, i) => {
      let moduleItems = tableData?.filter(item => m.entities.includes(item.type));
      if (moduleItems && moduleItems.length > 0) {
        tabs.push({
          label: m.name,
          key: `tab-${i}-${m.name}`,
          children: <Table
            rowKey="id"
            pagination={{
              ...tableProps.pagination,
              position: ["bottomRight"]
            }}
            dataSource={moduleItems}
          >
            <Table.Column
              dataIndex="name"
              key="name"
              title="Name"
              render={(value) => <TextField value={value} />}
              defaultSortOrder={getDefaultSortOrder("name", sorter)}
              sorter
              onCell={rowClick}
              className="mouseHand"
            />
            <Table.Column
              dataIndex="type"
              key="type"
              title="Type"
              render={(value) => <TextField value={stringExtensions.capitalize(value)} />}
              defaultSortOrder={getDefaultSortOrder("type", sorter)}
              sorter
              onCell={rowClick}
              className="mouseHand"
            />
            <Table.Column
              dataIndex={["stateManager", "state"]}
              key="state"
              title="State"
              render={(value) => <TextField value={stringExtensions.capitalize(value)} />}
              onCell={rowClick}
              className="mouseHand"
            />
            <Table.Column
              dataIndex={["authorName"]}
              key="authorName"
              title="Authors"
              render={(value: string[]) =>
                value && value.map((r, i) => (
                  <div key={i} style={{ marginBottom: 5 }}>
                    <ShowUserCard id={r} />
                  </div>
                ))
              }
              onCell={rowClick}
              className="mouseHand"
            />
            <Table.Column
              dataIndex={["stateManager", "assignedBy"]}
              key="assignedBy"
              title="Assigned By"
              render={(value: string) =>
                <ShowUserCard id={value} />
              }
              defaultSortOrder={getDefaultSortOrder("stateManager.assignedBy", sorter)}
              sorter
              onCell={rowClick}
              className="mouseHand"
            />
            <Table.Column
              dataIndex="updatedAt"
              key="updatedAt"
              title="Last Updated"
              render={displayRelativeDate}
              defaultSortOrder={getDefaultSortOrder("updatedAt", sorter)}
              sorter
              onCell={rowClick}
              className="mouseHand"
            />
            <Table.Column
              title="Actions"
              key="actions"
              fixed="right"
              className="wrklstbtns"
              render={(_value, record: any) => (
                <StateButtons {...record as IBaseProps}
                  resourceToAct={record.type}
                  resourceToActId={record?.id || "0"}
                  resourceToNavigate={RESOURCE_PATH.PROFILE}
                  mode={"worklist"}
                />
              )}
            />
          </Table>
        });
      }
    })
    // If there are no action items assigned, simple display 
    if (tabs.length === 0) {
      tabs.push({
        label: "My Request",
        key: "tab-0",
        children: "No Requests !!!",
        disabled: true
      })
    }
    return tabs;
  }

  return (
    <Show
      canDelete={false}
      isLoading={metadataLoading}
      headerProps={{
        backIcon: false,
        title: "My Request",
        tags: <>

          <Tag color={"orange"}>
            {tableQueryResult.isLoading ? "Loading" : totalActionItems} Request{totalActionItems !== 1 && "s"}
          </Tag>
        </>
      }}
      headerButtons={() => (
        <>
          <RefreshButton resource={RESOURCE_PATH.WORKLIST} dataProviderName={DATAPROVIDER_WORKLIST}></RefreshButton>
          <Button key="filter" icon={<Icons.SearchOutlined />} onClick={() => switchFilterForm(true)}>{metadata?.data?.config?.search?.textMessages?.buttonText || "Search & Filter"}</Button>
        </>
      )}
    >
      <Tabs
        tabPosition="top"
        items={getTabContent()}
      />
      <Drawer
        title={metadata?.data?.config?.search?.textMessages?.drawerFormHeader || "Search & Filter"}
        placement="right"
        size="default"
        open={filterOpen}
        onClose={() => { switchFilterForm(false) }}
      >
        <WorklistFilter formProps={searchFormProps} onApplyFilter={() => switchFilterForm(false)} />
      </Drawer>
    </Show>
  );
};
