import { Button, Tooltip, Typography } from "@pankod/refine-antd";
import { useNavigation } from "@pankod/refine-core";
import { LookupProps } from "./lookupButton";

export declare type RenderLinkProps = LookupProps & {
    name: string | undefined;
};

export const RenderLink: React.FC<RenderLinkProps> = ({
    id,
    resource,
    name,
    noClick,
    noTooltip,
    ...rest
}) => {
    const { show } = useNavigation();

    return (
        id !== "" && resource !== ""
            ? (name ?
                (noClick === true
                    ? <Typography.Text>
                        {(noTooltip === true)
                            ? <>{name ?? id}</>
                            : <Tooltip title={id}>
                                {name ?? id}
                            </Tooltip>
                        }
                    </Typography.Text>
                    : <Button
                        type="link"
                        size="small"
                        style={{ padding: 0 }}
                        {...rest}
                        onClick={(e) => {
                            if (!noClick === true) {
                                show(resource, id);
                            }
                            e.stopPropagation();
                        }}
                    >
                        {(noTooltip === true)
                            ? <>{name ?? id}</>
                            : <Tooltip title={id}>
                                {name ?? id}
                            </Tooltip>
                        }
                    </Button>
                )
                : <Typography.Text>{id}</Typography.Text>)
            : <>---</>
    );
};