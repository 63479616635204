import {
    Button, getDefaultSortOrder, Icons, Table, TextField, Typography, useTable
} from "@pankod/refine-antd";
import { CrudFilter, CrudFilters, HttpError, useNavigation } from "@pankod/refine-core";
import { ShowUserCard } from "components/profile/showUserCard";
import { displayRelativeDate } from "components/utils/displayRelativeDate";
import { ILocations } from "interfaces";
import { DATAPROVIDER_READ, RESOURCE_PATH } from "scripts/site";

export declare type LocationListProps = {
    countryId?: string,
};

export const LocationsList: React.FC<LocationListProps> = ({ countryId }) => {
    const { show, edit } = useNavigation();

    const LocationFilter: CrudFilter[] = countryId ? [{
        field: "countryId",
        operator: "eq",
        value: countryId
    }] : [];

    const { tableProps, sorter } = useTable<ILocations, HttpError, { q: string }>({
        dataProviderName: DATAPROVIDER_READ,
        resource: RESOURCE_PATH.LOCATION,
        initialSorter: [
            {
                field: "name",
                order: "asc"
            },
        ],
        permanentFilter: LocationFilter,
        initialPageSize: 50,
        onSearch: (params) => {
            const filters: CrudFilters = [];
            const { q } = params;
            filters.push({
                field: "q",
                operator: "contains",
                value: q
            });
            return filters;
        }
    });

    function rowClick(record: ILocations) {
        return {
            onClick: () => {
                show(RESOURCE_PATH.LOCATION, record.id);
            },
        };
    }

    return (
        <>
            <Table {...tableProps}
                rowKey="id"
                className="mouseHand"
                pagination={{
                    ...tableProps.pagination,
                    position: ["bottomRight"],
                    showTotal: (total => <Typography.Title level={4} style={{ marginRight: 10 }} >Total {total}</Typography.Title>)
                }}
            >
                <Table.Column
                    dataIndex="id"
                    key="id"
                    title="Location ID"
                    render={(value) =>
                        <div>
                            <TextField value={value} />
                        </div>
                    }
                    onCell={rowClick}
                    defaultSortOrder={getDefaultSortOrder("id", sorter)}
                    sorter
                />

                <Table.Column
                    dataIndex="name"
                    key="name"
                    title="Location Name"
                    render={(value) =>
                        <div>
                            <TextField value={value} />
                        </div>
                    }
                    onCell={rowClick}
                    defaultSortOrder={getDefaultSortOrder("name", sorter)}
                    sorter
                />

                <Table.Column
                    dataIndex="updatedBy"
                    title="Updated By"
                    defaultSortOrder={getDefaultSortOrder("updatedBy", sorter)}
                    sorter
                    onCell={rowClick}
                    render={(value) => <ShowUserCard id={value} />}
                />

                <Table.Column
                    dataIndex="updatedAt"
                    key="updatedAt"
                    onCell={rowClick}
                    title="Last Updated"
                    render={displayRelativeDate}
                    defaultSortOrder={getDefaultSortOrder("updatedAt", sorter)}
                    sorter
                />
                <Table.Column
                    title="Actions"
                    key="actions"
                    fixed="right"
                    className="actnbtns"
                    render={(_value, record: any) => (
                        <Button
                            type="default"
                            key="edit"
                            onClick={() => {
                                edit(RESOURCE_PATH.LOCATION, record.id);
                            }}
                            icon={<Icons.AimOutlined />}>Update</Button>
                    )}
                />
            </Table>
        </>
    );
};