import { stringExtensions } from "@architecture-innovation-transformation/lib-common";
import { Button, CreateButton, DateField, Drawer, Icons, List, NumberField, Table, Tag, TextField, Typography, getDefaultSortOrder, useTable } from "@pankod/refine-antd";
import { CanAccess, CrudFilters, HttpError, IResourceComponentsProps, useGetIdentity, useNavigation, useOne } from "@pankod/refine-core";
import { RenderWorkflowModal } from "components/common";
import { IntakeFilter } from "components/intake/intakeFilter";
import { IntakeSubMenu } from "components/intake/intakeSubmenu";
import { ShowUserCard } from "components/profile/showUserCard";
import { IState, IUser } from "interfaces";
import { IIntake, IIntakeFilterVariables, IIntakeMetadata } from "interfaces/intake";
import { useState } from "react";
import { useLocation } from "react-router-dom";
import { DATAPROVIDER_CREATE, DATAPROVIDER_LOOKUP, DATAPROVIDER_READ, RESOURCE_PATH, STALE_DURATION, getQueryStringParams } from "scripts/site";

export const IntakeList: React.FC<IResourceComponentsProps> = () => {
  const { show } = useNavigation();
  const [filterOpen, switchFilterForm] = useState(false);
  const { search } = useLocation();
  const { programId } = getQueryStringParams(search);
  const mode: "List" | "Program" = programId ? "Program" : "List";
  //const [selectedIntakeId, setSelectedIntakeId] = useState<string>();
  const { data } = useGetIdentity();
  const user = data as IUser;

  const listFilter: CrudFilters = [
    {
      field: "_arrayfields",
      operator: "eq",
      value: "stateManager.assignedTo,smes"
    },
  ];

  const { data: entityMetadata } = useOne<IIntakeMetadata>({
    dataProviderName: DATAPROVIDER_LOOKUP,
    resource: RESOURCE_PATH.METADATA,
    id: RESOURCE_PATH.INTAKE,
    queryOptions: {
      enabled: true,
      staleTime: STALE_DURATION
    }
  });
  const metaConfig = entityMetadata?.data?.config;
  const entityStates = entityMetadata?.data?.states as IState[] ?? [];

  // const { data: activityMetadata } = useOne<IIntakeActivityMetadata>({
  //   dataProviderName: DATAPROVIDER_LOOKUP,
  //   resource: RESOURCE_PATH.METADATA,
  //   id: RESOURCE_PATH.INTAKE_ACTIVITY,
  //   queryOptions: {
  //     enabled: true,
  //     staleTime: STALE_DURATION
  //   }
  // });
  //const activityMetaConfig = activityMetadata?.data?.config;

  const { tableProps, sorter, tableQueryResult, searchFormProps, filters } = useTable<IIntake, HttpError, IIntakeFilterVariables>({
    dataProviderName: DATAPROVIDER_READ,
    initialSorter: [
      {
        field: "updatedAt",
        order: "desc"
      },
    ],
    permanentFilter: listFilter,
    onSearch: (params) => {
      const filters: CrudFilters = [];
      const { q, requestType, requestSubType, assignedTo, smes, salesExecutive, deliveryExecutive, state } = params;
      filters.push({
        field: "q",
        operator: "contains",
        value: q
      });
      filters.push({
        field: "requestType",
        operator: "eq",
        value: requestType
      });
      filters.push({
        field: "requestSubType",
        operator: "eq",
        value: requestSubType
      });
      filters.push({
        field: "stateManager.assignedTo ARRAY_CONTAINS_ANY",
        operator: "eq",
        value: assignedTo
      });
      filters.push({
        field: "smes ARRAY_CONTAINS_ANY",
        operator: "eq",
        value: smes
      });
      filters.push({
        field: "deliveryExecutive",
        operator: "eq",
        value: deliveryExecutive
      });
      filters.push({
        field: "salesExecutive",
        operator: "eq",
        value: salesExecutive
      });
      filters.push({
        field: "stateManager.state",
        operator: "eq",
        value: state
      });
      return filters;
    }
  });

  function rowClick(record: IIntake) {
    return {
      onClick: () => {
        show(RESOURCE_PATH.INTAKE, record.id);
      },
    };
  }

  // const onFormSubmit = () => {
  //   switchLogActivityForm(false);
  // };

  // const onFormCancel = () => {
  //   switchLogActivityForm(false);
  // }

  const totalRequests = tableQueryResult?.data?.total ?? 0;
  let isFiltered = filters && filters.length > listFilter.length && filters.some(fltr => fltr.value !== undefined)

  const clearFilters = () => {
    searchFormProps.form?.resetFields();
    searchFormProps.form?.submit();
  }

  // const {
  //   modalProps: createModalProps,
  //   formProps: createFormProps,
  //   show: createModal
  // } = useModalForm<IIntakeActivity>({
  //   action: "create",
  //   resource: RESOURCE_PATH.INTAKE_ACTIVITY,
  //   dataProviderName: DATAPROVIDER_CREATE,
  //   redirect: false,
  //   onMutationSuccess: () => {
  //     tableQueryResult.refetch();
  //   }
  // });

  let pageTitle = (tableQueryResult.isLoading ? "Loading" : totalRequests) + " Work item" + (totalRequests !== 1 ? "s" : "");;
  let pageTag = "";

  return (
    <List
      headerProps={{
        title: pageTitle,
        tags: <>
          {mode === "Program" && <Tag color={"default"}>
            {pageTag}
          </Tag>}
          <Button key="filter" type="default"
            icon={isFiltered ? <Icons.FilterFilled /> : <Icons.FilterOutlined />}
            style={{ paddingRight: (isFiltered ? 4 : 15) }}
            title={isFiltered ? "You are viewing a filtered list" : (entityMetadata?.data?.config?.search?.textMessages?.buttonText || "Search & Filter")}
            onClick={() => switchFilterForm(true)}>
            Search
            {isFiltered &&
              <>
                <Button
                  type="text"
                  danger
                  title={"Clear Filters"}
                  size="small"
                  style={{ marginLeft: 5 }}
                  onClick={(e) => {
                    clearFilters();
                    e.stopPropagation();
                  }}
                  icon={<Icons.CloseOutlined />}
                >
                </Button>
              </>
            }
          </Button>
          <Button
            title={"Show My work items"}
            icon={<Icons.SolutionOutlined />}
            style={{ marginLeft: 10 }}
            onClick={() => {
              searchFormProps.form?.setFieldsValue({ assignedTo: user.id });
              searchFormProps.form?.submit();
            }}
          >Assigned to me</Button>
        </>,
        extra:
          <>
            <CanAccess resource={RESOURCE_PATH.INTAKE} action="create" params={{ id: "1", dataProviderName: DATAPROVIDER_CREATE }}>
              <CreateButton icon={<Icons.PlusOutlined />}>Add</CreateButton>
            </CanAccess>
            {entityStates.length > 0 ? RenderWorkflowModal(entityStates) : <></>}
          </>
      }}
    >
      <Table {...tableProps}
        rowKey="id"
        pagination={{
          ...tableProps.pagination,
          position: ["bottomRight"],
          showTotal: (total => <Typography.Title level={4} style={{ marginRight: 10 }} >Total {total}</Typography.Title>)
        }}
      >
        <Table.Column
          dataIndex="name"
          key="name"
          title="Title"
          defaultSortOrder={getDefaultSortOrder("title", sorter)}
          sorter
          onCell={rowClick}
          className="mouseHand"
          render={(value) => <Typography.Title
            style={{
              fontWeight: "unset",
              fontSize: "14px",
            }}
            level={5}
            ellipsis={{
              rows: 2,
              tooltip: value,
            }}
          >
            {value}
          </Typography.Title>}
        />
        <Table.Column
          dataIndex="requestType"
          key="requestType"
          title="Request Type"
          onCell={rowClick}
          className="mouseHand"
          sorter
        />
        <Table.Column
          dataIndex="requestSubtype"
          key="requestSubtype"
          title="Request Sub Type"
          onCell={rowClick}
          className="mouseHand"
          sorter
        />
        <Table.Column
          dataIndex="opportunity"
          key="opportunity"
          title="Revenue Opportunity"
          onCell={rowClick}
          className="mouseHand"
          sorter
          render={(value) => <NumberField
            options={{
              currency: "USD",
              style: "currency",
              notation: "compact"
            }}
            value={value ?? 0} />}
        />
        <Table.Column
          dataIndex="requestDeadline"
          key="requestDeadline"
          title="Request Deadline"
          onCell={rowClick}
          sorter
          className="mouseHand"
          render={(value) => value && <DateField format="ll" value={value} />}
        />
        <Table.Column
          dataIndex="businessGroup"
          key="businessGroup"
          title="Business Group"
          onCell={rowClick}
          sorter
          className="mouseHand"
        />
        <Table.Column
          dataIndex={["stateManager", "assignedTo"]}
          key="stateManager.assignedTo"
          title="Assigned To"
          onCell={rowClick}
          className="mouseHand"
          render={(value: string[]) =>
            value && value.map((r, i) => (
              <div key={i} style={{ marginBottom: 5 }}>
                <ShowUserCard id={r} />
              </div>
            ))
          }
        />
        <Table.Column
          dataIndex={["stateManager", "state"]}
          key="stateManager.state"
          title="State"
          onCell={rowClick}
          className="mouseHand"
          sorter
          render={(value) => <TextField value={stringExtensions.capitalize(value)} />}
        />
        <Table.Column<IIntake>
          dataIndex="actions"
          title=""
          render={(_, record) =>
            <IntakeSubMenu intakeId={record?.id} selectedResource={RESOURCE_PATH.INTAKE_ACTIVITY} renderAsMenu={false} hideOthers={true} hideText={false} />
          }
        />

      </Table>
      <Drawer
        title={metaConfig?.search?.textMessages?.drawerFormHeader || "Search & Filter"}
        placement="right"
        size="default"
        forceRender={true}
        open={filterOpen}
        onClose={() => { switchFilterForm(false) }}
      >
        <IntakeFilter formProps={searchFormProps}
          onApplyFilter={() => switchFilterForm(false)} />
      </Drawer>
      {/* <IntakeActivityCreate
        modalProps={createModalProps}
        intakeId={selectedIntakeId}
        formProps={createFormProps}
      /> */}
    </List>
  );
};