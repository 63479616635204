import { Statistic } from "@pankod/refine-antd";
import { BaseRecord, HttpError, useList } from "@pankod/refine-core";
import { UseListProps } from "@pankod/refine-core/dist/hooks/data/useList";

export const StatsTile: React.FC<{
    listProps: UseListProps<BaseRecord, HttpError>,
    title?: string,
}> = ({ listProps, title = "" }) => {

    const { data: enggProgCount, isLoading } = useList(listProps);
    return <Statistic title={title} value={(enggProgCount?.data?.toString() ?? "0")} loading={isLoading} />
}