import { buildAttachmentUrl, RESOURCE_PATH, useUrlFromBlob } from "scripts/site";
import { RenderSpinner } from "./spinner";

export declare type pdfProps = {
    pdfFilePath: string;
    resourceName: string;
};

export const RenderPDFFile: React.FC<pdfProps> = ({
    pdfFilePath,
    resourceName
}) => {
    const pdfFileAttachment = buildAttachmentUrl(RESOURCE_PATH.METADATA, resourceName, pdfFilePath);
    const { response: pdfUrl } = useUrlFromBlob(pdfFileAttachment);
    return (
        pdfUrl ?
            <object data={pdfUrl} type="application/pdf" width="100%" height="800px">
                <p>View <a href={pdfUrl}>PDF in another tab!</a></p>
            </object>
            :
            <RenderSpinner message="Loading content..." />
    );
};