import { Avatar, Card, Col, Divider, Empty, Row, Spin } from '@pankod/refine-antd';
import { useList, useNavigation } from '@pankod/refine-core';
import { IUser } from 'interfaces';
import { DATAPROVIDER_READ, FETCH_ACTION, HELIUM_LOGO_PATH, RESOURCE_PATH } from 'scripts/site';

export const OrgChart: React.FC<IUser> = (record: IUser) => {

    const threeColumnLayout = { xs: { span: 24 }, sm: { span: 24 }, md: { span: 8 }, lg: { span: 8 }, xl: { span: 8 }, xxl: { span: 8 } };
    const rowGutter = { xs: 8, sm: 16, md: 24, lg: 32 };
    const { show } = useNavigation();
    const { data: reportsToData, isLoading: resportsToLoading } = useList<IUser>({
        dataProviderName: DATAPROVIDER_READ,
        resource: `${RESOURCE_PATH.PEOPLE}/${record.id}/${FETCH_ACTION}`,
        config: {
            filters: [
                {
                    field: "operation",
                    operator: "eq",
                    value: "leads"
                }
            ]
        }
    });

    const { data: reporteesData, isLoading: reporteesToLoading } = useList<IUser>({
        dataProviderName: DATAPROVIDER_READ,
        resource: `${RESOURCE_PATH.PEOPLE}/${record.id}/${FETCH_ACTION}`,
        config: {
            filters: [
                {
                    field: "operation",
                    operator: "eq",
                    value: "reportee"
                }
            ]
        }
    });

    const reportees = reporteesData?.data;

    return (
        <div className='org-chart'>
            <Spin spinning={resportsToLoading}>
                {reportsToData &&
                    <Row gutter={rowGutter}>
                        {reportsToData.data.map((rep, ndx) => {
                            return (
                                <>
                                    <Col key={`col-${ndx}-1`} className="gutter-row" {...threeColumnLayout}></Col>
                                    <Col key={`col-${ndx}-2`} className="gutter-row" {...threeColumnLayout}>
                                        <Card className="prntcrd" key={`prnt-${ndx}`} hoverable={true} onClick={() => show(RESOURCE_PATH.PEOPLE, rep.id)}>
                                            <Card.Meta
                                                avatar={<Avatar src={rep?.userImages?.find(img => img.uid === rep.userProfileImage)?.url || HELIUM_LOGO_PATH} />}
                                                title={rep.name}
                                                description={rep.headline}
                                            />
                                        </Card>
                                        <Divider type="vertical" key={`dvdr-${ndx}`} style={{ marginLeft: "50%", fontSize: 24, borderWidth: 3 }} />
                                    </Col>
                                    <Col key={`col-${ndx}-3`} className="gutter-row" {...threeColumnLayout}></Col>
                                </>
                            );
                        })}
                    </Row>
                }
            </Spin>
            <Row gutter={rowGutter}>
                <Col className="gutter-row" {...threeColumnLayout}></Col>
                <Col className="gutter-row" {...threeColumnLayout}>
                    <Card className="usrcrd" key="usr-crd" style={{ borderColor: "#663399" }}>
                        <Card.Meta
                            avatar={<Avatar src={record?.userImages?.find(img => img.uid === record.userProfileImage)?.url || HELIUM_LOGO_PATH} />}
                            title={record.name}
                            description={record.headline}
                        />
                    </Card>
                    <Divider type="vertical" style={{ marginLeft: "50%", fontSize: 24, borderWidth: 3 }} />
                </Col>
                <Col className="gutter-row" {...threeColumnLayout}></Col>
            </Row>
            <Spin spinning={reporteesToLoading}>
                <Divider orientation="left" plain>People reporting to {record.name} ({reporteesData?.data.length})</Divider>
                {reportees && reportees.length > 0 ?
                    <Row gutter={rowGutter}>
                        {reportees.map((rep, ndx) => {
                            return (
                                <Col className="gutter-row" {...threeColumnLayout}>
                                    <Card className="repcrds" key={`rep-${ndx}`} style={{ marginBottom: "1rem" }} hoverable={true} onClick={() => show(RESOURCE_PATH.PEOPLE, rep.id)}>
                                        <Card.Meta
                                            avatar={<Avatar src={rep?.userImages?.find(img => img.uid === rep.userProfileImage)?.url || HELIUM_LOGO_PATH} />}
                                            title={rep.name}
                                            description={rep.headline}
                                        />
                                    </Card>
                                </Col>
                            );
                        })}
                    </Row> :
                    <Empty
                        image={Empty.PRESENTED_IMAGE_SIMPLE}
                        description={reporteesToLoading ? "Loading Reportees" : "No Reportee"}
                    />
                }
            </Spin>
        </div>
    );
};