import { arrayExtensions, stringExtensions } from "@architecture-innovation-transformation/lib-common";
import { Button, Card, Form, Icons, Input, Radio, Select, Upload, getValueFromEvent, message, useFileUploadState } from "@pankod/refine-antd";
import { useApiUrl } from "@pankod/refine-core";
import MDEditor from "@uiw/react-md-editor";
import { image } from "interfaces";
import { ImageParagraphAlignment, MediaType } from "interfaces/enum";
import { useEffect, useState } from "react";
import { DATAPROVIDER_UPLOAD } from "scripts/site";
import { getAppTheme } from "scripts/theme";

export const RenderParagraphWithImage: React.FC<{
  resourcePath: string;
  onUploadChange: (info: any) => void;
  images?: image[];
  paragraphFieldName?: string;
  imagesFieldName?: string;
  featuredImage: boolean
}> = ({ resourcePath, onUploadChange, images, featuredImage, paragraphFieldName = "body", imagesFieldName = "images" }) => {

  const [imageList, setImageList] = useState([{ label: "No Image Uploaded", value: "no-image" }]);
  const apiUrl = useApiUrl(DATAPROVIDER_UPLOAD);

  useEffect(() => {
    if (images && arrayExtensions.validateArray(images)) {
      setImageList(images.map(ut => ({ label: stringExtensions.capitalize(ut.name), value: ut.uid })));
    }
  }, [images])

  const { onChange: defaultOnUploadChange } = useFileUploadState();

  return (<>
    <Form.Item
      label="Upload images"
      name={imagesFieldName}
      valuePropName="fileList"
      getValueFromEvent={getValueFromEvent}
      tooltip="Upload images to be used in this page, minimum image dimension should be 128x128 for better visual results."
      rules={[
        {
          required: false,
          validator(_, value) {
            if (value) {
              setImageList(value.map((ut: any) => ({ label: stringExtensions.capitalize(ut.name), value: ut.uid })))
            }
            return Promise.resolve();
          }
        }
      ]}
    >
      <Upload
        name="file"
        action={`${apiUrl}/${resourcePath}/${MediaType.ArticleImage}`}
        listType="picture-card"
        onChange={onUploadChange ?? defaultOnUploadChange}
        maxCount={10}
        beforeUpload={(file) => {
          const isImageFile = file.type.search('image') === 0;
          if (!isImageFile) {
            message.error(`${file.name} is not an image file`);
          }
          return isImageFile;
        }}
      >
        <div>
          <Icons.PlusOutlined />
          <div style={{ marginTop: 8 }}>Upload</div>
        </div>
      </Upload>
    </Form.Item>
    {featuredImage === true &&
      <Form.Item
        label="Featured Image"
        name="featuredImage"
        tooltip="Select the image to be used for as poster image"
        rules={[
          {
            required: false,
            whitespace: true
          }
        ]}
      >
        <Select placeholder="Select image from list of uploaded images" >
          {imageList.map((m, i) => (
            <Select.Option value={m.value} key={i}>{m.label}</Select.Option>
          ))}
        </Select>
      </Form.Item>
    }
    <Form.List
      name={paragraphFieldName}
      rules={[
        {
          validator: async (_, body) => {
            if (!body || body.length < 1) {
              return Promise.reject(new Error('Minimum 1 paragraph is required'));
            }
          },
        },
      ]}
    >
      {(fields, { add, remove }, { errors }) => (
        <>
          {fields.map((field, index) => (
            <Card
              style={{ marginBottom: 10 }}
              size="small"
              title={`Paragraph ${index + 1}`}
              key={field.key}
              extra={
                <Button
                  type="text"
                  danger
                  title={"Remove Paragraph"}
                  size="small"
                  //style={{ marginLeft: 5 }}
                  onClick={(e) => {
                    remove(field.name);
                  }}
                  icon={<Icons.CloseOutlined />}
                >
                </Button>
              }
            >
              <Form.Item
                label="Content"
                name={[index, "paragraph"]}
                tooltip="Provide the content for Paragraph"
                rules={[
                  {
                    required: true
                  },
                ]}
              >
                <MDEditor height={250} data-color-mode={getAppTheme()} />
              </Form.Item>
              <Form.Item
                label="Associate image with this paragraph"
                name={[index, "isImageAdded"]}
                tooltip="Associate an image with this paragraph"
                initialValue={false}
                rules={[
                  {
                    type: "boolean",
                    required: true
                  }
                ]}
              >
                <Radio.Group
                  optionType="button"
                  buttonStyle="solid"
                  name="isImageAdded"
                >
                  <Radio key="cd-01" value={true}>Yes</Radio>
                  <Radio key="cd-02" value={false}>No</Radio>
                </Radio.Group>
              </Form.Item>
              <Form.Item
                noStyle
                shouldUpdate={(prevValues, currentValues) => prevValues[paragraphFieldName][index].isImageAdded !== currentValues[paragraphFieldName][index].isImageAdded}
              >
                {({ getFieldValue }) =>
                  getFieldValue([paragraphFieldName, index, "isImageAdded"]) === true ? (
                    <>
                      <Form.Item
                        label="Image"
                        name={[index, "paraImage"]}
                        rules={[
                          {
                            required: true,
                            whitespace: true
                          }
                        ]}
                      >
                        <Select placeholder="Select image from list of uploaded images" >
                          {imageList.map((m, i) => (
                            <Select.Option value={m.value} key={i}>{m.label}</Select.Option>
                          ))}
                        </Select>
                      </Form.Item>
                      <Form.Item
                        label="Image alignment with respect to paragraph"
                        name={[index, "alignment"]}
                        initialValue="ImageAboveParagraph"
                        rules={[
                          {
                            required: false,
                            whitespace: true
                          }
                        ]}
                      >
                        <Radio.Group
                          optionType="button"
                          buttonStyle="solid"
                          name="alignment"
                          options={Object.entries(ImageParagraphAlignment)?.map(ut => ({
                            label: ut[1].toString(),
                            value: ut[0].toString()
                          }))}
                        />
                      </Form.Item>
                      <Form.Item
                        label="Image Title"
                        name={[index, "imageTitle"]}
                        tooltip="Image title will be display below the image and can be used to reference image elsewhere."
                        rules={[
                          {
                            required: false,
                            whitespace: true
                          }
                        ]}
                      >
                        <Input placeholder="Provide title for image, this will be displayed below image" />
                      </Form.Item>
                    </>
                  ) : null
                }
              </Form.Item>
            </Card>
          ))}
          <Form.Item>
            <Button
              type="dashed"
              style={{ width: "100%" }}
              onClick={() => add()}
              icon={<Icons.PlusOutlined />}
            >
              Add Paragraph
            </Button>
            <Form.ErrorList errors={errors} />
          </Form.Item>
        </>
      )}
    </Form.List>
  </>
  );
};