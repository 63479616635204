import { IResourceComponentsProps } from "@pankod/refine-core";
import { useLocation } from "react-router-dom";
import { getQueryStringParams } from "scripts/site";
import { EnggSolutionList } from "./solutionList";

export const EngagementSolutionList: React.FC<IResourceComponentsProps> = () => {
  const { search } = useLocation();
  const { engagementId, programId } = getQueryStringParams(search);

  return (<EnggSolutionList engagementId={engagementId} programId={programId} />);
};