import { Col, Divider, Row, Typography } from "@pankod/refine-antd";
import { IUserEmployee } from "interfaces";
import { arrayJoinString } from "scripts/site";
const { Title, Text } = Typography;

export const DisplayRnRTab: React.FC<IUserEmployee> = (record: IUserEmployee) => {

    return (
        <>
            <Row gutter={[16, 16]}>
                <Col xl={12} xs={24}>
                    <Divider orientation="left" dashed >Certifications</Divider>

                    <Title level={5}>Technical Certifications</Title>
                    <Text>{arrayJoinString(record?.technicalCertificationList ?? [])}</Text>

                    <Title level={5}>Functional Certifications</Title>
                    <Text>{arrayJoinString(record?.functionalCertificationList ?? [])}</Text>

                </Col>
                <Col xl={12} xs={24}>
                    <Divider orientation="left" dashed>Hall of fame</Divider>

                    <Title level={5}>Contextual Master</Title>
                    <Typography.Text>{record?.contextualMaster ? "Yes" : "No"}</Typography.Text>

                    <Title level={5}>Contextual Master Story</Title>
                    <Typography.Text>{record?.contextualMasterStory ?? "---"}</Typography.Text>

                </Col>
            </Row>
        </>
    );
};