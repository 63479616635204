import { Col, Divider, Row, Typography } from "@pankod/refine-antd";
import { ICandidateBA, ICandidateExternal } from "interfaces/resourcing";
import { rowGutter, threeColumnLayout } from "scripts/layout";
import { doesExist } from "scripts/site";

export const DisplayExternalCandidate: React.FC<ICandidateExternal | ICandidateBA> = (record: ICandidateExternal | ICandidateBA) => {
    const { Title, Text } = Typography;

    return (
        <>
            <Divider orientation="left">{record.candidateType} Candidate Details</Divider>
            <Row gutter={rowGutter}>
                <Col className="gutter-row" {...threeColumnLayout}>
                    {record && record.candidateType === "External" &&
                        <>
                            <Title level={5}>Rate Yearly (In USD)</Title>
                            <Text copyable>{(record as ICandidateExternal)?.yearlyRate ?? "---"}</Text>
                        </>
                    }
                    {record && record.candidateType === "BA" &&
                        <>
                            <Title level={5}>Rate Hourly (In USD)</Title>
                            <Text copyable>{(record as ICandidateBA)?.hourlyRate ?? "---"}</Text>
                        </>
                    }
                    <Title level={5}>RGS ID</Title>
                    <Text copyable={doesExist(record?.rgsId)}>{record?.rgsId ?? "---"}</Text>
                </Col>
                <Col className="gutter-row" {...threeColumnLayout}>
                    <Title level={5}>Work Authorization Type</Title>
                    <Text copyable={doesExist(record?.workAuthorizationType)}>{record?.workAuthorizationType ?? "---"}</Text>
                    <Title level={5}>Prior Microsoft ID</Title>
                    <Text copyable={doesExist(record?.priorMicrosoftID)}>{record?.priorMicrosoftID ?? "---"}</Text>
                </Col>
                <Col className="gutter-row" {...threeColumnLayout}>
                    <Title level={5}>Source</Title>
                    <Text copyable={doesExist(record?.profileSource)}>{record?.profileSource ?? "---"}</Text>
                    <Title level={5}>Screening Availability</Title>
                    <Text copyable={doesExist(record?.screeningAvailability)}>{record?.screeningAvailability ?? "---"}</Text>
                </Col>
            </Row>
        </>
    );
};