import { NumberField, TextField } from "@pankod/refine-antd";
import { IScore, IUIProp } from "interfaces";

export declare type DisplayScoreProps = {
    ui: IUIProp;
    output: IScore,
    override?: boolean;
    overrideScore?: number,
};
export const DisplayScore: React.FC<DisplayScoreProps> = ({
    ui,
    output,
    override,
    overrideScore,
    ...rest
}) => {

    var isScoreDisplay = (override || ui.field === 'score');
    var uiType:any;

    if(override || ui.field === 'score')
    {
        var scoreValue = overrideScore ?? output.score;
        uiType = ui.cutoffs?.sort(v => v.value).find(val => scoreValue>= val.value)?.displayMode;

    }else
    {
        uiType = ui.cutoffs?.sort(v => v.value).find(val => output.score >= val.value)?.displayMode;
    }
    
    return (
        <>
        {isScoreDisplay?
        <NumberField            
            strong
            {...rest}
            type={uiType}
            options={{
                style: "percent",
                maximumFractionDigits: 0
            }}
            value={(override? overrideScore: output.score) || 0} />
            :
            <TextField            
            strong
            {...rest}
            type={uiType}
            value={output.maturityLevel || "None"} />
        }
    </>
    );
};