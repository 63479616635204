import { Descriptions, Divider, Tag, Typography } from "@pankod/refine-antd";
import { PositionList } from "components/resourcing/positionList";
import { IResourcingRequest } from "interfaces/resourcing";

export const RequestExpand: React.FC<IResourcingRequest> = (record) => {
    const { Text } = Typography;
    let numPositions = record?.positions ? record.positions.length : 0
    return (
        <>
            <Descriptions labelStyle={{ fontWeight: "bold" }} size="small" style={{marginLeft: "3%"}}>
                <Descriptions.Item label="WON" >
                    <Text copyable={record?.projectWON !== undefined}>{record?.projectWON ?? "---"}</Text>
                </Descriptions.Item>
                <Descriptions.Item label="CRM/Opportunity ID" >
                    <Text copyable={record?.crmOpportunityId !== undefined}>{record?.crmOpportunityId ?? "---"}</Text>
                </Descriptions.Item>
                <Descriptions.Item label="Request ID" >
                    <Text copyable={record?.requestID !== undefined}>{record?.requestID ?? "To Be Published"}</Text>
                </Descriptions.Item>
                <Descriptions.Item label="Group" >
                    <Text copyable={record?.group !== undefined}>{record?.group ?? "---"}</Text>
                </Descriptions.Item>
                <Descriptions.Item label="Sub Group" >
                    <Text copyable={record?.subGroup !== undefined}>{record?.subGroup ?? "---"}</Text>
                </Descriptions.Item>
                <Descriptions.Item label="Billable/Non-Billable" >
                    <Text copyable={record?.billable !== undefined}>{record?.billable ?? "---"}</Text>
                </Descriptions.Item>
            </Descriptions>
            <Divider dashed orientation="left">
                <Tag color={(record.positions ? "blue" : "red")}>
                    {numPositions} Position{numPositions !== 1 && "s"}
                </Tag>
            </Divider>
            {numPositions > 0 && <PositionList requestId={record?.id} requestor={record?.requestor}/>}
        </>
    );
};