import { arrayExtensions } from "@architecture-innovation-transformation/lib-common";
import { Divider, EditButton, MarkdownField, Show, Table, Typography } from "@pankod/refine-antd";
import { CanAccess, IResourceComponentsProps, useShow } from "@pankod/refine-core";
import MDEditor from "@uiw/react-md-editor";
import { ShowUserCard } from "components/profile/showUserCard";
import { ProgramSubMenu } from "components/program/subMenu";
import { displayRelativeDate } from "components/utils/displayRelativeDate";
import { IProgram } from "interfaces";
import { useLocation } from "react-router-dom";
import { DATAPROVIDER_READ, DATAPROVIDER_UPDATE, RESOURCE_PATH, THINK_GEN_AI_LOGO_BLACK, THINK_GEN_AI_LOGO_WHITE, buildAttachmentUrl, getQueryStringParams, greyStyle, useAttachment } from "scripts/site";
import { getAppTheme } from "scripts/theme";

const { Title, Text } = Typography;

export const ProgramShow: React.FC<IResourceComponentsProps> = () => {
    const { queryResult, showId } = useShow<IProgram>({
        dataProviderName: DATAPROVIDER_READ
    });
    const { data, isLoading } = queryResult;
    const record = data?.data;

    const { search } = useLocation();
    const { tabId } = getQueryStringParams(search);

    const selectedTab = tabId || "okr";

    const groupedTasks = arrayExtensions.groupBy(record?.tasks ?? [], "group");
    const groupedJourney = arrayExtensions.groupBy(record?.journey ?? [], "group");

    const mdUrl = buildAttachmentUrl(RESOURCE_PATH.PROGRAM, record?.id, record?.mdPath);

    const { response: mdData } = useAttachment(mdUrl);

    return (
        <div className="engagement">
            <Show
                isLoading={isLoading}
                canDelete={false}
                title={record?.name}
                headerProps={{
                    extra: <>
                        <img src={getAppTheme() === "dark" ? THINK_GEN_AI_LOGO_WHITE : THINK_GEN_AI_LOGO_BLACK} alt="Think GenAI" style={{
                            height: 30,
                            width: 150,
                        }}></img>
                        <CanAccess key={RESOURCE_PATH.ENGAGEMENT} resource={RESOURCE_PATH.ENGAGEMENT.toLowerCase()} action="edit" params={{ id: showId, dataProviderName: DATAPROVIDER_UPDATE }}>
                            <EditButton></EditButton>
                        </CanAccess>
                    </>,
                    backIcon: false
                }}
            >
                <ProgramSubMenu programId={showId?.toString() || ""}
                    selectedResource={RESOURCE_PATH.PROGRAM} tabId={selectedTab}
                ></ProgramSubMenu>
                <div style={{ margin: 24 }}>
                    {selectedTab === "okr" &&
                        <>
                            <Title level={4}>Objective</Title>
                            <div className="container" data-color-mode={getAppTheme()}>
                                <MDEditor.Markdown source={record?.objective} />
                            </div>
                            {/* <Divider dashed orientation="left">Key Results</Divider> */}
                            <Table style={{ marginTop: 20 }}
                                dataSource={arrayExtensions.sortBy("order", record?.keyResults ?? [])} size="small"
                                bordered={true}
                                key={"krTable"} rowKey={"id"}
                                pagination={false}>
                                <Table.Column
                                    dataIndex="name"
                                    key="name"
                                    title="Key Result"
                                />
                                <Table.Column
                                    dataIndex="description"
                                    key="description"
                                    title="Description"
                                />
                                <Table.Column
                                    dataIndex="target"
                                    key="target"
                                    title="Target"
                                />
                            </Table>
                        </>
                    }

                    {selectedTab === "tasks" &&
                        <>
                            {arrayExtensions.validateArray(record?.tasks ?? [])
                                ? Object.keys(groupedTasks)
                                    .map((k, ind) =>
                                        <div key={ind}>
                                            <Title level={5}>{k}</Title>
                                            <ul>
                                                {arrayExtensions.sortBy("order", groupedTasks[k] ?? [])
                                                    .map((r, i) =>
                                                    (<div style={{ marginBottom: 10 }}>
                                                        <li key={i}>
                                                            <Text>{`${r.name}`}</Text>
                                                            {r.description && <>
                                                                <br />
                                                                <Text style={greyStyle}>{r.description}</Text>
                                                            </>}
                                                        </li>
                                                    </div>))}
                                            </ul>
                                        </div>)
                                : "None"}
                        </>
                    }
                    {selectedTab === "journey" &&
                        <>
                            {arrayExtensions.validateArray(record?.journey ?? [])
                                ? Object.keys(groupedJourney)
                                    .map((k, ind) =>
                                        <div key={ind}>
                                            <Title level={5}>{k}</Title>
                                            <ul>
                                                {arrayExtensions.sortBy("order", groupedJourney[k] ?? [])
                                                    .map((r, i) =>
                                                    (<div style={{ marginBottom: 10 }}>
                                                        <li key={i}>
                                                            <Text>{`${r.name}`}</Text>
                                                            {r.description && <>
                                                                <br />
                                                                <Text style={greyStyle}>{r.description}</Text>
                                                            </>}
                                                        </li>
                                                    </div>))}
                                            </ul>
                                        </div>)
                                : "None"}
                        </>
                    }
                    {selectedTab === "help" &&
                        <div className={"markdown"}>
                            <MarkdownField value={mdData || "# Loading..."} ></MarkdownField>
                        </div>
                    }
                    <Divider></Divider>
                    <div style={{ marginTop: 15 }}>
                        <Typography.Text style={greyStyle}>Created by </Typography.Text>
                        <ShowUserCard id={record?.createdBy}></ShowUserCard>
                        <br />
                        <Typography.Text style={greyStyle}>Last Updated </Typography.Text>
                        {displayRelativeDate(new Date((record?._ts ?? 0) * 1000))}
                    </div>
                </div >
            </Show >
        </div >
    );
};


