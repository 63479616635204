import { ILookup } from "@architecture-innovation-transformation/lib-common";
import { Button, Form, FormProps, Icons, Input, Select, Space, useSelect } from "@pankod/refine-antd";
import { useOne } from "@pankod/refine-core";
import { RenderSpinner } from "components/common";
import { IPromptMetadata } from "interfaces/article";
import { DATAPROVIDER_LOOKUP, RESOURCE_PATH, STALE_DURATION } from "scripts/site";

export const PromptFilter: React.FC<{
    formProps: FormProps,
    onApplyFilter: () => void,
}> = (props) => {
    const { isLoading, data: metadata } = useOne<IPromptMetadata>({
        dataProviderName: DATAPROVIDER_LOOKUP,
        resource: RESOURCE_PATH.METADATA,
        id: RESOURCE_PATH.PROMPT,
        queryOptions: {
            enabled: true,
            staleTime: STALE_DURATION
        }
    });

    const { selectProps: userProps } = useSelect<ILookup>({
        dataProviderName: DATAPROVIDER_LOOKUP,
        resource: RESOURCE_PATH.PEOPLE,
        optionLabel: "nameEmail",
        optionValue: "id",
        sort: [
            {
                field: "lastAccessedAt",
                order: "desc"
            },
        ],
        filters: [{
            field: "stateManager.state",
            operator: "eq",
            value: "active"
        }],
        fetchSize: 50
    });
    const metaConfig = metadata?.data.config;
    return (
        isLoading ? <RenderSpinner message="Loading..." ></RenderSpinner> :
            <Form layout="vertical" {...props.formProps} autoComplete="off">
                <Form.Item
                    label={"Search"}
                    name="q"
                    tooltip={metaConfig?.search?.textMessages?.searchTextPlaceholder || "Search Prompts"}
                >
                    <Input
                        placeholder={metaConfig?.search?.textMessages?.searchTextPlaceholder || "Search Prompts"}
                        prefix={<Icons.SearchOutlined />}
                    />
                </Form.Item>
                <Form.Item
                    label="Applicable Platforms"
                    name="applicableTo">
                    <Select mode="multiple"
                        placeholder="Platforms"
                        options={((metaConfig?.copilots || []).concat(metaConfig?.models || [])).sort().map((m, i) => ({ value: m, label: m }))}
                        allowClear>
                    </Select>
                </Form.Item>
                <Form.Item
                    label="Authors"
                    name="authorName"
                    tooltip="To look up any missing name type the name of the person and then select"
                >
                    <Select
                        allowClear
                        mode="multiple"
                        placeholder="Authors"
                        {...userProps}
                    />
                </Form.Item>
                <Form.Item>
                    <Space>
                        <Button
                            //style={{ width: "100%" }}
                            htmlType="submit"
                            type="primary"
                            icon={<Icons.FilterOutlined />}
                            onClick={() => {
                                props.onApplyFilter();
                            }}
                        >
                            Apply
                        </Button>
                        <Button
                            //style={{ width: "100%" }}
                            icon={<Icons.ClearOutlined />}
                            htmlType="submit"
                            onClick={() => { props.formProps.form?.resetFields() }}
                        >
                            Clear
                        </Button>
                    </Space>
                </Form.Item>
            </Form>
    );
}