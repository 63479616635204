import { List, Typography } from "@pankod/refine-antd";
import { IResourceComponentsProps } from "@pankod/refine-core";


export const EvaluationList: React.FC<IResourceComponentsProps> = () => {
  return (
    <List canCreate={false}>
      <Typography.Title level={5} type="danger">Evaluations must be accessed via Engagement details page</Typography.Title>
    </List>
  );
};
