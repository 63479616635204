import { Button, Col, Create, Form, Icons, Input, Radio, RcFile, Row, SaveButton, Select, Upload, getValueFromEvent, message, useForm, useSelect } from "@pankod/refine-antd";
import { IResourceComponentsProps, useApiUrl, useGetIdentity, useOne } from "@pankod/refine-core";
import { CancelButton } from "components/utils/cancelButton";
import { ILookup, IUser } from "interfaces";
import { IOffering, IOfferingMetadata } from "interfaces/article";
import { useState } from "react";
import { DATAPROVIDER_CREATE, DATAPROVIDER_LOOKUP, DATAPROVIDER_UPLOAD, RESOURCE_PATH, STALE_DURATION } from "scripts/site";

export const OfferingCreate: React.FC<IResourceComponentsProps> = () => {
  const { data } = useGetIdentity();
  const user = data as IUser;

  const { formProps, saveButtonProps } = useForm<IOffering>({
    dataProviderName: DATAPROVIDER_CREATE
  });

  const { selectProps: usersList } = useSelect<ILookup>({
    dataProviderName: DATAPROVIDER_LOOKUP,
    resource: RESOURCE_PATH.PEOPLE,
    optionLabel: "nameEmail",
    optionValue: "id",
    filters: [{
      field: "stateManager.state",
      operator: "eq",
      value: "active"
    }],
    sort: [{
      field: "lastAccessedAt",
      order: "desc"
    }]
  });

  const { selectProps: serviceLineList } = useSelect<ILookup>({
    dataProviderName: DATAPROVIDER_LOOKUP,
    resource: RESOURCE_PATH.SERVICELINE,
    optionLabel: "name",
    optionValue: "id",
    sort: [{
      field: "name",
      order: "asc"
    }]
  });

  const { data: metadata } = useOne<IOfferingMetadata>({
    dataProviderName: DATAPROVIDER_LOOKUP,
    resource: RESOURCE_PATH.METADATA,
    id: RESOURCE_PATH.OFFERING,
    queryOptions: {
      enabled: true,
      staleTime: STALE_DURATION
    }
  });

  const metaConfig = metadata?.data.config;
  const apiUrl = useApiUrl(DATAPROVIDER_UPLOAD);
  const threeColumnLayout = { xs: { span: 24 }, sm: { span: 24 }, md: { span: 8 }, lg: { span: 8 }, xl: { span: 8 }, xxl: { span: 8 } };
  const rowGutter = { xs: 8, sm: 16, md: 24, lg: 32 };
  const [validateForm] = useState<boolean>(true);

  return (
    <Create
      title="Create an Offering"
      footerButtons={<>
        <SaveButton {...saveButtonProps}>Save draft</SaveButton>
        <CancelButton />
      </>}

    >
      <Form {...formProps} layout="vertical" autoComplete="off"
        initialValues={{
          authorName: user?.id ? [user?.id] : []
        }}
        scrollToFirstError={true}
      >
        <Form.Item
          label="Title"
          name="title"
          rules={[
            {
              required: true,
              whitespace: true,
              min: 5,
              max: 250
            },
          ]}
        >
          <Input placeholder="Title for the offering" />
        </Form.Item>
        <Row gutter={rowGutter}>
          <Col className="gutter-row" {...threeColumnLayout}>
            <Form.Item
              label="Offering Type"
              name="offeringtype"
              rules={[
                {
                  required: true,
                  whitespace: true,
                  message: "Please select the offering type."
                },
              ]}
            >
              <Radio.Group
                optionType="button"
                buttonStyle="solid"
                options={(metaConfig?.offeringtype ?? []).map(a => a)}
              />
            </Form.Item>
          </Col>
          <Col className="gutter-row" {...threeColumnLayout}>
            <Form.Item
              label="Author(s)"
              name="authorName"
              rules={[
                {
                  required: true,
                  whitespace: true,
                  type: "array",
                  min: 1,
                  max: 3,
                  message: "Minimum 1 and maximum of 3 authors"
                },
              ]}
            >
              <Select placeholder="Search Name or Email" mode="multiple" {...usersList} allowClear />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={rowGutter}>
          <Col className="gutter-row" {...threeColumnLayout}>
            <Form.Item
              label="Description"
              name="description"
              rules={[
                {
                  required: true,
                  whitespace: true,
                  min: 15,
                  max: 2500
                },
              ]}
            >
              <Input.TextArea rows={2} placeholder="Short description about the offering" />
            </Form.Item>
          </Col>
          <Col className="gutter-row" {...threeColumnLayout}>
            <Form.Item
              label="Value Proposition"
              name="valueproposition"
              rules={[
                {
                  required: true,
                  whitespace: true,
                  min: 15,
                  max: 2500
                },
              ]}
            >
              <Input.TextArea rows={2} placeholder="Explain value proposition the offering would deliver" />
            </Form.Item>
          </Col>
          <Col className="gutter-row" {...threeColumnLayout}>
            <Form.Item
              label="Value Dimensions"
              name="valueDimensions"
              rules={[
                {
                  required: true,
                  type: "array",
                  min: 1,
                },
              ]}
            >
              <Select
                placeholder="Search Value Dimension. You can add as many as you want."
                mode="multiple"
                options={(metaConfig?.valueDimensions || []).map((m, i) => ({ value: m, label: m }))}
                allowClear
              >
              </Select>
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={rowGutter}>
          <Col className="gutter-row" {...threeColumnLayout}>
            <Form.Item
              name="licensingmodel"
              label="Licensing Model"
              rules={[
                {
                  required: true,
                  whitespace: true,
                  message: "Please select the licensing model for this offering."
                },
              ]}
            >
              <Radio.Group
                optionType="button"
                buttonStyle="solid"
                options={(metaConfig?.licensingmodel ?? []).map(a => a)}
              />
            </Form.Item>
          </Col>
          <Col className="gutter-row" {...threeColumnLayout}>
            <Form.Item
              name="prodimplementations"
              label="Number of Production Implementations"
              rules={[
                {
                  required: true,
                  whitespace: true,
                  message: "Please select the licensing model for this offering."
                },
              ]}
            >
              <Radio.Group
                optionType="button"
                buttonStyle="solid"
                options={(metaConfig?.prodimplementations ?? []).map(a => a)}
              />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={rowGutter}>
          <Col className="gutter-row" {...threeColumnLayout}>
            <Form.Item
              label="Service Lines"
              name="serviceLineIds"
              rules={[
                {
                  required: true,
                  whitespace: true,
                  type: "array",
                  min: 1,
                },
              ]}
            >
              <Select placeholder="Search Service Lines" mode="multiple" {...serviceLineList} allowClear />
            </Form.Item>
          </Col>
          <Col className="gutter-row" {...threeColumnLayout}>
            <Form.Item
              label="Fulfilled via Technology"
              name="technology"
              rules={[
                {
                  required: false
                },
              ]}
            >
              <Input.TextArea rows={2} placeholder="List the technologies used in this offering." />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={rowGutter}>
          <Col className="gutter-row" {...threeColumnLayout}>
            <Form.Item
              label="Owning Unit"
              name="owningunit"
              rules={[
                {
                  required: true,
                  whitespace: true,
                  min: 1,
                  max: 250
                },
              ]}
            >
              <Input placeholder="Name of the unit that owns the offering" />
            </Form.Item>
          </Col>
          <Col className="gutter-row" {...threeColumnLayout}>
            <Form.Item
              label="Owning Account"
              name="owningaccount"
              rules={[
                {
                  required: true,
                  whitespace: true,
                  min: 1,
                  max: 250
                },
              ]}
            >
              <Input placeholder="Name of the account within the unit that owns the offering" />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={rowGutter}>
          <Col className="gutter-row" {...threeColumnLayout}>
            <Form.Item
              label="Point of Contact (First and Last Name)"
              name="pointofcontact"
              rules={[
                {
                  required: true,
                  whitespace: true,
                  min: 1,
                  max: 250
                },
              ]}
            >
              <Input placeholder="First and last name of the key point of contact for this offering." />
            </Form.Item>
          </Col>
          <Col className="gutter-row" {...threeColumnLayout}>
            <Form.Item
              label="Point of Contact (Email)"
              name="pointofcontactemail"
              rules={[
                {
                  required: validateForm,
                  whitespace: true,
                  type: "email"
                },
              ]}
            >
              <Input placeholder="Email of the key point of contact for this offering." />
            </Form.Item>
          </Col>
        </Row>

        <Form.Item
          label="Account SME(s)"
          name="accountsme"
          rules={[
            {
              required: false,
              type: "array",
            },
          ]}
        >
          <Select placeholder="Search Name(s) or Email(s) of SME(s) within Microsoft Account who has knowledge about this offering" mode="multiple" {...usersList} allowClear />
        </Form.Item>

        <Form.Item
          label="Upload Offering Collateral"
          name="offeringcollateral"
          valuePropName="fileList"
          getValueFromEvent={getValueFromEvent}
          tooltip="Upload collaterals related to this offering (Less than 5 MB)"
          help="Supported formats: .doc, .docx, .ppt, .pptx, .pdf"
        >
          <Upload
            name="file"
            accept=".doc,.docx,application/msword,application/vnd.openxmlformats-officedocument.wordprocessingml.document,.ppt,.pptx,application/vnd.ms-powerpoint,application/vnd.openxmlformats-officedocument.presentationml.presentation,.pdf,application/pdf,"
            action={`${apiUrl}/${RESOURCE_PATH.OFFERING}/document`}
            maxCount={5}
            beforeUpload={(file: RcFile) => {
              const isLt5M = (file.size / 1024 / 1024) < 5;
              if (!isLt5M) {
                message.error(`${file.name} is greater than 5 MB!`);
              }
              return isLt5M;
            }}
            onChange={(info) => {
              if (info.file.status === 'done') {
                //parseResume(info.file.response?.url || "");
              }
              else if (info.file.status === 'removed') {
                //setParsedResume(null);
                formProps?.form?.setFieldsValue({ "offeringcollateral": null });
              }
            }}
          >
            <Button icon={<Icons.UploadOutlined />}>Upload</Button>
          </Upload>
        </Form.Item>
      </Form>
    </Create >
  );
};