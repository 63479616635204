import { Col, DatePicker, Form, Input, InputNumber, Modal, ModalProps, notification, Row, Spin } from "@pankod/refine-antd";
import { useCan, useUpdate } from "@pankod/refine-core";
import MDEditor from "@uiw/react-md-editor";
import dayjs from "dayjs";
import { IBaseProps } from "interfaces";
import { IIntakeActivity } from "interfaces/intake";
import { useEffect, useState } from "react";
import { DATAPROVIDER_UPDATE, RESOURCE_PATH } from "scripts/site";
import { getAppTheme } from "scripts/theme";

export declare type IntakeActivityProps = {
    modalProps: ModalProps;
    intakeActivity: IIntakeActivity | undefined;
    open: boolean;
    refetch: () => void;
    onFormSubmission: () => void;
};

export const IntakeActivityEdit: React.FC<IntakeActivityProps> = ({ modalProps, intakeActivity, open = false, refetch, onFormSubmission }) => {

    const [form] = Form.useForm<IIntakeActivity>();
    const { mutate } = useUpdate<IBaseProps>();
    const [submitButton, switchSubmitButton] = useState(false);
    const threeColumnLayout = { xs: { span: 24 }, sm: { span: 24 }, md: { span: 8 }, lg: { span: 8 }, xl: { span: 8 }, xxl: { span: 8 } };
    const rowGutter = { xs: 8, sm: 16, md: 24, lg: 32 };

    useEffect(() => {
        if (intakeActivity) {
            form.resetFields();
        }
    }, [intakeActivity, form]);

    const canEdit = useCan({
        resource: RESOURCE_PATH.INTAKE_ACTIVITY,
        action: "edit",
        params: {
            dataProviderName: DATAPROVIDER_UPDATE,
            id: intakeActivity?.id
        }
    });

    if (intakeActivity?.activitydate) {
        intakeActivity.activitydate = dayjs(intakeActivity?.activitydate);
    }

    const saveForm = () => {
        switchSubmitButton(true);
        form.validateFields().then((values) => {
            mutate({
                resource: RESOURCE_PATH.INTAKE_ACTIVITY,
                dataProviderName: DATAPROVIDER_UPDATE,
                id: intakeActivity?.id ?? "",
                values
            },
                {
                    onSuccess: () => {
                        onFormSubmission();
                        refetch();
                        switchSubmitButton(false);
                    },
                    onError: () => {
                        onFormSubmission();
                        switchSubmitButton(false);
                    }
                });
        }).catch((_errorInfo) => {
            notification.error({
                message: 'Incomplete Details',
                description:
                    'Please validate all the fields and save before submitting.'
            });
            switchSubmitButton(false);
            return;
        });
    };

    if (!canEdit.data?.can) {
        return (<></>)
    }

    return (
        <Spin spinning={submitButton}>
            <Modal {...modalProps}
                width={"70%"}
                title="Update Activity"
                open={open}
                okButtonProps={{
                    onClick: async () => {
                        saveForm();
                    }
                }}
                cancelButtonProps={{
                    onClick: () => {
                        onFormSubmission();
                    }
                }}
                forceRender={true}
            >
                <Form
                    form={form}
                    layout="vertical" autoComplete="off"
                    initialValues={intakeActivity}
                    scrollToFirstError={true}
                >
                    <Form.Item
                        name="id"
                        hidden
                    >
                        <Input type="hidden" value={intakeActivity?.id} />
                    </Form.Item>
                    <Form.Item
                        label="Activity Title"
                        name="name"
                        rules={[
                            {
                                required: true,
                                whitespace: true,
                                max: 100,
                                min: 5
                            },
                        ]}
                    >
                        <Input placeholder="Provide brief title of the activity you performed" value={intakeActivity?.name} />
                    </Form.Item>
                    <Form.Item
                        label="Activity Description"
                        name="description"
                    >
                        <MDEditor height={250} data-color-mode={getAppTheme()} placeholder="Describe the activity performed." value={intakeActivity?.description} />
                    </Form.Item>
                    <Row gutter={rowGutter}>
                    <Col className="gutter-row" {...threeColumnLayout}>
                        <Form.Item
                            label="Effort (in hours)"
                            name="effort"
                            rules={[
                                {
                                    required: true,
                                    whitespace: true,
                                    type: "number"
                                }
                            ]}
                        >
                            <InputNumber defaultValue={0} min={0} precision={2} step={0.25} placeholder="Provide effort in hours" style={{ width: "100%" }} />
                        </Form.Item>
                    </Col>
                    <Col className="gutter-row" {...threeColumnLayout}>
                        <Form.Item
                            label="Activity Date"
                            name="activitydate"
                            rules={[
                                {
                                    required: true,
                                    type: "date"
                                }
                            ]}
                        >
                            <DatePicker picker="date"
                                        placeholder="MMM DD, YYYY"
                                        format={"MMM DD, YYYY"}
                                        allowClear={true} 
                                        defaultValue={dayjs(Date.now())}/>
                        </Form.Item>
                    </Col>
                </Row>
                </Form>
            </Modal>
        </Spin>
    );
};