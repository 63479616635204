import { Alert, Button, Form, Icons, Input, Progress, Space, Spin, Tag, useForm } from "@pankod/refine-antd";
import { useUpdate } from "@pankod/refine-core";
import { EntityImportFields, IBaseProps, IUser, IUserMetadata } from "interfaces";
import { useState } from "react";
import { DATAPROVIDER_CREATE, DATAPROVIDER_UPDATE, RESOURCE_PATH, formatImportDataType } from "scripts/site";
import * as XLSX from "xlsx";
import { checkIdForDeactivation } from "./validateUser";

export const DeActivateUsers: React.FC<IUserMetadata> = ({ config: metaConfig }) => {
    const { form, formProps } = useForm<IBaseProps>({
        dataProviderName: DATAPROVIDER_CREATE,
        warnWhenUnsavedChanges: false
    });
    const [dataRows, setDataRows] = useState<any[]>([]);
    const [userCount, setUserCount] = useState<number>(0);
    const [disableForm, setDisableForm] = useState<boolean>(false);
    const { mutate: updateUser } = useUpdate<IUser>();
    const [importFields, setImportFields] = useState<EntityImportFields[]>([]);
    const [percentComplete, setPercentComplete] = useState<number>(0);
    const [alertMessage, setAlertMessage] = useState<string>("Kindly select the file containing user details");

    const readExcelFile = (event: React.ChangeEvent) => {
        event.preventDefault();
        const reader = new FileReader();
        const file = (event.target as HTMLInputElement).files?.[0];
        if (file) {
            reader.readAsArrayBuffer(file);
        }
        reader.onload = (event) => {
            var data = event.target?.result;
            const workbook = XLSX.read(data, { type: 'array' });
            const sheetName = workbook.SheetNames[0];
            const worksheet = workbook.Sheets[sheetName];
            const rows = XLSX.utils.sheet_to_json(worksheet);
            setUserCount(rows.length); // Setting up users count
            setDataRows(rows); // Setting up data rows
            setImportFields(metaConfig?.importMapping?.find((it) => it.type === "employee")?.fields || []);
            setAlertMessage("There are " + rows.length + " users in the file. Kindly click on 'Reconcile Users' to upload this data.");
            setPercentComplete(0);
        };
    };

    const reconcileUsers = () => {
        // Disable the form
        setDisableForm(true);

        // Loop through the data rows
        dataRows.forEach(async (row, indx) => {
            let importedData: { [key: string]: any } = {};
            // Map the excel fields to the predefined fields of the entity
            importFields.forEach((field) => {
                let value = row[field.displayName];
                // If the respective column value is not present in the excel sheet, then skip it
                if (value) {
                    // Get the field and fromat as specified in entity metadata
                    importedData[field.name] = formatImportDataType(field.type, value, field.seperator);
                }
            })
            // Check is the user ID field exists in the excel sheet
            let userId = checkIdForDeactivation(importedData);

            if (userId && userId.length > 0) {
                try {
                    updateUser({
                        resource: RESOURCE_PATH.USERS,
                        dataProviderName: DATAPROVIDER_UPDATE,
                        id: userId,
                        values: {
                            stateAction: "inactive",
                            comments: "User deactivated - ID found deactivated in Active Directory"
                        },
                        successNotification: false,
                        errorNotification: false
                    });
                } catch (e) {
                    console.log(e);
                }
            }
            setPercentComplete(Math.round(((indx + 1) / dataRows.length) * 100));
        })

        // Enable the form again
        setDisableForm(false);
        setUserCount(0);
        setAlertMessage("Upload completed !!! Total " + dataRows.length + " users were processed. Refer console logs for more information");
        form?.resetFields();
    };

    return (
        <Spin spinning={disableForm}>
            <Alert
                message={alertMessage}
                type="info"
                style={{ marginBottom: "1.2em" }}
                closable
            />
            <Form {...formProps} form={form} layout="vertical" autoComplete="off" scrollToFirstError={true}>
                <Form.Item
                    label="Select file with users data"
                    name="inputFile"
                    tooltip="Select file containing users data."
                    rules={[
                        {
                            required: true,
                            whitespace: true
                        }
                    ]}
                >
                    <Input
                        type="file"
                        accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
                        onChange={(e) => { readExcelFile(e) }}
                        addonAfter={<Icons.FileExcelFilled />}
                    />
                </Form.Item>
                <Form.Item
                    noStyle
                >
                    {userCount > 0 ?
                        <Space direction="vertical" style={{ width: "100%" }}>
                            <Tag color="success">Total {userCount} rows of data found in the excel.</Tag>
                            <Button
                                type="primary"
                                style={{ backgroundColor: "green" }}
                                onClick={() => { reconcileUsers() }}
                            >
                                <Icons.UploadOutlined />
                                Reconcile Users
                            </Button>
                        </Space>
                        : null
                    }
                </Form.Item>
                <Form.Item
                    noStyle
                >
                    {percentComplete > 0 ?
                        <Space direction="vertical" style={{ width: "100%" }}>
                            {
                                percentComplete === 100 ?
                                    <Tag color="success">Data upload completed !!!</Tag> :
                                    <Tag color="warning">WARNING : Do not close this window !!! Data upload in progress...</Tag>
                            }
                            <Progress percent={percentComplete} style={{ marginRight: 8 }} />
                        </Space>
                        : null
                    }
                </Form.Item>
            </Form>
        </Spin>
    );
}