import { arrayExtensions } from "@architecture-innovation-transformation/lib-common";
import { Divider, Tag, Typography } from "@pankod/refine-antd";
import { BaseRecord, useList, useMany } from "@pankod/refine-core";
import { RenderSpinner } from "components/common";
import { ILookup, IUser } from "interfaces";
import { arrayJoinString, DATAPROVIDER_LOOKUP, DATAPROVIDER_READ, FETCH_ACTION, RESOURCE_PATH } from "scripts/site";

export const DisplaySystemMembers: React.FC<IUser> = (userObj: IUser) => {
    const { Title, Text } = Typography;
    const { data: roleData } = useMany<ILookup>({
        dataProviderName: DATAPROVIDER_LOOKUP,
        resource: RESOURCE_PATH.ROLE,
        ids: userObj?.roleIds ?? [],
        queryOptions: {
            enabled: (userObj?.roleIds ?? []).length > 0,
        },
    });


    const { data: groupMembersData, isLoading: isMembersLoading } = useList<BaseRecord>({
        dataProviderName: DATAPROVIDER_READ,
        resource: `${RESOURCE_PATH.PEOPLE}/${userObj?.id}/${FETCH_ACTION}`,
        config: {
            filters: [
                {
                    field: "operation",
                    operator: "eq",
                    value: "linkedMembersList"
                }
            ]
        },
        queryOptions: {
            enabled: !!userObj?.id
        }
    });
    const groupMembers = ((groupMembersData?.data as unknown ?? []) as any[]).map(m => m.nameEmail);

    return (
        <>
            <Title style={{ marginTop: 15 }} level={5}>Email</Title>
            <Typography.Text copyable>{userObj?.id ?? "---"}</Typography.Text>
            <Title level={5} style={{ marginTop: "1.2em" }}>{`${groupMembers?.length || ""} Members`}</Title>
            {isMembersLoading ? <RenderSpinner message="Loading Members ..." /> :
                <div className="grpmmbrs">
                    <Text copyable disabled>{groupMembers ? arrayJoinString(groupMembers, "; ") : "No Members"}</Text>
                </div>
            }
            <Tag style={{ marginTop: "1.2em" }} color="blue">Please use copy icon to copy the members list</Tag>
            <Divider></Divider>
            {userObj?.roleIds &&
                <>
                    <Title style={{ marginTop: 20 }} level={5}>Roles</Title>
                    {roleData?.data
                        ? arrayExtensions.sortByName(roleData?.data).map((r, i) => (
                            <Tag key={i} color={r.ui?.color || "blue"}>{r.name || ""}</Tag>
                        ))
                        : ((userObj?.roleIds ?? ["No Role"]).map((r, i) => (
                            <Tag key={i} color={"default"}>{r || ""}</Tag>
                        )))}
                </>
            }
        </>
    );
};