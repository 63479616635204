import { Button, Form, FormProps, Icons, Input, Select, Space } from "@pankod/refine-antd";
import { useOne } from "@pankod/refine-core";
import { IEnggSolutionMetadata } from "interfaces/article";
import { DATAPROVIDER_LOOKUP, RESOURCE_PATH, STALE_DURATION } from "scripts/site";

export const EngagementSolutionFilter: React.FC<{
    formProps: FormProps,
    onApplyFilter: () => void,
}> = (props) => {


    const { data: metadata } = useOne<IEnggSolutionMetadata>({
        dataProviderName: DATAPROVIDER_LOOKUP,
        resource: RESOURCE_PATH.METADATA,
        id: RESOURCE_PATH.ENGAGEMENTSOLUTION,
        queryOptions: {
            enabled: true,
            staleTime: STALE_DURATION
        }
    });

    const metaConfig = metadata?.data.config;

    return (
        <Form layout="vertical" {...props.formProps} autoComplete="off">
            <Form.Item
                label={"Search"}
                name="q"
                tooltip={metaConfig?.search?.textMessages?.searchTextPlaceholder || "Search"}
            >
                <Input
                    placeholder={metaConfig?.search?.textMessages?.searchTextPlaceholder || "Search"}
                    prefix={<Icons.SearchOutlined />}
                />
            </Form.Item>
            <Form.Item
                name={["phase"]}
                label="Phase"
                key={`phase`}

            >
                <Select
                    allowClear
                    mode="multiple"
                    placeholder="Phase"
                >
                    {metaConfig?.phase.map((m, i) => (
                        <Select.Option value={m} key={i}>{m}</Select.Option>
                    ))}
                </Select>
            </Form.Item>

            <Form.Item
                name={["status"]}
                label="Status"
                key={`status`}

            >
                <Select
                    allowClear
                    mode="multiple"
                    placeholder="Status"
                >
                    {metaConfig?.status.map((m, i) => (
                        <Select.Option value={m} key={i}>{m}</Select.Option>
                    ))}
                </Select>

            </Form.Item>


            <Form.Item
                name={["impact"]}
                label="Impact"
                key={`impact`}

            >
                <Select
                    allowClear
                    mode="multiple"
                    placeholder="Impact"
                >
                    {metaConfig?.impact.map((m, i) => (
                        <Select.Option value={m} key={i}>{m}</Select.Option>
                    ))}
                </Select>

            </Form.Item>

            <Form.Item>
                <Space>
                    <Button
                        htmlType="submit"
                        type="primary"
                        icon={<Icons.FilterOutlined />}
                        onClick={() => {
                            props.onApplyFilter();
                        }}
                    >
                        Apply
                    </Button>
                    <Button
                        icon={<Icons.ClearOutlined />}
                        htmlType="submit"
                        onClick={() => { props.formProps.form?.resetFields() }}
                    >
                        Clear
                    </Button>
                </Space>
            </Form.Item>
        </Form>
    );
}