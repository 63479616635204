import { Typography } from "@pankod/refine-antd";
import { ShowUserCard } from "components/profile/showUserCard";
import dayjs from "dayjs";
import relativeTime from "dayjs/plugin/relativeTime";
import { IBaseProps } from "interfaces";
import { displayRelativeDate } from "./displayRelativeDate";
import { greyStyle } from "scripts/site";
dayjs.extend(relativeTime);

export const CreatedBy: React.FC<IBaseProps> = (propObj: IBaseProps) => {
    if (propObj) {
        return (
            <>
                <Typography.Text style={greyStyle}>Created by </Typography.Text>
                <ShowUserCard id={propObj.createdBy}></ShowUserCard>
                {displayRelativeDate(propObj?.createdAt)}
            </>
        );
    }
    return <></>;
};