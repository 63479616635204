import { arrayExtensions } from "@architecture-innovation-transformation/lib-common";
import { Checkbox, Divider, Edit, Form, Icons, Input, InputNumber, SaveButton, Select, Spin, Table, Tabs, Typography, notification, useForm, useSelect } from "@pankod/refine-antd";
import { IResourceComponentsProps, useOne } from "@pankod/refine-core";
import MDEditor from "@uiw/react-md-editor";
import { CancelButton } from "components/utils/cancelButton";
import { IEngagement, IEngagementProgram, ILookup, IProgram, IRecordMetricValue } from "interfaces";
import { useEffect, useState } from "react";
import { DATAPROVIDER_LOOKUP, DATAPROVIDER_READ, DATAPROVIDER_UPDATE, RESOURCE_PATH, THINK_GEN_AI_LOGO_BLACK, THINK_GEN_AI_LOGO_WHITE, greyStyle, syncMetricValueList } from "scripts/site";
import { getAppTheme } from "scripts/theme";

export const EngagementProgramEdit: React.FC<IResourceComponentsProps> = () => {

  const { form, formProps, saveButtonProps, queryResult } = useForm<IEngagementProgram>({
    dataProviderName: DATAPROVIDER_UPDATE,
    redirect: "show",
  });
  const record = queryResult?.data?.data;
  const { Title, Text } = Typography;
  const [disableForm, setDisableForm] = useState<boolean>(false);
  const { selectProps: userProps } = useSelect<ILookup>({
    dataProviderName: DATAPROVIDER_LOOKUP,
    resource: RESOURCE_PATH.PEOPLE,
    optionLabel: "nameEmail",
    optionValue: "id",
    filters: [{
      field: "stateManager.state",
      operator: "eq",
      value: "active"
    }],
    sort: [{
      field: "lastAccessedAt",
      order: "desc"
    }]
  });

  const { data: engagmentRecord } = useOne<IEngagement>({
    dataProviderName: DATAPROVIDER_READ,
    resource: RESOURCE_PATH.ENGAGEMENT,
    id: record?.engagementId ?? "",
    queryOptions: {
      enabled: !!record?.engagementId
    },
  });

  const { data: programRecord } = useOne<IProgram>({
    dataProviderName: DATAPROVIDER_READ,
    resource: RESOURCE_PATH.PROGRAM,
    id: record?.programId ?? "",
    queryOptions: {
      enabled: !!record?.programId
    },
  });

  const { selectProps: enggUserProps } = useSelect<ILookup>({
    dataProviderName: DATAPROVIDER_LOOKUP,
    resource: RESOURCE_PATH.PEOPLE,
    optionLabel: "nameEmail",
    optionValue: "id",
    filters: [
      {
        field: "id",
        operator: "eq",
        value: engagmentRecord?.data.salesExecutives
          .concat(engagmentRecord?.data.deliveryExecutives)
          .concat(engagmentRecord?.data.otherAuthorizedUsers)
          .join(",")  // this is the value of the field to be filtered
      },
      {
        field: "stateManager.state",
        operator: "eq",
        value: "active"
      }
    ],
    sort: [
      {
        field: "lastAccessedAt",
        order: "desc"
      },
    ],
    fetchSize: 50,
    queryOptions: {
      enabled: !!engagmentRecord?.data
    }
  });

  // Use state to store the record key results
  const [recordKeyResults, setRecordKeyResults] = useState<IRecordMetricValue[]>([]);
  const [recordTasks, setRecordTasks] = useState<IRecordMetricValue[]>([]);
  const [recordJourney, setRecordJourney] = useState<IRecordMetricValue[]>([]);


  useEffect(() => {
    if (record) {
      // Sync up the key results with the program key results
      if (!record?.keyResults) {
        record.keyResults = [];
      }
      const syncedKeyResults = syncMetricValueList(programRecord?.data?.keyResults ?? [], record.keyResults);
      setRecordKeyResults(syncedKeyResults);

      form.setFieldsValue({
        keyResults: syncedKeyResults.map((kr) => kr.metricValue),
      });

      // Sync up the tasks with the program tasks
      if (!record?.tasks) {
        record.tasks = [];
      }
      const syncedTasks = syncMetricValueList(programRecord?.data?.tasks ?? [], record.tasks, false);
      setRecordTasks(syncedTasks);

      form.setFieldsValue({
        tasks: syncedTasks.map((kr) => kr.metricValue),
      });

      // Sync up the tasks with the program jounrey
      if (!record?.journey) {
        record.journey = [];
      }
      const syncedJourney = syncMetricValueList(programRecord?.data?.journey ?? [], record.journey, false);
      setRecordJourney(syncedJourney);
      form.setFieldsValue({
        journey: syncedJourney.map((kr) => kr.metricValue),
      });
    }
  }, [form, programRecord?.data, record]);

  const taskGroupNames = arrayExtensions.arrayRemoveDuplicate((programRecord?.data?.tasks ?? []).flatMap(t => t.group));
  const journeyGroupNames = arrayExtensions.arrayRemoveDuplicate((programRecord?.data?.journey ?? []).flatMap(t => t.group));

  function renderMetricValueGroup(formItemName: string, groups: string[], recordMetricValue: IRecordMetricValue[]) {
    return (<>
      {arrayExtensions.validateArray(recordMetricValue ?? [])
        ?
        <Form.List name={[formItemName]}>
          {() => {
            return (
              groups.map((grp, ind) =>
                <div key={ind}>
                  <Title level={5}>{grp}</Title>
                  {(arrayExtensions.sortBy("order", recordMetricValue?.filter(rt => rt.metric.group === grp) ?? []) as IRecordMetricValue[])
                    .map((rt, i) => {
                      const itemIndex = recordMetricValue.findIndex(r => r.metricValue.id === rt.metricValue.id);
                      return renderMetricValueCheckbox(rt, itemIndex);
                    })}
                </div>)
            );
          }}
        </Form.List>
        : "None"}
    </>);
  }

  function renderMetricValueCheckbox(metricValue: IRecordMetricValue, index: number) {
    return (
      <div style={{ marginBottom: 10 }}>
        <Form.Item
          name={[index, "id"]}
          key={`task-${index}-id`}
          hidden
          noStyle
        >
          <Input />
        </Form.Item>

        <Form.Item
          style={{ marginBottom: 0, minWidth: 100 }}
          name={[index, "value"]}
          key={`task-${index}-value`}
          valuePropName={"checked"}
        >
          <Checkbox>
            <Text>{`${metricValue.metric.name}`}</Text>
            {metricValue.metric.description && <>
              <br />
              <Text style={greyStyle}>{metricValue.metric.description}</Text>
            </>}
          </Checkbox>
        </Form.Item>
      </div>
    );
  }

  const handleSave = () => {
    setDisableForm(true);
    form.validateFields().then(async () => {
      form.submit();
    })
      .catch((errorInfo) => {
        setDisableForm(false);
        notification.error({
          message: 'Incomplete Details',
          description:
            'Please validate all the fields and then save.'
        });
      });
  }

  return (
    <div className="engagement">
      <Spin spinning={disableForm || queryResult?.isLoading}>
        <Edit
          isLoading={queryResult?.isFetching}
          headerProps={{
            title: "Update program",
            subTitle: `for ${engagmentRecord?.data?.name ?? ""}`,
            extra: <>
              <img src={getAppTheme() === "dark" ? THINK_GEN_AI_LOGO_WHITE : THINK_GEN_AI_LOGO_BLACK} alt="Think GenAI" style={{
                height: 30,
                width: 150,
              }}></img>
            </>
          }}
          footerButtons={<>
            <SaveButton {...saveButtonProps}
              onClick={() => handleSave()}
            ></SaveButton>
            <CancelButton />
          </>}>
          <Form {...formProps} layout="vertical" autoComplete="off">
            <Tabs defaultActiveKey={"program"} style={{ marginLeft: 16, marginRight: 16, marginBottom: 16 }}
              items={[
                {
                  label: <span><Icons.AimOutlined />OKRs</span>,
                  key: "program",
                  style: { margin: 8 },
                  children: <>
                    <Title level={4}>Objective</Title>
                    <div className="container" data-color-mode={getAppTheme()}>
                      <MDEditor.Markdown source={programRecord?.data?.objective} />
                    </div>
                    <Form.List name={["keyResults"]}>
                      {() => {
                        return (
                          <Table<IRecordMetricValue> style={{ marginTop: 20 }}
                            dataSource={recordKeyResults} size="small"
                            bordered={true}
                            key={"krTable"} rowKey={"metricValue.id"}
                            pagination={false}>
                            <Table.Column
                              dataIndex={["metric", "name"]}
                              key="metric.name"
                              title="Key Result"
                              render={(value, row, index) =>
                                <>
                                  <span style={{ color: "#dc4446" }}>* </span>
                                  <Typography.Text>{value}</Typography.Text>
                                </>
                              }
                            />
                            <Table.Column
                              dataIndex={["metric", "description"]}
                              key="metric.description"
                              title="Description"
                            />
                            <Table.Column
                              dataIndex={["metric", "target"]}
                              key="metric.target"
                              title="Target"
                            />
                            <Table.Column
                              dataIndex={["metricValue", "value"]}
                              key="metricValue.value"
                              title="Accomplished"
                              render={(value, row, index) =>
                                <>
                                  <Form.Item
                                    name={[index, "id"]}
                                    key={`kr-${index}-id`}
                                    hidden
                                    noStyle
                                  >
                                    <Input />
                                  </Form.Item>
                                  <Form.Item
                                    name={[index, "value"]}
                                    key={`kr-${index}-value`}
                                    style={{ marginBottom: 0 }}
                                    rules={[
                                      {
                                        required: true,
                                        whitespace: true,
                                        type: "number",
                                        min: -100,
                                        max: 200,
                                        message: <>Please provide value accomplished.<br />Enter zero if not accomplished.</>
                                      },
                                    ]}
                                  >
                                    <InputNumber min={-100} max={200} step={1} precision={0} addonAfter="%"
                                      style={{ width: "100%" }}
                                    />
                                  </Form.Item>
                                </>
                              }
                            />
                            <Table.Column
                              dataIndex={["metricValue", "comments"]}
                              key="metricValue.comments"
                              title="Comments"
                              render={(value, row, index) =>
                                <Form.Item
                                  style={{ marginBottom: 0 }}
                                  key={`kr-${index}-comments`}
                                  name={[index, "comments"]} >
                                  <Input />
                                </Form.Item>
                              }
                            />
                          </Table>);
                      }}
                    </Form.List>
                    <Divider />
                    <Form.Item
                      label="Program SPOC"
                      name="programSpoc"
                      tooltip="Associate must be part of the engagement team in Helium."
                      rules={[
                        {
                          required: true,
                          whitespace: true
                        },
                      ]}
                    >
                      <Select
                        allowClear
                        placeholder="Search Name or Email"
                        {...enggUserProps}
                      />
                    </Form.Item>

                    <Form.Item
                      label="Program Champions"
                      name="programChampions"
                      rules={[
                        {
                          required: false,
                          whitespace: true,
                          type: "array",
                        },
                      ]}
                    >
                      <Select placeholder="Search Name or Email" mode="multiple" {...userProps} allowClear />
                    </Form.Item>
                  </>
                },
                {
                  label: <span><Icons.OrderedListOutlined />Tasks</span>,
                  key: "tasks",
                  style: { margin: 8 },
                  children: renderMetricValueGroup("tasks", taskGroupNames, recordTasks)
                },
                {
                  label: <span><Icons.NodeIndexOutlined />Journey</span>,
                  key: "journey",
                  style: { margin: 8 },
                  children: renderMetricValueGroup("journey", journeyGroupNames, recordJourney)
                },
              ]}
            >
            </Tabs>
          </Form>
        </Edit>
      </Spin>
    </div>
  );
};