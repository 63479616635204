import { Button, Drawer, Icons, Show } from "@pankod/refine-antd";
import {
    IResourceComponentsProps, useCan, useOne
} from "@pankod/refine-core";
import { OutreachForm, RenderMDFile } from "components/common";
import { IInfoPagesMetadata } from "interfaces";
import React, { useState } from "react";
import { DATAPROVIDER_LOOKUP, RESOURCE_PATH, STALE_DURATION } from "scripts/site";

export const AskTheExpert: React.FC<IResourceComponentsProps> = () => {

    const { data: metadata, isLoading: metadataLoading } = useOne<IInfoPagesMetadata>({
        dataProviderName: DATAPROVIDER_LOOKUP,
        resource: RESOURCE_PATH.METADATA,
        id: RESOURCE_PATH.ASKTHEEXPERT,
        queryOptions: {
            enabled: true,
            staleTime: STALE_DURATION
        }
    });

    const entityMetadata = metadata?.data;
    const [outreachOpen, switchOutreach] = useState(false);
    const contentUrl = entityMetadata?.fields?.find(f => f.name === RESOURCE_PATH.ASKTHEEXPERT)?.attachment || null;
    const canListExpertOutreach = useCan({
        resource: RESOURCE_PATH.EXPERT_OUTREACH,
        action: "create"
    });

    return (
        <Show
            isLoading={metadataLoading}
            headerProps={{
                title: entityMetadata?.config?.pageHeading,
                extra: canListExpertOutreach.data?.can && entityMetadata?.config?.connectButtonText &&
                    <Button key="help" onClick={() => switchOutreach(true)} icon={<Icons.PhoneOutlined />}>{entityMetadata?.config?.connectButtonText}</Button>
            }}
        >
            {contentUrl &&
                <RenderMDFile resourceName={RESOURCE_PATH.ASKTHEEXPERT} mdFilePath={contentUrl} />
            }
            {canListExpertOutreach.data?.can &&
                <Drawer
                    title={entityMetadata?.config?.drawerTitle}
                    placement="right"
                    size={entityMetadata?.config?.drawerFormSize}
                    open={outreachOpen}
                    onClose={() => { switchOutreach(false) }}
                >
                    <OutreachForm categoryType={entityMetadata?.config?.connectCategory} onFormSubmit={() => switchOutreach(false)} />
                </Drawer>
            }
        </Show>
    );
};
