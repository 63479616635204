import { Button, Edit, Icons } from "@pankod/refine-antd";
import { IResourceComponentsProps, useNavigation, useShow } from "@pankod/refine-core";
import { CandidateEvaluationForm } from "components/resourcing/candidateEvaluationForm";
import { ICandidateEvaluation } from "interfaces/resourcing";
import { DATAPROVIDER_UPDATE, RESOURCE_PATH } from "scripts/site";

export const CandidateEvaluationEdit: React.FC<IResourceComponentsProps> = () => {
    const { queryResult, showId } = useShow<ICandidateEvaluation>({
        dataProviderName: DATAPROVIDER_UPDATE,
    });
    const { data, isLoading } = queryResult;
    const { show } = useNavigation();
    const resourcingRequestId = data?.data.requestId || "";
    const candidateId = data?.data.candidateId || "";

    return (
        <Edit
            isLoading={isLoading}
            title="Add Candidate Evaluation Feedback"
            headerButtons={
                <>
                    <Button
                        onClick={() => { show(RESOURCE_PATH.RESOURCINGREQUEST, resourcingRequestId) }}
                        icon={<Icons.SkinOutlined />}
                    >Resourcing Request</Button>
                    <Button
                        onClick={() => { show(RESOURCE_PATH.CANDIDATE, candidateId) }}
                        icon={<Icons.UserSwitchOutlined />}
                    >Candidate</Button>
                </>
            }
            footerButtons={[]}
        >
            <CandidateEvaluationForm evaluationId={showId as string} onFormSubmission={()=>(show(RESOURCE_PATH.CANDIDATE, candidateId))} />
        </Edit>
    );
};