import { Col, Divider, Row, Typography } from "@pankod/refine-antd";
import { ICandidateInternal } from "interfaces/resourcing";
import { rowGutter, threeColumnLayout } from "scripts/layout";
import { doesExist } from "scripts/site";

export const DisplayInternalCandidate: React.FC<ICandidateInternal> = (record: ICandidateInternal) => {
    const { Title, Text } = Typography;

    return (
        <>
            <Divider orientation="left">Internal Candidate Details</Divider>
            <Row gutter={rowGutter}>
                <Col className="gutter-row" {...threeColumnLayout}>
                    <Title level={5}>Employee ID</Title>
                    <Text copyable={doesExist(record?.employeeId)}>{record?.employeeId ?? "---"}</Text>
                    <Title level={5}>TCS Experience (In Years)</Title>
                    <Text copyable={doesExist(record?.tcsExperience)}>{record?.tcsExperience ?? "---"}</Text>
                    <Title level={5}>Experience Category</Title>
                    <Text copyable={doesExist(record?.experienceCategory)}>{record?.experienceCategory ?? "---"}</Text>
                </Col>
                <Col className="gutter-row" {...threeColumnLayout}>
                    <Title level={5}>TCS Email</Title>
                    <Text copyable={doesExist(record?.tcsEmail)}>{record?.tcsEmail ?? "---"}</Text>
                    <Title level={5}>Base Branch</Title>
                    <Text copyable={doesExist(record?.baseBranch)}>{record?.baseBranch ?? "---"}</Text>
                    <Title level={5}>Depute Branch</Title>
                    <Text copyable={doesExist(record?.deputeBranch)}>{record?.deputeBranch ?? "---"}</Text>
                </Col>
                <Col className="gutter-row" {...threeColumnLayout}>
                    <Title level={5}>Grade</Title>
                    <Text copyable={doesExist(record?.grade)}>{record?.grade ?? "---"}</Text>
                    <Title level={5}>RMG SPOC</Title>
                    <Text copyable={doesExist(record?.rmgSpoc)}>{record?.rmgSpoc ?? "---"}</Text>
                    <Title level={5}>RMG Status</Title>
                    <Text copyable={doesExist(record?.rmgStatus)}>{record?.rmgStatus ?? "---"}</Text>
                </Col>
            </Row>
        </>
    );
};