import { ILookup } from "@architecture-innovation-transformation/lib-common";
import { Button, Form, FormProps, Icons, Select, Space, useSelect } from "@pankod/refine-antd";
import { DATAPROVIDER_LOOKUP, RESOURCE_PATH } from "scripts/site";

export const EngagementProgramFilter: React.FC<{
    formProps: FormProps,
    onApplyFilter: () => void,
}> = (props) => {

    const { selectProps: engagementList } = useSelect<ILookup>({
        dataProviderName: DATAPROVIDER_LOOKUP,
        resource: RESOURCE_PATH.ENGAGEMENT,
        optionLabel: "name",
        optionValue: "id",
        sort: [{
            field: "name",
            order: "asc"
        }]
    });

    const { selectProps: userProps } = useSelect<ILookup>({
        dataProviderName: DATAPROVIDER_LOOKUP,
        resource: RESOURCE_PATH.PEOPLE,
        optionLabel: "nameEmail",
        optionValue: "id",
        filters: [{
            field: "stateManager.state",
            operator: "eq",
            value: "active"
        }],
        sort: [
            {
                field: "lastAccessedAt",
                order: "desc"
            },
        ],
        fetchSize: 50
    });

    return (
        <Form layout="vertical" {...props.formProps} autoComplete="off">
            <Form.Item
                label="Engagement"
                name="engagementId"
            >
                <Select placeholder="Search Engagement" {...engagementList} allowClear />
            </Form.Item>

            <Form.Item
                label="Program SPOC"
                name="programSpoc"
                tooltip="To look up any missing name type the name of the person and then select"
            >
                <Select
                    allowClear
                    placeholder="Program SPOC"
                    {...userProps}
                />
            </Form.Item>
            <Form.Item
                label="Program Champions"
                name="programChampions"
                tooltip="To look up any missing name type the name of the person and then select"
            >
                <Select
                    allowClear
                    mode="multiple"
                    placeholder="Program Champions"
                    {...userProps}
                />
            </Form.Item>
            <Form.Item>
                <Space>
                    <Button
                        htmlType="submit"
                        type="primary"
                        icon={<Icons.FilterOutlined />}
                        onClick={() => {
                            props.onApplyFilter();
                        }}
                    >
                        Apply
                    </Button>
                    <Button
                        icon={<Icons.ClearOutlined />}
                        htmlType="submit"
                        onClick={() => { props.formProps.form?.resetFields() }}
                    >
                        Clear
                    </Button>
                </Space>
            </Form.Item>
        </Form>
    );
}