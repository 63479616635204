import { Button, Checkbox, Col, Divider, Form, Icons, Row, Space, Spin, Typography } from "@pankod/refine-antd";
import { useUpdate } from "@pankod/refine-core";
import { IBaseProps, IEntityListColumns, IUser } from "interfaces";
import { TableFieldType } from "interfaces/enum";
import { useEffect, useState } from "react";
import { DATAPROVIDER_UPDATE, MY_PROFILE, removeLocalSessionStorage, RESOURCE_PATH } from "scripts/site";
import { preferencesData } from "./getListTableColumns";

export declare type ColumnPickerProps = {
    entityListColumns: IEntityListColumns | undefined;
    user: IUser;
    onColumnSelection: () => void;
};
export const ListColumnPicker: React.FC<ColumnPickerProps> = ({ entityListColumns, user, onColumnSelection }) => {

    const [form] = Form.useForm<IEntityListColumns>();
    const { mutate } = useUpdate<IBaseProps>();
    const [submitButton, switchSubmitButton] = useState(false);

    useEffect(() => {
        if (entityListColumns) {
            form.resetFields();
        }
    }, [entityListColumns, form]);

    const saveForm = () => {
        if (entityListColumns) {
            switchSubmitButton(true);

            //Formulate the preference item
            let columnData = entityListColumns.listColumns.filter(lst => lst.fieldType === TableFieldType.Mandatory || lst.fieldType === TableFieldType.Default)?.map(elem => elem.key);
            let userPreferences = preferencesData(user.preferences, entityListColumns.id, columnData);

            // Save the preference to profile
            mutate({
                dataProviderName: DATAPROVIDER_UPDATE,
                resource: RESOURCE_PATH.PROFILE,
                id: user.id,
                values: { preferences: userPreferences },
                successNotification: false,
                errorNotification: false
            },
                {
                    onSuccess: () => {
                        // Remove the user Profile local data to ensure latest preferences are loaded
                        removeLocalSessionStorage(MY_PROFILE);
                        
                        switchSubmitButton(false);
                        onColumnSelection();
                    },
                    onError: () => {
                        switchSubmitButton(false);
                    }
                }
            );
        }
    }

    return (
        <Spin spinning={submitButton}>
            <Form
                layout="vertical"
                autoComplete="off"
                initialValues={entityListColumns}
                form={form}
            >
                <Form.List
                    name={"listColumns"}
                >
                    {(fields, { move }, { errors }) => (
                        <>
                            {fields.map(({ key, name, ...restField }) => (
                                <Form.Item
                                    required={false}
                                    key={key}
                                >
                                    <Row style={{ border: "1px dashed grey", padding: 8 }} align="middle" justify="center" gutter={[16, 16]}>
                                        <Col span={6} offset={2}>
                                            <Form.Item
                                                {...restField}
                                                noStyle
                                            >
                                                <Checkbox
                                                    disabled={entityListColumns?.listColumns[key].fieldType === TableFieldType.Mandatory}
                                                    defaultChecked={
                                                        entityListColumns?.listColumns[key].fieldType === TableFieldType.Mandatory ||
                                                        entityListColumns?.listColumns[key].fieldType === TableFieldType.Default
                                                    }
                                                    onChange={(ev) => {
                                                        if (entityListColumns) {
                                                            if (ev.target.checked) {
                                                                entityListColumns.listColumns[key].fieldType = TableFieldType.Default;
                                                            } else {
                                                                entityListColumns.listColumns[key].fieldType = TableFieldType.Optional;
                                                            }
                                                        }
                                                    }}
                                                />
                                            </Form.Item>
                                        </Col>
                                        <Col span={16}>
                                            <Form.Item
                                                {...restField}
                                                name={[name, "title"]}
                                                noStyle
                                            >
                                                <Typography.Text style={{ fontWeight: "bold" }}>{entityListColumns?.listColumns[key].title}</Typography.Text>
                                            </Form.Item>
                                        </Col>
                                        <Form.ErrorList errors={errors} />
                                    </Row>
                                </Form.Item>
                            ))}
                        </>
                    )}
                </Form.List>
                <Divider />
                <Form.Item>
                    <Space align="center" size={32}>
                        <Button
                            type="primary"
                            onClick={() => onColumnSelection()}
                        >
                            <Icons.SendOutlined />
                            Apply Changes
                        </Button>
                        <Button
                            type="primary"
                            onClick={() => saveForm()}
                            style={{ backgroundColor: "green" }}
                            disabled={submitButton}
                        >
                            <Icons.SaveOutlined />
                            Save Preference
                        </Button>
                    </Space>
                </Form.Item>
            </Form>
        </Spin>
    );
};
