import { IBase } from "@architecture-innovation-transformation/lib-common";
import { useOne } from "@pankod/refine-core";
import { DATAPROVIDER_LOOKUP, STALE_DURATION } from "scripts/site";

export declare type EntityProps = {
    entity: string;
    id: string | undefined;
};

export const GetEntityName: React.FC<EntityProps> = ({
    entity,
    id
}) => {
    const { data } = useOne<IBase>({
        dataProviderName: DATAPROVIDER_LOOKUP,
        resource: entity,
        id: id ?? "",
        queryOptions: {
            enabled: (id !== undefined && id !== ""),
            staleTime: STALE_DURATION
        },
    });
    const entityName = data?.data?.name;

    return (
        <>
            {entityName ?? id}
        </>
    );
};


