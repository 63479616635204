import { IBaseProps } from "@architecture-innovation-transformation/lib-common";
import { Button, EditButton, Icons, Space } from "@pankod/refine-antd";
import { useCan, useNavigation, useOne } from "@pankod/refine-core";
import { StateButtons } from "components/common";
import { IPosition, IPositionConfigSet, IPositionMetadata } from "interfaces/resourcing";
import { DATAPROVIDER_CONFIGSET, DATAPROVIDER_UPDATE, RESOURCE_PATH } from "scripts/site";

export const RenderPositionActions: React.FC<{
    record: IPosition,
    metadata: IPositionMetadata | undefined,
    onClickManage: (e: any) => void,
    hideViewRequest?: boolean
}> = ({ record, metadata, onClickManage, hideViewRequest = true }) => {

    const { show } = useNavigation();
    const metaConfig = metadata?.config;

    const { data: configSet } = useOne<IPositionConfigSet>({
        dataProviderName: DATAPROVIDER_CONFIGSET,
        resource: RESOURCE_PATH.POSITION,
        id: record?.id ?? "",
        queryOptions: {
            enabled: !!record?.id
        }
    });

    // Set the rules for the request based on the config set. If config set is not found, then use the metadata config as default.
    const configSetRules = configSet?.data?.values ?? metaConfig?.defaultConfigSet;

    const canEdit = useCan({
        resource: RESOURCE_PATH.POSITION,
        action: "edit",
        params: {
            dataProviderName: DATAPROVIDER_UPDATE,
            id: record?.id
        },
        queryOptions: {
            enabled: configSetRules && configSetRules?.editableStates?.includes(record?.stateManager?.state) && !!record?.id
        }
    });

    const renderEditButtons = configSetRules && canEdit.data?.can && configSetRules?.editableStates?.includes(record?.stateManager?.state);

    return (
        <Space>
            {!hideViewRequest && <Button icon={<Icons.SkinOutlined />}
                onClick={(ev) => {
                    ev?.stopPropagation();
                    show(RESOURCE_PATH.RESOURCINGREQUEST, record.resourcingRequestID)
                }}
            >
                View Request
            </Button>}
            {renderEditButtons && configSetRules.canManagePosition &&
                <Button
                    type="primary"
                    key="manage"
                    onClick={onClickManage}
                    icon={<Icons.FormOutlined />}>Manage</Button>
            }
            {renderEditButtons && configSetRules.canRescopePosition &&
                <EditButton resourceNameOrRouteName={RESOURCE_PATH.POSITION} recordItemId={record.id}>Rescope</EditButton>
            }
            {renderEditButtons && <StateButtons {...record as IBaseProps}
                resourceToAct={RESOURCE_PATH.POSITION}
                resourceToActId={record?.id || "0"}
                resourceToNavigate={RESOURCE_PATH.RESOURCINGREQUEST}
                navigateToId={record?.resourcingRequestID}
                hideActions={configSetRules.stateButtonsHideActions}
                skipAssignedToCheck={true}
            />}
        </Space>
    );
};