import { IBaseProps, stringExtensions } from "@architecture-innovation-transformation/lib-common";
import { Button, Col, DatePicker, Descriptions, Divider, Edit, Form, Input, InputNumber, Radio, Result, Row, Select, Spin, Tag, Typography, useForm } from "@pankod/refine-antd";
import { IResourceComponentsProps, useCan, useNavigation, useOne } from "@pankod/refine-core";
import { StateButtons } from "components/common";
import { CancelButton } from "components/utils/cancelButton";
import dayjs, { Dayjs } from "dayjs";
import { IStateTransitionComments } from "interfaces";
import { IPosition, IPositionConfigSet, IPositionMetadata, IResourcingRequestMetadata } from "interfaces/resourcing";
import { useEffect, useState } from "react";
import { rowGutter, threeColumnLayout } from "scripts/layout";
import { DATAPROVIDER_CONFIGSET, DATAPROVIDER_LOOKUP, DATAPROVIDER_UPDATE, RESOURCE_PATH, STALE_DURATION } from "scripts/site";

export const PositionEdit: React.FC<IResourceComponentsProps> = () => {
    const { form, formProps, queryResult, formLoading } = useForm<IPosition>({
        dataProviderName: DATAPROVIDER_UPDATE
    });

    const record = queryResult?.data?.data;
    const [isInitialLoad, setIsInitialLoad] = useState(true);
    const [billingStartDate, setBillingStartDate] = useState<Dayjs | null>();

    if (isInitialLoad && !formLoading && record) {
        setIsInitialLoad(false);
        if (record?.billingStartDate) {
            record.billingStartDate = dayjs(record.billingStartDate);
            setBillingStartDate(dayjs(record.billingStartDate));
        }

        if (record.resourceRequiredDate) {
            record.resourceRequiredDate = dayjs(record.resourceRequiredDate);
        }
    }

    const [validateForm, setValidateForm] = useState<boolean>(true);
    const [disableForm, setDisableForm] = useState<boolean>(false);
    const [positionType, setPositionType] = useState("");

    useEffect(() => {
        if (record?.positionType) {
            setPositionType(record?.positionType);
        }
    }, [record?.positionType])
    const today = dayjs().endOf('day');
    const sixMonth = dayjs().add(6, 'months').endOf('day');

    const handleFormSave = (values: IStateTransitionComments, stateAction: string) => {
        setDisableForm(true);
        setValidateForm(true);
        form.validateFields().then(async () => {
            form.setFieldsValue({
                stateAction: stateAction,
                comments: values.comments
            });
            if (positionType !== "Replacement") {
                form.setFieldsValue({
                    replacementForEmployeeName: "",
                    replacementForEmployeeId: "",
                    replacementReason: ""
                });
            }

            if (form.getFieldValue('billable') === "Non-Billable") {
                form.setFieldsValue({
                    billingType: "",
                    billingStartDate: "",
                    billRate: ""
                });
            }
            form.submit();
        })
            .catch(() => {
                setDisableForm(false);
            });
    }

    const setFormPreValidationRules = (stateAction: string) => {
        setDisableForm(true);
    }

    const onFailureFormValidation = () => {
        setDisableForm(false);
    }

    const { data: metadata, isLoading: positionMetadataLoading } = useOne<IPositionMetadata>({
        dataProviderName: DATAPROVIDER_LOOKUP,
        resource: RESOURCE_PATH.METADATA,
        id: RESOURCE_PATH.POSITION,
        queryOptions: {
            enabled: true,
            staleTime: STALE_DURATION
        }
    });
    const metaConfig = metadata?.data.config;

    const { isLoading: resourcingMetadataLoading, data: resourcingMetadata } = useOne<IResourcingRequestMetadata>({
        dataProviderName: DATAPROVIDER_LOOKUP,
        resource: RESOURCE_PATH.METADATA,
        id: RESOURCE_PATH.RESOURCINGREQUEST,
        queryOptions: {
            enabled: true,
            staleTime: STALE_DURATION
        }
    });
    const resourcingMetaConfig = resourcingMetadata?.data.config;

    useEffect(() => {
        form.setFieldsValue({ 'positionTypeName': resourcingMetaConfig?.positionType.find(m => m.id === positionType)?.name });
    }, [form, resourcingMetaConfig?.positionType, positionType])

    const { data: configSet, isLoading: configSetLoading } = useOne<IPositionConfigSet>({
        dataProviderName: DATAPROVIDER_CONFIGSET,
        resource: RESOURCE_PATH.POSITION,
        id: record?.id ?? "",
        queryOptions: {
            enabled: !!record?.id
        }
    });

    // Set the rules for the request based on the config set. If config set is not found, then use the metadata config as default.
    const configSetRules = configSet?.data?.values ?? metaConfig?.defaultConfigSet;

    const canEdit = useCan({
        resource: RESOURCE_PATH.POSITION,
        action: "edit",
        params: {
            dataProviderName: DATAPROVIDER_UPDATE,
            id: record?.id
        },
        queryOptions: {
            enabled: configSetRules && configSetRules?.editableStates?.includes(record?.stateManager?.state ?? "") && !!record?.id
        }
    });
    const { list } = useNavigation();

    if (queryResult?.data?.data?.billingStartDate) {
        queryResult.data.data.billingStartDate = dayjs(queryResult.data.data.billingStartDate);
    }
    useEffect(() => {
        form.setFieldsValue({
            billingStartDate: billingStartDate,
        });
    }, [form, billingStartDate]);

    return (
        (queryResult?.isLoading || positionMetadataLoading || resourcingMetadataLoading || (record?.id && configSetLoading))
            ? <Result
                status="info"
                title="Checking permission !!!"
                subTitle="Kindly wait for few moments while we verify your permission and load this request for you."
            />
            : configSetRules && (canEdit.data?.can ?? true) && configSetRules?.canRescopePosition && configSetRules?.editableStates?.includes(record?.stateManager?.state ?? "")
                ? <Spin spinning={disableForm}>
                    <Edit
                        headerProps={{
                            title: "Resourcing Position",
                            subTitle: `for ${record?.name}`,
                            tags: record && <Tag color="blue" >{stringExtensions.capitalize(record?.stateManager?.state)}</Tag>,
                            extra: []
                        }}

                        footerButtons={() => (
                            <>
                                {record &&
                                    <StateButtons {...record as IBaseProps}
                                        resourceToAct={RESOURCE_PATH.POSITION}
                                        resourceToActId={record?.id || "0"}
                                        resourceToNavigate={RESOURCE_PATH.POSITION}
                                        hideActions={['open', 'hold', 'mapped', 'cancel', 'lost', 'closed', 'requestClosure']}
                                        skipAssignedToCheck={true}
                                        form={form}
                                        setFormPreValidationRules={setFormPreValidationRules}
                                        onFailureFormValidate={onFailureFormValidation}
                                        customMutate={handleFormSave}
                                    />
                                }
                                <CancelButton />
                            </>
                        )}
                    >
                        <Form {...formProps} form={form} layout="vertical" autoComplete="off" scrollToFirstError={true}>
                            <Descriptions column={4}>
                                <Descriptions.Item label="RGS ID" labelStyle={{ fontSize: 15 }}>
                                    <Typography.Text copyable>{record?.rgsId}</Typography.Text>
                                </Descriptions.Item>
                                <Descriptions.Item label="Request ID" labelStyle={{ fontSize: 15 }}>
                                    <Typography.Text copyable>{record?.requestID}</Typography.Text>
                                </Descriptions.Item>
                                <Descriptions.Item label="Position ID" labelStyle={{ fontSize: 15 }}>
                                    <Typography.Text copyable>{record?.id}</Typography.Text>
                                </Descriptions.Item>
                            </Descriptions>
                            <Divider style={{ marginTop: 5 }} />
                            <Form.Item
                                name="stateAction"
                                hidden={true}
                            >
                                <Input />
                            </Form.Item>
                            <Form.Item
                                name="comments"
                                hidden={true}
                            >
                                <Input />
                            </Form.Item>
                            <Row gutter={rowGutter}>
                                <Col className="gutter-row" {...threeColumnLayout}>
                                    <Form.Item
                                        label="Position Type"
                                        name="positionType"
                                        tooltip={<>
                                            Select the appropriate position type.
                                            <br />
                                            Select "New" only if you want to Hire a new resource.
                                            <br />
                                            Select "Replacement" for replacement of an existing resource.
                                        </>}
                                        rules={[
                                            {
                                                required: validateForm,
                                                whitespace: true
                                            }
                                        ]}
                                    >
                                        <Select
                                            placeholder="Select Position Type"
                                            onChange={(value) => {
                                                setPositionType(value);
                                                if (value === "Investment") {
                                                    form.setFieldsValue({ 'billable': "Non-Billable" });
                                                    setBillingStartDate(null);
                                                } else {
                                                    form.resetFields(['billable']);
                                                }

                                                if (value === "New-Committed" || value === "Replacement") {
                                                    form.setFieldsValue({ 'confidence': "Confirmed" });
                                                } else {
                                                    form.resetFields(['confidence']);
                                                }
                                            }}
                                        >
                                            {(resourcingMetaConfig?.positionType ?? []).map((m, i) => (
                                                <Select.Option value={m.id} key={i}>{m.name}</Select.Option>
                                            ))}
                                        </Select>
                                    </Form.Item>
                                    <Form.Item
                                        label="Position Type Name"
                                        name="positionTypeName"
                                        hidden={true}
                                    >
                                        <Input />
                                    </Form.Item>
                                </Col>
                            </Row>
                            {positionType === "Replacement" ?
                                <>
                                    <Divider orientation="left">Additional details for Replacement position</Divider>
                                    <Row gutter={rowGutter}>
                                        <Col className="gutter-row" {...threeColumnLayout}>
                                            <Form.Item
                                                label="Replacement for Employee"
                                                name="replacementForEmployeeName"
                                                rules={[
                                                    {
                                                        required: validateForm,
                                                        whitespace: true
                                                    }
                                                ]}
                                            >
                                                <Input placeholder="Provide name of employee being replaced" />
                                            </Form.Item>

                                        </Col>
                                        <Col className="gutter-row" {...threeColumnLayout}>
                                            <Form.Item
                                                label="Replacement for Employee ID"
                                                name="replacementForEmployeeId"
                                                rules={[
                                                    {
                                                        required: validateForm,
                                                        type: "number",
                                                        whitespace: true
                                                    }
                                                ]}
                                            >
                                                <InputNumber min={0} step={1} precision={0} style={{ width: "100%" }} placeholder="Provide employee ID of employee being replaced" controls={false} />
                                            </Form.Item>
                                        </Col>
                                        <Col className="gutter-row" {...threeColumnLayout}>
                                            <Form.Item
                                                label="Replacement reason"
                                                name="replacementReason"
                                                rules={[
                                                    {
                                                        required: validateForm,
                                                        whitespace: true
                                                    }
                                                ]}
                                            >
                                                <Select placeholder="Select reason for replacement" showSearch>
                                                    {resourcingMetaConfig?.replacementReason.sort().map((m, i) => (
                                                        <Select.Option value={m} key={`rr-${i}`}>{m}</Select.Option>
                                                    ))}
                                                </Select>
                                            </Form.Item>
                                        </Col>
                                    </Row>
                                    <Divider />
                                </>
                                : <>
                                    <Form.Item
                                        name="replacementForEmployeeName"
                                        hidden={true}
                                    >
                                        <Input />
                                    </Form.Item>
                                    <Form.Item
                                        name="replacementForEmployeeId"
                                        hidden={true}
                                    >
                                        <Input />
                                    </Form.Item>
                                    <Form.Item
                                        name="replacementReason"
                                        hidden={true}
                                    >
                                        <Input />
                                    </Form.Item>
                                </>
                            }

                            <Row gutter={rowGutter}>
                                <Col className="gutter-row" {...threeColumnLayout}>
                                    <Form.Item
                                        label="Billable/Non Billable"
                                        name="billable"
                                        rules={[
                                            {
                                                required: validateForm,
                                                whitespace: true
                                            }
                                        ]}
                                    >
                                        <Radio.Group
                                            disabled={positionType === "Investment"}
                                            optionType="button"
                                            buttonStyle="solid"
                                            name="billable"
                                            options={(["Billable", "Non-Billable"]).map(a => a)}
                                        />
                                    </Form.Item>
                                </Col>
                                <Col className="gutter-row" {...threeColumnLayout}>
                                    <Form.Item
                                        label="Confidence"
                                        name="confidence"
                                        rules={[
                                            {
                                                required: validateForm,
                                                whitespace: true
                                            }
                                        ]}
                                    >
                                        <Radio.Group
                                            optionType="button"
                                            disabled={positionType === "New-Committed" || positionType === "Replacement"}
                                            buttonStyle="solid"
                                            name="confidence"
                                            options={(["Confirmed", "Tentative"]).map(a => a)}
                                        />
                                    </Form.Item>
                                </Col>
                            </Row><Row gutter={rowGutter}>
                                <Col className="gutter-row" {...threeColumnLayout}>
                                    <Form.Item
                                        label="Resource Required Date"
                                        name="resourceRequiredDate"
                                        tooltip="Please select a date for when the resource needs to be available for this role."
                                        rules={[
                                            {
                                                required: validateForm,
                                                whitespace: true,
                                                type: "date",
                                                validator: async (_, value) => {
                                                    if (validateForm || value) {
                                                        if (value > today && value < (billingStartDate ?? sixMonth)) {
                                                            return Promise.resolve();
                                                        }
                                                        else {
                                                            throw new Error("Resource Required Date should be a future date and before billing start date")
                                                        }
                                                    }
                                                }
                                            }
                                        ]}
                                    >
                                        <DatePicker picker="date"
                                            style={{ width: 280 }}
                                            placeholder="YYYY-MM-DD"
                                            format={"YYYY-MM-DD"}
                                            disabledDate={(selDate) => selDate && (selDate < today || selDate > (billingStartDate ?? sixMonth))}
                                            allowClear={false}
                                        />
                                    </Form.Item>
                                </Col>
                            </Row>
                            <Form.Item noStyle
                                shouldUpdate={(prevValues, currentValues) => prevValues.billable !== currentValues.billable}>
                                {({ getFieldValue }) =>
                                    getFieldValue('billable') === "Billable" ? (
                                        <>
                                            <Divider orientation="left">Additional details for billable resources</Divider>
                                            <Row gutter={rowGutter}>
                                                <Col className="gutter-row" {...threeColumnLayout}>
                                                    <Form.Item
                                                        label="Billing Type"
                                                        name="billingType"
                                                        rules={[
                                                            {
                                                                required: validateForm,
                                                                whitespace: true
                                                            }
                                                        ]}
                                                    >
                                                        <Radio.Group
                                                            optionType="button"
                                                            buttonStyle="solid"
                                                            name="billingType"
                                                            options={(resourcingMetaConfig?.billingType ?? []).map(a => a)}
                                                        />
                                                    </Form.Item>
                                                </Col>
                                                <Col className="gutter-row" {...threeColumnLayout}>
                                                    <Form.Item
                                                        label="Billing Start Date"
                                                        name="billingStartDate"
                                                        tooltip="Billing start date should be a future date and within 6 months from today"
                                                        rules={[
                                                            {
                                                                required: validateForm,
                                                                whitespace: true,
                                                                type: "date",
                                                                validator: async (_, value) => {
                                                                    if (validateForm || value) {
                                                                        if (value > today && value < sixMonth) {
                                                                            return Promise.resolve();
                                                                        }
                                                                        else {
                                                                            throw new Error("Billing start date should be a future date and within 6 months from today")
                                                                        }
                                                                    }
                                                                }
                                                            }
                                                        ]}
                                                    >
                                                        <DatePicker picker="date"
                                                            style={{ width: 280 }}
                                                            placeholder="YYYY-MM-DD"
                                                            format={"YYYY-MM-DD"}
                                                            disabledDate={(selDate) => selDate && (selDate < today || selDate > sixMonth)}
                                                            onChange={(date) => {
                                                                setBillingStartDate(date);
                                                                form.validateFields(['resourceRequiredDate']);
                                                            }}
                                                            allowClear={false}
                                                        />
                                                    </Form.Item>
                                                </Col>
                                                <Col className="gutter-row" {...threeColumnLayout}>
                                                    <Form.Item
                                                        label="Bill Rate (USD per Hour)"
                                                        name="billRate"
                                                        rules={[
                                                            {
                                                                required: validateForm,
                                                                type: "integer"
                                                            }
                                                        ]}
                                                    >
                                                        <InputNumber min={0} max={200} precision={0} placeholder="0" addonAfter="$" style={{ width: 200 }} />
                                                    </Form.Item>
                                                </Col>
                                            </Row>
                                        </>
                                    ) :
                                        <>
                                            <Form.Item
                                                name="billingType"
                                                hidden={true}
                                            >
                                                <Input />
                                            </Form.Item>
                                            <Form.Item
                                                name="billingStartDate"
                                                hidden={true}
                                            >
                                                <Input />
                                            </Form.Item>
                                            <Form.Item
                                                name="billRate"
                                                hidden={true}
                                            >
                                                <Input />
                                            </Form.Item>
                                        </>
                                }
                            </Form.Item>
                            {/* <Form.Item
                                label="Billing Start Date"
                                name="billingStartDate"
                                tooltip="Provide a future date"
                                rules={[
                                    {
                                        required: validateForm,
                                        whitespace: true,
                                        type: "date"
                                    }
                                ]}
                            >
                                <DatePicker picker="date"
                                    style={{ width: 280 }}
                                    placeholder="YYYY-MM-DD"
                                    format={"YYYY-MM-DD"}
                                    disabledDate={(selDate) => selDate && (selDate < dayjs().endOf('day') || selDate > dayjs().add(6, 'months').endOf('day'))}
                                    allowClear={false}
                                />
                            </Form.Item>
                            <Form.Item
                                label="Bill Rate (USD per Hour)"
                                name="billRate"
                                rules={[
                                    {
                                        required: validateForm,
                                        type: "integer"
                                    }
                                ]}
                            >
                                <InputNumber min={0} max={200} precision={0} placeholder="0" addonAfter="$" style={{ width: 280 }} />
                            </Form.Item> */}
                        </Form>
                    </Edit>
                </Spin>
                :
                <Result
                    status="403"
                    title="Edit operation not allowed."
                    subTitle="Sorry, In current state, you don't have permission to edit this position."
                    extra={<Button type="primary" onClick={() => list(RESOURCE_PATH.POSITION)}>Back to Positions</Button>}
                />
    );
};