import { Button, Col, Result, Row } from "@pankod/refine-antd";
import { Typography } from "@pankod/refine-antd";
export const AppError = <>
    <Row>
        <Col style={{margin: "auto", paddingLeft: "2rem"}} span={6}>
            <Typography.Text style={{fontWeight: "bold"}}>Sorry, you are not a recognized user of this system. Request Helium access if you believe you should have access to it.</Typography.Text>
            <br />
            <Button style={{marginTop: "1rem"}} type="primary" href={`${process.env.PUBLIC_URL}/#/register`}>Request access</Button>
        </Col>
        <Col span={18}>
            <Result 
                status="403"
                title="403: Not Authorized"
            />
        </Col>
    </Row>
</>;