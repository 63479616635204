import { Alert, Create, Divider, Form, Input, Radio, SaveButton, Select, useFileUploadState, useForm, useSelect } from "@pankod/refine-antd";
import { IResourceComponentsProps, useCan, useGetIdentity, useOne } from "@pankod/refine-core";
import { RenderParagraphWithImage } from "components/common/renderParagraphWithImage";
import { CancelButton } from "components/utils/cancelButton";
import { ILookup, IUser } from "interfaces";
import { IArticle, IArticleMetadata } from "interfaces/article";
import { DATAPROVIDER_CREATE, DATAPROVIDER_LOOKUP, RESOURCE_PATH, handleSelectChange } from "scripts/site";

export const ArticleCreate: React.FC<IResourceComponentsProps> = () => {
  const { data } = useGetIdentity();
  const user = data as IUser;

  const { form, formProps, saveButtonProps } = useForm<IArticle>({
    dataProviderName: DATAPROVIDER_CREATE,
    redirect: "show",
  });

  const { data: metadata } = useOne<IArticleMetadata>({
    dataProviderName: DATAPROVIDER_LOOKUP,
    resource: RESOURCE_PATH.METADATA,
    id: RESOURCE_PATH.ARTICLE
  });
  const metaConfig = metadata?.data?.config;

  const { isLoading: isUploading, onChange: onUploadChange } = useFileUploadState();

  const { selectProps: usersList } = useSelect<ILookup>({
    dataProviderName: DATAPROVIDER_LOOKUP,
    resource: RESOURCE_PATH.PEOPLE,
    optionLabel: "nameEmail",
    optionValue: "id",
    sort: [{
      field: "lastAccessedAt",
      order: "desc"
    }],
    filters: [{
      field: "stateManager.state",
      operator: "eq",
      value: "active"
  }],
  });

  const canListEnagagement = useCan({
    resource: RESOURCE_PATH.ENGAGEMENT,
    action: "get"
  });

  const canListProgram = useCan({
    resource: RESOURCE_PATH.PROGRAM,
    action: "get"
  });


  const { selectProps: engagementList } = useSelect<ILookup>({
    dataProviderName: DATAPROVIDER_LOOKUP,
    resource: RESOURCE_PATH.ENGAGEMENT,
    optionLabel: "name",
    optionValue: "id",
    sort: [{
      field: "name",
      order: "asc"
    }],
    queryOptions: {
      enabled: canListEnagagement?.data?.can === true
    }
  });

  const { selectProps: programList } = useSelect<ILookup>({
    dataProviderName: DATAPROVIDER_LOOKUP,
    resource: RESOURCE_PATH.PROGRAM,
    optionLabel: "name",
    optionValue: "id",
    sort: [{
      field: "name",
      order: "asc"
    }],
    queryOptions: {
      enabled: canListProgram?.data?.can === true
    }
  });

  return (
    <Create
      title="Author a Solution"
      footerButtons={() => (
        <>
          <SaveButton {...saveButtonProps} disabled={isUploading} />
          <CancelButton />
        </>
      )}

    >
      <Form {...formProps} layout="vertical" autoComplete="off"
        initialValues={{
          body: [{ paragraph: "" }],
          authorName: user?.id ? [user?.id] : []
        }}
      >
        <Divider orientation="left">Synopsis</Divider>
        <Form.Item
          label="Title"
          name="name"
          rules={[
            {
              required: true,
              whitespace: true,
              min: 5,
              max: 100
            },
          ]}
        >
          <Input placeholder="Title for the solution" />
        </Form.Item>
        <Form.Item
          label="Short description"
          name="description"
          rules={[
            {
              required: true,
              whitespace: true,
              min: 15,
              max: 200
            },
          ]}
        >
          <Input.TextArea rows={2} placeholder="Short abstract outlining purpose, intended audience and benefits" />
        </Form.Item>
        <Divider orientation="left">Metadata</Divider>

        <Form.Item
          label="Category"
          name="category"
          tooltip="Category of Solution"
          rules={[
            {
              required: true
            }
          ]}
        >
          <Radio.Group
            optionType="button"
            buttonStyle="solid"
            options={(metaConfig?.category ?? []).sort().map(a => a)}
          />
        </Form.Item>
        <Form.Item
          label="Content Type"
          name="contentType"
          tooltip="Content of Solution"
          rules={[
            {
              required: true
            }
          ]}
        >
          <Radio.Group
            optionType="button"
            buttonStyle="solid"
            options={(metaConfig?.contentType ?? []).sort().map(a => a)}
          />
        </Form.Item>
        <Form.Item
          label="Tags"
          name="tagList"
          tooltip="Add tags"
          rules={[
            {
              required: true,
              type: "array"
            }
          ]}
        >
          <Select mode="tags"
            placeholder="Type a keyword and press enter. You can add as many as you want."
            tokenSeparators={[',']}
          >
          </Select>
        </Form.Item>

        {canListProgram.data?.can ?
          <Form.Item
            label="Program"
            name="programIds"
            rules={[
              {
                required: false,
                whitespace: true,
                type: "array",
                min: 1,
              },
            ]}
          >
            <Select placeholder="Search Program" mode="multiple" {...programList} allowClear
              onChange={(e) => { handleSelectChange(form, e, "programIds") }} />
          </Form.Item>
          : <Alert
            message="Program access denied"
            description={"You don't have access to Program data. Please contact your Engagement owners to map a Program with this solution."}
            type="warning"
            showIcon
            style={{ marginBottom: 15 }}
          />
        }

        {canListEnagagement.data?.can ?
          <Form.Item
            label="Engagement"
            name="engagementId"
            rules={[
              {
                required: false,
                whitespace: true,
              },
            ]}
          >
            <Select placeholder="Search Engagement" {...engagementList} allowClear
              onChange={(e) => { handleSelectChange(form, e, "engagementId") }} />
          </Form.Item>
          : <Alert
            message="Engagement access denied"
            description={"You don't have access to Engagements data. Please contact your Engagement owners to map an Engagement with this solution."}
            type="warning"
            showIcon
            style={{ marginBottom: 15 }}
          />
        }

        <Divider orientation="left">Main Content</Divider>
        <RenderParagraphWithImage
          resourcePath={RESOURCE_PATH.ARTICLE} onUploadChange={onUploadChange}
          paragraphFieldName={"body"} imagesFieldName={"articleImages"} featuredImage={true} />

        <Divider orientation="left">Authors</Divider>
        <Form.Item
          label="Author(s)"
          name="authorName"
          rules={[
            {
              required: true,
              whitespace: true,
              type: "array",
              min: 1,
              max: 3,
              message: "Minimum 1 and maximum of 3 authors"
            },
          ]}
        >
          <Select placeholder="Search Name or Email" mode="multiple" {...usersList} allowClear />
        </Form.Item>
      </Form>
    </Create>
  );
};