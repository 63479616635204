import { ILookup } from "@architecture-innovation-transformation/lib-common";
import { Alert, Button, Create, Form, Icons, Input, Radio, Select, Space, Spin, Tag, message, useForm, useSelect } from "@pankod/refine-antd";
import { IResourceComponentsProps, useCreateMany, useOne } from "@pankod/refine-core";
import { CancelButton } from "components/utils/cancelButton";
import { EntityImportFields } from "interfaces";
import { ICandidate, ICandidateMetadata } from "interfaces/resourcing";
import { useState } from "react";
import { DATAPROVIDER_CREATE, DATAPROVIDER_LOOKUP, DATAPROVIDER_UPSERT, RESOURCE_PATH, STALE_DURATION, formatImportDataType } from "scripts/site";
import * as XLSX from "xlsx";
import { RequestForm } from "./requestForm";

export const CandidateCreate: React.FC<IResourceComponentsProps> = () => {

  const { data: entityMetadata, isLoading: metadataLoading } = useOne<ICandidateMetadata>({
    dataProviderName: DATAPROVIDER_LOOKUP,
    resource: RESOURCE_PATH.METADATA,
    id: RESOURCE_PATH.CANDIDATE,
    queryOptions: {
      enabled: true,
      staleTime: STALE_DURATION
    }
  });

  const { selectProps: countryProps } = useSelect<ILookup>({
    dataProviderName: DATAPROVIDER_LOOKUP,
    resource: RESOURCE_PATH.COUNTRY,
    optionLabel: "name",
    optionValue: "id",
    sort: [
      {
        field: "name",
        order: "asc"
      }
    ],
    fetchSize: 100
  });

  const { form, formProps } = useForm<ICandidate>({
    dataProviderName: DATAPROVIDER_CREATE
  });

  const { mutate: createCandidates } = useCreateMany<ICandidate>();
  const [disableForm, setDisableForm] = useState<boolean>(false);
  const [disableScoping, setDisableScoping] = useState<boolean>(false);
  const [alertMessage, setAlertMessage] = useState<string>("Please answer scoping questions for creating appropriate profiles.");
  const [importFields, setImportFields] = useState<EntityImportFields[]>([]);
  const [dataRows, setDataRows] = useState<any[]>([]);
  const [profileCount, setProfileCount] = useState<number>(0);
  const metaConfig = entityMetadata?.data?.config;

  const readExcelFile = (event: React.ChangeEvent) => {
    event.preventDefault();
    const reader = new FileReader();
    const file = (event.target as HTMLInputElement).files?.[0];
    if (file) {
      reader.readAsArrayBuffer(file);
    }
    reader.onload = (event) => {
      var data = event.target?.result;
      const workbook = XLSX.read(data, { type: 'array' });
      const sheetName = workbook.SheetNames[0];
      const worksheet = workbook.Sheets[sheetName];
      const rows = XLSX.utils.sheet_to_json(worksheet);
      setProfileCount(rows.length); // Setting up profile count
      setDataRows(rows); // Setting up data rows
    };
  };

  const bulkUploadProfiles = () => {
    form.validateFields().then(async () => {
      // Disable the form
      setDisableForm(true);

      // Formulate the profiles
      let profiles: any[] = [];

      // For each row in the excel sheet
      dataRows.forEach((row) => {
        let profile: { [key: string]: any } = {};
        // Map the excel fields to the predefined fields of the entity
        importFields.forEach((field) => {
          let value = row[field.displayName];
          // If the respective column value is not present in the excel sheet, then skip it
          if (value) {
            // Get the field and fromat as specified in entity metadata
            profile[field.name] = formatImportDataType(field.type, value, field.seperator);
          }
        })

        // Push the profile only if we have the Name & ID of the candidate mapped
        if (profile["name"] && profile["id"]) {
          // Add the additional fields specified on form
          profile["candidateType"] = form.getFieldValue('candidateType');
          profile["countryId"] = form.getFieldValue('country');

          //By default, set the profile in 'available' state during bulk upload
          profile["stateAction"] = "available";
          profile["comments"] = "Profile created via Bulk Upload";

          // Push the profile to the array
          profiles.push(profile);
        }
      });

      // Create the profiles
      if (profiles.length > 0) {
        createCandidates(
          {
            dataProviderName: DATAPROVIDER_UPSERT,
            resource: RESOURCE_PATH.CANDIDATE,
            values: profiles,
            successNotification: { key: "notificationKey", message: "Successful", description: `Successfully uploaded ${profiles.length} profile(s)`, type: "success" },
            errorNotification: { key: "notificationKey", message: "Error Occured", description: `Unable to create the profiles. Kindly try again later`, type: "error" }
          },
          {
            onError: () => {
              setDisableForm(false);
              setAlertMessage("Profile upload failed. Kindly validate the excel sheet and try again.");
            },
            onSuccess: () => {
              setDisableForm(false);
              form?.resetFields();
              setProfileCount(0);
              setDisableScoping(false);
              setAlertMessage("Profile data uploaded successfully. Do you want to upload more profiles?");
            }
          }
        )
      } else {
        // Enable the form as there was no profile to be uploaded
        message.error("Data is not in correct format. Kindly validate the data in excel and try again.");
        setDisableForm(false);
      }
    })
  }

  const handleSingleProfile = () => {
    setDisableForm(true);
    form.validateFields().then(async () => {
      if (form.getFieldValue('candidateType') === "TCS Internal") {
        form.setFieldsValue({ 'id': form.getFieldValue('employeeId')?.toString() });
      }
      form.submit();
    })
      .catch((errorInfo) => {
        setDisableForm(false);
      });
  }


  return (
    <Spin spinning={disableForm || metadataLoading || countryProps.loading}>
      <Create
        headerProps={
          {
            backIcon: false,
            title: "Create/Upload Candidate profiles"
          }
        }
        footerButtons={() => (
          <>
            <CancelButton />
            <Button
              key="reset"
              onClick={() => { form?.resetFields(); setProfileCount(0); setDisableScoping(false); }}
              icon={<Icons.ClearOutlined />}
            >
              Reset
            </Button>
          </>
        )}
      >
        <Alert
          message={alertMessage}
          type="info"
          style={{ marginBottom: "1.2em" }}
          closable
        />
        <Form {...formProps} form={form} layout="vertical" autoComplete="off" scrollToFirstError={true}>
          <Form.Item
            label="What type of candidate profiles do you want to create?"
            name="candidateType"
            tooltip="Select candidate type for which you want to create profiles."
            rules={[
              {
                required: true,
                whitespace: true
              }
            ]}
          >
            <Radio.Group
              optionType="button"
              buttonStyle="solid"
              name="candidateType"
              disabled={disableScoping}
              onChange={(e) => {
                let fieldDetails = metaConfig?.importMapping?.find((it) => it.type === e.target.value)?.fields;
                if (fieldDetails) {
                  setImportFields(fieldDetails);
                } else {
                  setImportFields([]);
                }
              }
              }
              options={(metaConfig?.candidateTypes ?? []).map(a => a)}
            />
          </Form.Item>

          <Form.Item
            noStyle
            shouldUpdate={(prevValues, currentValues) => prevValues.candidateType !== currentValues.candidateType}
          >
            {({ getFieldValue }) =>
              getFieldValue('candidateType') ? (

                <Form.Item
                  label="How you want to create profiles?"
                  name="operationType"
                  tooltip="Select how you want to create profiles."
                  rules={[
                    {
                      required: true,
                      whitespace: true
                    }
                  ]}
                >
                  <Radio.Group
                    optionType="button"
                    buttonStyle="solid"
                    name="operationType"
                    disabled={disableScoping}
                    onChange={() => { setDisableScoping(true) }}
                    options={(["Bulk Upload Profiles", "Single Profile"]).map(a => a)}
                  />
                </Form.Item>
              ) : null
            }
          </Form.Item>
          <Form.Item
            noStyle
            shouldUpdate={(prevValues, currentValues) => prevValues.operationType !== currentValues.operationType}
          >
            {({ getFieldValue }) =>
              getFieldValue('operationType') === "Bulk Upload Profiles" ? (
                <>
                  <Form.Item
                    label="Country"
                    name="country"
                    tooltip="Select country for which you want to create profiles."
                    rules={[
                      {
                        required: true,
                        type: "string",
                      }
                    ]}
                  >
                    <Select
                      placeholder="Select Country"
                      style={{ width: "50%" }}
                      showSearch
                      {...countryProps}
                    >
                    </Select>
                  </Form.Item>
                  <Form.Item
                    label="Select file with data"
                    name="inputFile"
                    tooltip="Select file containing profile data."
                    rules={[
                      {
                        required: true,
                        whitespace: true
                      }
                    ]}
                  >
                    <Input
                      type="file"

                      accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
                      onChange={(e) => { readExcelFile(e) }}
                      addonAfter={<Icons.FileExcelFilled />}
                    />
                  </Form.Item>
                </>
              ) :
                (
                  getFieldValue('operationType') === "Single Profile" ?
                    <>
                      <RequestForm candidateType={form.getFieldValue("candidateType")} formprops={formProps} />
                      <Button
                        type="primary"
                        style={{ float: "right", backgroundColor: "green" }}
                        onClick={() => handleSingleProfile()}
                      >
                        <Icons.SaveOutlined />
                        Create Profile
                      </Button>
                    </> : null
                )
            }
          </Form.Item>
          <Form.Item
            noStyle
          >
            {profileCount > 0 ?
              <Space direction="vertical">
                <Tag color="success">{profileCount} profiles found in the excel data.</Tag>
                <Button
                  type="primary"
                  style={{ backgroundColor: "green" }}
                  onClick={() => { bulkUploadProfiles() }}
                >
                  <Icons.UploadOutlined />
                  Upload Profiles
                </Button>
              </Space>
              : null
            }
          </Form.Item>
        </Form>
      </Create>
    </Spin>
  );
};