export enum UserType {
    Employee = "employee",
    Client = "client",
    System = "system"
}

export enum GroupType {
    Static = "static",
    Dynamic = "dynamic"
}

export enum TableValueType {
    Text = "text",
    CapitalizeText = "capitalizeText",
    RelativeDate = "relativeDate",
    Date = "date",
    Time = "time",
    TimeText = "timetext",
    Array = "array",
    Number = "number",
    UserCard = "userCard",
    UserCardArray = "userCardArray",
    Boolean = "boolean",
    ActionButton = "actionButton",
    EntityLookup = "entityLookup",
    Custom = "custom"
}

export enum TableFieldType {
    Mandatory = "mandatory",
    Default = "default",
    Optional = "optional"
}

export enum ImageParagraphAlignment {
    ImageAboveParagraph = "Image above paragraph",
    ImageBelowParagraph = "Image below paragraph",
}

export enum MediaType {
    Image = "image",
    ArticleImage = "articleImage",
    Document = "document"
}