import { MarkdownField } from "@pankod/refine-antd";
import { buildAttachmentUrl, RESOURCE_PATH, useAttachment } from "scripts/site";
import { RenderSpinner } from "./spinner";
import { getAppTheme } from "scripts/theme";

export declare type mdProps = {
    mdFilePath: string;
    resourceName: string;
};

export const RenderMDFile: React.FC<mdProps> = ({
    mdFilePath,
    resourceName
}) => {

    const mdFileUrl = buildAttachmentUrl(RESOURCE_PATH.METADATA, resourceName, mdFilePath);
    const { response: mdData } = useAttachment(mdFileUrl);

    return (
        mdData ?
            <div className={`hlm-md ${getAppTheme()} markdown`}>
                <MarkdownField value={mdData} />
            </div>
            :
            <RenderSpinner message="Loading content..." />
    );
};