import { arrayExtensions } from "@architecture-innovation-transformation/lib-common";
import { Col, Divider, Image, Row, Space, Tag, Typography } from "@pankod/refine-antd";
import { useMany } from "@pankod/refine-core";
import { ILookup, IUser } from "interfaces";
import { arrayJoinString, DATAPROVIDER_LOOKUP, HELIUM_LOGO_PATH, RESOURCE_PATH } from "scripts/site";
import { ShowUserBasics } from "./showUserBasics";

export declare type BasicProfileProps = IUser & {
    isCurrentUser?: boolean;
};
export const DisplayBasicProfile: React.FC<BasicProfileProps> = (userObj: BasicProfileProps) => {
    const { Title, Text } = Typography;
    const { data: roleData } = useMany<ILookup>({
        dataProviderName: DATAPROVIDER_LOOKUP,
        resource: RESOURCE_PATH.ROLE,
        ids: userObj?.roleIds ?? [],
        queryOptions: {
            enabled: (userObj?.roleIds ?? []).length > 0,
        },
    });

    return (
        <>
            <Row gutter={[16, 16]}>
                <Col xl={12} xs={24}>
                    <Title style={{ marginTop: 15 }} level={5}>About Me</Title>
                    <Text>{userObj.aboutMe ?? "---"}</Text>
                    <Title style={{ marginTop: 15 }} level={5}>My Hobbies</Title>
                    <Text>{arrayJoinString(userObj.hobbies)}</Text>
                    <Title style={{ marginTop: 15 }} level={5}>My Technical Expertise</Title>
                    <Text>{arrayJoinString(userObj.technicalExpertiseAreas)}</Text>
                    <Title style={{ marginTop: 15 }} level={5}>My Functional Expertise</Title>
                    <Text>{arrayJoinString(userObj.functionalExpertiseAreas)}</Text>
                    <Title style={{ marginTop: 15 }} level={5}>Desk</Title>
                    <Text>{userObj?.employee?.desk || "---"}</Text>
                </Col>
                <Col xl={12} lg={24} xs={24}>
                    <Space align="center" style={{ marginBottom: 10 }} size={12}>
                        <Image
                            width={120}
                            height={120}
                            style={{ borderRadius: "50%" }}
                            alt="Profile Image"
                            src={userObj?.userImages?.find(img => img.uid === userObj.userProfileImage)?.url || HELIUM_LOGO_PATH}
                            placeholder={true}
                        />
                    </Space>
                    <ShowUserBasics {...userObj} ></ShowUserBasics>
                </Col>
            </Row>
            <Divider></Divider>
            {userObj?.roleIds &&
                <>
                    <Title style={{ marginTop: 20 }} level={5}>Roles</Title>
                    {roleData?.data
                        ? arrayExtensions.sortByName(roleData?.data).map((r, i) => (
                            <Tag key={i} color={r.ui?.color || "blue"}>{r.name || ""}</Tag>
                        ))
                        : ((userObj?.roleIds ?? ["No Role"]).map((r, i) => (
                            <Tag key={i} color={"default"}>{r || ""}</Tag>
                        )))}
                </>
            }
        </>
    );
};