import { stringExtensions } from "@architecture-innovation-transformation/lib-common";
import { Divider, EditButton, Icons, Show, Tabs, Tag } from "@pankod/refine-antd";
import { IResourceComponentsProps, useGetIdentity, useNavigation } from "@pankod/refine-core";
import { OrgChart } from "components/common/orgChart";
import { DisplayAuthZ } from "components/profile/displayAuthZ";
import { DisplayBasicProfile } from "components/profile/displayBasicProfile";
import { DisplayDetailedProfile } from "components/profile/displayDetailedProfile";
import { DisplayMoreTab } from "components/profile/displayMoreTab";
import { DisplayPreferencesTab } from "components/profile/displayPreferencesTab";
import { DisplayRnRTab } from "components/profile/displayRnRTab";
import { ObjectMetadata } from "components/utils/objectMetadata";
import { IBaseProps, IUser } from "interfaces";
import { useLocation } from "react-router-dom";

export const ProfileList: React.FC<IResourceComponentsProps> = () => {
  const { data: userData, isLoading } = useGetIdentity();
  const record = userData as IUser;
  const { push } = useNavigation();
  const { pathname } = useLocation();

  const getTabs = () => {
    let tabs: any[] = [];

    // Render tabs, if record exists
    if (record) {
      // Add the Info Tab
      tabs.push(
        {
          label: (<span><Icons.IdcardOutlined />Info</span>),
          key: "info",
          children: <>
            {<DisplayBasicProfile {...record} isCurrentUser></DisplayBasicProfile>}
            {<DisplayDetailedProfile {...record}></DisplayDetailedProfile>}
          </>
        });


      // For Employees
      if (record.employee) {
        //Add R&R Tabs
        tabs.push({
          label: (<span><Icons.StarOutlined />Rewards and Recognition</span>),
          key: "randr",
          children: <DisplayRnRTab {...record.employee}></DisplayRnRTab>
        });

        // Organization Chart
        tabs.push({
          label: (<span><Icons.ApartmentOutlined />True Hierarchy&trade;</span>),
          key: "orgchart",
          children: <OrgChart {...record}></OrgChart>
        });

        // Add More Tab
        tabs.push({
          label: (<span><Icons.HolderOutlined />More</span>),
          key: "more",
          children: <DisplayMoreTab {...record.employee}></DisplayMoreTab>
        });
      }

      // Add permissions Tab
      tabs.push(
        {
          label: (<span><Icons.SafetyOutlined />Permissions</span>),
          key: "permissions",
          children: <DisplayAuthZ></DisplayAuthZ>
        });

      // Add Preferences tab
      tabs.push(
        {
          label: (<span><Icons.DesktopOutlined />Preferences</span>),
          key: "preferences",
          children: <DisplayPreferencesTab {...record}></DisplayPreferencesTab>
        });
    }

    return tabs;
  }

  return (
    <Show
      isLoading={isLoading}
      canDelete={false}
      headerProps={{
        backIcon: false,
        title: record?.name,
        tags: record?.stateManager?.state ? <Tag color={"green"}>{stringExtensions.capitalize(record?.stateManager?.state)}</Tag> : <></>,
        extra: <EditButton recordItemId={record?.id}>Edit Profile</EditButton>
      }} >

      <Tabs
        //reset the table filters on navigation
        onChange={() => {
          push(pathname)
        }}
        items={getTabs()}
      />
      <Divider></Divider>
      <div style={{ marginTop: 15 }}>
        <ObjectMetadata {...record as IBaseProps} ></ObjectMetadata>
      </div>
    </Show>
  );
};
