import { stringExtensions } from "@architecture-innovation-transformation/lib-common";
import { Drawer, Icons, Show, Tabs, Tag } from "@pankod/refine-antd";
import { IResourceComponentsProps, useOne, useShow } from "@pankod/refine-core";
import { HistoryTab } from "components/common";
import { ManagePosition } from "components/resourcing/managePosition";
import { RenderPositionActions } from "components/resourcing/renderPositionActions";
import { RequestDetails } from "components/resourcing/showRequest";
import { IPosition, IPositionMetadata } from "interfaces/resourcing";
import { useEffect, useState } from "react";
import { DATAPROVIDER_LOOKUP, DATAPROVIDER_READ, RESOURCE_PATH, STALE_DURATION } from "scripts/site";


export const PositionShow: React.FC<IResourceComponentsProps> = () => {

    const { queryResult } = useShow<IPosition>({
        dataProviderName: DATAPROVIDER_READ,
    });

    const { data, isLoading } = queryResult;
    const record = data?.data as IPosition;

    const [reviewFormOpen, switchReviewForm] = useState(false);
    const [refetchData, switchRefetchFlag] = useState(false);

    useEffect(() => {
        if (refetchData) {
            queryResult.refetch();
            switchRefetchFlag(false);
        }
    }, [refetchData, queryResult]);

    const { data: metadata } = useOne<IPositionMetadata>({
        dataProviderName: DATAPROVIDER_LOOKUP,
        resource: RESOURCE_PATH.METADATA,
        id: RESOURCE_PATH.POSITION,
        queryOptions: {
            enabled: true,
            staleTime: STALE_DURATION
        }
    });

    const refetchPositionsData = () => {
        switchRefetchFlag(true);
    }

    const onFormSubmit = () => {
        switchReviewForm(false);
    };

    return (
        <div className="engagement">
            <Show
                isLoading={isLoading}
                headerProps={{
                    title: "Resourcing Position",
                    subTitle: `for ${record?.name}`,
                    tags: record && <Tag color="blue" >{stringExtensions.capitalize(record?.stateManager?.state)}</Tag>
                }}
                goBack=""
                headerButtons={<RenderPositionActions record={record} metadata={metadata?.data} hideViewRequest={false}
                    onClickManage={(e) => {
                        e?.stopPropagation();
                        switchReviewForm(true);
                    }} />}
            >
                <Tabs style={{ marginBottom: 16 }}
                    defaultActiveKey="details"
                    items={[
                        {
                            label: (<span><Icons.InfoOutlined />Position Details</span>),
                            key: "details",
                            children: <>
                                {record && <RequestDetails {...record as IPosition} />}
                            </>
                        },
                        {
                            label: (<span><Icons.HistoryOutlined />History</span>),
                            key: "stdorkflow",
                            children: <>
                                {record &&
                                    <HistoryTab stateManager={record.stateManager} tabPosition='left' />
                                }
                            </>
                        }
                    ]}
                />
                <Drawer
                    title="Manage Position"
                    placement="right"
                    size="large"
                    open={reviewFormOpen}
                    onClose={() => { switchReviewForm(false) }}
                >
                    {metadata?.data && record &&
                        <ManagePosition position={record} positionMetadata={metadata.data} onFormSubmission={onFormSubmit} refetch={refetchPositionsData} />
                    }
                </Drawer>
            </Show >
        </div>
    );
};
