import { AntdList, DateField, DatePicker, Divider, Form, useSimpleList } from "@pankod/refine-antd";
import { CrudFilters, HttpError, useOne } from "@pankod/refine-core";
import { RenderSlots } from "components/spaces/renderSlots";
import dayjs, { Dayjs } from "dayjs";
import { IAvailFilterVariables, IAvailability, ILocations, IRoomConfigSet } from "interfaces";
import { DATAPROVIDER_CONFIGSET, DATAPROVIDER_SEARCH, RESOURCE_PATH, dateRange } from "scripts/site";
import { ReservationLegendTags } from "./reservationLegendTags";

export const RoomAvailability: React.FC<{ roomId: string, locationData?: ILocations }> = ({ roomId, locationData }) => {

    const defaultDateRange = dateRange(dayjs(), dayjs().add(7, 'day'));

    const { listProps, queryResult: dataList, searchFormProps } = useSimpleList<IAvailability, HttpError, IAvailFilterVariables>({
        dataProviderName: DATAPROVIDER_SEARCH,
        resource: RESOURCE_PATH.ROOM,
        permanentFilter: [{
            field: "id",
            operator: "eq",
            value: roomId
        }],
        initialFilter: [{
            field: "_searchDates",
            operator: "in",
            value: defaultDateRange
        }],
        onSearch: (params) => {
            const filters: CrudFilters = [];
            const { selectedDates } = params;

            filters.push({
                field: "_searchDates",
                operator: "in",
                value: dateRange(selectedDates[0], selectedDates[1])
            });

            return filters;
        }
    });

    const { data: configSet } = useOne<IRoomConfigSet>({
        dataProviderName: DATAPROVIDER_CONFIGSET,
        resource: RESOURCE_PATH.ROOM,
        id: roomId,
        queryOptions: {
            enabled: !!roomId,
        }
    });
    const reservationRules = configSet?.data.values;

    function isDateDisabled(selDate: Dayjs): boolean {
        return (selDate
            //Nobody can set a reservation in the past
            && selDate.startOf('day') < dayjs().tz(locationData?.timezoneIANA).startOf('day'))
            //If advance days exist or it's equal to 0. (!!0 returns false) 
            || ((!!reservationRules?.advanceDays || reservationRules?.advanceDays === 0)
                //Can't be more than the last
                && (selDate.startOf('day') > dayjs().tz(locationData?.timezoneIANA).add(reservationRules.advanceDays, 'day').startOf('day'))
            );
    }

    return (
        <>
            <Form {...searchFormProps}>
                <Form.Item
                    //style={{ marginBottom: 10 }}
                    name='selectedDates'
                    initialValue={[dayjs(), dayjs().add(7, 'day')]}
                >
                    <DatePicker.RangePicker
                        disabledDate={isDateDisabled}
                        onChange={() => { searchFormProps.form?.submit(); }}
                    />
                </Form.Item>
            </Form>
            <div style={{ marginBottom: 10 }}>
                <ReservationLegendTags></ReservationLegendTags>
            </div>
            <Divider dashed style={{ marginTop: 5, marginBottom: 10 }}></Divider>
            <AntdList itemLayout="vertical"
                {...listProps}
                loading={dataList.isRefetching || dataList.isLoading}
                pagination={false}
                renderItem={(item, index) => (
                    <AntdList.Item key={index}>
                        <DateField format="ll" value={item.date} style={{ marginLeft: 10 }} />
                        <Divider type="vertical"></Divider>

                        <RenderSlots {...item}></RenderSlots>
                    </AntdList.Item>
                )}
            >
            </AntdList>
        </>
    );
}