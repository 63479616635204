import { arrayExtensions } from "@architecture-innovation-transformation/lib-common";
import { Button, Col, Divider, Row, Space, Tag, Typography } from "@pankod/refine-antd";
import { ShowUserCard } from "components/profile/showUserCard";
import { displayRelativeDate } from "components/utils/displayRelativeDate";
import { ICandidate, ICandidateBA, ICandidateExternal, ICandidateInternal } from "interfaces/resourcing";
import { rowGutter, threeColumnLayout } from "scripts/layout";
import { RESOURCE_PATH, doesExist, formatMS, timeDiff } from "scripts/site";
import { DisplayInternalCandidate } from "./displayInternalCandidate";
import { useNavigation } from "@pankod/refine-core";
import { DisplayExternalCandidate } from "./displayExternalCandidate";

export const CandidateDetails: React.FC<ICandidate | ICandidateInternal | ICandidateBA | ICandidateExternal> = (record) => {

    const { Title, Text } = Typography;
    const { show } = useNavigation();
    return (
        <>
            <Row gutter={rowGutter}>
                <Col className="gutter-row" {...threeColumnLayout}>
                    <Title level={5}>Candidate ID</Title>
                    <Text copyable>{record?.id}</Text>
                    <Title level={5}>Candidate Type</Title>
                    <Text copyable>{record?.candidateType}</Text>
                    <Title level={5}>Contact Phone Number</Title>
                    <Text copyable={doesExist(record?.phoneNumber)}>{record?.phoneNumber ?? "---"}</Text>
                    <Title level={5}>Total Experience (In Years)</Title>
                    <Text copyable={doesExist(record?.totalExperience)}>{record?.totalExperience ?? "---"}</Text>
                    <Title level={5}>Remarks</Title>
                    <Text copyable={doesExist(record?.remarks)}>{record?.remarks ?? "---"}</Text>
                </Col>
                <Col className="gutter-row" {...threeColumnLayout}>
                    <Title level={5}>Tagged to Request</Title>
                    {record?.currentMappedRequestId ?
                        <Button
                            type="link"
                            style={{ padding: 0 }}
                            onClick={() => { show(RESOURCE_PATH.RESOURCINGREQUEST, record?.currentMappedRequestId) }}
                        >{record.currentMappedRequestId}</Button> : <Text>---</Text>
                    }
                    <Title level={5}>Country</Title>
                    <Text copyable={doesExist(record?.countryId)}>{record?.countryId ?? "---"}</Text>
                    <Title level={5}>State</Title>
                    <Text copyable={doesExist(record?.state)}>{record?.state ?? "---"}</Text>
                    <Title level={5}>City</Title>
                    <Text copyable={doesExist(record?.city)}>{record?.city ?? "---"}</Text>
                    <Title level={5}>Preferred Location</Title>
                    <Text copyable={doesExist(record?.preferredLocation)}>{record?.preferredLocation ?? "---"}</Text>
                </Col>
                <Col className="gutter-row" {...threeColumnLayout}>
                    {
                        record && arrayExtensions.validateArray(record.stateManager.assignedTo) &&
                        <>
                            <Title level={5}>Assigned To</Title>
                            <Space style={{ marginBottom: 15 }} wrap split={<Divider type="vertical" />} >
                                {record.stateManager.assignedTo?.map((e, i) =>
                                    <ShowUserCard key={`assgnd-${i}`} id={e}></ShowUserCard>
                                )}
                            </Space>
                        </>
                    }
                    <Title level={5}>Profile Age (Created in System)</Title>
                    <Text copyable>{record ? formatMS(timeDiff(record.createdAt, new Date().toUTCString()), false, false, 5, true, false) : "Loading"}</Text>
                    <Title level={5}>Last Updated</Title>
                    <Text copyable>{record ? displayRelativeDate(record.updatedAt) : "Loading"}</Text>
                    <Title level={5}>Skill Category</Title>
                    <Text copyable={doesExist(record?.skillCategory)}>{record?.skillCategory ?? "---"}</Text>
                    <Title level={5}>Skills</Title>
                    <Text>{arrayExtensions.validateArray(record?.skills ?? []) ? record?.skills?.map((e, i) => <Tag style={{ marginBottom: 8 }} key={`skl-${i}`}>{e}</Tag>) : "---"}</Text>
                </Col>
            </Row>
            {
                record?.candidateType === "TCS Internal" &&
                <DisplayInternalCandidate {...record as ICandidateInternal} />
            }
            {
                record?.candidateType === "BA" &&
                <DisplayExternalCandidate {...record as ICandidateBA} />
            }
            {
                record?.candidateType === "External" &&
                <DisplayExternalCandidate {...record as ICandidateExternal} />
            }
        </>
    );
}