import { arrayExtensions, stringExtensions } from "@architecture-innovation-transformation/lib-common";
import { Button, CreateButton, Drawer, Icons, List, Space, Table, Tag, TextField, Typography, getDefaultSortOrder, useTable } from "@pankod/refine-antd";
import { CrudFilters, HttpError, IResourceComponentsProps, useGetIdentity, useNavigation, useOne } from "@pankod/refine-core";
import { RenderWorkflowModal } from "components/common";
import { ShowUserCard } from "components/profile/showUserCard";
import { PromptFilter } from "components/prompt/promptFilter";
import dayjs from "dayjs";
import { IState, IUser } from "interfaces";
import { IPrompt, IPromptFilterVariables, IPromptMetadata } from "interfaces/article";
import { useState } from "react";
import { DATAPROVIDER_LOOKUP, DATAPROVIDER_READ, RESOURCE_PATH, STALE_DURATION } from "scripts/site";

export const PromptList: React.FC<IResourceComponentsProps> = () => {
  const { data } = useGetIdentity();
  const user = data as IUser;

  const { show } = useNavigation();
  const [filterOpen, switchFilterForm] = useState(false);
  const [myPrompts, setMyPrompts] = useState(false);

  const listFilter: CrudFilters = [
    {
      field: "stateManager.state",
      operator: "eq",
      value: myPrompts ? "" : "active,archived"
    },
    {
      field: "authorName ARRAY_CONTAINS_ANY",
      operator: "eq",
      value: myPrompts ? user.id : ""
    },
    {
      field: "_arrayfields",
      operator: "eq",
      value: "applicableTo,authorName"
    }
  ];

  const { data: entityMetadata } = useOne<IPromptMetadata>({
    dataProviderName: DATAPROVIDER_LOOKUP,
    resource: RESOURCE_PATH.METADATA,
    id: RESOURCE_PATH.PROMPT,
    queryOptions: {
      enabled: true,
      staleTime: STALE_DURATION
    }
  });
  const metaConfig = entityMetadata?.data?.config;
  const entityStates = entityMetadata?.data?.states as IState[] ?? [];

  const { tableProps, sorter, tableQueryResult, searchFormProps, filters } = useTable<IPrompt, HttpError, IPromptFilterVariables>({
    dataProviderName: DATAPROVIDER_READ,
    initialSorter: [
      {
        field: "updatedAt",
        order: "desc"
      },
    ],
    permanentFilter: listFilter,
    onSearch: (params) => {
      const filters: CrudFilters = [];
      const { q, applicableTo, authorName } = params;
      filters.push({
        field: "q",
        operator: "contains",
        value: q
      });
      filters.push({
        field: "applicableTo ARRAY_CONTAINS_ANY",
        operator: "eq",
        value: applicableTo
      });
      filters.push({
        field: "authorName ARRAY_CONTAINS_ANY",
        operator: "eq",
        value: authorName
      });
      return filters;
    }
  });

  function rowClick(record: IPrompt) {
    return {
      onClick: () => {
        show(RESOURCE_PATH.PROMPT, record.id);
      },
    };
  }
  const totalRequests = tableQueryResult?.data?.total ?? 0;
  let isFiltered = filters && filters.length > listFilter.length && filters.some(fltr => fltr.value !== undefined)

  const clearFilters = () => {
    searchFormProps.form?.resetFields();
    searchFormProps.form?.submit();
  }

  return (
    <List
      headerProps={{
        title: (tableQueryResult.isLoading ? "Loading" : totalRequests) + " Prompt" + (totalRequests !== 1 ? "s" : ""),
        tags: <>
          <Button key="filter" type="default"
            icon={isFiltered ? <Icons.FilterFilled /> : <Icons.FilterOutlined />}
            style={{ paddingRight: (isFiltered ? 4 : 15) }}
            title={isFiltered ? "You are viewing a filtered list" : (entityMetadata?.data?.config?.search?.textMessages?.buttonText || "Search & Filter")}
            onClick={() => switchFilterForm(true)}>
            Search
            {isFiltered &&
              <>
                <Button
                  type="text"
                  danger
                  title={"Clear Filters"}
                  size="small"
                  style={{ marginLeft: 5 }}
                  onClick={(e) => {
                    clearFilters();
                    e.stopPropagation();
                  }}
                  icon={<Icons.CloseOutlined />}
                >
                </Button>
              </>
            }
          </Button>
        </>,
        extra:
          <Space>
            <CreateButton icon={<Icons.PlusOutlined />}>Add</CreateButton>
            <Button
              title={myPrompts ? "Show All Prompts" : "Show My Prompts"}
              icon={myPrompts ? <Icons.BarsOutlined /> : <Icons.SolutionOutlined />}
              onClick={() => setMyPrompts(!myPrompts)}
            >
              {myPrompts ? "All Prompts" : "My Prompts"}
            </Button>
            {entityStates.length > 0 ? RenderWorkflowModal(entityStates) : <></>}
          </Space>
      }}
    >

      <Table {...tableProps}
        rowKey="id"
        pagination={{
          ...tableProps.pagination,
          position: ["bottomRight"],
          showTotal: (total => <Typography.Title level={4} style={{ marginRight: 10 }} >Total {total}</Typography.Title>)
        }}
      >
        <Table.Column
          dataIndex="name"
          key="name"
          title="Title"
          defaultSortOrder={getDefaultSortOrder("name", sorter)}
          sorter
          onCell={rowClick}
          className="mouseHand"
        />

        <Table.Column
          dataIndex="description"
          key="description"
          title="Intent"
          defaultSortOrder={getDefaultSortOrder("description", sorter)}
          sorter
          onCell={rowClick}
          className="mouseHand"
        />

        <Table.Column
          dataIndex={["stateManager", "state"]}
          key="stateManager.state"
          title="State"
          onCell={rowClick}
          className="mouseHand"
          sorter
          render={(value) => <TextField value={stringExtensions.capitalize(value)} />}
        />

        <Table.Column
          dataIndex="applicableTo"
          key="applicableTo"
          title="Applicable Platforms"
          onCell={rowClick}
          className="mouseHand"
          render={(value: string[]) =>
            value && <Typography.Title
              style={{
                fontWeight: "unset",
                fontSize: "14px",
              }}
              level={5}
              ellipsis={{
                rows: 2,
                tooltip: value.join(", "),
              }}
            >
              {arrayExtensions.validateArray(value ?? [])
                && value.map((e, i) =>
                  <div key={i} style={{ marginBottom: 5 }}>
                    <Tag //style={{ marginBottom: 7 }}
                      color={"blue"}
                      key={`tag-${i}`}>{e}</Tag>
                  </div>
                )}
            </Typography.Title>
            //value.map((r, i) => (

            // <div key={i} style={{ marginBottom: 5 }}>
            //   <Tag color="blue">{r}</Tag>
            // </div>
            //))
          }
        />

        <Table.Column
          dataIndex="authorName"
          title="Authors"
          onCell={rowClick}
          className="mouseHand"
          render={(value: string[]) =>
            value && value.map((r, i) => (
              <div key={i} style={{ marginBottom: 5 }}>
                <ShowUserCard id={r} />
              </div>
            ))
          }
        />

        <Table.Column
          dataIndex="publishedDate"
          key="publishedDate"
          title="Published Date"
          render={(value: string) => <>
            {value &&
              <Typography.Text style={{ color: "GrayText" }}>{dayjs(value).format('LLLL')}</Typography.Text>
            }
          </>}
          defaultSortOrder={getDefaultSortOrder("publishedDate", sorter)}
          sorter
          onCell={rowClick}
          className="mouseHand"
        />

      </Table>
      <Drawer
        title={metaConfig?.search?.textMessages?.drawerFormHeader || "Prompt - Search & Filter"}
        placement="right"
        size="default"
        open={filterOpen}
        onClose={() => { switchFilterForm(false) }}
      >
        <PromptFilter formProps={searchFormProps}
          onApplyFilter={() => switchFilterForm(false)} />
      </Drawer>
    </List>
  );
};