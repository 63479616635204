import { IBaseProps } from "interfaces";
import { CreatedBy } from "./createdBy";
import { UpdatedBy } from "./updatedBy";

export const ObjectMetadata: React.FC<IBaseProps> = (propObj: IBaseProps) => {
    return <div>
        <UpdatedBy {...propObj}></UpdatedBy>
        <br />
        <CreatedBy {...propObj}></CreatedBy>
    </div>;
};