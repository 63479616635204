import { Edit, Form, SaveButton, Typography, useFileUploadState, useForm } from "@pankod/refine-antd";
import { IResourceComponentsProps, useOne } from "@pankod/refine-core";
import { CancelButton } from "components/utils/cancelButton";
import { LookupButton } from "components/utils/lookupButton";
import { IEngagement, IEngagementSolution, IProgram, IRecordMetricValue } from "interfaces";
import { useEffect, useState } from "react";
import { DATAPROVIDER_READ, DATAPROVIDER_UPDATE, RESOURCE_PATH, THINK_GEN_AI_LOGO_BLACK, THINK_GEN_AI_LOGO_WHITE, syncMetricValueList } from "scripts/site";
import { getAppTheme } from "scripts/theme";
import { RenderFields } from "./renderFields";

export const EngagementSolutionEdit: React.FC<IResourceComponentsProps> = () => {

  const { form, formProps, saveButtonProps, queryResult } = useForm<IEngagementSolution>({
    dataProviderName: DATAPROVIDER_UPDATE,
    redirect: "show",
  });
  const record = queryResult?.data?.data;

  const { data: progRecord } = useOne<IProgram>({
    dataProviderName: DATAPROVIDER_READ,
    resource: RESOURCE_PATH.PROGRAM,
    id: record?.programId ?? "",
    queryOptions: {
      enabled: !!record?.programId
    },
  });

  const { data: engagmentRecord } = useOne<IEngagement>({
    dataProviderName: DATAPROVIDER_READ,
    resource: RESOURCE_PATH.ENGAGEMENT,
    id: record?.engagementId ?? "",
    queryOptions: {
      enabled: !!record?.engagementId
    },
  });

  const [recordKeyResults, setRecordKeyResults] = useState<IRecordMetricValue[]>([]);

  useEffect(() => {
    if (record) {
      // Sync up the key results with the program key results
      if (!record?.keyResults) {
        record.keyResults = [];
      }
      const syncedKeyResults = syncMetricValueList(progRecord?.data?.keyResults ?? [], record.keyResults);
      setRecordKeyResults(syncedKeyResults);

      form.setFieldsValue({
        keyResults: syncedKeyResults.map((kr) => kr.metricValue),
      });
    }
  }, [form, progRecord?.data, record]);
  const { isLoading: isUploading, onChange: onUploadChange } = useFileUploadState();

  return (
    <Edit
      isLoading={queryResult?.isFetching}
      headerProps={{
        title: "Update scenario",
        subTitle: `for ${engagmentRecord?.data?.name ?? ""}`,
        extra: <>
          <img src={getAppTheme() === "dark" ? THINK_GEN_AI_LOGO_WHITE : THINK_GEN_AI_LOGO_BLACK} alt="Think GenAI" style={{
            height: 30,
            width: 150,
          }}></img>
        </>
      }}
      footerButtons={<>
        <SaveButton {...saveButtonProps} disabled={isUploading}></SaveButton>
        <CancelButton />
      </>}>
      <Form {...formProps} layout="vertical" autoComplete="off">
        <div style={{ marginBottom: 10 }}>
          <Typography.Title level={5}>Scenario</Typography.Title>
          <LookupButton id={record?.scenarioId ?? ""} resource={RESOURCE_PATH.SCENARIO} />
        </div>
        <RenderFields recordKeyResults={recordKeyResults} onUploadChange={onUploadChange} images={record?.images ?? []} />
      </Form>
    </Edit>
  );
};