import { Divider, Space, Typography } from "@pankod/refine-antd";
import { IUser } from "interfaces";
import { ShowUserCard } from "./showUserCard";
const { Title } = Typography;

export const DisplayDetailedProfile: React.FC<IUser> = (record: IUser) => {

    return (
        <>
            {record?.managerUserId &&
                <>
                    <Title style={{ marginTop: 15 }} level={5}>TCS Manager</Title>
                    <ShowUserCard id={record?.managerUserId} />
                </>
            }

            {((record?.linkedUserIds?.length || 0) > 0) &&
                <>
                    <Title style={{ marginTop: 15 }} level={5}>Linked Users</Title>
                    <Space wrap split={<Divider type="vertical" />} >
                        {record?.linkedUserIds?.map((e, i) =>
                            <ShowUserCard key={`linked-${i}`} id={e}></ShowUserCard>
                        )}
                    </Space>
                </>
            }
        </>
    );
};