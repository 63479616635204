import { Tag, Typography } from "@pankod/refine-antd";
import { doesExist } from "scripts/site";


export const ParsedResume: React.FC<any> = (resume) => {
    const { Title, Text } = Typography;

    return (<>
        {resume && (Object.keys(resume).map((key, i) => (
            <>
                <Title key={`title-${i}`} level={5}>{key}</Title>
                { // Check if field is array. If yes, then display comma separated values
                    Array.isArray(resume[key])
                        ? (<Text
                            key={`val-${i}`}
                            copyable={{ text: resume[key].join(", ") }}>
                            {
                                (resume[key] as []).map((k, i) =>
                                    key === "InterviewQuestions"
                                        ? <>
                                            <Text>{i + 1}. {k}</Text><br></br>
                                        </>
                                        : <Tag style={{ marginBottom: 7 }} color="blue">{k}</Tag>
                                )
                            }
                        </Text>)
                        : <Text key={`val-${i}`} copyable={doesExist(resume[key])}>{doesExist(resume[key]) ? resume[key] : "---"}</Text>
                }
            </>
        )))}
    </>);
}