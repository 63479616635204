import { Alert, Create, Form, SaveButton, Select, Typography, useFileUploadState, useForm, useSelect } from "@pankod/refine-antd";
import { IResourceComponentsProps, useNavigation, useOne } from "@pankod/refine-core";
import { CancelButton } from "components/utils/cancelButton";
import { LookupButton } from "components/utils/lookupButton";
import { IEngagementSolution, ILookup, IProgram, IRecordMetricValue } from "interfaces";
import { useLocation } from "react-router-dom";
import { DATAPROVIDER_CREATE, DATAPROVIDER_LOOKUP, DATAPROVIDER_READ, RESOURCE_PATH, THINK_GEN_AI_LOGO_BLACK, THINK_GEN_AI_LOGO_WHITE, getQueryStringParams, syncMetricValueList } from "scripts/site";
import { getAppTheme } from "scripts/theme";
import { RenderFields } from "./renderFields";

export const EngagementSolutionCreate: React.FC<IResourceComponentsProps> = () => {
  const { search } = useLocation();
  const { goBack } = useNavigation();
  const { engagementId, programId } = getQueryStringParams(search);

  const { form, formProps, saveButtonProps } = useForm<IEngagementSolution>({
    dataProviderName: DATAPROVIDER_CREATE,
    onMutationSuccess: () => {
      goBack();
    }
  });

  const { data: progRecord } = useOne<IProgram>({
    dataProviderName: DATAPROVIDER_READ,
    resource: RESOURCE_PATH.PROGRAM,
    id: programId,
    queryOptions: {
      enabled: !!programId
    },
  });

  const { selectProps: engagementList } = useSelect<ILookup>({
    dataProviderName: DATAPROVIDER_LOOKUP,
    resource: RESOURCE_PATH.ENGAGEMENT,
    optionLabel: "name",
    optionValue: "id",
    sort: [{
      field: "name",
      order: "asc"
    }],
    queryOptions: {
      enabled: !engagementId
    }
  });

  const { selectProps: scenarioProps } = useSelect<ILookup>({
    dataProviderName: DATAPROVIDER_LOOKUP,
    resource: RESOURCE_PATH.SCENARIO,
    optionLabel: "name",
    optionValue: "id",
    sort: [{
      field: "name",
      order: "asc"
    }],
    // this is the value of the field to be filtered
    filters: [
      {
        field: "programId",
        operator: "eq",
        value: programId
      },
      {
        field: "stateManager.state",
        operator: "eq",
        value: "active"
      },
    ],
    queryOptions: {
      enabled: !!programId
    }
  });


  let recordKeyResults: IRecordMetricValue[] = [];
  recordKeyResults = syncMetricValueList(progRecord?.data?.keyResults ?? [], []);

  form.setFieldsValue({
    keyResults: recordKeyResults.map((kr) => kr.metricValue),
  });

  const { isLoading: isUploading, onChange: onUploadChange } = useFileUploadState();

  return (
    programId ?
      <Create
        headerProps={{
          title: "Implement a Scenario",
          subTitle: progRecord?.data?.name ?? "",
          extra: <>
            {programId && <img src={getAppTheme() === "dark" ? THINK_GEN_AI_LOGO_WHITE : THINK_GEN_AI_LOGO_BLACK} alt="Think GenAI" style={{
              height: 30,
              width: 150,
            }}></img>}
          </>
        }}
        footerButtons={<>
          <SaveButton {...saveButtonProps} disabled={isUploading}>Save</SaveButton>
          <CancelButton />
        </>}
      >
        <Form {...formProps} layout="vertical" autoComplete="off"
          initialValues={{
            engagementId: engagementId,
            programId: programId,
          }}
        >
          <Form.Item
            hidden
            label="Program"
            name="programId"
          >
            <Typography.Text>{programId}</Typography.Text>
          </Form.Item>
          {engagementId ?
            <div style={{ marginBottom: 10 }}>
              <Form.Item
                hidden
                label="Engagement"
                name="engagementId"
              >
                <Typography.Text>{engagementId}</Typography.Text>
              </Form.Item>
              <Typography.Title level={5}>Engagement</Typography.Title>
              <LookupButton id={engagementId} resource={RESOURCE_PATH.ENGAGEMENT} />
            </div>
            : <Form.Item
              label="Engagement"
              name="engagementId"
              rules={[
                {
                  required: true,
                  whitespace: true,
                  type: "string",
                },
              ]}
            >
              <Select placeholder="Search Engagement" {...engagementList} allowClear />
            </Form.Item>
          }

          <Form.Item
            name={["scenarioId"]}
            label="Scenario"
            rules={[
              {
                required: true,
                whitespace: true,
                message: "Please provide a name for the scenario."
              },
            ]}>
            <Select placeholder="Search Scenario" {...scenarioProps} allowClear />
          </Form.Item>
          <RenderFields recordKeyResults={recordKeyResults} onUploadChange={onUploadChange} />
        </Form>
      </Create >
      : <Alert
        showIcon
        message={"Scenario cannot be created without a program. Please use correct navigation to map a scenario."}
        type="warning"
      />
  );
};