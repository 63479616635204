import { arrayExtensions, stringExtensions } from "@architecture-innovation-transformation/lib-common";
import {
  Checkbox,
  EditButton, FilterDropdown, FilterDropdownProps, getDefaultSortOrder, Input, Select, ShowButton, Space, Table, TableProps, TextField, Typography, useSelect} from "@pankod/refine-antd";
import { CrudSorting, useNavigation } from "@pankod/refine-core";
import { StateButtons } from "components/common";
import { ShowUserCard } from "components/profile/showUserCard";
import { displayRelativeDate } from "components/utils/displayRelativeDate";
import { IAsset, IAssetMetadata, ILookup, IUser } from "interfaces";
import { DATAPROVIDER_LOOKUP, RESOURCE_PATH } from "scripts/site";
import { ShowAssetConfig } from "./showAssetConfig";

export declare type ListAssetProps = {
  myAsset: boolean,
  assetData: TableProps<IAsset>,
  sorter: CrudSorting,
  metadata: IAssetMetadata | undefined
};

const resourcePath = RESOURCE_PATH.ASSET;

export const ListAssetTable: React.FC<ListAssetProps> = ({ myAsset, assetData, sorter, metadata }) => {

  const { show } = useNavigation();

  const { selectProps: userProps } = useSelect<ILookup>({
    dataProviderName: DATAPROVIDER_LOOKUP,
    resource: RESOURCE_PATH.PEOPLE,
    optionLabel: "name",
    optionValue: "id",
    sort: [
      {
        field: "lastAccessedAt",
        order: "desc"
      },
    ],
  });

  function rowClick(record: IAsset) {
    return {
      onClick: () => {
        show(RESOURCE_PATH.ASSET, record.id);
      },
    };
  }

  return (
    <Table {...assetData}
      rowKey="id"
      pagination={{
        ...assetData.pagination,
        position: ["bottomRight"],
        showTotal: (total => <Typography.Title level={4} style={{ marginRight: 10 }} >Total {total}</Typography.Title>)
      }}
    >
      <Table.Column
        dataIndex="name"
        key="name"
        title="Asset Type"
        render={(value) => <TextField value={value} />}
        defaultSortOrder={getDefaultSortOrder("name", sorter)}
        sorter
        onCell={rowClick}
        className="mouseHand"

        // Add text filter
        filterDropdown={(props) => (
          <FilterDropdown  {...props} >
            <Checkbox.Group options={(metadata?.config.assetTypes ?? []).map(a => a.name).sort()} />
          </FilterDropdown>
        )}
      />
      <Table.Column
        dataIndex="id"
        key="id"
        onCell={rowClick}
        className="mouseHand"
        title="Asset ID"
        render={(value: string) => <TextField value={value} />}
        defaultSortOrder={getDefaultSortOrder("id", sorter)}
        sorter

        // Add text filter
        filterDropdown={(props: FilterDropdownProps) => (
          <FilterDropdown {...props} >
            <Input />
          </FilterDropdown>
        )}
      />
      <Table.Column
        dataIndex={["stateManager", "state"]}
        key="stateManager.state"
        className="mouseHand"
        title="State"
        render={(value) => <TextField value={stringExtensions.capitalize(value)} />}
        defaultSortOrder={getDefaultSortOrder("stateManager.state", sorter)}
        sorter
        onCell={rowClick}

        // Add text filter
        filterDropdown={(props) => (
          <FilterDropdown {...props}>
            <Checkbox.Group options={(metadata?.states ?? []).map(a => a.state).sort()} />
          </FilterDropdown>
        )}
      />

      {myAsset
        ? <></>
        : <Table.Column
          key="stateManager.assignedTo"
          dataIndex={["stateManager", "assignedTo"]}
          title="Allocated to"
          onCell={rowClick}
          className="mouseHand"
          defaultSortOrder={getDefaultSortOrder("stateManager.assignedTo", sorter)}
          sorter
          render={(value: string) =>
            <ShowUserCard id={value} />
          }

          // Add text filter
          filterDropdown={(props) => (
            <FilterDropdown {...props}>
              <Select
                style={{ minWidth: 200 }}
                mode="multiple"
                placeholder="Select User"
                {...userProps}
              />
            </FilterDropdown>
          )}
        />
      }
      <Table.Column
        dataIndex="serialNumber"
        key="serialNumber"
        onCell={rowClick}
        className="mouseHand"
        title="Serial Number"
        render={(value: string) => <TextField value={value} />}
        sorter

        // Add text filter        
        filterDropdown={(props: FilterDropdownProps) => (
          <FilterDropdown {...props} >
            <Input />
          </FilterDropdown>
        )}
      />

      <Table.Column
        dataIndex="makeModel"
        key="makeModel"
        onCell={rowClick}
        className="mouseHand"
        title="Make/Model"
        render={(value: string) => <TextField value={value} />}
        sorter

        filterDropdown={(props) => (
          <FilterDropdown {...props}>
            <Select
              style={{ minWidth: 200 }}
              placeholder="Select Make/Model"
              showSearch
              mode="multiple"
              optionFilterProp="children"
              filterOption={(input, option: any) =>
                option?.children?.toLowerCase().indexOf(input.toLowerCase()) >= 0
              }
              filterSort={(optionA, optionB) =>
                optionA?.children?.toLowerCase().localeCompare(optionB?.children?.toLowerCase())
              }
            >
              {arrayExtensions.arrayRemoveDuplicate((metadata?.config.assetTypes ?? []).flatMap(a => a.makeModel)).sort().map((m, i) => (
                <Select.Option value={m} key={i}>{m}</Select.Option>
              ))}
            </Select>
          </FilterDropdown>
        )}
      />

      <Table.Column
        onCell={rowClick}
        className="mouseHand"
        title="Configuration"
        render={(value: IAsset) => <ShowAssetConfig {...value} ></ShowAssetConfig>}
      />

      <Table.Column
        dataIndex="updatedAt"
        key="updatedAt"
        title="Last Updated"
        render={displayRelativeDate}
        defaultSortOrder={getDefaultSortOrder("updatedAt", sorter)}
        sorter
        onCell={rowClick}
        className="mouseHand"
      />
      {myAsset
        ?
        <Table.Column
          title="Actions"
          dataIndex="actions"
          render={(_, record: any) => (
            <Space>
              <StateButtons {...record}
                resourceToAct={record.type}
                resourceToActId={record?.id || "0"}
                resourceToNavigate={RESOURCE_PATH.PROFILE}
                hideActions={['submit', 'approve']}
              />
            </Space>
          )}
        />
        : <Table.Column<IUser>
          title="Actions"
          dataIndex="actions"
          render={(_, record) => (
            <Space>
              {/* Hidden button to let AuthZ module check access for it */}
              <ShowButton hidden resourceNameOrRouteName={resourcePath} size="small" recordItemId={record.id} />
              <EditButton resourceNameOrRouteName={resourcePath} size="small" recordItemId={record.id} />
            </Space>
          )}
        />}
    </Table>
  );
};
