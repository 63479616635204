
import { DownCircleOutlined, NodeIndexOutlined, UpCircleTwoTone } from "@ant-design/icons";
import { stringExtensions } from "@architecture-innovation-transformation/lib-common";
import { Button, Tag, Timeline, Typography } from "@pankod/refine-antd";
import { ShowUserCard } from "components/profile/showUserCard";
import { displayRelativeDate } from "components/utils/displayRelativeDate";
import { IStateManager } from "interfaces";
import { useState } from "react";
import { formatMS, timeDiff } from "scripts/site";

const STATE_PREFIX = "In"
const DURATION_PREFIX = "for"
const RESPONIBILITY_PREFIX = "Assigned by"
const ASSIGN_PREFIX = "Assigned to"
const SEPARATOR = ","
const FINAL_CONJUNCTION = "and"

export const TimelineView: React.FC<IStateManager> = (stateManager) => {
    const { Text } = Typography
    const collapseDefaults = [3, 20, 99999, 0, 4]
    const [collapseFlag, setCollapseFlag] = useState(true)
    const [collapseStart, setCollapseStart] = useState(collapseDefaults[0])
    const [collapseEnd, setCollapseEnd] = useState(collapseDefaults[1])
    const [recordFound, setRecordFound] = useState(false)
    if (!recordFound && stateManager.workflow !== undefined) {
        setRecordFound(true)
        setCollapseEnd(stateManager.workflow.length - collapseDefaults[4])
    }

    function click(index: number) {
        if (collapseStart === collapseDefaults[2]) setCollapseStart(index)
        else if (index === collapseStart) reset()
        else if (collapseStart === 0 && index === stateManager.workflow.length - 1) reset()
        else {
            //Clicked Bottom  -> Top
            if (index < collapseStart) {
                setCollapseEnd(collapseStart)
                setCollapseStart(index)
            }
            //Clicked Top -> Bottom
            else {
                setCollapseEnd(index)
            }
            setCollapseFlag(true)
        }
    }

    function reset() {
        setCollapseFlag(false)
        setCollapseStart(collapseDefaults[2])
        setCollapseEnd(collapseDefaults[3])
    }

    function defaultCollapse() {
        setCollapseStart(collapseDefaults[0])
        setCollapseEnd(stateManager.workflow.length - collapseDefaults[4])
        setCollapseFlag(true)
    }

    const mapTimeline = () => {
        return stateManager.workflow.map(function (entry, index: number) {

            let timeInfo: JSX.Element = <>{STATE_PREFIX} <Tag>{stringExtensions.capitalize(entry.state)}</Tag>{DURATION_PREFIX} {formatMS(timeDiff(entry.beginTS, entry.endTS), false, false, undefined, true)}</>
            let assignees: JSX.Element[] = []
            let responsibility: JSX.Element = <><br />{<Typography.Text style={{ color: "GrayText" }}> {RESPONIBILITY_PREFIX} </Typography.Text>} <ShowUserCard id={entry.stateChangedBy} /> {displayRelativeDate(entry.beginTS)}</>
            let currentItem: JSX.Element = <></>

            //Setting assignedTo if there is anyone assigned
            for (let i = 0; i < entry.assignedTo.length; i++) {
                if (i === entry.assignedTo.length - 1 && i > 0) assignees.push(<Text key={"conjunction-" + index.toString()}> {FINAL_CONJUNCTION} </Text>)
                assignees.push(<ShowUserCard id={entry.assignedTo[i]} key={index.toString() + '-' + i.toString()} />)
                if (i < entry.assignedTo.length - 2) assignees.push(<Text key={"separator-" + index.toString() + '-' + i.toString()}>{SEPARATOR} </Text>)
            }

            //Collapsing within range of selected indexes
            if (collapseFlag && index <= collapseEnd && index >= collapseStart) {
                if (index === collapseStart) {
                    //Turn the dot into a button
                    currentItem = (
                        <Timeline.Item
                            dot={<Button shape="circle" type="primary" icon={<NodeIndexOutlined />} onClick={() => { reset() }} />}
                            key={"collapseButton"}
                        >
                            <br />
                        </Timeline.Item>
                    )

                }
                else if (index > collapseStart) {
                    return <Text key={"none-" + index.toString()}></Text>
                }
            }

            //Standard Timeline Item
            else {
                currentItem = (
                    <Timeline.Item
                        dot={(!collapseFlag) ? <Button type="link" icon={(index < collapseStart) ? <DownCircleOutlined /> : <UpCircleTwoTone />} onClick={() => click(index)} /> : null}
                        key={index.toString()}
                    >
                        {timeInfo}
                        {entry.assignedTo.length > 0 && <><br />{ASSIGN_PREFIX} </>}
                        {assignees}
                        {responsibility}
                    </Timeline.Item>
                )
            }

            return currentItem
        });
    }

    return (
        <>
            {
                stateManager.workflow.length >= collapseDefaults[0] + collapseDefaults[4] &&
                <Button onClick={defaultCollapse} key={"defaultButton"}>
                    Default View
                </Button>
            }
            <Timeline mode="alternate" style={{ marginTop: '10px' }} key={"timeline"}>
                {mapTimeline()}
            </Timeline>
        </>
    );
};