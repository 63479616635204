import { arrayExtensions } from "@architecture-innovation-transformation/lib-common";
import { DateField, Divider, EditButton, Show, Space, Typography } from "@pankod/refine-antd";
import { CanAccess, IResourceComponentsProps, useShow } from "@pankod/refine-core";
import { EngagementSubMenu } from "components/engagement/subMenu";
import { ShowUserCard } from "components/profile/showUserCard";
import { LookupButton } from "components/utils/lookupButton";
import { ObjectMetadata } from "components/utils/objectMetadata";
import { IBaseProps, IEngagement } from "interfaces";
import { DATAPROVIDER_READ, DATAPROVIDER_UPDATE, RESOURCE_PATH } from "scripts/site";

const { Title, Text } = Typography;

export const EngagementShow: React.FC<IResourceComponentsProps> = () => {
    const { queryResult, showId } = useShow<IEngagement>({
        dataProviderName: DATAPROVIDER_READ
    });

    const { data, isLoading } = queryResult;
    const record = data?.data;

    return (
        <div className="engagement">
            <Show
                isLoading={isLoading}
                canDelete={false}
                title={record?.name}
                headerProps={{
                    extra: <>
                        <CanAccess key={RESOURCE_PATH.ENGAGEMENT} resource={RESOURCE_PATH.ENGAGEMENT.toLowerCase()} action="edit" params={{ id: showId, dataProviderName: DATAPROVIDER_UPDATE }}>
                            <EditButton></EditButton>
                        </CanAccess>
                    </>,
                    backIcon: false
                }}
            >
                <EngagementSubMenu engagementId={showId?.toString() || ""} selectedResource={RESOURCE_PATH.ENGAGEMENT} ></EngagementSubMenu>
                <div style={{ margin: 24 }}>
                    <Title level={5}>Name</Title>
                    <Text>{record?.name}</Text>

                    <Title level={5}>Description</Title>
                    <Text>{record?.description ?? "---"}</Text>

                    <Title level={5}>Start Date</Title>
                    <Text>{record?.startDate && <DateField format="ll" value={record?.startDate} />}</Text>

                    <Title level={5}>End Date</Title>
                    <Text>{record?.endDate && <DateField format="ll" value={record?.endDate} />}</Text>

                    <Title level={5}>Business Group</Title>
                    <Text>{record?.businessGroup}</Text>

                    <Title level={5}>Service Lines</Title>
                    <Space wrap split={<Divider type="vertical" />} >
                        {arrayExtensions.validateArray(record?.serviceLineIds ?? []) ? record?.serviceLineIds?.map((e, i) => <LookupButton resource={RESOURCE_PATH.SERVICELINE} noClick key={`other-${i}`} id={e}></LookupButton>) : "None"}
                    </Space>

                    <Title style={{ marginTop: 20 }} level={5}>Sales Executives</Title>
                    <Space wrap split={<Divider type="vertical" />} >
                        {arrayExtensions.validateArray(record?.salesExecutives ?? []) ? record?.salesExecutives?.map((e, i) => <ShowUserCard key={`sales-${i}`} id={e}></ShowUserCard>) : "None"}
                    </Space>

                    <Title style={{ marginTop: 20 }} level={5}>Delivery Executives</Title>
                    <Space wrap split={<Divider type="vertical" />} >
                        {arrayExtensions.validateArray(record?.deliveryExecutives ?? []) ? record?.deliveryExecutives?.map((e, i) => <ShowUserCard key={`delivery-${i}`} id={e}></ShowUserCard>) : "None"}
                    </Space>

                    <Title style={{ marginTop: 20 }} level={5}>Other Authorized users</Title>
                    <Space wrap split={<Divider type="vertical" />} >
                        {arrayExtensions.validateArray(record?.otherAuthorizedUsers ?? []) ? record?.otherAuthorizedUsers?.map((e, i) => <ShowUserCard key={`other-${i}`} id={e}></ShowUserCard>) : "None"}
                    </Space>

                    <Title style={{ marginTop: 20 }} level={5}>Clients</Title>
                    <Space wrap split={<Divider type="vertical" />} >
                        {arrayExtensions.validateArray(record?.clients ?? []) ? record?.clients?.map((e, i) => <ShowUserCard key={`client-${i}`} id={e}></ShowUserCard>) : "None"}
                    </Space>

                    <Divider />
                    <div>
                        <ObjectMetadata {...record as IBaseProps}></ObjectMetadata>
                    </div>
                </div>
            </Show>

        </div>
    );
};


