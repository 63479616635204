import { Col, DeleteButton, Divider, EditButton, MarkdownField, Row, Show, Tag, Typography } from "@pankod/refine-antd";
import { IResourceComponentsProps, useGetIdentity, useShow } from "@pankod/refine-core";
import { ObjectMetadata } from "components/utils/objectMetadata";
import dayjs from "dayjs";
import { IBaseProps, IEvent, IUser } from "interfaces";
import { DATAPROVIDER_DELETE, DATAPROVIDER_LOOKUP, parseTimeZone, roundToNearesetFive } from "scripts/site";

export const EventShow: React.FC<IResourceComponentsProps> = () => {

  const { queryResult } = useShow<IEvent>({
    dataProviderName: DATAPROVIDER_LOOKUP,
  });

  const { data: userData } = useGetIdentity();
  const userObj = userData as IUser;
  const { data, isLoading } = queryResult;
  const { Title, Text } = Typography;
  const record = data?.data as IEvent;

  return (
    <Show
      isLoading={isLoading}
      title={data?.data.name}
      headerProps={{
        backIcon: false,
        extra: <>
          {((record?.stateManager.state === "scheduled" || record?.stateManager.state === "draft") &&
            ((record?.eventOrganizer === userObj.id) || record?.eventOrganizingTeam?.find(x => x === userObj.id))) ?
            <EditButton></EditButton>
            : ""}
          {(record?.stateManager.state === "draft" &&
            ((record?.eventOrganizer === userObj.id) || record?.eventOrganizingTeam?.find(x => x === userObj.id))) ?
            <DeleteButton dataProviderName={DATAPROVIDER_DELETE}></DeleteButton>
            : ""}
        </>
      }}
    >
      <Divider></Divider>
      <Row gutter={[16, 16]}>
        <Col xl={18} xs={24}>
          <Title level={5}>Event Name</Title>
          <Text>{record?.name}</Text>

          <Title level={5}>Description</Title>
          <Text>{record?.description}</Text>

          <Title level={5}>Joining Details</Title>
          <div className={"markdown"}><MarkdownField value={record?.eventJoiningBlurb} /></div>

          <Title level={5}>Starts at</Title>
          <Text>{dayjs(record?.eventStartTime).format('LLLL')}
            <Tag color="cyan" style={{ marginLeft: "15px" }}>
              {parseTimeZone(userObj?.userTime)}
            </Tag>
          </Text>

          <Title level={5}>Ends at</Title>
          <Text>{dayjs(record?.eventEndTime).format('LLLL')}
            <Tag color="cyan" style={{ marginLeft: "15px" }}>
              {parseTimeZone(userObj?.userTime)}
            </Tag>
          </Text>

        </Col>
        <Col xl={6} lg={24} xs={24}>
          <Title style={{ marginTop: 15 }} level={5}>Target Audience</Title>
          <Text>{record?.eventAudience}</Text>

          <Title level={5}>Category</Title>
          <Text>{record?.eventCategory}</Text>

          <Title level={5}>Delivery Mode</Title>
          <Text>{record?.eventMode}</Text>

          <Title level={5}>Event Duration</Title>
          {/* Add 1 minute - To address Zero based index */}
          <Text>{`${roundToNearesetFive(dayjs(record?.eventEndTime).diff(dayjs(record?.eventStartTime), "minute"))} minutes`}</Text>
        </Col>
      </Row>
      <Divider></Divider>
      <div style={{ marginTop: 15 }}>
        <ObjectMetadata {...record as IBaseProps} ></ObjectMetadata>
      </div>
    </Show>
  );
};