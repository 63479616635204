import { Button, CreateButton, Drawer, ExportButton, Icons, List, useTable } from "@pankod/refine-antd";
import { CrudFilter, CrudFilters, HttpError, IResourceComponentsProps, useExport, useGetIdentity, useOne, } from "@pankod/refine-core";
import { AssetFilter } from "components/asset/assetFilter";
import { RenderSpinner } from "components/common";
import { IAsset, IAssetFilterVariables, IAssetMetadata, IUser } from "interfaces";
import { useEffect, useState } from "react";
import { DATAPROVIDER_EXPORT, DATAPROVIDER_LOOKUP, DATAPROVIDER_READ, RESOURCE_PATH } from "scripts/site";
import { ListAssetTable } from "./listAssetTable";

const resourcePath = RESOURCE_PATH.ASSET;
export const AssetList: React.FC<IResourceComponentsProps> = () => {

  const { data: entityMetadata, isLoading } = useOne<IAssetMetadata>({
    dataProviderName: DATAPROVIDER_LOOKUP,
    resource: RESOURCE_PATH.METADATA,
    id: RESOURCE_PATH.ASSET
  });

  const { data: userData, isLoading: userLoading } = useGetIdentity();
  const userObj = userData as IUser;
  const metaConfig = entityMetadata?.data?.config;
  const canAllocate = (metaConfig?.roleIdsCanAssign ?? []).some(r => userObj?.entityRoleIds.some(ri => r === ri));

  const [filterOpen, switchFilterForm] = useState(false);
  const [myAssetView, switchMyAssetView] = useState(false);
  const [fetchData, switchFetchData] = useState(false);

  const assignedFilter: CrudFilter[] = myAssetView ? [{
    field: "stateManager.assignedTo ARRAY_CONTAINS",
    operator: "eq",
    value: userObj?.id
  }
  ] : [
    {
      field: "_arrayfields",
      operator: "eq",
      value: "stateManager.assignedTo"
    }
  ];

  const { tableProps, tableQueryResult, searchFormProps, sorter } = useTable<IAsset, HttpError, IAssetFilterVariables>({
    dataProviderName: DATAPROVIDER_READ,
    resource: resourcePath,
    initialSorter: [
      {
        field: "updatedAt",
        order: "desc"
      },
    ],
    permanentFilter: assignedFilter,
    onSearch: (params) => {
      const filters: CrudFilters = [];
      const { q, state, location, assetType } = params;
      filters.push({
        field: "q",
        operator: "contains",
        value: q
      });
      filters.push({
        field: "stateManager.state",
        operator: "eq",
        value: state
      });
      filters.push({
        field: "location",
        operator: "eq",
        value: location
      });
      filters.push({
        field: "name",
        operator: "eq",
        value: assetType
      });
      return filters;
    },
    queryOptions:
    {
      enabled: fetchData
    }
  });

  const { triggerExport, isLoading: exportLoading } = useExport<any>({
    dataProviderName: DATAPROVIDER_EXPORT,
    resourceName: RESOURCE_PATH.ASSET,
    pageSize: metaConfig?.export.pageSize ?? 100,
  });

  useEffect(() => {

    if (!isLoading && !userLoading) {
      switchFetchData(true);
      if (!canAllocate) {
        switchMyAssetView(true);
      }
    }
  }, [isLoading, userLoading, canAllocate])

  return (
    <List
      headerProps={{
        title: `${myAssetView ? "My Assets" : "Assets"}`,
        extra: <>
          {canAllocate &&
            <Button key="myasset" icon={<Icons.EyeOutlined />} onClick={() => { switchMyAssetView(!myAssetView); tableQueryResult.refetch(); }}>{myAssetView ? "Show All Assets" : "Show My Assets"}</Button>
          }
          <CreateButton icon={<Icons.PlusOutlined />}>Add Asset</CreateButton>
          {metaConfig?.export.enabled && <ExportButton onClick={triggerExport} loading={exportLoading} />}
          <Button key="filter" icon={<Icons.SearchOutlined />} onClick={() => switchFilterForm(true)}>{metaConfig?.search?.textMessages?.buttonText || "Search & Filter"}</Button>
        </>
      }} >
      {isLoading || tableQueryResult.isLoading || userLoading ? <RenderSpinner message="Loading..." ></RenderSpinner> :
        <ListAssetTable
          myAsset={myAssetView}
          metadata={entityMetadata?.data}
          assetData={tableProps}
          sorter={sorter || []}
        />
      }
      <Drawer
        title={metaConfig?.search?.textMessages?.drawerFormHeader || "Assets - Search & Filter"}
        placement="right"
        size="default"
        open={filterOpen}
        onClose={() => { switchFilterForm(false) }}
      >
        <AssetFilter formProps={searchFormProps} hideSearch={myAssetView} />
      </Drawer>
    </List >
  );
};
