import { CrudFilters, CrudSorting } from "@pankod/refine-core";

// Extension Function to Merge the User Preferences with the standard list of Columns
export function generateCrudFilters(formFields: any, mode: "save" | "render"): CrudFilters {
    let filters: CrudFilters = [];
    let excludeFields = ["pageSize", "sortOrder"];
    if (formFields) {
        Object.keys(formFields).forEach((key) => {
            if ((validateFormField(formFields[key]) || mode === "render") && !excludeFields.includes(key)) {
                filters.push({
                    field: key === "state" ? "stateManager.state" : key,
                    operator: key === "q" ? "contains" : "eq",
                    value: formFields[key]
                });
            }
        });
    }
    return filters;
}

export function generateSortOrder(formFields: any, defaultSortOder?: CrudSorting): CrudSorting {
    let sortOrder = formFields?.sortOrder as CrudSorting;
    return sortOrder || defaultSortOder;
}

export function generatePageSize(formFields: any): number {
    let pageSize = formFields.pageSize as number;
    return pageSize || 10;
}

function validateFormField(formField: any): boolean {
    if (formField && formField.length > 0) {
        return true;
    }
    return false;
}