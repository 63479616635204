import { ClickAnalyticsPlugin } from '@microsoft/applicationinsights-clickanalytics-js';
import { ReactPlugin, withAITracking } from '@microsoft/applicationinsights-react-js';
import { ApplicationInsights } from '@microsoft/applicationinsights-web';
import { createBrowserHistory } from "history";
import { ComponentType } from 'react';
import {LayoutProps} from '@pankod/refine-core';

const browserHistory = createBrowserHistory();
var reactPlugin = new ReactPlugin();

const clickPluginInstance = new ClickAnalyticsPlugin();
// Click Analytics configuration
const clickPluginConfig = {
    autoCapture: true,
    dataTags: {
        useDefaultContentNameOrId: true
    }
};

export const ai = new ApplicationInsights({
    config: {
        connectionString: `InstrumentationKey=${process.env.REACT_APP_InstrumentationKey};IngestionEndpoint=${process.env.REACT_APP_IngestionEndpoint}`,
        extensions: [reactPlugin, clickPluginInstance],
        enableAutoRouteTracking: true,
        extensionConfig: {
            [reactPlugin.identifier]: { history: browserHistory },
            [clickPluginInstance.identifier]: clickPluginConfig
        }
    }
});

ai.loadAppInsights();

export const aITracking = (Component: ComponentType<LayoutProps>) => withAITracking(reactPlugin, Component)
export const appInsights = ai.appInsights;
//export const ai