import { Alert, DatePicker, Divider, Edit, Form, Input, SaveButton, Select, Tag, TimePicker, Typography, useForm, useSelect } from "@pankod/refine-antd";
import { IResourceComponentsProps, useGetIdentity, useList, useOne } from "@pankod/refine-core";
import { ShowUserCard } from "components/profile/showUserCard";
import { CancelButton } from "components/utils/cancelButton";
import { LookupButton } from "components/utils/lookupButton";
import dayjs, { Dayjs } from "dayjs";
import timezone from 'dayjs/plugin/timezone';
import utc from 'dayjs/plugin/utc';
import { ILocations, ILookup, IReservation, IRoom, IRoomConfigSet, IUser } from "interfaces";
import { useEffect, useState } from "react";
import { DATAPROVIDER_CONFIGSET, DATAPROVIDER_LOOKUP, DATAPROVIDER_READ, DATAPROVIDER_UPDATE, RESOURCE_PATH, STALE_DURATION, greyStyle, range } from "scripts/site";
dayjs.extend(utc)
dayjs.extend(timezone)

export const ReservationEdit: React.FC<IResourceComponentsProps> = () => {
    const { form, formProps, saveButtonProps, queryResult } = useForm<IReservation>({
        dataProviderName: DATAPROVIDER_UPDATE,
        redirect: "list"
    });

    const record = queryResult?.data?.data;
    const { data: userObj } = useGetIdentity<IUser>();

    const locationExists = record?.locationId !== undefined && record?.locationId !== "";

    const { data: locationData } = useOne<ILocations>({
        dataProviderName: DATAPROVIDER_LOOKUP,
        resource: RESOURCE_PATH.LOCATION,
        id: record?.locationId ?? "",
        queryOptions: {
            enabled: locationExists,
            staleTime: STALE_DURATION
        }
    });


    //const [roomId, setRoomId] = useState(record?.roomId ?? "");
    const roomExists = record?.roomId !== undefined && record?.roomId !== "";

    const { data: configSet } = useOne<IRoomConfigSet>({
        dataProviderName: DATAPROVIDER_CONFIGSET,
        resource: RESOURCE_PATH.ROOM,
        id: record?.roomId ?? "",
        queryOptions: {
            enabled: roomExists,
            staleTime: STALE_DURATION
        }
    });
    const reservationRules = configSet?.data.values;

    const { data: roomData } = useOne<IRoom>({
        dataProviderName: DATAPROVIDER_READ,
        resource: RESOURCE_PATH.ROOM,
        id: record?.roomId ?? "",
        queryOptions: {
            enabled: roomExists,
            //staleTime: STALE_DURATION
        }
    });

    const { selectProps: attendeeProps } = useSelect<ILookup>({
        dataProviderName: DATAPROVIDER_LOOKUP,
        resource: RESOURCE_PATH.PEOPLE,
        optionLabel: "nameEmail",
        optionValue: "id",
        filters: [{
            field: "id",
            operator: "ne",
            value: userObj?.id
        },
        {
            field: "stateManager.state",
            operator: "eq",
            value: "active"
        }],
        sort: [{
            field: "lastAccessedAt",
            order: "desc"
        }]
    });

    const [selectedDate, setSelectedDate] = useState<string | undefined>(record?.date);
    const [beginTime, setBeginTime] = useState<dayjs.Dayjs>();
    const [endTime, setEndTime] = useState<dayjs.Dayjs>();
    const [duration, setDuration] = useState<number>(record?.duration ?? 0);

    const [isInitialLoad, setIsInitialLoad] = useState(true);
    if (isInitialLoad && !(queryResult?.isLoading ?? true) && record) {
        setIsInitialLoad(false);
        setSelectedDate(record?.date ?? "");

        setBeginTime(dayjs(record?.begin));
        setEndTime(dayjs(record?.end));
    }

    if (queryResult?.data?.data?.datePicker) {
        queryResult.data.data.datePicker = dayjs(queryResult.data.data.datePicker);
    }

    if (queryResult?.data?.data?.schedule) {
        queryResult.data.data.schedule = [dayjs(queryResult.data.data.schedule[0]), dayjs(queryResult.data.data.schedule[1])]
    }


    function assignDateTime() {
        form?.setFieldsValue({
            date: selectedDate ?? null
        });

        if (selectedDate && beginTime && endTime) {
            const adjustedStart = dayjs.tz(selectedDate + " " + beginTime.format("HH:mm"), locationData?.data?.timezoneIANA);
            const adjustedEnd = dayjs.tz(selectedDate + " " + endTime.format("HH:mm"), locationData?.data?.timezoneIANA);

            form?.setFieldsValue({
                begin: dayjs.tz(selectedDate + " " + beginTime.format("HH:mm"), locationData?.data?.timezoneIANA).format(),
                end: dayjs.tz(selectedDate + " " + endTime.format("HH:mm"), locationData?.data?.timezoneIANA).format(),
            });
            setDuration(adjustedEnd.diff(adjustedStart, "minute"));
        }
        else {
            form?.setFieldsValue({
                begin: null,
                end: null
            });
            setDuration(0);
        }
    }

    function assignDuration() {
        form?.setFieldsValue({
            duration: duration
        });
    }

    useEffect(assignDateTime, [form, locationData?.data?.timezoneIANA, selectedDate, beginTime, endTime]);
    useEffect(assignDuration, [form, duration]);

    const alignmentBoundary = (reservationRules?.alignReservations &&             //You must align resvs? &
        !!reservationRules?.alignmentBoundary &&            //An interval exists? &
        60 % reservationRules?.alignmentBoundary === 0) ?   //A minute is divisible by the boundary?
        reservationRules?.alignmentBoundary : 5             //Use the alignment boundary. Otherwise, don't.

    //Used for conflict checking
    const { refetch: checkForConflicts } = useList<IReservation>({
        dataProviderName: DATAPROVIDER_READ,
        resource: RESOURCE_PATH.RESERVATION,
        config: {
            hasPagination: false,
            filters: [
                {
                    field: 'roomId',
                    operator: 'eq',
                    value: record?.roomId ?? "",
                },
                {
                    field: 'date',
                    operator: 'eq',
                    value: selectedDate, //'YYYY-MM-DD (2023-03-30)
                },
                {
                    field: 'id',
                    operator: 'ne',
                    value: record?.id,
                }
            ],
            sort: [
                {
                    field: 'begin',
                    order: 'asc'
                }
            ]
        }
    });

    const [validationErrors, setvalidationErrors] = useState<string[]>([]);
    const [isSavingData, setIsSavingData] = useState(false);

    function isDateDisabled(selDate: Dayjs): boolean {
        return (selDate
            //Nobody can set a reservation in the past
            && selDate.startOf('day') < dayjs().tz(locationData?.data?.timezoneIANA).startOf('day'))
            //If advance days exist or it's equal to 0. (!!0 returns false) 
            || ((!!reservationRules?.advanceDays || reservationRules?.advanceDays === 0)
                //Can't be more than the last
                && (selDate.startOf('day') > dayjs().tz(locationData?.data?.timezoneIANA).add(reservationRules.advanceDays, 'day').startOf('day'))
            );
    }

    return (
        <Edit isLoading={isSavingData}
            headerButtons={<></>}
            footerButtons={<>
                <SaveButton {...saveButtonProps} onClick={() => {
                    setIsSavingData(true);
                    assignDateTime();
                    assignDuration();
                    setvalidationErrors([]);
                    const errors: string[] = [];
                    // remove empty fields before saving
                    form.validateFields().then(async () => {
                        if (roomData?.data.offline) {
                            errors.push("Room is offline - " + roomData?.data.remarks);
                        }

                        if (roomData?.data.private && !(reservationRules?.reservePrivateRoom === true)) {
                            errors.push("Room is private. Please contact room administrator to edit the reservation.");
                        }

                        // Check if date time is alrady passed or in the past                        
                        const beginTime = dayjs(form.getFieldValue("begin"));
                        const endTime = dayjs(form.getFieldValue("end"));
                        if (beginTime < dayjs().tz(locationData?.data?.timezoneIANA)) {
                            errors.push("Reservations cannot be made in past.");
                        }

                        // No need to continue if there are validation errors
                        if (errors.length > 0) {
                            setvalidationErrors(errors);
                            return;
                        }

                        // Check for reservation conflicts
                        const resv = await checkForConflicts();
                        const conflicts = resv?.data?.data?.filter((r: IReservation) => {
                            const begin = dayjs(r.begin);
                            const end = dayjs(r.end);
                            return (begin <= beginTime && end > beginTime) || (begin < endTime && end >= endTime);
                        });

                        if (conflicts && conflicts.length > 0) {
                            setvalidationErrors(["The reservation conflicts with an existing reservation."]);
                        }
                        else {
                            form.submit();
                        }
                    }).then(() => {
                        setIsSavingData(false);
                    }).catch(() => { setIsSavingData(false); });
                }} />
                <CancelButton />
            </>}
        >
            <Form {...formProps} layout="vertical" autoComplete="off">
                <Form.Item label="Location" tooltip="Location cannot be edited">
                    <LookupButton id={record?.countryId || ""} resource={RESOURCE_PATH.COUNTRY} noClick></LookupButton>
                    <Typography.Text style={greyStyle}>{" / "}</Typography.Text>
                    <LookupButton id={record?.locationId || ""} resource={RESOURCE_PATH.LOCATION} noClick></LookupButton>
                    <Typography.Text style={greyStyle}>{" / "}</Typography.Text>
                    <LookupButton id={record?.buildingId || ""} resource={RESOURCE_PATH.BUILDING} noClick></LookupButton>
                    <Typography.Text style={greyStyle}> {" / "}</Typography.Text>
                    <LookupButton id={record?.zoneId || ""} resource={RESOURCE_PATH.ZONE} noClick></LookupButton>
                    <Typography.Text style={greyStyle}> {" / "}</Typography.Text>
                    <LookupButton id={record?.roomId || ""} resource={RESOURCE_PATH.ROOM}></LookupButton>
                </Form.Item>
                {roomData?.data.offline &&
                    <Alert
                        message="Room is offline. Reservation is not allowed."
                        description={roomData?.data.remarks}
                        type="warning"
                        showIcon
                        style={{ marginBottom: 20 }}
                    />}

                {roomData?.data.private &&
                    <Alert
                        message="Room is private"
                        description={(reservationRules?.reservePrivateRoom === true) ? "As an Administrator, you can edit the reservation." : "Reservation can only be modified by administrators."}
                        type="warning"
                        showIcon
                        style={{ marginBottom: 20 }}
                    />}

                <Form.Item label="Organizer">
                    <ShowUserCard id={record?.organizer} ></ShowUserCard>
                </Form.Item>

                <Form.Item
                    name="name"
                    label="Title"
                    rules={[
                        {
                            required: true,
                            type: "string",
                            min: 5,
                            max: 100,
                            pattern: new RegExp(/^[a-zA-Z0-9 ()/-]+$/),
                        }
                    ]}
                >
                    <Input max={100} />
                </Form.Item>
                <Form.Item
                    name="description"
                    label="Description"
                    rules={[
                        {
                            //required: true,
                            type: "string",
                            max: 300,
                            //pattern: new RegExp(/^[a-zA-Z0-9 ()/-]+$/),
                        }
                    ]}
                >
                    <Input.TextArea
                        maxLength={500}
                        showCount
                    />
                </Form.Item>

                <Form.Item
                    label="Attendees"
                    name="attendees"
                    rules={[
                        {
                            whitespace: true,
                            type: "array",
                            required: (roomData?.data.minOccupancy ?? 0) - 1 > 0,
                            min: Math.max(0, (roomData?.data.minOccupancy ?? 0) - 1),
                            message: `Please add at least ${(roomData?.data.minOccupancy ?? 0) - 1} attendee(s).`
                        },
                        {
                            whitespace: true,
                            type: "array",
                            max: Math.min(50, (roomData?.data.maxOccupancy ?? 0) - 1),
                            message: `Maximum ${(roomData?.data.maxOccupancy ?? 0) - 1} attendee(s) can be accomodated in room.`
                        }
                    ]}
                >
                    <Select placeholder="Search Name or Email" mode="multiple" {...attendeeProps} allowClear />
                </Form.Item>

                <Divider dashed orientation="left">When</Divider>

                {validationErrors.length > 0 &&
                    <Alert message={<>
                        {validationErrors.map((e, i) => <div>{e}</div>)}
                    </>} type="error" showIcon style={{ marginBottom: 20 }} />
                }
                <Form.Item name="date" hidden={true}>
                    <Input />
                </Form.Item>
                <Form.Item
                    name="datePicker"
                    label="Date"
                    rules={[{
                        required: true
                    }]}
                >
                    <DatePicker
                        style={{ width: 260 }}
                        format={"YYYY-MM-DD"}
                        disabled={!(locationExists && roomExists && reservationRules)}
                        disabledDate={isDateDisabled}
                        onSelect={(selectedDate) => {
                            setSelectedDate(selectedDate ? dayjs(selectedDate).format('YYYY-MM-DD') : undefined)
                        }}
                    />
                </Form.Item>
                <Form.Item name="begin" hidden={true}>
                    <Input />
                </Form.Item>
                <Form.Item name="end" hidden={true}>
                    <Input />
                </Form.Item>

                <Form.Item
                    label="Schedule"
                    name="schedule"
                    tooltip="Select time as per room timezone"
                    extra={locationExists && locationData?.data?.timezone && <div><Tag color="cyan">{locationData?.data?.timezone}</Tag> {locationData?.data?.timezoneIANA}</div>}
                    rules={[{
                        required: true
                    },
                    {
                        validator: async (_, value: [Dayjs, Dayjs]) => {
                            if (!value) {
                                return;
                            }

                            //Rules checking
                            if (value[0] && value[1]) {
                                const validationErrors = [];
                                const inputStartHour = value[0].get("hour");
                                const inputStartMinute = value[0].get("minute");
                                const inputEndHour = value[1].get("hour");
                                const inputEndMinute = value[1].get("minute");

                                //beginTime
                                if (reservationRules?.beginTime && reservationRules?.endTime && value[0] && value[1]) {
                                    const beginHour = parseInt(reservationRules.beginTime.slice(0, 2))
                                    const beginMinute = parseInt(reservationRules.beginTime.slice(3, 5))
                                    const endMinute = parseInt(reservationRules.endTime.slice(3, 5))
                                    const endHour = parseInt(reservationRules.endTime.slice(0, 2)) + (endMinute > 0 ? 1 : 0)

                                    if (inputStartHour < beginHour || inputStartHour > endHour || (inputStartHour === beginHour && inputStartMinute < beginMinute)) {
                                        validationErrors.push(`Start time is not within the allowed range (${reservationRules.beginTime} - ${reservationRules.endTime})`);
                                    }

                                    if (inputEndHour > endHour || inputEndHour < beginHour || (inputEndHour === endHour && inputEndMinute > endMinute)) {
                                        validationErrors.push(`End time is not within the allowed range (${reservationRules.beginTime} - ${reservationRules.endTime})`);
                                    }
                                }

                                //alignReservations and alignmentBoundary
                                if (reservationRules?.alignReservations && reservationRules?.alignmentBoundary) {
                                    //Get the minutes and find the remainder
                                    if (inputStartMinute % reservationRules.alignmentBoundary !== 0) {
                                        validationErrors.push(`Start time does not fit alignment boundary of ${reservationRules.alignmentBoundary}-minute intervals.`);
                                    }

                                    //Get the minutes and find the remainder
                                    if (inputEndMinute % reservationRules.alignmentBoundary !== 0) {
                                        validationErrors.push(`End time does not fit alignment boundary of ${reservationRules.alignmentBoundary}-minute intervals.`);
                                    }
                                }

                                if (validationErrors.length > 0) {
                                    return Promise.reject(validationErrors);
                                }
                            }

                            return Promise.resolve();
                        }
                    }]}
                >
                    <TimePicker.RangePicker
                        format="HH:mm"
                        disabled={!(locationExists && roomExists && reservationRules)}
                        minuteStep={alignmentBoundary}
                        placement="bottomLeft"
                        showNow={false}
                        value={[dayjs(record?.schedule[0]), dayjs(record?.schedule[1])]}
                        placeholder={["Start Time", "End Time"]}
                        onChange={(value) => {
                            setBeginTime(value?.[0] ?? undefined);
                            setEndTime(value?.[1] ?? undefined);
                        }}
                        disabledTime={() => {
                            if (!!(reservationRules?.beginTime) && !!(reservationRules?.endTime)) {
                                let beginHour = parseInt(reservationRules.beginTime.slice(0, 2))
                                let beginMinute = parseInt(reservationRules.beginTime.slice(3, 5))
                                let endMinute = parseInt(reservationRules.endTime.slice(3, 5))
                                let endHour = parseInt(reservationRules.endTime.slice(0, 2)) + (endMinute > 0 ? 1 : 0)

                                return {
                                    disabledHours: () => range(0, beginHour, 1).concat(range(endHour, 24, 1)), //[0, ..., beginHour-1, endHour, ..., 24]
                                    disabledMinutes: (hour: number) => {
                                        if (hour === beginHour) {
                                            return range(0, beginMinute, alignmentBoundary) //[0, ..., beginMinute-1]
                                        } else if (hour === endHour) {
                                            return range(endMinute, 60, alignmentBoundary) //[endMinute, ..., 59]
                                        }
                                        return [];
                                    }
                                }
                            }
                            else {
                                return {};
                            }
                        }}
                    />
                </Form.Item>
                <Form.Item name="duration" label="Duration" preserve
                    rules={[
                        {
                            required: true,
                            type: "number",
                            min: reservationRules?.minReservationDuration ?? 1,
                            max: reservationRules?.maxReservationDuration ?? 30,
                        },
                    ]}
                >
                    <Typography.Text>
                        {duration ?? 0} Minutes
                    </Typography.Text>
                </Form.Item>
            </Form>
        </Edit>
    )
}