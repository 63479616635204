import { Checkbox, Divider, Form, Input, Radio, Space, Typography } from "@pankod/refine-antd";
import { IQuestion } from "interfaces";

export const RenderQuestion: React.FC<IQuestion & {
    index: number,
    groupIndex: number
}> = ({ index, groupIndex, name, multiSelect, optionDisplay, options  }) => {

    const opts: any = [];
    options?.forEach((opt, indx) => {
        opts.push(<Radio value={opt.name} key={`g-${groupIndex}-q-${index}-o-${indx}`}>{opt.name}</Radio>);
    });
    
    return (
        <Form.Item
            key={index}
            style={{ marginBottom: 5 }}
        >
            <Divider dashed />
            <Form.Item
                style={{ marginBottom: 15 }}
            >
                <Typography.Text style={{ marginRight: 10 }} strong >  {`${index + 1} - ${name}`}</Typography.Text>
            </Form.Item>
            {multiSelect ?
            <Form.List
                name={[index, "options"]}
            >
                {(fields, opt, { errors }) => (
                    <Space wrap size={"large"} direction={optionDisplay} >
                        {fields
                            .map((field) => (
                                    <Form.Item
                                    style={{ marginBottom: 0, minWidth: 100 }}
                                    key={`g-${groupIndex}-q-${index}-o-${field.key}`}
                                    valuePropName={"checked"}
                                    name={[field.key, "checked"]}
                                    >
                                        <Checkbox>
                                            {options[field.key].name}
                                        </Checkbox>
                                    </Form.Item>
                                ))}
                            <Form.ErrorList errors={errors} />
                        </Space>
            )}
            </Form.List>
            :
            <Form.Item 
                name={[index, "selectedOption"]}
                initialValue={options.find(opt => opt.checked)?.name}
                getValueFromEvent={e=> e.target.value}
            >
                <Radio.Group
                    key={`g-${groupIndex}-q-${index}`}
                >
                    <Space wrap size={"large"} direction={optionDisplay}>
                        {opts}
                    </Space>
                </Radio.Group>
            </Form.Item>
            }
            <Form.Item
                style={{ marginBottom: 0, maxWidth: 300, marginTop: 20 }}
                name={[index, "comments"]} >
                <Input placeholder="Comments (optional)" ></Input>
            </Form.Item>
        </Form.Item>
    );
};