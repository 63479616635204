import { IBaseProps, stringExtensions } from "@architecture-innovation-transformation/lib-common";
import { Alert, Button, Col, Divider, Form, FormProps, Icons, Input, InputNumber, Row, Select, Spin, useSelect } from "@pankod/refine-antd";
import { CrudSorting, useOne, useUpdate } from "@pankod/refine-core";
import { generateCrudFilters, generateSortOrder } from "components/utils/generateCrudFilters";
import { preferencesData } from "components/utils/getListTableColumns";
import { IListTableColumn, ILookup, IUser, IUserPreferences } from "interfaces";
import { IPositionFilterVariables, IPositionMetadata, IResourcingRequestMetadata } from "interfaces/resourcing";
import { useEffect, useState } from "react";
import { DATAPROVIDER_LOOKUP, DATAPROVIDER_UPDATE, MY_PROFILE, RESOURCE_PATH, STALE_DURATION, removeLocalSessionStorage } from "scripts/site";

export declare type PositionFilterProps =
    {
        formProps: FormProps,
        user: IUser,
        onApplyFilter: () => void,
        defaultFilters: IPositionFilterVariables,
        listColumns: IListTableColumn[]
    };

export const PositionFilter: React.FC<PositionFilterProps> = ({
    formProps,
    user,
    onApplyFilter,
    defaultFilters,
    listColumns
}) => {
    const { isLoading, data: metadata } = useOne<IPositionMetadata>({
        dataProviderName: DATAPROVIDER_LOOKUP,
        resource: RESOURCE_PATH.METADATA,
        id: RESOURCE_PATH.POSITION,
        queryOptions: {
            enabled: true,
            staleTime: STALE_DURATION
        }
    });
    const { isLoading: resourcingMetadataLoading, data: resourcingMetadata } = useOne<IResourcingRequestMetadata>({
        dataProviderName: DATAPROVIDER_LOOKUP,
        resource: RESOURCE_PATH.METADATA,
        id: RESOURCE_PATH.RESOURCINGREQUEST,
        queryOptions: {
            enabled: true,
            staleTime: STALE_DURATION
        }
    });
    const { selectProps: userProps } = useSelect<ILookup>({
        dataProviderName: DATAPROVIDER_LOOKUP,
        resource: RESOURCE_PATH.PEOPLE,
        optionLabel: "nameEmail",
        optionValue: "id",
        filters: [{
            field: "stateManager.state",
            operator: "eq",
            value: "active"
        }],
        sort: [
            {
                field: "lastAccessedAt",
                order: "desc"
            },
        ],
        fetchSize: 50
    });
    const metaConfig = metadata?.data.config;
    const resourcingMetaConfig = resourcingMetadata?.data.config;
    const alertMessage = "Note: The filters you choose will remain applied even when you navigate away. To clear the filters, click on the Clear button at the bottom right corner of the filter panel.";
    const [saveButton, switchSaveButton] = useState(false);
    const { mutate } = useUpdate<IBaseProps>();

    useEffect(() => {
        // Reset form on every load
        if (formProps && formProps.form) {
            formProps.form.resetFields();
        }
    }, [formProps,]);

    const saveFilters = () => {
        let requestForm = formProps?.form;
        if (requestForm) {
            switchSaveButton(true);
            let filterValues = requestForm.getFieldsValue(true) as IPositionFilterVariables;

            // Formulate the preference item and Save to Profile
            let filterData = generateCrudFilters(filterValues, "save");
            let sorting: CrudSorting = generateSortOrder(filterValues);
            saveToProfile(preferencesData(user.preferences, RESOURCE_PATH.POSITION, undefined, filterData, sorting, requestForm.getFieldValue('pageSize')));
        }
    }

    const clearFilters = () => {
        switchSaveButton(true);
        //Switch to System Default 
        saveToProfile(preferencesData(user.preferences, RESOURCE_PATH.POSITION, undefined, [], [], 10), true);
    }

    const saveToProfile = (userPreferences: IUserPreferences, clear: boolean = false) => {
        // Save the preference to profile
        mutate({
            dataProviderName: DATAPROVIDER_UPDATE,
            resource: RESOURCE_PATH.PROFILE,
            id: user.id,
            values: { preferences: userPreferences, _skipTimeStamp: true },
            successNotification: false,
            errorNotification: false
        },
            {
                onSuccess: () => {
                    switchSaveButton(false);
                    //If clear, reset the form to facttory default
                    if (clear) {
                        formProps.form?.setFieldsValue({});
                        formProps.form?.submit();
                    }

                    // Remove the user Profile local data to ensure latest preferences are loaded
                    removeLocalSessionStorage(MY_PROFILE);
                    onApplyFilter();
                },
                onError: () => {
                    switchSaveButton(false);
                }
            }
        );
    }

    return (
        <Spin spinning={saveButton || isLoading || resourcingMetadataLoading}>
            <Form layout="vertical" {...formProps} initialValues={defaultFilters} autoComplete="off">
                <Row gutter={[8, 0]} align="bottom">
                    <Col span={24}>
                        <Alert
                            message={alertMessage}
                            type="info"
                            style={{ marginBottom: "1.2em" }}
                            closable
                        />
                        <Form.Item
                            label={"Search"}
                            name="q"
                            tooltip={metaConfig?.search?.textMessages?.searchTextPlaceholder || "Search Positions"}
                        >
                            <Input
                                placeholder={metaConfig?.search?.textMessages?.searchTextPlaceholder || "Search Positions"}
                                prefix={<Icons.SearchOutlined />}
                            />
                        </Form.Item>
                    </Col>
                </Row>
                <Divider orientation="left">Filters</Divider>
                <Row gutter={[8, 8]} align="bottom">
                    <Col span={12}>
                        <Form.Item
                            label="Requestor"
                            name="requestor"
                            tooltip="To look up any missing name type the name of the person and then select"
                        >
                            <Select
                                allowClear
                                mode="multiple"
                                placeholder="Requestor"
                                {...userProps}
                            />
                        </Form.Item>
                        <Form.Item
                            label="Onsite Delivery Partner (L2)"
                            name="deliveryPartnerOnsite"
                            tooltip="To look up any missing name type the name of the person and then select"
                        >
                            <Select
                                allowClear
                                mode="multiple"
                                placeholder="Onsite Delivery Partner (L2)"
                                {...userProps}
                            />
                        </Form.Item>
                        <Form.Item
                            label="Skill Category"
                            name="skillCategory"
                        >
                            <Select
                                allowClear
                                mode="multiple"
                                placeholder="Skill Category"
                            >
                                {resourcingMetaConfig?.skillCategory.map((m, i) => (
                                    <Select.Option value={m} key={i}>{m}</Select.Option>
                                ))}
                            </Select>
                        </Form.Item>
                        <Form.Item
                            label="Position Type"
                            name="positionType"
                        >
                            <Select
                                allowClear
                                mode="multiple"
                                placeholder="Position Type"
                            >
                                {resourcingMetaConfig?.positionType.map((m, i) => (
                                    <Select.Option value={m.id} key={i}>{m.name}</Select.Option>
                                ))}
                            </Select>
                        </Form.Item>
                        <Form.Item
                            label="Group"
                            name="group"
                        >
                            <Select
                                allowClear
                                mode="multiple"
                                placeholder="Group"
                            >
                                {resourcingMetaConfig?.group.map((m, i) => (
                                    <Select.Option value={m} key={i}>{m}</Select.Option>
                                ))}
                            </Select>
                        </Form.Item>
                        <Form.Item
                            label="Billable/Non Billable"
                            name="billable"
                        >
                            <Select
                                allowClear
                                mode="multiple"
                                placeholder="Billable/Non Billable"
                            >
                                {["Billable", "Non-Billable"].map((m, i) => (
                                    <Select.Option value={m} key={i}>{m}</Select.Option>
                                ))}
                            </Select>
                        </Form.Item>
                        <Form.Item
                            label="Sales Executive (BRM)"
                            name="salesExecutive"
                            tooltip="To look up any missing name type the name of the person and then select"
                        >
                            <Select
                                allowClear
                                mode="multiple"
                                placeholder="Sales Executive (BRM)"
                                {...userProps}
                            />
                        </Form.Item>
                        <Form.Item
                            label="Closure Channel"
                            name="closureChannel"
                        >
                            <Select
                                allowClear
                                placeholder="Closure Channel"
                            >
                                {metaConfig?.closureChannel.map((m, i) => (
                                    <Select.Option value={m} key={`cc-${i}`}>{m}</Select.Option>
                                ))}
                            </Select>
                        </Form.Item>
                    </Col>
                    <Col span={12}>
                        <Form.Item
                            label="Hiring Manager"
                            name="hiringManager"
                            tooltip="To look up any missing name type the name of the person and then select"
                        >
                            <Select
                                allowClear
                                mode="multiple"
                                placeholder="Hiring Manager"
                                {...userProps}
                            />
                        </Form.Item>
                        <Form.Item
                            label="Offshore Delivery Partner (L2)"
                            name="deliveryPartnerOffshore"
                            tooltip="To look up any missing name type the name of the person and then select"
                        >
                            <Select
                                allowClear
                                mode="multiple"
                                placeholder="Offshore Delivery Partner (L2)"
                                {...userProps}
                            />
                        </Form.Item>
                        <Form.Item
                            label="Position Status"
                            name="state"
                        >
                            <Select
                                allowClear
                                mode="multiple"
                                placeholder="Position status"
                            >
                                {metadata?.data?.states.map((m, i) => (
                                    <Select.Option value={m.state} key={i}>{stringExtensions.capitalize(m.state)}</Select.Option>
                                ))}
                            </Select>
                        </Form.Item>
                        <Form.Item
                            label="Resourcing Constraints"
                            name="resourceConstraint"
                        >
                            <Select
                                allowClear
                                placeholder="Select Resourcing Constraint(s)"
                                showSearch
                                showArrow={false}
                            >
                                {["BA Only", "FTE Only", "Citizen Only", "Trainee Only", "None"].map((m, i) => (
                                    <Select.Option value={m} key={i}>{m}</Select.Option>
                                ))}
                            </Select>
                        </Form.Item>
                        <Form.Item
                            label="Customer Interview"
                            name="customerInterview"
                        >
                            <Select
                                allowClear
                                placeholder="Select Option"
                                showSearch
                                showArrow={false}
                            >
                                {["Yes", "No"].map((m, i) => (
                                    <Select.Option value={m} key={i}>{m}</Select.Option>
                                ))}
                            </Select>
                        </Form.Item>
                        <Form.Item
                            label="Confidence"
                            name="confidence"
                        >
                            <Select
                                allowClear
                                placeholder="Confidence"
                                showSearch
                                showArrow={false}
                            >
                                {["Confirmed", "Tentative"].map((m, i) => (
                                    <Select.Option value={m} key={i}>{m}</Select.Option>
                                ))}
                            </Select>
                        </Form.Item>
                        <Form.Item
                            label="Delivery Head (L1)"
                            name="deliveryHead"
                            tooltip="To look up any missing name type the name of the person and then select"
                        >
                            <Select
                                allowClear
                                mode="multiple"
                                placeholder="Delivery Head (L1)"
                                {...userProps}
                            />
                        </Form.Item>
                        <Form.Item
                            label="Work Location Constraints"
                            name="locationConstraint"
                        >
                            <Select
                                allowClear
                                placeholder="Select Work Location Constraints"
                                showSearch
                                showArrow={false}
                            >
                                {["Remote", "MS Campus", "TCS Campus"].map((m, i) => (
                                    <Select.Option value={m} key={i}>{m}</Select.Option>
                                ))}
                            </Select>
                        </Form.Item>
                    </Col>
                </Row >
                <Divider orientation="left">Display Configuration</Divider>
                <Row gutter={[8, 8]} align="bottom">
                    <Col span={12}>
                        <Form.Item
                            label="Items per Page"
                            name="pageSize"
                            tooltip={<>
                                <span>Configure number of items to be displayed per page (Min: 10, Max: 100).</span>
                            </>}
                        >
                            <InputNumber min={10} max={100} precision={0} maxLength={3} placeholder="Items per page" style={{ width: "100%" }} controls={false} />
                        </Form.Item>
                    </Col>
                    <Col span={12}>
                        <Form.List
                            name="sortOrder"
                        >
                            {(fields) => (
                                <>
                                    {fields.map((field, index) => (
                                        <Form.Item
                                            label="Sort Order"
                                            key={field.key}
                                            tooltip={<>
                                                <span>Choose your preferred sorting order from the dropdown menus below. The first dropdown lets you select the column of the results, such as name, last updated, etc. The second dropdown lets you select the order of the results, such as ascending or descending.</span>
                                            </>}
                                        >
                                            <Form.Item
                                                name={[index, "field"]}
                                                noStyle
                                            >
                                                <Select
                                                    style={{ width: "65%", marginRight: 6 }}
                                                >
                                                    {listColumns.map((lstCol, i) => (
                                                        <Select.Option value={lstCol.dataIndex} key={`fld-${i}`}>{lstCol.title}</Select.Option>
                                                    ))}
                                                </Select>
                                            </Form.Item>
                                            <Form.Item
                                                name={[index, "order"]}
                                                noStyle
                                            >
                                                <Select
                                                    style={{ width: "30%" }}
                                                >
                                                    {["asc", "desc"].map((ut, i) => (
                                                        <Select.Option value={ut.toString()} key={`ordr-${i}`}>{ut.toString().toUpperCase()}</Select.Option>
                                                    ))}
                                                </Select>
                                            </Form.Item>
                                        </Form.Item>
                                    ))}
                                </>
                            )}
                        </Form.List>
                    </Col>
                </Row>
                <Row gutter={[8, 8]} align="bottom">
                    <Col span={12}>
                        <Form.Item>
                            <Button
                                style={{ width: "100%" }}
                                htmlType="submit"
                                onClick={() => {
                                    saveFilters()
                                }}
                                type="primary"
                                icon={<Icons.SendOutlined />}
                            >
                                Apply
                            </Button>
                        </Form.Item>
                    </Col>
                    <Col span={12}>
                        <Form.Item>
                            <Button
                                style={{ width: "100%" }}
                                onClick={() => {
                                    clearFilters();
                                }}
                                icon={<Icons.ClearOutlined />}
                            >
                                Clear
                            </Button>
                        </Form.Item>
                    </Col>
                </Row>
            </Form >
        </Spin >
    );
}