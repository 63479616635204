import { Button, DateField, DeleteButton, Descriptions, Drawer, Icons, List, NumberField, Space, Statistic, Table, Tag, Typography, useModal, useModalForm, useTable } from "@pankod/refine-antd";
import { CanAccess, CrudFilters, HttpError, IResourceComponentsProps, useList, useOne } from "@pankod/refine-core";
import { IntakeSubMenu } from "components/intake/intakeSubmenu";
import { IntakeActivityFilter } from "components/intakeactivity/intakeactivityfilter";
import { ShowUserCard } from "components/profile/showUserCard";
import { IIntake, IIntakeActivity, IIntakeActivityFilterVariables, IIntakeActivityMetadata } from "interfaces/intake";
import { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { DATAPROVIDER_CREATE, DATAPROVIDER_DELETE, DATAPROVIDER_LOOKUP, DATAPROVIDER_READ, DATAPROVIDER_UPDATE, getQueryStringParams, RESOURCE_PATH, STALE_DURATION } from "scripts/site";
import { IntakeActivityCreate } from "./create";
import { IntakeActivityEdit } from "./edit";
import { IntakeActivityShow } from "./show";

export const IntakeActivityList: React.FC<IResourceComponentsProps> = () => {
    const [selectedActivity, setSelectedActivity] = useState<IIntakeActivity>();
    const { search } = useLocation();
    const { intakeId } = getQueryStringParams(search);
    const [filterOpen, switchFilterForm] = useState(false);
    const [refetchData, switchRefetchFlag] = useState(false);
    const [editFormOpen, switchEditForm] = useState(false);

    const listFilter: CrudFilters = [
        {
            field: "intakeId",
            operator: "eq",
            value: intakeId
        }
    ];

    function rowClick(record: IIntakeActivity) {
        return {
            onClick: () => {
                setSelectedActivity(record);
                showModal();
            },
        };
    }

    const handleEditClick = (record: IIntakeActivity) => {
        setSelectedActivity(record);
        switchEditForm(true);
        editModal(record?.id);
    };

    const onEditFormSubmit = () => {
        switchEditForm(false);
    };

    const { data: intakeRecord } = useOne<IIntake>({
        dataProviderName: DATAPROVIDER_READ,
        resource: RESOURCE_PATH.INTAKE,
        id: intakeId,
        queryOptions: {
            enabled: true,
            staleTime: STALE_DURATION
        }
    });

    const { data: intakeActivityResult, refetch: refetchIntakeActivityResult, isLoading: isIntakeActivityLoading } = useList<IIntakeActivity>({
        dataProviderName: DATAPROVIDER_READ,
        resource: RESOURCE_PATH.INTAKE_ACTIVITY,
        config: {
            filters: [{
                field: "intakeId",
                operator: "eq",
                value: intakeId
            }],
            hasPagination: false
        }
    });
    
    const clearFilters = () => {
        searchFormProps.form?.resetFields();
        searchFormProps.form?.submit();
    }

    const { tableProps, tableQueryResult, searchFormProps, filters } = useTable<IIntakeActivity, HttpError, IIntakeActivityFilterVariables>({
        dataProviderName: DATAPROVIDER_READ,
        resource: RESOURCE_PATH.INTAKE_ACTIVITY,
        permanentFilter: listFilter,
        initialSorter: [
            {
                field: "createdAt",
                order: "desc"
            },
        ],
        onSearch: (params) => {
            const filters: CrudFilters = [];
            const { q, createdBy, updatedBy } = params;
            filters.push({
                field: "q",
                operator: "contains",
                value: q
            });
            filters.push({
                field: "createdBy",
                operator: "eq",
                value: createdBy
            });
            filters.push({
                field: "updatedBy",
                operator: "eq",
                value: updatedBy
            });
            return filters;
        }
    });

    useEffect(() => {
        if (refetchData) {
            tableQueryResult.refetch();
            switchRefetchFlag(false);
        }
    }, [refetchData, tableQueryResult]);

    const refetchActivitiesData = () => {
        switchRefetchFlag(true);
        refetchIntakeActivityResult();   
    }

    const { data: entityMetadata } = useOne<IIntakeActivityMetadata>({
        dataProviderName: DATAPROVIDER_LOOKUP,
        resource: RESOURCE_PATH.METADATA,
        id: RESOURCE_PATH.INTAKE_ACTIVITY,
        queryOptions: {
            enabled: true,
            staleTime: STALE_DURATION
        }
    });
    const metaConfig = entityMetadata?.data?.config;

    const {
        modalProps: createModalProps,
        formProps: createFormProps,
        show: createModal
    } = useModalForm<IIntakeActivity>({
        action: "create",
        resource: RESOURCE_PATH.INTAKE_ACTIVITY,
        dataProviderName: DATAPROVIDER_CREATE,
        redirect: false,
        onMutationSuccess: () => {
            tableQueryResult.refetch();
            refetchIntakeActivityResult();
        }
    });

    const {
        modalProps: editModalProps,
        show: editModal
    } = useModalForm<IIntakeActivity>({
        action: "edit",
        resource: RESOURCE_PATH.INTAKE_ACTIVITY,
        dataProviderName: DATAPROVIDER_UPDATE,
        redirect: false,
        onMutationSuccess: () => {
            tableQueryResult.refetch();
        }
    });

    const {
        modalProps: showModalProps,
        show: showModal
    } = useModal();

    const totalRequests = tableQueryResult?.data?.total ?? 0;



    // Calculate the sum of effort for all activities
    const totalEffort = intakeActivityResult?.data?.reduce((acc, curr) =>   acc + (curr.effort || 0), 0);
    const totalEffortHours = Math.floor(totalEffort ? totalEffort : 0);  
    const totalEffortMinutes = Math.ceil(((totalEffort ? totalEffort : 0) - totalEffortHours) * 60);
  
    // Find unique count of created by from all activities
    const totalUniqueCreatedBy = new Set(intakeActivityResult?.data?.map(e => e.createdBy)).size;

    let isFiltered = filters && filters.length > listFilter.length && filters.some(fltr => fltr.value !== undefined)

    let pageTitle = intakeRecord?.data?.name ?? "";
    let pageTag = (tableQueryResult.isLoading ? "Loading" : totalRequests) + (totalRequests !== 1 ? " Activities" : " Activity");
    let isAddEnabled = intakeRecord?.data?.stateManager?.state ? metaConfig?.editableStates?.includes(intakeRecord?.data?.stateManager?.state) : false;

    return (<>
        <List
            headerProps={{
                title: pageTitle,
                tags: <>
                    {<Tag color={"default"}>
                        {pageTag}
                    </Tag>}
                    <Button key="filter" type="default"
                        icon={isFiltered ? <Icons.FilterFilled /> : <Icons.FilterOutlined />}
                        style={{ paddingRight: (isFiltered ? 4 : 15) }}
                        title={isFiltered ? "You are viewing a filtered list" : (entityMetadata?.data?.config?.search?.textMessages?.buttonText || "Search & Filter")}
                        onClick={() => switchFilterForm(true)}>
                        Search
                        {isFiltered &&
                            <>
                                <Button
                                    type="text"
                                    danger
                                    title={"Clear Filters"}
                                    size="small"
                                    style={{ marginLeft: 5 }}
                                    onClick={(e) => {
                                        clearFilters();
                                        e.stopPropagation();
                                    }}
                                    icon={<Icons.CloseOutlined />}
                                >
                                </Button>
                            </>}
                    </Button>
                </>,
                extra: <>
                    {isAddEnabled ?
                        <CanAccess key={RESOURCE_PATH.INTAKE_ACTIVITY} resource={RESOURCE_PATH.INTAKE_ACTIVITY.toLowerCase()} action="create"
                            params={{ dataProviderName: DATAPROVIDER_CREATE, id: "1", intakeId: intakeId }}>
                            <Button
                                icon={<Icons.PlusOutlined />}
                                onClick={() => {
                                    createModal()
                                }}
                            >
                                Activity
                            </Button>
                        </CanAccess>
                        : <></>}
                </>
            }}
            headerButtons={<></>}
        >
            <Space direction="vertical" style={{ width: "100%" }}>
                <Descriptions column={3}>
                    <Descriptions.Item label="Total Activities" labelStyle={{ fontSize: 24 }}>
                        <Statistic value={intakeActivityResult?.data.length} loading={isIntakeActivityLoading} />
                    </Descriptions.Item>
                    <Descriptions.Item label="Total Effort" labelStyle={{ fontSize: 24 }}>
                    <Statistic value={(totalEffortHours?.toString().concat("h ") ?? "0").concat(totalEffortMinutes.toString().concat("m"))} loading={isIntakeActivityLoading} />
                    </Descriptions.Item>
                    <Descriptions.Item label="Activity Contributors" labelStyle={{ fontSize: 24 }}>
                        <Statistic value={(totalUniqueCreatedBy?.toString() ?? "0")} loading={isIntakeActivityLoading} />
                    </Descriptions.Item>
                </Descriptions>
                <IntakeSubMenu intakeId={intakeId || ""} selectedResource={RESOURCE_PATH.INTAKE_ACTIVITY} ></IntakeSubMenu>
                <Table {...tableProps}
                    rowKey="id"
                    pagination={{
                        ...tableProps.pagination,
                        position: ["bottomRight"],
                        showTotal: (total => <Typography.Title level={4} style={{ marginRight: 10 }} >Total {total}</Typography.Title>)
                    }}
                >
                    <Table.Column
                        dataIndex="name"
                        key="name"
                        title="Title"
                        sorter
                        className="mouseHand"
                        onCell={rowClick}
                        render={(value) => <Typography.Title
                            style={{
                                fontWeight: "unset",
                                fontSize: "14px",
                            }}
                            level={5}
                            ellipsis={{
                                rows: 1,
                                tooltip: value,
                            }}
                        >
                            {value}
                        </Typography.Title>}
                    />
                    <Table.Column
                        dataIndex="effort"
                        key="effort"
                        title="Effort (in hours)"
                        className="mouseHand"
                        sorter
                        onCell={rowClick}
                        render={(value) => <NumberField value={value ?? 0} />}
                    />
                    <Table.Column
                        dataIndex="activitydate"
                        key="activitydate"
                        title="Activity Date"
                        className="mouseHand"
                        onCell={rowClick}
                        sorter
                        render={(value) => <DateField value={value ?? 0} format="MMM DD, YYYY" />}
                    />
                    <Table.Column
                        dataIndex="createdBy"
                        key="createdBy"
                        title="Logged By"
                        className="mouseHand"
                        sorter
                        onCell={rowClick}
                        render={(value: string) =>
                            value &&
                            <ShowUserCard key={`sme-${value}`} id={value}></ShowUserCard>

                        }
                    />
                    <Table.Column
                        dataIndex="createdAt"
                        key="createdAt"
                        title="Logged On"
                        className="mouseHand"
                        onCell={rowClick}
                        sorter
                        render={(value) => <DateField value={value ?? 0} format="MMM DD, YYYY" />}
                    />
                    <Table.Column
                        dataIndex="updatedBy"
                        key="updatedBy"
                        title="Updated By"
                        className="mouseHand"
                        sorter
                        onCell={rowClick}
                        render={(value: string) =>
                            value &&
                            <ShowUserCard key={`sme-${value}`} id={value}></ShowUserCard>

                        }
                    />
                    <Table.Column
                        dataIndex="updatedAt"
                        key="updatedAt"
                        title="Updated On"
                        className="mouseHand"
                        sorter
                        onCell={rowClick}
                        render={(value) => <DateField value={value ?? 0} format="MMM DD, YYYY" />}
                    />
                    <Table.Column<IIntakeActivity>
                        dataIndex="id"
                        key="id"
                        title="Action"
                        className="mouseHand"
                        sorter
                        render={(_, record) =>
                            <Space>
                                <CanAccess key={RESOURCE_PATH.INTAKE_ACTIVITY} resource={RESOURCE_PATH.INTAKE_ACTIVITY.toLowerCase()} action="edit" params={{ dataProviderName: DATAPROVIDER_UPDATE, id: record.id }}
                                    fallback={<Button size="small" icon={<Icons.EditOutlined />} disabled title="You are not authorized to edit this activity." />}
                                >
                                    <Button size="small" onClick={() => { handleEditClick(record) }} icon={<Icons.EditOutlined />} title="Edit Activity" />
                                </CanAccess>
                                <CanAccess key={RESOURCE_PATH.INTAKE_ACTIVITY} resource={RESOURCE_PATH.INTAKE_ACTIVITY.toLowerCase()} action="delete" params={{ dataProviderName: DATAPROVIDER_DELETE, id: record.id }}
                                    fallback={<Button size="small" icon={<Icons.DeleteOutlined />} disabled title="You are not authorized to delete this activity." />}
                                >
                                    <DeleteButton resource={RESOURCE_PATH.INTAKE_ACTIVITY} size="small" recordItemId={record.id} icon={<Icons.DeleteOutlined />}
                                        title="Delete Activity" dataProviderName={DATAPROVIDER_DELETE} hideText
                                        onSuccess={() => {
                                            refetchActivitiesData();
                                        }} />
                                </CanAccess>
                            </Space>
                        }
                    />
                </Table>
            </Space>
            <Drawer
                title={metaConfig?.search?.textMessages?.drawerFormHeader || "Search & Filter"}
                placement="right"
                size="default"
                open={filterOpen}
                onClose={() => { switchFilterForm(false); }}
            >
                <IntakeActivityFilter formProps={searchFormProps}
                    onApplyFilter={() => switchFilterForm(false)} />
            </Drawer>
        </List>
        <IntakeActivityCreate
            modalProps={createModalProps}
            intakeId={intakeId}
            formProps={createFormProps}
        />
        <IntakeActivityEdit
            modalProps={editModalProps}
            intakeActivity={(selectedActivity)}
            open={editFormOpen}
            onFormSubmission={onEditFormSubmit}
            refetch={refetchActivitiesData}
        />
        <IntakeActivityShow
            modalProps={showModalProps}
            intakeActivity={(selectedActivity)}
        />
    </>
    );
};
