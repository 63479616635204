import { AntdLayout, Create, Form, Input, Result, SaveButton, Space, Typography, useForm } from "@pankod/refine-antd";
import { useGetIdentity } from "@pankod/refine-core";
import { CancelButton } from "components/utils/cancelButton";
import { IRegister, IUser } from "interfaces";
import { useState } from "react";
import { DATAPROVIDER_REGISTER, HELIUM_LOGO_PATH, TCS_LOGO_PATH } from "scripts/site";

export const RegisterPage: React.FC = () => {
    const { formProps, form, onFinish } = useForm<IRegister>({
        dataProviderName: DATAPROVIDER_REGISTER
    });
    const { data: userData, isLoading } = useGetIdentity();
    const userObj = userData as IUser;
    const [showRegister, setShowRegister] = useState<boolean>(true);
    const [titleMessage, setTitleMessage] = useState<string>("");
    const [subTitleMessage, setSubTitleMessage] = useState<string>("");

    const handleFormSubmit = () => {
        form.validateFields().then(async () => {
            const response = await onFinish(form.getFieldsValue());
            if (response && response.data) {
                setTitleMessage(response.data.response);
                setSubTitleMessage(response.data.responseMessage)
            }
            setShowRegister(false);
        })
            .catch((errorInfo) => {
            });
    }

    return (
        <AntdLayout>
            <div style={{ height: "100vh", margin: "2rem" }}>
                {showRegister ?
                    <Create
                        isLoading={isLoading}
                        headerProps={{
                            title: "Request Helium Access",
                            extra: <img src={HELIUM_LOGO_PATH} alt={process.env.REACT_APP_ApplicationTitle} style={{ maxWidth: "50px", marginRight: "1rem" }} />
                        }}
                        footerButtons={() => (
                            <>
                                <SaveButton onClick={() => { handleFormSubmit() }} >Submit</SaveButton>
                                <CancelButton />
                            </>
                        )}
                    >
                        <Form {...formProps} layout="vertical" autoComplete="off" initialValues={{ id: userObj?.id }}>
                            <Form.Item
                                label="Email"
                                name="id"
                                tooltip="This Email ID will be used to grant access. Kindly use work email ID for requesting access."
                            >
                                <Typography.Text>{userObj?.id}</Typography.Text>
                            </Form.Item>
                            <Form.Item
                                label="Name"
                                name="name"
                                tooltip="Provide your full name."
                                rules={[
                                    {
                                        required: true,
                                        whitespace: true,
                                        max: 100
                                    },
                                ]}
                            >
                                <Input placeholder="Provide your full name" />
                            </Form.Item>
                            <Form.Item
                                label="Work Location (City)"
                                name="workLocation"
                                tooltip="Provide work location city of your project execution. This is important to route your request accordingly."
                                rules={[
                                    {
                                        required: true,
                                        whitespace: true,
                                        max: 100
                                    },
                                    {
                                        pattern: new RegExp(/^[A-Za-z0-9 ]+$/),
                                        message: "Work location can be a combination of alphabets and numbers with Space characters only."
                                    }
                                ]}
                            >
                                <Input placeholder="Ex: Pune/Redmond/Toronto" />
                            </Form.Item>
                            <Form.Item
                                label="Manager's work email"
                                name="managerUserId"
                                tooltip="Kindly provide work email of your direct Lead. Any non-work email will cause rejection of request."
                                rules={[
                                    {
                                        required: true,
                                        type: "email",
                                        whitespace: true,
                                    }
                                ]}
                            >
                                <Input placeholder="Leads Work Email ID" />
                            </Form.Item>
                            <Form.Item
                                label="Why do you need access?"
                                name="justification"
                                tooltip="Provide valid business justification of why you need access. Provide name of your L1 and L2 leads, if you are aware to expediate the access request."
                                rules={[
                                    {
                                        required: true,
                                        whitespace: true,
                                        max: 250
                                    },
                                ]}
                            >
                                <Input.TextArea autoSize={{ minRows: 2, maxRows: 5 }}
                                    placeholder="Provide valid business justification outlining why you need access. Add name of L1 and L2 leads to expediate the access request, if you are aware" />
                            </Form.Item>
                        </Form>
                    </Create>
                    :
                    <Result
                        status="info"
                        title={titleMessage}
                        subTitle={subTitleMessage}
                        style={{ marginTop: "5rem" }}
                    />
                }
                <Space direction="horizontal">
                    <img src={TCS_LOGO_PATH} alt="TCS" style={{ maxWidth: "50px", marginLeft: "1rem" }} />
                </Space>
            </div>
        </AntdLayout>
    );
};