//Table to replace common/stateHistoryTable

import { stringExtensions } from "@architecture-innovation-transformation/lib-common";
import { Table, Tag, Typography } from "@pankod/refine-antd";
import type { ColumnsType } from 'antd/es/table';
import { ShowUserCard } from "components/profile/showUserCard";
import dayjs from "dayjs";
import { IStateManager, IWorkflowModel } from "interfaces";
import { formatMS, timeDiff } from "scripts/site";

const SEPARATOR = ","
const FINAL_CONJUNCTION = "and"

export const HistoryTable: React.FC<IStateManager> = ({
    workflow
}) => {

    const { Text } = Typography;
    let entryCount = 0;

    function assignDisplay(entry: any) {
        let assignees: JSX.Element[] = []
        if (entry.length < 1) {
            assignees.push(<Text key={"none-" + entryCount}>---</Text>)
        }
        for (let i = 0; i < entry.length; i++) {
            if (i === entry.length - 1 && i > 0) assignees.push(<Text key={"conjunction-" + entryCount.toString() + i.toString()}> {FINAL_CONJUNCTION} </Text>)
            assignees.push(<ShowUserCard id={entry[i]} key={entryCount.toString() + '-' + i.toString()} />)
            if (i < entry.length - 2) assignees.push(<Text key={"separator-" + entryCount.toString() + '-' + i.toString()}>{SEPARATOR} </Text>)
        }
        entryCount++
        return assignees
    }

    const columns: ColumnsType<IWorkflowModel> = [
        {
            title: 'State',
            dataIndex: 'state',
            key: 'activeState',
            render: (val) => <Tag>{stringExtensions.capitalize(val) ?? ""}</Tag>
        },
        {
            title: 'Assigned to',
            dataIndex: 'assignedTo',
            key: 'assignedTo',
            render: (val) => (assignDisplay(val))
        },
        {
            title: 'Time Spent',
            dataIndex: ['beginTS', 'endTS'],
            key: 'duration',
            render: (val, record) => <Text>{formatMS(timeDiff(record.beginTS, record.endTS), false, false)}</Text>

        },
        {
            title: 'Acted By',
            dataIndex: 'stateChangedBy',
            key: 'stateChangedBy',
            render: (val) => <ShowUserCard id={val} />
        },
        {
            title: 'Started',
            dataIndex: 'beginTS',
            key: 'beginTS',
            render: (val) => <Text>{dayjs(val).format("lll")}</Text>
        },
        {
            title: 'Comments',
            dataIndex: 'comments',
            key: 'comments',
            render: (text) => <Text>{text}</Text>
        }
    ]

    return (
        <Table columns={columns} dataSource={workflow} size="small" bordered={true}         
            scroll={{ x: 400 }} key={"historyTable"} rowKey={"beginTS"} />
    );
};