import { BooleanField, Spin, Table, TextField, Typography, getDefaultSortOrder, useTable } from "@pankod/refine-antd";
import { useNavigation, useOne } from "@pankod/refine-core";
import { ShowUserCard } from "components/profile/showUserCard";
import { displayRelativeDate } from "components/utils/displayRelativeDate";
import { GetEntityName } from "components/utils/getEntityName";
import { IState } from "interfaces";
import { ICandidateEvaluation, ICandidateEvaluationMetadata } from "interfaces/resourcing";
import { DATAPROVIDER_LOOKUP, DATAPROVIDER_READ, RESOURCE_PATH, STALE_DURATION, getStateDisplayName } from "scripts/site";

export declare type CandidateEvaluationListProps = {
    requestId: string;
};

export const CandidateEvaluationList: React.FC<CandidateEvaluationListProps> = ({ requestId }) => {

    const { show } = useNavigation();
    const { isLoading: metadataLoading, data: metadata } = useOne<ICandidateEvaluationMetadata>({
        dataProviderName: DATAPROVIDER_LOOKUP,
        resource: RESOURCE_PATH.METADATA,
        id: RESOURCE_PATH.CANDIDATEEVALUATION,
        queryOptions: {
            enabled: true,
            staleTime: STALE_DURATION
        }
    });
    const entityStates = metadata?.data?.states as IState[] ?? [];
    const { tableProps, sorter, tableQueryResult } = useTable<ICandidateEvaluation>({
        dataProviderName: DATAPROVIDER_READ,
        resource: RESOURCE_PATH.CANDIDATEEVALUATION,
        permanentFilter: [{
            field: "requestId",
            operator: "eq",
            value: requestId
        },
        {
            field: "stateManager.state",
            operator: "eq",
            value: "closed,selected"
        }
        ],
        queryOptions: {
            enabled: requestId.length > 0
        },
        hasPagination: false
    });

    function rowClick(record: ICandidateEvaluation) {
        return {
            onClick: () => {
                show(RESOURCE_PATH.CANDIDATEEVALUATION, record.id);
            },
        };
    }

    return (
        <Spin spinning={tableQueryResult.isLoading || metadataLoading}>
            <Table {...tableProps}
                rowKey="id"
                pagination={{
                    ...tableProps.pagination,
                    position: ["bottomRight"],
                    showTotal: (total => <Typography.Title level={4} style={{ marginRight: 10 }} >Total {total}</Typography.Title>)
                }}

            >
                <Table.Column
                    dataIndex="candidateId"
                    key="candidateId"
                    title="Candidate ID"
                    className="mouseHand"
                    onCell={rowClick}
                    render={(value: string) =>
                        <GetEntityName entity={RESOURCE_PATH.CANDIDATE} id={value} />
                    }
                    defaultSortOrder={getDefaultSortOrder("candidateId", sorter)}
                    sorter
                />
                <Table.Column
                    dataIndex={["stateManager", "state"]}
                    key="stateManager.state"
                    className="mouseHand"
                    onCell={rowClick}
                    title="State"
                    render={(value) => <TextField value={getStateDisplayName(value, entityStates)} />}
                    defaultSortOrder={getDefaultSortOrder("stateManager.state", sorter)}
                    sorter
                />
                <Table.Column
                    dataIndex="trCompleted"
                    key="trCompleted"
                    title="TR Completed"
                    onCell={rowClick}
                    className="mouseHand"
                    defaultSortOrder={getDefaultSortOrder("trCompleted", sorter)}
                    sorter
                    render={(value) => <BooleanField
                        value={value}
                        valueLabelTrue="Completed"
                        valueLabelFalse="Not Completed"
                    />}
                />
                <Table.Column
                    dataIndex="mrRequired"
                    title="MR Required"
                    key="mrRequired"
                    onCell={rowClick}
                    className="mouseHand"
                    defaultSortOrder={getDefaultSortOrder("mrRequired", sorter)}
                    sorter
                />
                <Table.Column
                    dataIndex="updatedBy"
                    key="updatedBy"
                    title="Updated By"
                    onCell={rowClick}
                    className="mouseHand"
                    defaultSortOrder={getDefaultSortOrder("updatedBy", sorter)}
                    sorter
                    render={(value) => <ShowUserCard id={value} />}
                />
                <Table.Column
                    dataIndex="updatedAt"
                    key="updatedAt"
                    title="Last Updated"
                    onCell={rowClick}
                    className="mouseHand"
                    render={displayRelativeDate}
                    defaultSortOrder={getDefaultSortOrder("updatedAt", sorter)}
                    sorter
                />
            </Table>
        </Spin>
    );
}