import mermaid from "mermaid";
import { useEffect } from "react";

export declare type mermaidProps = {
    graphDefinition: string;
    placeholderId: string;
  };

export const MermaidView: React.FC<mermaidProps> = ({
    graphDefinition,
    placeholderId
}) => {

    // Render Mermaid View
    useEffect(() => {  
        mermaid.render(`${placeholderId}`, graphDefinition, function (svgGraph: any) {
            const output = document.querySelector(`#${placeholderId}-parent`);
            if(output)
                output.innerHTML= svgGraph;
        });
    }, [graphDefinition, placeholderId]);

    return (
        <div id={`${placeholderId}-parent`} className="mermaid">
        </div>
    );
};