import { Col, DatePicker, Form, FormProps, Input, InputNumber, Modal, ModalProps, Row } from "@pankod/refine-antd";
import MDEditor from "@uiw/react-md-editor";
import dayjs from "dayjs";
import { getAppTheme } from "scripts/theme";

type IntakeActivityProps = {
    modalProps: ModalProps;
    formProps: FormProps;
    intakeId: string | undefined;
};

export const IntakeActivityCreate: React.FC<IntakeActivityProps> = ({
    modalProps,
    formProps,
    intakeId
}) => {

const threeColumnLayout = { xs: { span: 24 }, sm: { span: 24 }, md: { span: 8 }, lg: { span: 8 }, xl: { span: 8 }, xxl: { span: 8 } };
const rowGutter = { xs: 8, sm: 16, md: 24, lg: 32 };

    return (
        <Modal {...modalProps}
            width={"70%"}
            title="Log Activity"
            forceRender={true}
        >
            <Form {...formProps}
                layout="vertical" autoComplete="off"
                initialValues={{
                    intakeId: intakeId,
                    activitydate: dayjs(Date.now())
                }}
                scrollToFirstError={true}
                title="Log Activity"
            >
                <Form.Item
                    name="intakeId"
                    hidden
                >
                    <Input type="hidden"/>
                </Form.Item>
                <Form.Item
                    label="Activity Title"
                    name="name"
                    rules={[
                        {
                            required: true,
                            whitespace: true,
                            max: 100,
                            min: 5
                        },
                    ]}
                >
                    <Input placeholder="Provide brief title of the activity you performed" />
                </Form.Item>
                <Form.Item
                    label="Activity Description"
                    name="description"
                >
                    <MDEditor height={250} data-color-mode={getAppTheme()} placeholder="Describe the activity performed." />
                </Form.Item>
                <Row gutter={rowGutter}>
                    <Col className="gutter-row" {...threeColumnLayout}>
                        <Form.Item
                            label="Effort (in hours)"
                            name="effort"
                            rules={[
                                {
                                    required: true,
                                    whitespace: true,
                                    type: "number"
                                }
                            ]}
                        >
                            <InputNumber defaultValue={0} min={0} precision={2} step={0.25} placeholder="Provide effort in hours" style={{ width: "100%" }} />
                        </Form.Item>                        
                    </Col>
                    <Col className="gutter-row" {...threeColumnLayout}>
                        <Form.Item
                            label="Activity Date"
                            name="activitydate"
                            rules={[
                                {
                                    required: true,
                                    type: "date"
                                }
                            ]}
                        >
                            <DatePicker picker="date"
                                        placeholder="MMM DD, YYYY"
                                        format={"MMM DD, YYYY"}
                                        allowClear={true}
                                        defaultValue={dayjs(Date.now())}/>
                        </Form.Item>
                    </Col>
                </Row>
            </Form>
        </Modal>
    );
};
