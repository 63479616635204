import {
  CreateButton, getDefaultSortOrder, List, Table,
  Tag, TextField, Typography, useTable
} from "@pankod/refine-antd";
import { CanAccess, CrudFilters, HttpError, IResourceComponentsProps, useNavigation } from "@pankod/refine-core";
import { ShowUserCard } from "components/profile/showUserCard";
import { displayRelativeDate } from "components/utils/displayRelativeDate";
import { ICountry } from "interfaces";
import { DATAPROVIDER_CREATE, DATAPROVIDER_READ, RESOURCE_PATH } from "scripts/site";

export const CountryList: React.FC<IResourceComponentsProps> = () => {
  const { show } = useNavigation();

  const { tableProps, sorter, tableQueryResult } = useTable<ICountry, HttpError, { q: string }>({
    dataProviderName: DATAPROVIDER_READ,
    initialSorter: [
      {
        field: "name",
        order: "asc"
      },
    ],
    onSearch: (params) => {
      const filters: CrudFilters = [];
      const { q } = params;
      filters.push({
        field: "q",
        operator: "contains",
        value: q
      });
      return filters;
    }
  });

  function rowClick(record: ICountry) {
    return {
      onClick: () => {
        show(RESOURCE_PATH.COUNTRY, record.id);
      },
    };
  }

  const totalCountries = tableQueryResult?.data?.total ?? 0;

  return (
    <List
      headerProps={{
        title: "Countries",
        tags: <Tag color={"default"}>
          {tableQueryResult.isLoading ? "Loading" : totalCountries + " Countries"}
        </Tag>
      }}
      headerButtons={() => (
        <>
          <CanAccess key={RESOURCE_PATH.COUNTRY} resource={RESOURCE_PATH.COUNTRY.toLowerCase()} action="create" params={{ dataProviderName: DATAPROVIDER_CREATE, id: "1" }}>
            <CreateButton>Add Country</CreateButton>
          </CanAccess>
        </>
      )}
    >
      <Table {...tableProps}
        rowKey="id"
        onRow={rowClick}
        className="mouseHand"
        pagination={{
          ...tableProps.pagination,
          position: ["bottomRight"],
          showTotal: (total => <Typography.Title level={4} style={{ marginRight: 10 }} >Total {total}</Typography.Title>)
        }}
      >
        <Table.Column
          dataIndex="id"
          key="id"
          title="Country ID"
          render={(value) =>
            <div>
              <TextField value={value} />
            </div>
          }
          defaultSortOrder={getDefaultSortOrder("id", sorter)}
          sorter
        />

        <Table.Column
          dataIndex="name"
          key="name"
          title="Name"
          render={(value) =>
            <div>
              <TextField value={value} />
            </div>
          }
          defaultSortOrder={getDefaultSortOrder("name", sorter)}
          sorter
        />

        <Table.Column
          dataIndex="updatedBy"
          title="Updated By"
          defaultSortOrder={getDefaultSortOrder("updatedBy", sorter)}
          sorter
          render={(value) => <ShowUserCard id={value} />}
        />

        <Table.Column
          dataIndex="updatedAt"
          key="updatedAt"
          title="Last Updated"
          render={displayRelativeDate}
          defaultSortOrder={getDefaultSortOrder("updatedAt", sorter)}
          sorter
        />
      </Table>
    </List>
  );
};