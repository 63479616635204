import { Space, Spin, Typography } from "@pankod/refine-antd";

export declare type spinnerProps = {
    message: string;
    direction?: "horizontal" | "vertical" | undefined;
    size?: "default" | "large" | "small";
};
export const RenderSpinner: React.FC<spinnerProps> = ({
    message,
    direction = "vertical",
    size = "default"
}) => {
    return (
        <Space direction={direction} wrap align="center" >
            <Typography.Title level={5} style={{ margin: 30 }}>
                {message}
            </Typography.Title>
            <Spin size={size}>
            </Spin>
        </Space>
    );
};