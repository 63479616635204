import { Tag, Tooltip, Typography } from "@pankod/refine-antd";
import { useNavigation } from "@pankod/refine-core";
import { IAvailability, ISlot } from "interfaces";
import { RESOURCE_PATH } from "scripts/site";

export const RenderSlots: React.FC<IAvailability> = ({ slots, room, date, message }) => {
    const { push, show } = useNavigation();

    function handleClick(slot: ISlot) {
        if (slot.type === 'Available' || slot.type === 'NotMatchingCriteria') {
            push(`/${RESOURCE_PATH.SPACES}/${RESOURCE_PATH.RESERVATION}/create?room=${room.id}&date=${date}&begin=${slot.begin}&end=${slot.end}`);
        }
        else if (slot.type === 'Reserved' || slot.type === 'ReservedByMe') {
            show(RESOURCE_PATH.RESERVATION, slot.id)
        }
    }
    return (<>
        {message && <Typography.Text type="danger">{message}</Typography.Text>}
        {(!message && slots.length <= 0) && <Typography.Text type="warning">No slots available</Typography.Text>}
        {slots.map((slot, index) => {
            let tagColor = "default";

            switch (slot.type) {
                case 'Available':
                    tagColor = 'green';
                    break;
                case 'NotMatchingCriteria':
                    tagColor = 'default';
                    break;
                case 'Reserved':
                    tagColor = 'red';
                    break;
                case 'ReservedByMe':
                    tagColor = 'blue';
                    break;
            }
            return (<Tooltip title={slot.type} key={slot.id + '-' + index + '-button'}>
                <Tag
                    style={{ fontSize: 16, marginBottom: 8 }}
                    onClick={() => handleClick(slot)}

                    color={tagColor}
                    className="mouseHand"
                >{slot.begin} - {slot.end}
                </Tag>
            </Tooltip>)
        })}
    </>);
}