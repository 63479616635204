
import { Icons, Menu, Space } from "@pankod/refine-antd";
import { CanAccess, useCan, useNavigation, useResourceWithRoute } from "@pankod/refine-core";
import React, { useState } from "react";
import { RESOURCE_PATH } from "scripts/site";

export const ProgramSubMenu: React.FC<{
    programId: string,
    selectedResource?: string,
    tabId?: string,
    renderAsMenu?: boolean,
}> = ({ programId, selectedResource = "", tabId = "", renderAsMenu = true }) => {
    const { push } = useNavigation();
    const resourceWithRoute = useResourceWithRoute();
    const [progId] = useState(programId);

    function navigateWithProgramId(resourceName: string) {
        push(`/${resourceWithRoute(resourceName).route}?programId=${progId}`);
    }

    const canShowProgram = useCan({
        resource: RESOURCE_PATH.PROGRAM,
        action: "show"
    });

    return (
        renderAsMenu ?
            <Menu mode="horizontal" selectedKeys={[selectedResource + tabId]} theme={"light"} className="topmenu">
                {canShowProgram.data?.can &&
                    <Menu.Item key={RESOURCE_PATH.PROGRAM + "okr"} icon={<Icons.AimOutlined />}
                        onClick={() => {
                            push(`/${resourceWithRoute(RESOURCE_PATH.PROGRAM).route}/show/${progId}?tabId=okr`);
                        }}>
                        OKRs
                    </Menu.Item>
                }
                {canShowProgram.data?.can &&
                    <Menu.Item key={RESOURCE_PATH.PROGRAM + "tasks"} icon={<Icons.OrderedListOutlined />}
                        onClick={() => {
                            push(`/${resourceWithRoute(RESOURCE_PATH.PROGRAM).route}/show/${progId}?tabId=tasks`);
                        }}>
                        Tasks
                    </Menu.Item>
                }
                {canShowProgram.data?.can &&
                    <Menu.Item key={RESOURCE_PATH.PROGRAM + "journey"} icon={<Icons.NodeIndexOutlined />}
                        onClick={() => {
                            push(`/${resourceWithRoute(RESOURCE_PATH.PROGRAM).route}/show/${progId}?tabId=journey`);
                        }}>
                        Journey
                    </Menu.Item>
                }

                <CanAccess key={RESOURCE_PATH.SCENARIO} resource={RESOURCE_PATH.SCENARIO.toLowerCase()} action="list">
                    <Menu.Item key={RESOURCE_PATH.SCENARIO} icon={<Icons.BlockOutlined />} onClick={() => {
                        navigateWithProgramId(RESOURCE_PATH.SCENARIO);
                    }}>
                        Scenarios
                    </Menu.Item>
                </CanAccess>

                <CanAccess key={RESOURCE_PATH.ENGAGEMENTPROGRAM} resource={RESOURCE_PATH.ENGAGEMENTPROGRAM.toLowerCase()} action="list">
                    <Menu.Item key={RESOURCE_PATH.ENGAGEMENTPROGRAM} icon={<Icons.GlobalOutlined />} onClick={() => {
                        navigateWithProgramId(RESOURCE_PATH.ENGAGEMENTPROGRAM);
                    }}>
                        Engagements
                    </Menu.Item>
                </CanAccess>

                {canShowProgram.data?.can &&
                    <Menu.Item key={RESOURCE_PATH.PROGRAM + "help"} icon={<Icons.QuestionOutlined />}
                        onClick={() => {
                            push(`/${resourceWithRoute(RESOURCE_PATH.PROGRAM).route}/show/${progId}?tabId=help`);
                        }}>
                        Help
                    </Menu.Item>
                }
            </Menu>
            :
            <Space>
                {/*  <CanAccess key={RESOURCE_PATH.ASSESSMENT} resource={RESOURCE_PATH.ASSESSMENT.toLowerCase()} action="list">
                    <Button size="middle" icon={<Icons.ContainerOutlined />}
                        onClick={() => {
                            navigateWithProgramId(RESOURCE_PATH.ASSESSMENT);
                        }}>Assessments</Button>
                </CanAccess>
                 <CanAccess key={RESOURCE_PATH.SCENARIO} resource={RESOURCE_PATH.SCENARIO.toLowerCase()} action="list">
                    <Button size="middle" icon={<Icons.FileProtectOutlined />}
                        onClick={() => {
                            navigateWithProgramId(RESOURCE_PATH.SCENARIO);
                        }}>Scenarios</Button>
                </CanAccess>*/}
            </Space>
    );
};