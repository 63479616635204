import { useList } from "@pankod/refine-core";
import { DATAPROVIDER_COUNT, RESOURCE_PATH } from "scripts/site";

export const ScenarioCount: React.FC<{
    programId: string
}> = ({ programId }) => {

    const { data: ct, isLoading } = useList({
        dataProviderName: DATAPROVIDER_COUNT,
        resource: RESOURCE_PATH.SCENARIO,
        config: {
            filters: [
                {
                    field: "programId",
                    operator: "eq",
                    value: programId
                }]
        },
        queryOptions: {
            enabled: !!programId
        }
    });
    return (isLoading ? <>Loading...</> :
        <>{ct?.data ?? "0"}</>
    );
}