import { Alert, Button, CreateButton, Drawer, EditButton, Icons, List, Table, Tag, Typography, useTable } from "@pankod/refine-antd";
import { CanAccess, CrudFilters, HttpError, IResourceComponentsProps, useGetIdentity, useOne } from "@pankod/refine-core";
import type { ColumnsType } from 'antd/es/table';
import { RoomFilter } from "components/spaces/roomFilter";
import { GetListTableColumns, mergePreferences } from "components/utils/getListTableColumns";
import { ListColumnPicker } from "components/utils/listColumnPicker";
import { IEntityListColumns, IRoom, IRoomFilterVariables, IRoomMetadata, IUser } from "interfaces";
import { useEffect, useState } from "react";
import { DATAPROVIDER_CREATE, DATAPROVIDER_LOOKUP, DATAPROVIDER_READ, RESOURCE_PATH, STALE_DURATION } from "scripts/site";

export const RoomList: React.FC<IResourceComponentsProps> = () => {

    const { data: userData, isLoading: userLoading } = useGetIdentity();
    const userObj = userData as IUser;

    const { tableProps, sorter, tableQueryResult, searchFormProps } = useTable<IRoom, HttpError, IRoomFilterVariables>({
        dataProviderName: DATAPROVIDER_READ,
        resource: RESOURCE_PATH.ROOM,
        initialSorter: [
            {
                field: "updatedAt",
                order: "desc"
            },
        ],
        onSearch: (params) => {
            const filters: CrudFilters = [];
            const { q, zoneId, buildingId, occupancy, offline, privateRoom } = params;
            filters.push({
                field: "q",
                operator: "contains",
                value: q
            });
            filters.push({
                field: "zoneId",
                operator: "eq",
                value: zoneId
            });
            filters.push({
                field: "buildingId",
                operator: "eq",
                value: buildingId
            });
            filters.push({
                field: "maxOccupancy",
                operator: "gte",
                value: occupancy
            });
            filters.push({
                field: "offline",
                operator: "eq",
                value: offline
            });
            filters.push({
                field: "private",
                operator: "eq",
                value: privateRoom
            });
            return filters;
        }
    });

    const { data: entityMetadata } = useOne<IRoomMetadata>({
        dataProviderName: DATAPROVIDER_LOOKUP,
        resource: RESOURCE_PATH.METADATA,
        id: RESOURCE_PATH.ROOM,
        queryOptions: {
            enabled: true,
            staleTime: STALE_DURATION
        }
    });
    const metaConfig = entityMetadata?.data?.config;
    const [filterOpen, switchFilterForm] = useState(false);
    const [columnSelectionDrawer, switchColumnSelection] = useState(false);
    const [tableColumns, setTableColumns] = useState<IEntityListColumns>();
    const columnPreference = userObj?.preferences?.entityListView?.find(x => x.id === RESOURCE_PATH.ROOM)?.columns;

    useEffect(() => {
        if (metaConfig) {
            setTableColumns(mergePreferences(metaConfig.listColumns, columnPreference, RESOURCE_PATH.ROOM));
        }
    }, [metaConfig, columnPreference]);

    const getTableColumns = (): ColumnsType<IRoom> => {
        const columns: ColumnsType<IRoom> = GetListTableColumns(tableColumns, sorter, RESOURCE_PATH.ROOM);
        return columns
    }
    const OnColumnSelection = () => {
        switchColumnSelection(false);
    }


    return (
        <List
            headerProps={{
                title: "Rooms",
                tags: <Tag color={"default"}>
                    {tableQueryResult.isLoading ? "Loading" : tableQueryResult?.data?.total} Room{tableQueryResult?.data?.total !== 1 && "s"}
                </Tag>,
                extra: <>
                    <CanAccess key={RESOURCE_PATH.ROOM + "-edit"} resource={RESOURCE_PATH.ROOM.toLowerCase()} action="create" params={{ dataProviderName: DATAPROVIDER_CREATE, id: "1" }}>
                        <CreateButton></CreateButton>
                    </CanAccess>
                    <Button key="filter" icon={<Icons.SearchOutlined />} onClick={() => switchFilterForm(true)}>{metaConfig?.search?.textMessages?.buttonText || "Search & Filter"}</Button>
                    <Button key="colmnopt" icon={<Icons.ToolOutlined />} onClick={() => switchColumnSelection(true)}>Column Options</Button>
                </>
            }}
        >
            {(!userLoading && ((!userObj?.countryId) || userObj?.countryId === null || userObj?.countryId === "")) &&
                <Alert
                    message="Country is not assigned"
                    description={<>Please <EditButton style={{ padding: 0 }} type="link" icon={false} resourceNameOrRouteName={RESOURCE_PATH.PROFILE} recordItemId={userObj?.id}>update your profile</EditButton> and select your country.</>}
                    type="warning"
                    showIcon
                    style={{ marginBottom: 20 }}
                />
            }

            <Table {...tableProps}
                loading={tableQueryResult.isLoading || tableQueryResult.isRefetching || userLoading}
                rowKey="id"
                pagination={{
                    ...tableProps.pagination,
                    position: ["bottomRight"],
                    showTotal: (total => <Typography.Title level={4} style={{ marginRight: 10 }} >Total {total}</Typography.Title>)
                }}
                columns={getTableColumns()}
            >
            </Table>
            <Drawer
                title={metaConfig?.search?.textMessages?.drawerFormHeader || "Search & Filter"}
                placement="right"
                size="default"
                open={filterOpen}
                onClose={() => { switchFilterForm(false) }}
            >
                <RoomFilter formProps={searchFormProps} />
            </Drawer>
            <Drawer
                title="Column options"
                placement="right"
                size="default"
                open={columnSelectionDrawer}
                onClose={() => { switchColumnSelection(false) }}
            >
                <ListColumnPicker user={userObj} entityListColumns={tableColumns} onColumnSelection={OnColumnSelection} />
            </Drawer>
        </List>
    );
}