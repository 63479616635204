import { Col, Row, Typography } from "@pankod/refine-antd";
import { IUser } from "interfaces";
import { stringExtensions } from "@architecture-innovation-transformation/lib-common";
const { Title, Text } = Typography;

export const DisplayPreferencesTab: React.FC<IUser> = (record: IUser) => {

    return (
        <>
            <Row gutter={[16, 16]}>
                <Col xl={12} xs={24}>
                    <Title level={5}>App Theme</Title>
                    <Text>{stringExtensions.capitalize(record?.preferences?.theme) || "---"}</Text>
                </Col>
                <Col xl={12} xs={24}>
                    <Col xl={12} xs={24}>
                        <Title level={5}>Notify me on</Title>
                        <Text>{record?.preferences?.notificationEmail || "---"}</Text>
                    </Col>
                </Col>
            </Row>
        </>
    );
};