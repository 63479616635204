import { CrudFilters, useList } from "@pankod/refine-core";
import { DATAPROVIDER_COUNT, RESOURCE_PATH } from "scripts/site";

export const EngagementSolutionCount: React.FC<{
    programId?: string, engagementId?: string, scenarioId?: string
}> = ({ programId, engagementId, scenarioId }) => {

    const filters: CrudFilters = [];
    if (programId) {
        filters.push({
            field: "programId",
            operator: "eq",
            value: programId
        });
    }
    if (engagementId) {
        filters.push({
            field: "engagementId",
            operator: "eq",
            value: engagementId
        });
    }
    if (scenarioId) {
        filters.push({
            field: "scenarioId",
            operator: "eq",
            value: scenarioId
        });
    }

    const { data: enggSolutionCount, isLoading } = useList({
        dataProviderName: DATAPROVIDER_COUNT,
        resource: RESOURCE_PATH.ENGAGEMENTSOLUTION,
        config: {
            filters: filters
        },
    });
    return (isLoading ? <>Loading...</> :
        <>{enggSolutionCount?.data ?? "0"}</>
    );
}