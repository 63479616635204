import { Card, Col, Create, DatePicker, Form, Input, InputNumber, Row, SaveButton, Select, useForm, useSelect } from "@pankod/refine-antd";
import { useGetIdentity, useOne } from "@pankod/refine-core";
import MDEditor from "@uiw/react-md-editor";
import { CancelButton } from "components/utils/cancelButton";
import dayjs from "dayjs";
import { ILookup, IUser } from "interfaces";
import { UserType } from "interfaces/enum";
import { IIntake, IIntakeMetadata, IRequestType } from "interfaces/intake";
import React, { useEffect, useState } from "react";
import { DATAPROVIDER_CREATE, DATAPROVIDER_LOOKUP, RESOURCE_PATH, STALE_DURATION } from "scripts/site";
import { getAppTheme } from "scripts/theme";

export const IntakeCreate: React.FC = () => {
    const { data } = useGetIdentity();
    const user = data as IUser;

    const { form, formProps, saveButtonProps } = useForm<IIntake>({
        dataProviderName: DATAPROVIDER_CREATE,
        redirect: "show"
    });

    const { selectProps: smesList } = useSelect<ILookup>({
        dataProviderName: DATAPROVIDER_LOOKUP,
        resource: RESOURCE_PATH.PEOPLE,
        optionLabel: "nameEmail",
        optionValue: "id",
        sort: [{
            field: "lastAccessedAt",
            order: "desc"
        }],
        filters: [{
            field: "stateManager.state",
            operator: "eq",
            value: "active"
        },
        {
            field: "userType",
            operator: "eq",
            value: UserType.Employee
        }],
        fetchSize: 50
    });

    const { selectProps: salesExecList } = useSelect<ILookup>({
        dataProviderName: DATAPROVIDER_LOOKUP,
        resource: RESOURCE_PATH.PEOPLE,
        optionLabel: "nameEmail",
        optionValue: "id",
        filters: [{
            field: "roleIds ARRAY_CONTAINS",
            operator: "eq",
            value: "salesExecutive"
        },
        {
            field: "stateManager.state",
            operator: "eq",
            value: "active"
        }],
        sort: [{
            field: "lastAccessedAt",
            order: "desc"
        }],
        fetchSize: 50
    });

    const { selectProps: deliveryExecList } = useSelect<ILookup>({
        dataProviderName: DATAPROVIDER_LOOKUP,
        resource: RESOURCE_PATH.PEOPLE,
        optionLabel: "nameEmail",
        optionValue: "id",
        filters: [{
            field: "roleIds ARRAY_CONTAINS",
            operator: "eq",
            value: "deliveryExecutive"
        },
        {
            field: "stateManager.state",
            operator: "eq",
            value: "active"
        }],
        sort: [{
            field: "lastAccessedAt",
            order: "desc"
        }],
        fetchSize: 50
    });

    const { data: metadata } = useOne<IIntakeMetadata>({
        dataProviderName: DATAPROVIDER_LOOKUP,
        resource: RESOURCE_PATH.METADATA,
        id: RESOURCE_PATH.INTAKE,
        queryOptions: {
            enabled: true,
            staleTime: STALE_DURATION
        }
    });

    const metaConfig = metadata?.data.config;
    const threeColumnLayout = { xs: { span: 24 }, sm: { span: 24 }, md: { span: 8 }, lg: { span: 8 }, xl: { span: 8 }, xxl: { span: 8 } };
    const rowGutter = { xs: 8, sm: 16, md: 24, lg: 32 };    
    const [requestType, setRequestType] = useState<IRequestType>();

    useEffect(() => {
        if (!requestType?.dependentFields?.some((f) => f === "focusArea")) {
            form.setFieldsValue({ focusArea: null, crmOpportunityId: null });
        }
    }, [form, requestType])

    const today = dayjs().endOf('month');

    return (
        <Create
            title="Create a request"
            footerButtons={<>
                <SaveButton {...saveButtonProps}>Save</SaveButton>
                <CancelButton />
            </>}
        >
            <Form {...formProps} layout="vertical" autoComplete="off"
                scrollToFirstError={true}
                initialValues={{
                    smes: user?.id ? [user?.id] : [],
                    requestDeadline: dayjs(Date.now()).add(15, 'days'),
                }}
            >
                <Form.Item
                    label="Title"
                    name="name"
                    rules={[
                        {
                            required: true,
                            whitespace: true,
                            message: "Title is required.",
                            min: 5,
                            max: 250
                        },
                    ]}
                >
                    <Input placeholder="Title for the intake" />
                </Form.Item>
                <Form.Item
                    label="Business Group"
                    name="businessGroup"
                    rules={[
                        {
                            required: true,
                            type: "string",
                        },
                    ]}
                >
                    <Select placeholder="Select Business Group"
                        showSearch
                    >
                        {metaConfig?.businessGroups?.map((m) => (
                            <Select.Option value={m} key={`lc-${m}`}>{m}</Select.Option>
                        ))}
                    </Select>
                </Form.Item>
                <Row gutter={rowGutter}>
                    <Col className="gutter-row" {...threeColumnLayout}>
                        <Form.Item
                            label="Request Type"
                            name="requestType"
                            rules={[
                                {
                                    required: true,
                                    type: "string",
                                },
                            ]}
                        >
                            <Select placeholder="Select Request Type"
                                onChange={(_value) => {
                                    setRequestType(metaConfig?.requestType?.find(rst => rst.requestTypeName === _value));
                                    form.setFieldsValue({ requestSubtype: null });
                                }}
                                showSearch
                            >
                                {metaConfig?.requestType?.map((m) => (
                                    <Select.Option value={m.requestTypeName} key={`lc-${m.requestTypeName}`}>{m.requestTypeName}</Select.Option>
                                ))}
                            </Select>
                        </Form.Item>
                    </Col>

                    <Col className="gutter-row" {...threeColumnLayout}>
                        <Form.Item
                            label="Request Sub Type"
                            name="requestSubtype"
                            tooltip="Select the request subtype"
                            shouldUpdate={(prevValues, currentValues) => prevValues.requestType !== currentValues.requestType}
                            rules={[
                                {
                                    required: true,
                                    type: "string"
                                }
                            ]}
                        >
                            <Select placeholder={requestType ? "Select Request Sub Type" : "Select Request Type first"} showSearch>
                                {requestType?.requestSubType?.map((r) => (
                                    <Select.Option value={r} key={`rst-${r}`}>{r}</Select.Option>
                                ))}
                            </Select>
                        </Form.Item>
                    </Col>
                </Row>

                {requestType?.dependentFields?.some((f) => f === "focusArea") ?
                    <Card size="small" style={{ marginBottom: 20, paddingBottom: 0 }} title={"Road to 1 Billion"} >
                        <Row gutter={rowGutter}>
                            <Col className="gutter-row" {...threeColumnLayout}>
                                <Form.Item
                                    label="Strategic Focus Area"
                                    name="focusArea"
                                    tooltip="Select Strategic Focus Area as per account 1 Billion plan"
                                    rules={[
                                        {
                                            required: true,
                                            type: "string"
                                        }
                                    ]}
                                >
                                    <Select placeholder={requestType ? "Select Strategic Focus Area" : "Select Request Type first"} showSearch>
                                        {metaConfig?.focusAreas?.map((m) => (
                                            <Select.Option value={m} key={`fa-${m}`}>{m}</Select.Option>
                                        ))}
                                    </Select>
                                </Form.Item>
                            </Col>
                            <Col className="gutter-row" {...threeColumnLayout}>
                                <Form.Item
                                    label="CRM/Opportunity ID"
                                    name="crmOpportunityId"
                                    rules={[
                                        {
                                            required: true,
                                            message: "CRM/Opportunity ID is required.",
                                        },
                                        {
                                            whitespace: true,
                                            type: "integer",
                                            message: "CRM/Opportunity ID should be 7 digits long numeric value starting with 1 or 2",
                                            validator: async (_, value) => {
                                                if (value) {
                                                    let strValue = value?.toString();
                                                    if (strValue && strValue.length === 7 && (strValue.startsWith("1") || strValue.startsWith("2"))) {
                                                        return Promise.resolve();
                                                    }
                                                    else {
                                                        return Promise.reject(new Error("CRM/Opportunity ID does not match the criteria."));
                                                    }
                                                }
                                            }
                                        }
                                    ]}
                                >
                                    <InputNumber min={1} precision={0} maxLength={7} placeholder="CRM/Opportunity ID" style={{ width: "100%" }} controls={false} />
                                </Form.Item>
                            </Col>
                        </Row>
                    </Card>
                    : <>
                        <Form.Item
                            name="focusArea"
                            hidden={true}
                        >
                            <Input />
                        </Form.Item>
                        <Form.Item
                            name="crmOpportunityId"
                            hidden={true}
                        >
                            <Input />
                        </Form.Item>
                    </>
                }

                <Row gutter={rowGutter}>
                    <Col className="gutter-row" {...threeColumnLayout}>
                        <Form.Item
                            label="Revenue Opportunity"
                            name="opportunity"
                            rules={[
                                {
                                    required: true,
                                    type: "number"
                                }
                            ]}
                        >
                            <InputNumber min={0} precision={0} step={10} placeholder="0" addonAfter="$" style={{ width: "100%" }} />
                        </Form.Item>
                    </Col>
                    <Col className="gutter-row" {...threeColumnLayout}>
                        {
                            <Form.Item
                                label="Start of Revenue Realization (Month, Year)"
                                name="realizationDate"
                                tooltip="Select the month and year for $ value realization"
                                rules={[
                                    {
                                        required: true,
                                        type: "date"
                                    },
                                    {
                                        validator: async (_, value) => {
                                            if (value && Date.now() > value) {
                                                throw new Error("Realization Date should be in the future.");
                                            }
                                        }
                                    }
                                ]}
                            >
                                <DatePicker picker="month" format={"MMM, YYYY"}
                                    disabledDate={(selDate) => selDate && (selDate < today)}
                                    style={{ width: "100%" }} />
                            </Form.Item>
                        }
                    </Col>
                    <Col className="gutter-row" {...threeColumnLayout}>
                        {
                            <Form.Item
                                label="Revenue Realization Duration"
                                name="realizationDurationMonths"
                                tooltip="Number of months the $ value would be realized"
                                rules={[
                                    {
                                        required: true,
                                        type: "number"
                                    }
                                ]}
                            >
                                <InputNumber min={0} precision={0} placeholder="What is the duration to realize the $ value" addonAfter="Months" style={{ width: "100%" }} />
                            </Form.Item>
                        }
                    </Col>
                </Row>
                <Row gutter={rowGutter}>
                    <Col className="gutter-row" {...threeColumnLayout}>
                        {
                            <Form.Item
                                label="Request Deadline"
                                name="requestDeadline"
                                tooltip="Date by when this request needs to be completed."
                                rules={[
                                    {
                                        required: false,
                                        type: "date"
                                    }
                                ]}
                            >
                                <DatePicker //defaultValue={dayjs(Date.now()).add(15, 'days')}
                                    disabledDate={d => !d || d.isBefore(dayjs(Date.now()).add(14, 'days'))} format={"MMM DD, YYYY"}
                                    style={{ width: "100%" }} />
                            </Form.Item>
                        }
                    </Col>
                </Row>
                <Row gutter={rowGutter}>
                    <Col className="gutter-row" {...threeColumnLayout}>
                        <Form.Item
                            label="SME(s)"
                            name="smes"
                            rules={[
                                {
                                    required: true,
                                    whitespace: true,
                                    type: "array",
                                    message: "SME(s) are required."
                                },
                            ]}
                        >
                            <Select placeholder="Search name or email of the SME(s)" mode="multiple"  {...smesList} allowClear />
                        </Form.Item>
                    </Col>
                    <Col className="gutter-row" {...threeColumnLayout}>
                        <Form.Item
                            label="Delivery Executive"
                            name="deliveryExecutive"
                            rules={[
                                {
                                    required: true,
                                    whitespace: true,
                                    min: 1,
                                    type: "string",
                                    message: "Delivery Executive is required."
                                },
                            ]}
                        >
                            <Select placeholder="Search name or email of the delivery executive" {...deliveryExecList} allowClear />
                        </Form.Item>
                    </Col>
                    <Col className="gutter-row" {...threeColumnLayout}>
                        <Form.Item
                            label="Sales Executive"
                            name="salesExecutive"
                            rules={[
                                {
                                    required: true,
                                    whitespace: true,
                                    type: "string",
                                    message: "Sales Executive is required."
                                },
                            ]}
                        >
                            <Select placeholder="Search name or email of the sales executive"  {...salesExecList} allowClear />
                        </Form.Item>
                    </Col>
                </Row>
                <Form.Item
                    label="Problem Statement"
                    name="problemStatement"
                    rules={[
                        {
                            required: true,
                            whitespace: true,
                            min: 5
                        },
                    ]}
                >
                    <MDEditor height={250} data-color-mode={getAppTheme()} placeholder="Explain the problem statement in detail for which you need help." />
                </Form.Item>
                <Form.Item
                    label="Support Needed"
                    name="supportNeeded"
                    rules={[
                        {
                            required: true,
                            whitespace: true,
                            min: 5,
                            max: 4000
                        },
                    ]}
                >
                    <MDEditor height={250} data-color-mode={getAppTheme()} placeholder="Explain the support needed in detail." />
                </Form.Item>
            </Form>
        </Create>
    );
}