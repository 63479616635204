
import { Button, Icons, Menu, Space } from "@pankod/refine-antd";
import { CanAccess, useNavigation, useResourceWithRoute } from "@pankod/refine-core";
import React, { useState } from "react";
import { DATAPROVIDER_READ, RESOURCE_PATH } from "scripts/site";

export const EngagementSubMenu: React.FC<{
    engagementId: string,
    selectedResource?: string,
    renderAsMenu?: boolean,
}> = ({ engagementId, selectedResource = "", renderAsMenu = true }) => {
    const { push } = useNavigation();
    const resourceWithRoute = useResourceWithRoute();
    const [engId] = useState(engagementId);

    function navigateWithEngagementId(resourceName: string) {
        push(`/${resourceWithRoute(resourceName).route}?engagementId=${engId}`);
    }

    return (
        renderAsMenu ?
            <Menu mode="horizontal" selectedKeys={[selectedResource]} theme={"light"} className="topmenu">
                <CanAccess key={RESOURCE_PATH.ENGAGEMENT} resource={RESOURCE_PATH.ENGAGEMENT.toLowerCase()} action="show">
                    <Menu.Item icon={<Icons.InfoOutlined />}
                        onClick={() => {
                            push(`/${resourceWithRoute(RESOURCE_PATH.ENGAGEMENT).route}/show/${engId}`);
                        }}>
                        Details
                    </Menu.Item>
                </CanAccess>
                {/* 
                Using assessment show AuthZ to show the menu item only if the user has access to view the assessment in the engagement
                Need to pass the engagementId as a query parameter to build a dummy object for RBAC check
                */}
                <CanAccess key={RESOURCE_PATH.ENGAGEMENTPROGRAM} resource={RESOURCE_PATH.ENGAGEMENTPROGRAM.toLowerCase()} action="show"
                    params={{ dataProviderName: DATAPROVIDER_READ, id: "1", engagementId: engagementId, ignoreid: true }}>
                    <Menu.Item icon={<Icons.AimOutlined />} onClick={() => {
                        navigateWithEngagementId(RESOURCE_PATH.ENGAGEMENTPROGRAM);
                    }}>
                        Programs
                    </Menu.Item>
                </CanAccess>
                {/* 
                Using assessment show AuthZ to show the menu item only if the user has access to view the assessment in the engagement
                Need to pass the engagementId as a query parameter to build a dummy object for RBAC check
                */}
                <CanAccess key={RESOURCE_PATH.ASSESSMENT} resource={RESOURCE_PATH.ASSESSMENT.toLowerCase()} action="show"
                    params={{ dataProviderName: DATAPROVIDER_READ, id: "1", engagementId: engagementId, ignoreid: true }}>
                    <Menu.Item icon={<Icons.ContainerOutlined />}
                        onClick={() => {
                            navigateWithEngagementId(RESOURCE_PATH.ASSESSMENT);
                        }}>
                        Assessments
                    </Menu.Item>
                </CanAccess>
                <CanAccess key={RESOURCE_PATH.CONTRACT} resource={RESOURCE_PATH.CONTRACT.toLowerCase()} action="list">
                    <Menu.Item icon={<Icons.FileProtectOutlined />} onClick={() => {
                        navigateWithEngagementId(RESOURCE_PATH.CONTRACT);
                    }}>
                        Contracts
                    </Menu.Item>
                </CanAccess>
                {/* 
                Using entity show AuthZ to show the menu item only if the user has access to view the entity in the engagement
                Need to pass the engagementId as a query parameter to build a dummy object for RBAC check
                */}
                {/* <CanAccess key={RESOURCE_PATH.ASSOCIATEMAPPING} resource={RESOURCE_PATH.ASSOCIATEMAPPING.toLowerCase()} action="show"
                    params={{ dataProviderName: DATAPROVIDER_READ, id: "1", engagementId: engagementId, ignoreid: true }}>
                    <Menu.Item icon={<Icons.SolutionOutlined />} onClick={() => {
                        navigateWithEngagementId(RESOURCE_PATH.ASSOCIATEMAPPING);
                    }}>
                        Associates
                    </Menu.Item>
                </CanAccess> */}
            </Menu>
            :
            <Space>
                <CanAccess key={RESOURCE_PATH.ENGAGEMENTPROGRAM} resource={RESOURCE_PATH.ENGAGEMENTPROGRAM.toLowerCase()} action="show"
                    params={{ dataProviderName: DATAPROVIDER_READ, id: "1", engagementId: engagementId, ignoreid: true }}>
                    <Button size="middle" icon={<Icons.AimOutlined />}
                        onClick={() => {
                            navigateWithEngagementId(RESOURCE_PATH.ENGAGEMENTPROGRAM);
                        }}>Programs</Button>
                </CanAccess>
                <CanAccess key={RESOURCE_PATH.ASSESSMENT} resource={RESOURCE_PATH.ASSESSMENT.toLowerCase()} action="show"
                    params={{ dataProviderName: DATAPROVIDER_READ, id: "1", engagementId: engagementId, ignoreid: true }}>
                    <Button size="middle" icon={<Icons.ContainerOutlined />}
                        onClick={() => {
                            navigateWithEngagementId(RESOURCE_PATH.ASSESSMENT);
                        }}>Assessments</Button>
                </CanAccess>
            </Space >
    );
};