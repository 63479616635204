import { Divider, Form, Input, InputNumber, Radio, Table, Typography } from "@pankod/refine-antd";
import { useOne } from "@pankod/refine-core";
import { RenderParagraphWithImage } from "components/common/renderParagraphWithImage";
import { IRecordMetricValue, image } from "interfaces";
import { IEnggSolutionMetadata } from "interfaces/article";
import { DATAPROVIDER_LOOKUP, RESOURCE_PATH, STALE_DURATION } from "scripts/site";

export const RenderFields: React.FC<{
  recordKeyResults: IRecordMetricValue[]
  onUploadChange: (info: any) => void;
  images?: image[];
}> = ({ recordKeyResults, onUploadChange, images }) => {

  const { data: metadata } = useOne<IEnggSolutionMetadata>({
    dataProviderName: DATAPROVIDER_LOOKUP,
    resource: RESOURCE_PATH.METADATA,
    id: RESOURCE_PATH.ENGAGEMENTSOLUTION,
    queryOptions: {
      enabled: true,
      staleTime: STALE_DURATION
    }
  });

  const metaConfig = metadata?.data.config;
  return (<>
    <Form.Item
      label="Title"
      name="name"
      key={`name`}
      rules={[
        {
          required: true,
          whitespace: true,
          min: 5,
          max: 100
        },
      ]}
    >
      <Input placeholder="Title for the scenario" />
    </Form.Item>

    <Form.Item
      name={["impact"]}
      label="Impact"
      key={`impact`}
      rules={[
        {
          required: true,
          whitespace: true,
          message: "Please select an impact for the scenario."
        },
      ]}
    >
      <Radio.Group
        optionType="button"
        buttonStyle="solid"
        options={(metaConfig?.impact ?? []).map(a => a)}
      />
    </Form.Item>

    <Form.Item
      name={["phase"]}
      label="Phase"
      key={`phase`}
      rules={[
        {
          required: true,
          whitespace: true,
          message: "Please select a phase for the scenario."
        },
      ]}
    >
      <Radio.Group
        optionType="button"
        buttonStyle="solid"
        options={(metaConfig?.phase ?? []).map(a => a)}
      />
    </Form.Item>

    <Form.Item
      name={["status"]}
      label="Status"
      key={`status`}
      rules={[
        {
          required: true,
          whitespace: true,
          message: "Please select a status for the scenario."
        },
      ]}
    >
      <Radio.Group
        optionType="button"
        buttonStyle="solid"
        options={(metaConfig?.status ?? []).map(a => a)}
      />
    </Form.Item>

    <Form.Item
      name={["pocDeveloped"]}
      label="PoC Developed?"
      key={`pocDeveloped`}
      rules={[
        {
          required: true,
          whitespace: true,
          type: "boolean"
        },
      ]}
    >
      <Radio.Group
        optionType="button"
        buttonStyle="solid"
        options={[
          {
            label: "Yes",
            value: true,
          },
          {
            label: "No",
            value: false,
          }
        ]}
      />
    </Form.Item>

    <Form.Item
      label="Client Contact"
      name="clientContact"
      key={`clientContact`}
      rules={[
        {
          required: false,
          whitespace: true,
          min: 5,
          max: 100
        },
      ]}
    >
      <Input placeholder="Customer point of contact" />
    </Form.Item>

    <Form.List name={["keyResults"]}>
      {() => {
        return (
          <Table<IRecordMetricValue> style={{ marginTop: 20, marginBottom: 20 }}
            dataSource={recordKeyResults} size="small"
            bordered={true}
            key={"krTable"} rowKey={"metricValue.id"}
            pagination={false}>
            <Table.Column
              dataIndex={["metric", "name"]}
              key="metric.name"
              title="Key Result"
              render={(value, row, index) =>
                <>
                  <span style={{ color: "#dc4446" }}>* </span>
                  <Typography.Text>{value}</Typography.Text>
                </>
              }
            />
            <Table.Column
              dataIndex={["metric", "description"]}
              key="metric.description"
              title="Description"
            />
            <Table.Column
              dataIndex={["metric", "target"]}
              key="metric.target"
              title="Target"
            />
            <Table.Column
              dataIndex={["metricValue", "value"]}
              key="metricValue.value"
              title="Accomplished"
              render={(value, row, index) =>
                <>
                  <Form.Item
                    name={[index, "id"]}
                    key={`kr-${index}-id`}
                    hidden
                    noStyle
                  >
                    <Input />
                  </Form.Item>
                  <Form.Item
                    name={[index, "value"]}
                    key={`kr-${index}-value`}
                    style={{ marginBottom: 0 }}
                    rules={[
                      {
                        required: true,
                        whitespace: true,
                        type: "number",
                        min: -100,
                        max: 200,
                        message: <>Please provide value accomplished.<br />Enter zero if not accomplished.</>
                      },
                    ]}
                  >
                    <InputNumber min={-100} max={200} step={1} precision={0} addonAfter="%"
                      style={{ width: "100%" }}
                    />
                  </Form.Item>
                </>
              }
            />
            <Table.Column
              dataIndex={["metricValue", "comments"]}
              key="metricValue.comments"
              title="Comments"
              render={(value, row, index) =>
                <Form.Item
                  style={{ marginBottom: 0 }}
                  key={`kr-${index}-comments`}
                  name={[index, "comments"]} >
                  <Input />
                </Form.Item>
              }
            />
          </Table>);
      }}
    </Form.List>
    <Divider orientation="left">Context</Divider>
    <RenderParagraphWithImage
      resourcePath={RESOURCE_PATH.ENGAGEMENTSOLUTION} onUploadChange={onUploadChange}
      images={images ?? []} paragraphFieldName={"context"} imagesFieldName={"images"}
      featuredImage={false} />
  </>
  );
};