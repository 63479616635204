import { Alert, Button, CreateButton, Drawer, EditButton, Icons, List, Table, Tag, Typography, useTable } from "@pankod/refine-antd";
import { CanAccess, CrudFilters, HttpError, IResourceComponentsProps, useGetIdentity, useOne } from "@pankod/refine-core";
import type { ColumnsType } from 'antd/es/table';
import { ReservationFilter } from "components/spaces/reservationFilter";
import { GetListTableColumns, mergePreferences } from "components/utils/getListTableColumns";
import { ListColumnPicker } from "components/utils/listColumnPicker";
import dayjs from "dayjs";
import { IEntityListColumns, IReservation, IReservationFilterVariables, IReservationMetadata, IUser } from "interfaces";
import { useEffect, useState } from "react";
import { DATAPROVIDER_LOOKUP, DATAPROVIDER_READ, RESOURCE_PATH, STALE_DURATION } from "scripts/site";

import { default as timezone, default as utc } from "dayjs";
dayjs.extend(utc)
dayjs.extend(timezone)

export const ReservationList: React.FC<IResourceComponentsProps> = () => {

  const { data: userData, isLoading: userLoading } = useGetIdentity();
  const userObj = userData as IUser;

  const { tableProps, sorter, tableQueryResult, searchFormProps } = useTable<IReservation, HttpError, IReservationFilterVariables>({
    dataProviderName: DATAPROVIDER_READ,
    resource: RESOURCE_PATH.RESERVATION,
    permanentFilter: [{
      field: "_arrayfields",
      operator: "eq",
      value: "attendees"
    }],
    initialSorter: [
      {
        field: "updatedAt",
        order: "desc"
      },
    ],
    onSearch: (params) => {
      const filters: CrudFilters = [];
      const { q, zoneId, buildingId, date, roomId, userFilter } = params;
      filters.push({
        field: "q",
        operator: "contains",
        value: q
      });
      filters.push({
        field: "zoneId",
        operator: "eq",
        value: zoneId
      });
      filters.push({
        field: "buildingId",
        operator: "eq",
        value: buildingId
      });
      filters.push({
        field: "roomId",
        operator: "eq",
        value: roomId
      });
      filters.push({
        field: "date",
        operator: "eq",
        value: date ? dayjs(date).format("YYYY-MM-DD") : null
      });

      filters.push({
        field: "organizer",
        operator: "eq",
        value: userFilter === "me" ? userObj.id : undefined
      });

      filters.push({
        field: "attendees",
        operator: "contains",
        value: userFilter === "attendee" ? userObj.id : undefined
      });

      return filters;
    }
  });

  const { data: entityMetadata } = useOne<IReservationMetadata>({
    dataProviderName: DATAPROVIDER_LOOKUP,
    resource: RESOURCE_PATH.METADATA,
    id: RESOURCE_PATH.RESERVATION,
    queryOptions: {
      enabled: true,
      staleTime: STALE_DURATION
    }
  });
  const metaConfig = entityMetadata?.data?.config;
  const [filterOpen, switchFilterForm] = useState(false);
  const [columnSelectionDrawer, switchColumnSelection] = useState(false);
  const [tableColumns, setTableColumns] = useState<IEntityListColumns>();
  const columnPreference = userObj?.preferences?.entityListView?.find(x => x.id === RESOURCE_PATH.RESERVATION)?.columns;

  useEffect(() => {
    if (metaConfig) {
      setTableColumns(mergePreferences(metaConfig.listColumns, columnPreference, RESOURCE_PATH.RESERVATION));
    }
  }, [metaConfig, columnPreference]);

  const getTableColumns = (): ColumnsType<IReservation> => {
    return GetListTableColumns(tableColumns, sorter, RESOURCE_PATH.RESERVATION);
  }
  const OnColumnSelection = () => {
    switchColumnSelection(false);
  }



  return (
    <List
      headerProps={{
        title: "Reservations",
        tags: <Tag color={"default"}>
          {tableQueryResult.isLoading ? "Loading" : tableQueryResult?.data?.total} Reservation{tableQueryResult?.data?.total !== 1 && "s"}
        </Tag>,
        extra: <>
          <CanAccess key={RESOURCE_PATH.RESERVATION + "-create"} resource={RESOURCE_PATH.RESERVATION.toLowerCase()} action="create">
            <CreateButton></CreateButton>
          </CanAccess>
          <Button key="filter" icon={<Icons.SearchOutlined />} onClick={() => switchFilterForm(true)}>{metaConfig?.search?.textMessages?.buttonText || "Search & Filter"}</Button>
          <Button key="colmnopt" icon={<Icons.ToolOutlined />} onClick={() => switchColumnSelection(true)}>Column Options</Button>
        </>
      }}
    >
      {(!userLoading && ((!userObj?.countryId) || userObj?.countryId === null || userObj?.countryId === "")) &&
        <Alert
          message="Country is not assigned"
          description={<>Please <EditButton style={{ padding: 0 }} type="link" icon={false} resourceNameOrRouteName={RESOURCE_PATH.PROFILE} recordItemId={userObj?.id}>update your profile</EditButton> and select your country.</>}
          type="warning"
          showIcon
          style={{ marginBottom: 20 }}
        />
      }
      <Table {...tableProps}
        rowKey="id"
        pagination={{
          ...tableProps.pagination,
          position: ["bottomRight"],
          showTotal: (total => <Typography.Title level={4} style={{ marginRight: 10 }} >Total {total}</Typography.Title>)
        }}
        columns={getTableColumns()}
      >
      </Table>
      <Drawer
        title={metaConfig?.search?.textMessages?.drawerFormHeader || "Search & Filter"}
        placement="right"
        size="default"
        open={filterOpen}
        onClose={() => { switchFilterForm(false) }}
      >
        <ReservationFilter formProps={searchFormProps} />
      </Drawer>
      <Drawer
        title="Column options"
        placement="right"
        size="default"
        open={columnSelectionDrawer}
        onClose={() => { switchColumnSelection(false) }}
      >
        <ListColumnPicker user={userObj} entityListColumns={tableColumns} onColumnSelection={OnColumnSelection} />
      </Drawer>
    </List>
  );
};

