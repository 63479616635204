import { Drawer, Table, Typography, useTable } from "@pankod/refine-antd";
import { CrudFilter, useGetIdentity, useOne } from "@pankod/refine-core";
import type { ColumnsType } from 'antd/es/table';
import { GetListTableColumns, mergePreferences } from "components/utils/getListTableColumns";
import { IEntityListColumns, IUser } from "interfaces";
import { IPosition, IPositionMetadata } from "interfaces/resourcing";
import { useEffect, useState } from "react";
import { DATAPROVIDER_LOOKUP, DATAPROVIDER_READ, RESOURCE_PATH, STALE_DURATION } from "scripts/site";
import { ManagePosition } from "./managePosition";
import { RenderPositionActions } from "./renderPositionActions";

export declare type PositionFilterProps = {
    requestId: string,
    requestor: string
};

export const PositionList: React.FC<PositionFilterProps> = ({ requestId, requestor }) => {

    const { data: userData } = useGetIdentity();
    const userObj = userData as IUser;
    const [reviewFormOpen, switchReviewForm] = useState(false);
    const [refetchData, switchRefetchFlag] = useState(false);
    const [currentPosition, setCurrentPosition] = useState<IPosition>();
    const [tableColumns, setTableColumns] = useState<IEntityListColumns>();
    const columnPreference = userObj?.preferences?.entityListView?.find(x => x.id === RESOURCE_PATH.POSITION)?.columns;

    const onFormSubmit = () => {
        switchReviewForm(false);
    };

    const refetchSuggestionsData = () => {
        switchRefetchFlag(true);
    }

    const positionFilter: CrudFilter[] = requestId ? [{
        field: "resourcingRequestID",
        operator: "eq",
        value: requestId
    }] : [];

    const { tableProps, sorter, tableQueryResult } = useTable<IPosition>({
        dataProviderName: DATAPROVIDER_READ,
        resource: RESOURCE_PATH.POSITION,
        permanentFilter: positionFilter,
        hasPagination: false
    });

    const { data: metadata } = useOne<IPositionMetadata>({
        dataProviderName: DATAPROVIDER_LOOKUP,
        resource: RESOURCE_PATH.METADATA,
        id: RESOURCE_PATH.POSITION,
        queryOptions: {
            enabled: true,
            staleTime: STALE_DURATION
        }
    });

    const metaConfig = metadata?.data?.config;

    useEffect(() => {
        if (refetchData) {
            tableQueryResult.refetch();
            switchRefetchFlag(false);
        }
    }, [refetchData, tableQueryResult]);

    useEffect(() => {
        if (metaConfig) {
            setTableColumns(mergePreferences(metaConfig.listColumns, columnPreference, RESOURCE_PATH.POSITION));
        }
    }, [metaConfig, columnPreference]);

    const getTableColumns = (): ColumnsType<IPosition> => {
        const columns: ColumnsType<IPosition> = GetListTableColumns(tableColumns, sorter, RESOURCE_PATH.POSITION);
        if (metadata?.data) {
            columns.push(
                {
                    title: "Actions",
                    dataIndex: "actions",
                    render: (_, record) => record && <RenderPositionActions record={record} metadata={metadata.data}
                        onClickManage={(e) => {
                            e?.stopPropagation();
                            setCurrentPosition(record);
                            switchReviewForm(true);
                        }} />,
                }
            );
        }
        return columns
    }

    return (
        <>
            <Table {...tableProps}
                rowKey="id"
                pagination={{
                    ...tableProps.pagination,
                    position: ["bottomRight"],
                    showTotal: (total => <Typography.Title level={4} style={{ marginRight: 10 }} >Total {total}</Typography.Title>)
                }}
                columns={getTableColumns()}
            />
            <Drawer
                title="Manage Position"
                placement="right"
                size="large"
                open={reviewFormOpen}
                onClose={() => { switchReviewForm(false) }}
            >
                {currentPosition && metadata?.data &&
                    <ManagePosition position={currentPosition} positionMetadata={metadata.data} onFormSubmission={onFormSubmit} refetch={refetchSuggestionsData} />
                }
            </Drawer>
        </>
    );
};