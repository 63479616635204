import { AntdLayout, Grid, Space, Spin, Typography } from "@pankod/refine-antd";
import { LayoutProps } from "@pankod/refine-core";
import React from "react";
import { useAuthZ } from "scripts/site";
import { getAppTheme } from "scripts/theme";

export const AppLayout: React.FC<LayoutProps> = ({
    children,
    Header,
    Sider,
    Footer,
    OffLayoutArea,
}) => {
    const breakpoint = Grid.useBreakpoint();
    const { isLoading } = useAuthZ();

    const divStyle = isLoading
        ? {
            padding: breakpoint.sm ? 24 : 12,
            minHeight: 360,
            display: "flex"
        }
        : {
            padding: breakpoint.sm ? 24 : 12,
            minHeight: 360
        };

    return (
        <AntdLayout className={`hlm-${getAppTheme()}`} style={{ minHeight: "100vh", flexDirection: "row" }}>
            {Sider && <Sider />}
            <AntdLayout>
                {Header && <Header />}
                <AntdLayout.Content>
                    <div style={divStyle}>
                        {isLoading
                            ? <div style={{ margin: "auto" }}>
                                <Space direction="vertical" wrap align="center" >
                                    <Spin size="large">
                                    </Spin>
                                    <Typography.Title level={5} style={{ marginTop: 30 }}>
                                        {`${process.env.REACT_APP_ApplicationTitle} is loading...`}
                                    </Typography.Title>
                                </Space>
                            </div>
                            : children}
                    </div>
                    {OffLayoutArea && <OffLayoutArea />}
                </AntdLayout.Content>
                {Footer && <Footer />}
            </AntdLayout>
        </AntdLayout>
    );
};
