import { Typography } from "@pankod/refine-antd";
import { IAsset } from "interfaces";
const { Text } = Typography;

export const ShowAssetConfig: React.FC<IAsset> = (record: IAsset) => {
  const grayStyle = { color: "GrayText" }
  return (
    <>

      {
        record?.processor ?
          <div>
            <Text style={grayStyle}>Processor: </Text>
            <Text>{record?.processor}</Text>
          </div>
          : <></>
      }
      {
        record?.ram ?
          <div>
            <Text style={grayStyle}>RAM: </Text>
            <Text>{record?.ram}</Text>
          </div>
          : <></>
      }
      {
        record?.storage ?
          <div>
            <Text style={grayStyle}>Storage: </Text>
            <Text>{record?.storage}</Text>
          </div>
          : <></>
      }
      {
        record?.resolution ?
          <div>
            <Text style={grayStyle}>Resolution: </Text>
            <Text>{record?.resolution}</Text>
          </div>
          : <></>
      }

    </>
  );
};
