import { arrayExtensions } from "@architecture-innovation-transformation/lib-common";
import { Button, CreateButton, Drawer, EditButton, Icons, List, ShowButton, Space, Table, Typography, getDefaultSortOrder, useTable } from "@pankod/refine-antd";
import { CanAccess, CrudFilters, HttpError, useNavigation, useOne, useResourceWithRoute } from "@pankod/refine-core";
import { EngagementSolutionFilter } from "components/engagementsolution/engagementSolutionFilter";
import { ShowUserCard } from "components/profile/showUserCard";
import { displayRelativeDate } from "components/utils/displayRelativeDate";
import { LookupButton } from "components/utils/lookupButton";
import { IEngagementSolution, IMetricValue, IProgram } from "interfaces";
import { IEnggSolutionFilterVariables, IEnggSolutionMetadata, IScenario } from "interfaces/article";
import { useState } from "react";
import { DATAPROVIDER_CREATE, DATAPROVIDER_LOOKUP, DATAPROVIDER_READ, DATAPROVIDER_UPDATE, RESOURCE_PATH, STALE_DURATION, THINK_GEN_AI_LOGO_BLACK, THINK_GEN_AI_LOGO_WHITE } from "scripts/site";
import { getAppTheme } from "scripts/theme";

export const EnggSolutionList: React.FC<{
  engagementId?: string,
  programId?: string,
  scenarioId?: string,
}> = ({ engagementId, programId, scenarioId }) => {
  const { show } = useNavigation();
  const [filterOpen, switchFilterForm] = useState(false);

  const listFilter: CrudFilters = [];

  if (engagementId) {
    listFilter.push({
      field: "engagementId",
      operator: "eq",
      value: engagementId
    });
  }

  if (programId) {
    listFilter.push({
      field: "programId",
      operator: "eq",
      value: programId
    });
  }
  if (scenarioId) {
    listFilter.push({
      field: "scenarioId",
      operator: "eq",
      value: scenarioId
    });
  }

  const { data: entityMetadata } = useOne<IEnggSolutionMetadata>({
    dataProviderName: DATAPROVIDER_LOOKUP,
    resource: RESOURCE_PATH.METADATA,
    id: RESOURCE_PATH.ENGAGEMENTSOLUTION,
    queryOptions: {
      enabled: true,
      staleTime: STALE_DURATION
    }
  });
  const metaConfig = entityMetadata?.data?.config;

  const { tableProps, sorter, tableQueryResult, searchFormProps, filters } = useTable<IEngagementSolution, HttpError, IEnggSolutionFilterVariables>({
    dataProviderName: DATAPROVIDER_READ,
    resource: RESOURCE_PATH.ENGAGEMENTSOLUTION,
    initialSorter: [
      {
        field: "updatedAt",
        order: "desc"
      },
    ],
    syncWithLocation: false,
    permanentFilter: listFilter,
    onSearch: (params) => {
      const filters: CrudFilters = [];
      const { q, impact, phase, status, engagementId: enggId, programId: progId, scenarioId: scnrId } = params;
      filters.push({
        field: "q",
        operator: "contains",
        value: q
      });
      filters.push({
        field: "impact",
        operator: "eq",
        value: impact
      });
      filters.push({
        field: "phase",
        operator: "eq",
        value: phase
      });
      filters.push({
        field: "status",
        operator: "eq",
        value: status
      });
      if (!engagementId) {
        filters.push({
          field: "engagementId",
          operator: "eq",
          value: enggId
        });
      }
      if (!programId) {
        filters.push({
          field: "programId",
          operator: "eq",
          value: progId
        });
      }
      if (!scenarioId) {
        filters.push({
          field: "scenarioId",
          operator: "eq",
          value: scnrId
        });
      }
      return filters;
    }
  });

  function rowClick(record: IScenario) {
    return {
      onClick: () => {
        show(RESOURCE_PATH.ENGAGEMENTSOLUTION, record.id);
      },
    };
  }
  const totalRequests = tableQueryResult?.data?.total ?? 0;
  let isFiltered = filters && filters.length > listFilter.length && filters.some(fltr => fltr.value !== undefined)

  const clearFilters = () => {
    searchFormProps.form?.resetFields();
    searchFormProps.form?.submit();
  }

  let pageTitle = "";
  if (scenarioId) {
    pageTitle = (tableQueryResult.isLoading ? "Loading" : totalRequests) + " Implementation" + (totalRequests !== 1 ? "s" : "");
  }
  else {
    pageTitle = (tableQueryResult.isLoading ? "Loading" : totalRequests) + " Scenario" + (totalRequests !== 1 ? "s" : "");
  }

  const { push } = useNavigation();
  const resourceWithRoute = useResourceWithRoute();
  function navigateWithIds() {
    push(`/${resourceWithRoute(RESOURCE_PATH.ENGAGEMENTSOLUTION).route}/create?engagementId=${engagementId}&programId=${programId}`);
  }

  const { data: programRecord } = useOne<IProgram>({
    dataProviderName: DATAPROVIDER_READ,
    resource: RESOURCE_PATH.PROGRAM,
    id: programId ?? "",
    queryOptions: {
      enabled: !!programId
    },
  });

  return (<>
    <List
      breadcrumb={false}
      headerProps={
        {
          title: pageTitle,
          tags: <>
            <Button key="filter" type="default"
              icon={isFiltered ? <Icons.FilterFilled /> : <Icons.FilterOutlined />}
              style={{ paddingRight: (isFiltered ? 4 : 15) }}
              title={isFiltered ? "You are viewing a filtered list" : (entityMetadata?.data?.config?.search?.textMessages?.buttonText || "Search & Filter")}
              onClick={() => switchFilterForm(true)}>
              Search
              {isFiltered &&
                <>
                  <Button
                    type="text"
                    danger
                    title={"Clear Filters"}
                    size="small"
                    style={{ marginLeft: 5 }}
                    onClick={(e) => {
                      clearFilters();
                      e.stopPropagation();
                    }}
                    icon={<Icons.CloseOutlined />}
                  >
                  </Button>
                </>}
            </Button>
          </>,
          extra: <>
            {programId && engagementId && <>
              {/* Need to pass the engagementId as a query parameter to build a dummy object for RBAC check */}
              <CanAccess key={RESOURCE_PATH.ENGAGEMENTSOLUTION} resource={RESOURCE_PATH.ENGAGEMENTSOLUTION.toLowerCase()} action="create"
                params={{ dataProviderName: DATAPROVIDER_CREATE, id: "1", engagementId: engagementId }}>
                <CreateButton onClick={navigateWithIds} resourceNameOrRouteName={RESOURCE_PATH.ENGAGEMENTSOLUTION} icon={<Icons.PlusOutlined />}>Add Scenario</CreateButton>
              </CanAccess>
            </>}
          </>
        }}
    >
      <Table {...tableProps}
        rowKey="id"
        pagination={{
          ...tableProps.pagination,
          position: ["bottomRight"],
          showTotal: (total => <Typography.Title level={4} style={{ marginRight: 10 }}>Total {total}</Typography.Title>)
        }}
      >
        {!programId &&
          <>
            <Table.Column
              dataIndex="id"
              key="id"
              title=""
              width={100}
              render={() =>
                <img src={getAppTheme() === "dark" ? THINK_GEN_AI_LOGO_WHITE : THINK_GEN_AI_LOGO_BLACK} alt="Think GenAI"
                  style={{
                    height: 20,
                    width: 100,
                  }}></img>}
              onCell={rowClick}
              className="mouseHand" />
            <Table.Column
              dataIndex="programId"
              key="programId"
              title="Program"
              onCell={rowClick}
              className="mouseHand"
              render={(value: string) => <>
                {value &&
                  <LookupButton noTooltip id={value} resource={RESOURCE_PATH.PROGRAM} />}
              </>} />
          </>}
        {!engagementId &&
          <Table.Column
            dataIndex="engagementId"
            key="engagementId"
            title="Engagement"
            onCell={rowClick}
            className="mouseHand"
            render={(value: string) => <>
              {value &&
                <LookupButton noTooltip id={value} resource={RESOURCE_PATH.ENGAGEMENT} />}
            </>} />
        }

        <Table.Column
          dataIndex="name"
          key="name"
          title="Title"
          defaultSortOrder={getDefaultSortOrder("name", sorter)}
          sorter
          onCell={rowClick}
          className="mouseHand"
        />
        {!scenarioId &&
          <Table.Column
            dataIndex="scenarioId"
            key="scenarioId"
            title="Scenario"
            onCell={rowClick}
            className="mouseHand"
            render={(value: string) => <>
              {value && <LookupButton noTooltip id={value} resource={RESOURCE_PATH.SCENARIO} />}
            </>
            }
          />
        }
        <Table.Column
          dataIndex="impact"
          key="impact"
          title="Impact"
          onCell={rowClick}
          className="mouseHand"
        />
        <Table.Column
          dataIndex="phase"
          key="phase"
          title="Phase"
          onCell={rowClick}
          className="mouseHand"
        />
        <Table.Column
          dataIndex="status"
          key="status"
          title="Status"
          onCell={rowClick}
          className="mouseHand"
        />

        {programId && programRecord && arrayExtensions.validateArray(programRecord?.data?.keyResults) &&
          programRecord?.data?.keyResults.map((kr, i) => {
            return <Table.Column
              dataIndex={["keyResults"]}
              key={kr.id}
              title={kr.name}
              onCell={rowClick}
              className="mouseHand"
              render={(value: IMetricValue[]) => {
                const valueIndex = value.findIndex((v: IMetricValue) => v.id === kr.id);
                return valueIndex >= 0 ? (value[valueIndex].value + "%") : "";
              }}
            />
          })
        }

        <Table.Column
          dataIndex="pocDeveloped"
          key="pocDeveloped"
          title="PoC Developed?"
          render={(value: boolean) => <>
            {value === true ? "Yes" : "No"}
          </>}
          onCell={rowClick}
          className="mouseHand" />

        <Table.Column
          dataIndex="updatedBy"
          key="updatedBy"
          title="Updated by"
          render={(value: string) => <>
            {value &&
              <ShowUserCard id={value} />}
          </>}
          onCell={rowClick}
          className="mouseHand" />

        <Table.Column
          dataIndex="updatedAt"
          key="updatedAt"
          title="Last Updated"
          render={displayRelativeDate}
          defaultSortOrder={getDefaultSortOrder("updatedAt", sorter)}
          sorter
          onCell={rowClick}
          className="mouseHand" />

        <Table.Column<IEngagementSolution>
          title="Actions"
          dataIndex="actions"
          render={(_, record) => (
            <Space>
              {/* Hidden button to let AuthZ module check access for it */}
              <ShowButton resourceNameOrRouteName={RESOURCE_PATH.ENGAGEMENTSOLUTION} hidden size="small" recordItemId={record.id} />
              <CanAccess key={`edit`} resource={RESOURCE_PATH.ENGAGEMENTSOLUTION.toLowerCase()} action="edit" params={{ dataProviderName: DATAPROVIDER_UPDATE, id: record.id }}>
                <EditButton resourceNameOrRouteName={RESOURCE_PATH.ENGAGEMENTSOLUTION} size="small" recordItemId={record.id} />
              </CanAccess>
              {/* <DeleteButton resourceNameOrRouteName={RESOURCE_PATH.ENGAGEMENTSOLUTION} dataProviderName={DATAPROVIDER_DELETE} size="small" recordItemId={record.id} /> */}
            </Space>
          )}
        />
      </Table>
      <Drawer
        title={metaConfig?.search?.textMessages?.drawerFormHeader || "Search & Filter"}
        placement="right"
        size="default"
        open={filterOpen}
        onClose={() => { switchFilterForm(false); }}
      >
        <EngagementSolutionFilter formProps={searchFormProps}
          onApplyFilter={() => switchFilterForm(false)} />
      </Drawer>
    </List>
  </>
  );
};