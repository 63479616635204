import { arrayExtensions, stringExtensions } from "@architecture-innovation-transformation/lib-common";
import { DateField, DeleteButton, EditButton, NumberField, Space, TextField } from "@pankod/refine-antd";
import { CanAccess } from "@pankod/refine-core";
import { ShowUserCard } from "components/profile/showUserCard";
import { TableValueType } from "interfaces/enum";
import { IPositionStats } from "interfaces/resourcing";
import { DATAPROVIDER_DELETE, DATAPROVIDER_UPDATE, RESOURCE_PATH } from "scripts/site";
import { displayRelativeDate } from "./displayRelativeDate";
import { LookupButton } from "./lookupButton";

export function DisplayColumnValue(value: any, valueType: TableValueType, entity: string, customRenderer: string | undefined, record: any, lookupEntityName?: string) {
    let renderedValue = null;
    // For all regular renderers
    if (value && valueType !== TableValueType.Custom && valueType !== TableValueType.Boolean) {
        switch (valueType) {
            case TableValueType.Text:
                renderedValue = <TextField value={value} />;
                break;

            case TableValueType.CapitalizeText:
                renderedValue = <TextField value={stringExtensions.capitalize(value)} />;
                break;

            case TableValueType.UserCard:
                renderedValue = <ShowUserCard id={value} />;
                break;

            case TableValueType.UserCardArray:
                renderedValue = arrayExtensions.validateArray(value) && value.map((r: string, i: number) => (
                    <div key={i} style={{ marginBottom: 5 }}>
                        <ShowUserCard id={r} />
                    </div>
                ))
                break;

            case TableValueType.RelativeDate:
                renderedValue = displayRelativeDate(value);
                break;

            case TableValueType.Date:
                renderedValue = <DateField format="ll" value={value} />;
                break;

            case TableValueType.Time:
                renderedValue = <DateField format="LT" value={value} />;
                break;

            case TableValueType.TimeText:
                renderedValue = <TextField value={value && value.substring(11, 16)}></TextField>;
                break;

            case TableValueType.Array:
                renderedValue = <TextField value={value.join(', ')} />;
                break;

            case TableValueType.Number:
                renderedValue = <NumberField value={value} />;
                break;

            case TableValueType.EntityLookup:
                renderedValue = <LookupButton id={value || ""} resource={lookupEntityName ?? ""} noClick></LookupButton>;
                break;

            case TableValueType.ActionButton:
                renderedValue = <>
                    <Space>
                        <CanAccess key={entity + "-edit"} resource={entity.toLowerCase()} action="edit" params={{ id: record.id, dataProviderName: DATAPROVIDER_UPDATE }}>
                            <EditButton resource={entity} size="small" recordItemId={record.id} ></EditButton>
                        </CanAccess>
                        <CanAccess key={entity + "-delete"} resource={entity.toLowerCase()} action="delete" params={{ id: record.id, dataProviderName: DATAPROVIDER_DELETE }}>
                            <DeleteButton resource={entity} size="small" recordItemId={record.id} dataProviderName={DATAPROVIDER_DELETE} ></DeleteButton>
                        </CanAccess>
                    </Space>
                </>;
                break;

            default:
                break;
        }
    }
    // moving boolen to handle false value
    else if (valueType === TableValueType.Boolean) {
        renderedValue = <TextField value={value === true ? "Yes" : "No"} />;
    }
    else {
        // Custom renderers for Specific Entity
        switch (entity) {
            case RESOURCE_PATH.RESOURCINGREQUEST:
                if (customRenderer && customRenderer === "renderPositionColumn") {
                    let positions = value as IPositionStats[];
                    let textValue = "No positions";
                    if (arrayExtensions.validateArray(positions)) {
                        textValue = `${positions.length || 0} | ${positions.filter(pos => pos.currentState === "mapped").length || 0} | ${positions.filter(pos => pos.currentState === "closed").length || 0}`;
                    }
                    renderedValue = <TextField value={textValue} />;
                }
                break;

            default:
                break;
        }
    }
    return renderedValue;
}