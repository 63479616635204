import { ILookup } from "@architecture-innovation-transformation/lib-common";
import { Button, DatePicker, Form, FormProps, Icons, Input, Select, useSelect } from "@pankod/refine-antd";
import { useOne } from "@pankod/refine-core";
import { RenderSpinner } from "components/common";
import { IReservationMetadata } from "interfaces";
import { DATAPROVIDER_LOOKUP, RESOURCE_PATH, STALE_DURATION } from "scripts/site";

export const ReservationFilter: React.FC<{ formProps: FormProps }> = (props) => {
    const { isLoading, data: metadata } = useOne<IReservationMetadata>({
        dataProviderName: DATAPROVIDER_LOOKUP,
        resource: RESOURCE_PATH.METADATA,
        id: RESOURCE_PATH.RESERVATION,
        queryOptions: {
            enabled: true,
            staleTime: STALE_DURATION
        }
    });

    const { selectProps: buildingProps } = useSelect<ILookup>({
        dataProviderName: DATAPROVIDER_LOOKUP,
        resource: RESOURCE_PATH.BUILDING,
        optionLabel: "name",
        optionValue: "id",
        sort: [
            {
                field: "name",
                order: "asc"
            },
        ],
        fetchSize: 100
    });
    const { selectProps: zoneProps } = useSelect<ILookup>({
        dataProviderName: DATAPROVIDER_LOOKUP,
        resource: RESOURCE_PATH.ZONE,
        optionLabel: "name",
        optionValue: "id",
        sort: [
            {
                field: "name",
                order: "asc"
            },
        ],
        fetchSize: 100
    });

    const { selectProps: roomProps } = useSelect<ILookup>({
        dataProviderName: DATAPROVIDER_LOOKUP,
        resource: RESOURCE_PATH.ROOM,
        optionLabel: "name",
        optionValue: "id",
        sort: [
            {
                field: "name",
                order: "asc"
            },
        ],
        fetchSize: 100
    });

    const metaConfig = metadata?.data?.config;

    return (
        isLoading ? <RenderSpinner message="Loading..." ></RenderSpinner> :
            <Form layout="vertical" {...props.formProps} autoComplete="off">
                <Form.Item
                    label={"Search"}
                    name="q"
                    tooltip={metaConfig?.search?.textMessages?.searchTextPlaceholder || "Search Reservations"}
                >
                    <Input
                        placeholder={metaConfig?.search?.textMessages?.searchTextPlaceholder || "Search Reservations"}
                        prefix={<Icons.SearchOutlined />}
                    />
                </Form.Item>

                <Form.Item
                    label="Building"
                    name="buildingId"
                >
                    <Select
                        allowClear
                        mode="multiple"
                        placeholder="Building"
                        {...buildingProps}
                    >
                    </Select>
                </Form.Item>

                <Form.Item
                    label="Zone"
                    name="zoneId"
                >
                    <Select
                        allowClear
                        mode="multiple"
                        placeholder="Zone"
                        {...zoneProps}
                    >
                    </Select>
                </Form.Item>

                <Form.Item
                    label="Room"
                    name="roomId"
                >
                    <Select
                        allowClear
                        mode="multiple"
                        placeholder="Room"
                        {...roomProps}
                    >
                    </Select>
                </Form.Item>

                {/* <Form.Item
                    label="Date"
                    name="date"
                    hidden
                /> */}
                <Form.Item label="Date" name="date">
                    <DatePicker style={{ width: '100%' }} />
                </Form.Item>

                <Form.Item label="Filter by" name="userFilter">
                    {/* <Radio.Group>
                        <Radio value={"me"} key="me">Me as organizer</Radio>
                        <Radio value={"attendee"} key="attendee">Attendee contains me</Radio>
                    </Radio.Group> */}

                    <Select allowClear>
                        <Select.Option value={"me"} key="me">Me as organizer</Select.Option>
                        <Select.Option value={"attendee"} key="attendee">Attendee contains me</Select.Option>
                    </Select>
                </Form.Item>

                <Form.Item>
                    <Button
                        style={{ width: "100%" }}
                        htmlType="submit"
                        type="primary"
                    >
                        Apply Filter(s)
                    </Button>
                </Form.Item>
                <Form.Item>
                    <Button
                        style={{ width: "100%" }}
                        htmlType="submit"
                        onClick={() => { props.formProps.form?.resetFields() }}
                    >
                        Clear Filter(s)
                    </Button>
                </Form.Item>
            </Form>
    );
}