import { Button, CreateButton, Drawer, Icons, List, Table, Typography, useTable } from "@pankod/refine-antd";
import { CanAccess, CrudFilters, CrudSorting, HttpError, IResourceComponentsProps, useGetIdentity, useNavigation, useOne, useUpdate } from "@pankod/refine-core";
import type { ColumnsType } from 'antd/es/table';
import { RenderMDFile } from "components/common";
import { RenderWorkflowModal } from "components/common/workflowModal";
import { AssignCandidates } from 'components/resourcing/assignCandidates';
import { CandidateFilter } from "components/resourcing/candidateFilter";
import { mapFiltersToFormFields } from 'components/resourcing/mapFilters';
import { generateCrudFilters, generatePageSize, generateSortOrder } from 'components/utils/generateCrudFilters';
import { GetListTableColumns, mergePreferences, preferencesData } from 'components/utils/getListTableColumns';
import { ListColumnPicker } from 'components/utils/listColumnPicker';
import { IBaseProps, IEntityListColumns, IState, IUser, IUserPreferences } from "interfaces";
import { ICandidate, ICandidateFilterVariables, ICandidateMetadata } from "interfaces/resourcing";
import { useEffect, useState } from "react";
import { DATAPROVIDER_CREATE, DATAPROVIDER_LOOKUP, DATAPROVIDER_READ, DATAPROVIDER_UPDATE, MY_PROFILE, RESOURCE_PATH, STALE_DURATION, removeLocalSessionStorage } from "scripts/site";

export const CandidateList: React.FC<IResourceComponentsProps> = () => {
    const { data: userData } = useGetIdentity();
    const userObj = userData as IUser;
    const { show } = useNavigation();

    // Search and Filters
    const initialFilter: CrudFilters = [
        {
            field: "stateManager.state",
            operator: "ne",
            value: "archived",
        }
    ];
    let savedFilters = userObj?.preferences?.entityListView?.find(x => x.id === RESOURCE_PATH.CANDIDATE)?.filters || initialFilter;
    let savedSortOrder = userObj?.preferences?.entityListView?.find(x => x.id === RESOURCE_PATH.CANDIDATE)?.sortOrder;
    let savedPageSize = userObj?.preferences?.entityListView?.find(x => x.id === RESOURCE_PATH.CANDIDATE)?.pageSize || 10;
    let initialSortOrder: CrudSorting = (savedSortOrder && savedSortOrder.length > 0) ? savedSortOrder : [{
        field: "updatedAt",
        order: "desc"
    }];


    const { tableProps, tableQueryResult: candidateResult, sorter, searchFormProps, setSorter, setPageSize } = useTable<ICandidate, HttpError, ICandidateFilterVariables>({
        dataProviderName: DATAPROVIDER_READ,
        resource: RESOURCE_PATH.CANDIDATE,
        defaultSetFilterBehavior: "replace",
        initialSorter: initialSortOrder,
        initialFilter: savedFilters,
        initialPageSize: savedPageSize,
        onSearch: (params) => {
            const filters: CrudFilters = generateCrudFilters(params, "render");
            setSorter(generateSortOrder(params, initialSortOrder));
            setPageSize(generatePageSize(params));
            return filters;
        },
        queryOptions: {
            enabled: (savedFilters !== undefined)
        }
    });

    const { data: entityMetadata } = useOne<ICandidateMetadata>({
        dataProviderName: DATAPROVIDER_LOOKUP,
        resource: RESOURCE_PATH.METADATA,
        id: RESOURCE_PATH.CANDIDATE,
        queryOptions: {
            enabled: true,
            staleTime: STALE_DURATION
        }
    });
    const metaConfig = entityMetadata?.data?.config;
    const entityStates = entityMetadata?.data?.states as IState[] ?? [];
    const columnPreference = userObj?.preferences?.entityListView?.find(x => x.id === RESOURCE_PATH.CANDIDATE)?.columns;
    const helpFilePath = entityMetadata?.data?.fields?.find(x => x.name === "help")?.attachment || "";

    function rowClick(record: ICandidate) {
        return {
            onClick: () => {
                show(RESOURCE_PATH.CANDIDATE, record.id);
            },
        };
    }

    const [helpOpen, switchHelpContent] = useState(false);
    const [filterOpen, switchFilterForm] = useState(false);
    const [columnSelectionDrawer, switchColumnSelection] = useState(false);
    const [candidateAssignmentDrawer, switchCandidateAssignmentDrawer] = useState(false);
    const [tableColumns, setTableColumns] = useState<IEntityListColumns>();
    const [selectedRowKeys, setSelectedRowKeys] = useState<React.Key[]>([]);
    const [refetchData, switchRefetchFlag] = useState(false);
    const refetchCandidateList = () => {
        switchRefetchFlag(true);
    }

    useEffect(() => {
        if (refetchData) {
            candidateResult.refetch();
            setSelectedRowKeys([]);
            switchRefetchFlag(false);
        }
    }, [refetchData, candidateResult]);

    const getTableColumns = (): ColumnsType<ICandidate> => {
        const columns: ColumnsType<ICandidate> = GetListTableColumns(tableColumns, sorter, RESOURCE_PATH.CANDIDATE);
        return columns
    }

    useEffect(() => {
        if (metaConfig) {
            setTableColumns(mergePreferences(metaConfig.listColumns, columnPreference, RESOURCE_PATH.CANDIDATE));
        }
    }, [metaConfig, columnPreference]);

    const OnColumnSelection = () => {
        switchColumnSelection(false);
    }


    const clearFilters = () => {
        //Switch to System Default 
        saveToProfile(preferencesData(userObj.preferences, RESOURCE_PATH.CANDIDATE, undefined, [], [], 10), true);
    }

    const { mutate } = useUpdate<IBaseProps>();
    const saveToProfile = (userPreferences: IUserPreferences, clear: boolean = false) => {
        // Save the preference to profile
        mutate({
            dataProviderName: DATAPROVIDER_UPDATE,
            resource: RESOURCE_PATH.PROFILE,
            id: userObj.id,
            values: { preferences: userPreferences, _skipTimeStamp: true },
            successNotification: false,
            errorNotification: false
        },
            {
                onSuccess: () => {
                    //If clear, reset the form to facttory default
                    if (clear) {
                        searchFormProps.form?.setFieldsValue({});
                        searchFormProps.form?.submit();
                    }

                    // Remove the user Profile local data to ensure latest preferences are loaded
                    removeLocalSessionStorage(MY_PROFILE);
                },
                onError: () => {
                }
            }
        );
    }
    let isFiltered = savedFilters && savedFilters.length > 0 && savedFilters.some(fltr => fltr.value !== undefined)

    return (
        <List
            headerProps={{
                title: (candidateResult.isLoading ? "Loading" : candidateResult?.data?.total) + " Candidate" + (candidateResult?.data?.total !== 1 ? "s" : ""),
                tags: <>
                    <Button key="filter" type="default"
                        icon={isFiltered ? <Icons.FilterFilled /> : <Icons.FilterOutlined />}
                        style={{ paddingRight: (isFiltered ? 4 : 15) }}
                        title={isFiltered ? "You are viewing a filtered list" : (entityMetadata?.data?.config?.search?.textMessages?.buttonText || "Search & Filter")}
                        onClick={() => switchFilterForm(true)}>
                        Search
                        {isFiltered &&
                            <>
                                <Button
                                    type="text"
                                    danger
                                    title={"Clear Filters"}
                                    size="small"
                                    style={{ marginLeft: 5 }}
                                    onClick={(e) => {
                                        clearFilters();
                                        e.stopPropagation();
                                    }}
                                    icon={<Icons.CloseOutlined />}
                                >
                                </Button>
                            </>
                        }
                    </Button>
                </>
            }}
            headerButtons={
                <>
                    <CanAccess key={`cand-asgn`} resource={RESOURCE_PATH.CANDIDATE} action="create" params={{ id: "1", dataProviderName: DATAPROVIDER_CREATE }}>
                        <Button
                            key="nvgn-asgn-btn"
                            disabled={!(selectedRowKeys.length > 0)}
                            className="nvgn-btn"
                            icon={<Icons.LinkOutlined />}
                            title="Associate Candidates with Requests"
                            onClick={() => { switchCandidateAssignmentDrawer(true) }}
                        >
                            Assign
                        </Button>
                        <CreateButton icon={<Icons.PlusOutlined />}>Add</CreateButton>
                    </CanAccess>
                    <Button key="colmnopt" title="Column Options" icon={<Icons.ToolOutlined />} onClick={() => switchColumnSelection(true)}></Button>
                    {helpFilePath &&
                        <Button key="help"
                            title="Help & FAQs"
                            icon={<Icons.ReadOutlined />} onClick={() => switchHelpContent(true)}></Button>
                    }
                    {entityStates.length > 0 ? RenderWorkflowModal(entityStates) : <></>}
                </>
            }
        >
            <Table {...tableProps}
                rowKey="id"
                pagination={{
                    ...tableProps.pagination,
                    position: ["bottomRight"],
                    showTotal: (total => <Typography.Title level={4} style={{ marginRight: 10 }} >Total {total}</Typography.Title>)
                }}
                onRow={rowClick}
                columns={getTableColumns()}
                rowSelection={{
                    selectedRowKeys,
                    getCheckboxProps: (record) => ({
                        disabled: record.stateManager.state !== "available"
                    }),
                    onChange: (selectedRowKeys: React.Key[]) => {
                        setSelectedRowKeys(selectedRowKeys);
                    },
                    selections: [
                        Table.SELECTION_NONE
                    ]
                }}
            />
            <Drawer
                title={metaConfig?.search?.textMessages?.drawerFormHeader || "Candidates - Search & Filter"}
                placement="right"
                size="large"
                open={filterOpen}
                onClose={() => { switchFilterForm(false) }}
                forceRender={true}
            >
                {metaConfig &&
                    <CandidateFilter
                        formProps={searchFormProps}
                        user={userObj}
                        onApplyFilter={() => switchFilterForm(false)}
                        defaultFilters={mapFiltersToFormFields(savedFilters, initialSortOrder, savedPageSize)}
                        listColumns={metaConfig.listColumns}
                    />
                }
            </Drawer>
            {(helpFilePath) &&
                <Drawer
                    title="FAQs"
                    placement="right"
                    size="large"
                    open={helpOpen}
                    onClose={() => { switchHelpContent(false) }}
                >
                    <RenderMDFile mdFilePath={helpFilePath} resourceName={RESOURCE_PATH.RESOURCINGREQUEST} />
                </Drawer>
            }
            <Drawer
                title="Column options"
                placement="right"
                size="default"
                open={columnSelectionDrawer}
                onClose={() => { switchColumnSelection(false) }}
            >
                <ListColumnPicker user={userObj} entityListColumns={tableColumns} onColumnSelection={OnColumnSelection} />
            </Drawer>
            <Drawer
                title="Assign Candidate"
                placement="right"
                size="large"
                open={candidateAssignmentDrawer}
                onClose={() => { switchCandidateAssignmentDrawer(false) }}
            >
                {metaConfig &&
                    <AssignCandidates candidateIds={selectedRowKeys.join(",")} resourcingSPOCGroupName={metaConfig.deliveryTeamResourcingSPOCGroup} onFormSubmission={() => { switchCandidateAssignmentDrawer(false) }} refetch={refetchCandidateList} />
                }
            </Drawer>
        </List>
    );
};
