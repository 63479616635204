import { CrudFilters, CrudSorting, LogicalFilter } from "@pankod/refine-core";

export function mapFiltersToFormFields(filters: CrudFilters, sorting: CrudSorting, pageSize: number = 10): any {
    let formFields: any = {};
    filters.forEach((element) => {
        let elem = element as LogicalFilter;
        if (elem.value) {
            if (elem.field === "stateManager.state") {
                formFields["state"] = elem.value;
            } else {
                formFields[elem.field] = elem.value;
            }
        }
    })
    formFields.pageSize = pageSize;
    formFields.sortOrder = sorting;
    return formFields;
}