import { stringExtensions } from "@architecture-innovation-transformation/lib-common";
import { Divider, EditButton, Icons, Show, Tabs, Tag } from "@pankod/refine-antd";
import { IResourceComponentsProps, useShow } from "@pankod/refine-core";
import { HistoryTab } from "components/common";
import { DisplayBasicProfile } from "components/profile/displayBasicProfile";
import { DisplayDetailedProfile } from "components/profile/displayDetailedProfile";
import { DisplayMoreTab } from "components/profile/displayMoreTab";
import { DisplayRnRTab } from "components/profile/displayRnRTab";
import { DisplaySystemMembers } from "components/profile/displaySystemMembers";
import { ObjectMetadata } from "components/utils/objectMetadata";
import { IBaseProps, IUser } from "interfaces";
import { DATAPROVIDER_READ, appendToAppTitle } from "scripts/site";

export const UserShow: React.FC<IResourceComponentsProps> = () => {
  const { queryResult } = useShow<IUser>({
    dataProviderName: DATAPROVIDER_READ
  });
  const { data, isLoading } = queryResult;
  const record = data?.data as IUser;

  const getTabs = () => {
    let tabs: any[] = [];

    // Render tabs, if record exists
    if (record) {
      // Add the Info Tab
      tabs.push(
        {
          label: (<span><Icons.IdcardOutlined />Info</span>),
          key: "info",
          children: record.userType === "system"
            ? <DisplaySystemMembers {...record} ></DisplaySystemMembers>
            : <>
              <DisplayBasicProfile {...record}></DisplayBasicProfile>
              <DisplayDetailedProfile {...record}></DisplayDetailedProfile>
            </>
        });

      // For Employees
      if (record.employee) {
        //Add R&R Tabs
        tabs.push({
          label: (<span><Icons.StarOutlined />Rewards and Recognition</span>),
          key: "randr",
          children: <DisplayRnRTab {...record.employee}></DisplayRnRTab>
        });

        // Add More Tab
        tabs.push({
          label: (<span><Icons.HolderOutlined />More</span>),
          key: "more",
          children: <DisplayMoreTab {...record.employee}></DisplayMoreTab>
        });
      }

      // Worklfow Tab
      tabs.push(
        {
          label: (<span><Icons.HistoryOutlined />History</span>),
          key: "stdorkflow",
          children: <>
            {record &&
              <HistoryTab stateManager={record.stateManager} tabPosition='left' />
            }
          </>
        })
    }
    return tabs;
  }

  appendToAppTitle(record?.name);
  return (
    <Show
      isLoading={isLoading}
      title={record?.name}
      headerProps={{
        tags: record?.stateManager?.state ? <Tag>{stringExtensions.capitalize(record?.stateManager?.state)}</Tag> : <></>
      }}
      headerButtons={
        <>
          <EditButton></EditButton>
        </>
      }
    >
      <Tabs
        items={getTabs()}
      />
      <Divider></Divider>
      <div style={{ marginTop: 15 }}>
        <ObjectMetadata {...record as IBaseProps} ></ObjectMetadata>
      </div>
    </Show>
  );
};
