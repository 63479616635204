import { Button, Drawer, Icons, Space, Spin, Tabs, message } from "@pankod/refine-antd";
import { useOne, useUpdate } from "@pankod/refine-core";
import { ShowUserCard } from "components/profile/showUserCard";
import { ISocial } from "interfaces/article";
import { useEffect, useState } from "react";
import { DATAPROVIDER_SOCIAL, RESOURCE_PATH } from "scripts/site";
import { SocialComments } from "./socialComments";

export declare type socialProps = {
    activeMode: boolean;
    socialElementId: string;
    expanded?: boolean;
};

export const RenderSocialButtons: React.FC<socialProps> = ({
    socialElementId,
    activeMode,
    expanded = true
}) => {

    const { mutate } = useUpdate<ISocial>();
    const [showCommentsForm, setShowCommentsForm] = useState<boolean>(false);
    const [likeCount, setLikeCount] = useState<number>(0);
    const [viewCount, setViewCount] = useState<number>(0);
    const [isLiked, setIsLiked] = useState<boolean>(false);
    const [isViewed, setIsViewed] = useState<boolean>(true);
    const [linkCopied, setLinkCopied] = useState<boolean>(false);
    const [socialActiveTab, setSocialActiveTab] = useState<string>("comment");

    const { isLoading, data: socialRecord } = useOne<ISocial>({
        dataProviderName: DATAPROVIDER_SOCIAL,
        resource: RESOURCE_PATH.SOCIAL,
        id: socialElementId,
        queryOptions: {
            enabled: socialElementId !== undefined
        }
    });

    const handleInteraction = (mode: string) => {
        // Update the social Element as per the mode
        mutate({
            dataProviderName: DATAPROVIDER_SOCIAL,
            resource: RESOURCE_PATH.SOCIAL,
            id: socialElementId,
            values: { operation: mode },
            successNotification: false,
            errorNotification: false
        },
            {
                onSuccess: () => {
                    if (mode === "like") {
                        setLikeCount(likeCount + 1);
                        setIsLiked(true);
                    } else if (mode === "view" && !isViewed) {
                        setIsViewed(true);
                        setViewCount(viewCount + 1);
                    }
                },
                onError: () => {
                }
            }
        );
    }

    const handleLinkCopy = () => {
        // Disable the button
        setLinkCopied(true);

        // Copy the link to clipboard
        navigator.clipboard.writeText(window.location.href);
        message.success("Link copied to clipboard");

        // Enable the button after 5 seconds
        setTimeout(() => {
            setLinkCopied(false);
        }, 5000);
    }

    const record = socialRecord?.data;
    useEffect(() => {
        if (activeMode) {
            setTimeout(() => {
                handleInteraction("view");
            }, 10000);
        }
        // eslint-disable-next-line
    }, []);

    useEffect(() => {
        if (record) {
            setLikeCount(record.likeCount);
            setViewCount(record.viewCount);
            setIsLiked(record.isLiked);
            setIsViewed(record.isViewed);
        }
    }, [record]);

    const socialTabs = [
        {
            label: (<span><Icons.LikeOutlined /> {likeCount} Like{likeCount !== 1 && "s"}</span>),
            key: "like",
            children: record?.likes?.map((like, index) => (
                <div key={index} style={{ color: "GrayText" }}>
                    <ShowUserCard id={like} key={`like-${index}`}></ShowUserCard>
                </div>
            ))
        },
        {
            label: (<span><Icons.CommentOutlined /> {record?.comments?.length} Comment{record?.comments?.length !== 1 && "s"}</span>),
            key: "comment",
            children: <SocialComments socialElementId={socialElementId} comments={record?.comments || []} />
        },
        {
            label: (<span><Icons.EyeOutlined /> {viewCount} View{viewCount !== 1 && "s"}</span>),
            key: "views",
            children: record?.views?.map((like, index) => (
                <div key={index} style={{ color: "GrayText" }}>
                    <ShowUserCard id={like} key={`views-${index}`}></ShowUserCard>
                </div>
            ))
        },
    ];

    return (
        <Spin spinning={activeMode && isLoading}>
            <Space wrap >
                <Button
                    type={expanded ? "default" : "text"}
                    disabled={!activeMode}
                    icon={<Icons.LikeOutlined />}
                    title={isLiked ? "You liked this" : "Like"}
                    size="small"
                    //style={{ cursor: activeMode ? (isLiked ? "default" : "pointer") : "not-allowed" }}
                    onClick={(e) => {
                        isLiked ? (() => {
                            setShowCommentsForm(true);
                            setSocialActiveTab("like");
                        })() : handleInteraction("like");
                        e.stopPropagation();
                    }}>
                    <span>
                        {likeCount}
                        {expanded && <> Like{likeCount !== 1 && "s"}</>}
                    </span>
                </Button>
                <Button
                    disabled={!activeMode}
                    type={expanded ? "default" : "text"}
                    icon={<Icons.CommentOutlined />}
                    title="Post a comment"
                    size="small"
                    onClick={(e) => {
                        setShowCommentsForm(true);
                        setSocialActiveTab("comment");
                        e.stopPropagation();
                    }}>
                    <span>
                        {record?.comments?.length}
                        {expanded && <> Comment{record?.comments?.length !== 1 && "s"}</>}
                    </span>
                </Button>
                <Button
                    disabled={!activeMode}
                    type={expanded ? "default" : "text"}
                    icon={<Icons.EyeFilled />}
                    title="Unique views"
                    size="small"
                    //style={{ cursor: activeMode ? "default" : "not-allowed" }}
                    onClick={(e) => {
                        setShowCommentsForm(true);
                        setSocialActiveTab("views");
                        e.stopPropagation();
                    }}
                >
                    <span>
                        {viewCount}
                        {expanded && <> View{viewCount !== 1 && "s"}</>}
                    </span>
                </Button>
                <Button
                    type={expanded ? "default" : "text"}
                    icon={<Icons.ShareAltOutlined />}
                    disabled={!activeMode || linkCopied}
                    title="Copy link to share"
                    size="small"
                    onClick={(e) => {
                        handleLinkCopy();
                        e.stopPropagation();
                    }}>
                    {expanded && <> Share</>}
                </Button>
            </Space>
            <Drawer
                closeIcon={false}
                placement="right"
                size="large"
                open={showCommentsForm}
                headerStyle={{ display: "none" }}
                onClose={(e) => { setShowCommentsForm(false); e.stopPropagation(); }}
                bodyStyle={{ paddingTop: 0 }}
                contentWrapperStyle={{ width: "500px" }}
            >
                <Tabs items={socialTabs} defaultActiveKey={socialActiveTab}
                    tabBarExtraContent={
                        <Button danger type="text" icon={<Icons.CloseOutlined />} onClick={(e) => { setShowCommentsForm(false); e.stopPropagation(); }} />
                    }>
                </Tabs>
            </Drawer>
        </Spin>
    );
};