import { Button, DeleteButton, Icons } from "@pankod/refine-antd";
import { useGetIdentity, useNavigation } from "@pankod/refine-core";
import { IBaseProps, IUser } from "interfaces";
import { DATAPROVIDER_DELETE } from "scripts/site";

export declare type NavigationButtonProps = IBaseProps & {
    navigateToId: string;
    resourceToNavigate: string;
};
export const NavigationButtons: React.FC<NavigationButtonProps> = (
    {
        navigateToId,
        resourceToNavigate,
        ...rest
    }) => {

    const { edit, show } = useNavigation();
    const { data } = useGetIdentity();
    const user = data as IUser;
     
    // Populate buttons depending on state and next state
    const actions = [];
    const currentState = rest.stateManager.state;
    if(currentState === 'new' && rest.stateManager.assignedTo.includes(user?.id))
    {
        actions.push( <Button
            type="primary"
            key="nvgn-edit-btn"
            size="small"
            className = "nvgn-btn"
            icon={<Icons.EditOutlined />}
            onClick={(): void => edit(resourceToNavigate, navigateToId)}
        >
            Edit
        </Button>);
        actions.push(
            <DeleteButton 
            size="small"
            key="nvgn-del-btn"
            title="Delete Evaluation"
            className = "nvgn-btn"
            resourceNameOrRouteName={resourceToNavigate}
            dataProviderName={DATAPROVIDER_DELETE}
            recordItemId={navigateToId}>
            Delete
        </DeleteButton>);
    }else{
        actions.push( <Button
            type="primary"
            size="small"
            key="nvgn-view-btn"
            className = "nvgn-btn"
            icon={<Icons.EyeFilled />}
            onClick={(): void => show(resourceToNavigate, navigateToId)}
        >View
        </Button>);
    }
    if(currentState === 'submit' && rest.stateManager.assignedTo.includes(user?.id))
    {
        actions.push(
                <Button
                size="small"
                key="nvgn-sub-btn"
                style={{ background: "green"}}
                icon={<Icons.TableOutlined />}
                onClick={(): void => edit(resourceToNavigate, navigateToId)}
                className = "nvgn-btn"
            >
            Review
        </Button>);
    }
    return (
        <div className="navbtn">
            {actions}
        </div>
    );
};