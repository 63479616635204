import { Button, Col, Form, FormProps, Icons, Input, Row } from "@pankod/refine-antd";
import { useOne } from "@pankod/refine-core";
import { RenderSpinner } from "components/common";
import { IWorkListMetadata } from "interfaces";
import { DATAPROVIDER_LOOKUP, RESOURCE_PATH, STALE_DURATION } from "scripts/site";

export declare type WorklistFilterProps =
    {
        formProps: FormProps,
        onApplyFilter: () => void
    };

export const WorklistFilter: React.FC<WorklistFilterProps> = ({ formProps, onApplyFilter }) => {
    const { isLoading, data: metadata } = useOne<IWorkListMetadata>({
        dataProviderName: DATAPROVIDER_LOOKUP,
        resource: RESOURCE_PATH.METADATA,
        id: RESOURCE_PATH.WORKLIST,
        queryOptions: {
            enabled: true,
            staleTime: STALE_DURATION
        }
    });

    const metaConfig = metadata?.data.config;
    return (
        isLoading ? <RenderSpinner message="Loading..." ></RenderSpinner> :
            <Form layout="vertical" {...formProps} autoComplete="off">
                <Row gutter={[10, 0]} align="bottom">
                    <Col xs={24} xl={24} md={12}>
                        <Form.Item
                            label={"Search"}
                            name="q"
                            tooltip={metaConfig?.search?.textMessages?.searchTextPlaceholder || "Search Worklist"}
                        >
                            <Input
                                placeholder={metaConfig?.search?.textMessages?.searchTextPlaceholder || "Search Worklist"}
                                prefix={<Icons.SearchOutlined />}
                            />
                        </Form.Item>
                    </Col>
                    <Col xs={24} xl={24} md={8}>
                        <Form.Item
                            label="Item State"
                            name="state"
                        >
                            <Input
                                placeholder="Provide State of Item"
                                onInput={
                                    e => (e.target as HTMLInputElement).value = (e.target as HTMLInputElement).value.toLowerCase()
                                } />
                        </Form.Item>
                    </Col>
                    <Col xs={24} xl={24} md={8}>
                        <Form.Item>
                            <Button
                                style={{ width: "100%" }}
                                htmlType="submit"
                                type="primary"
                                onClick={() => {
                                    onApplyFilter()
                                }}
                            >
                                Apply Filter(s)
                            </Button>
                        </Form.Item>
                        <Form.Item>
                            <Button
                                style={{ width: "100%" }}
                                htmlType="submit"
                                onClick={() => { onApplyFilter(); formProps.form?.resetFields() }}
                            >
                                Clear Filter(s)
                            </Button>
                        </Form.Item>
                    </Col>
                </Row>
            </Form>
    );
}