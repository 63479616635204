import { CrudFilters, useList } from "@pankod/refine-core";
import { DATAPROVIDER_DISTINCT, RESOURCE_PATH } from "scripts/site";

export const EngagementSolutionDistinct: React.FC<{
    fields: string,
    programId?: string, engagementId?: string, scenarioId?: string
}> = ({ fields, programId, engagementId, scenarioId }) => {

    const filters: CrudFilters = [{
        field: "_fields",
        operator: "eq",
        value: fields
    }];
    if (programId) {
        filters.push({
            field: "programId",
            operator: "eq",
            value: programId
        });
    }
    if (engagementId) {
        filters.push({
            field: "engagementId",
            operator: "eq",
            value: engagementId
        });
    }
    if (scenarioId) {
        filters.push({
            field: "scenarioId",
            operator: "eq",
            value: scenarioId
        });
    }
    const { data: enggProgCount, isLoading } = useList({
        dataProviderName: DATAPROVIDER_DISTINCT,
        resource: RESOURCE_PATH.ENGAGEMENTSOLUTION,
        config: {
            filters: filters
        },
        queryOptions: {
            enabled: !!fields
        }
    });
    return (isLoading ? <>Loading...</> :
        <>{enggProgCount?.data?.length ?? "0"}</>
    );
}