import { IBaseProps, arrayExtensions, stringExtensions } from "@architecture-innovation-transformation/lib-common";
import { Alert, Divider, EditButton, Show, Space, Tag, Typography } from "@pankod/refine-antd";
import { CanAccess, IResourceComponentsProps, useOne, useShow } from "@pankod/refine-core";
import MDEditor from "@uiw/react-md-editor";
import { RenderTimelineModal, StateButtons } from "components/common";
import { RenderSocialButtons } from "components/common/socialButtons";
import { ShowUserCard } from "components/profile/showUserCard";
import { LookupButton } from "components/utils/lookupButton";
import dayjs from "dayjs";
import { IArticle, IArticleMetadata } from "interfaces/article";
import { DATAPROVIDER_LOOKUP, DATAPROVIDER_READ, DATAPROVIDER_UPDATE, HELIUM_LOGO_PATH, RESOURCE_PATH, appendToAppTitle } from "scripts/site";
import { getAppTheme } from "scripts/theme";

export const ArticleShow: React.FC<IResourceComponentsProps> = () => {
  const { queryResult } = useShow<IArticle>({
    dataProviderName: DATAPROVIDER_READ
  });

  const { data: metadata } = useOne<IArticleMetadata>({
    dataProviderName: DATAPROVIDER_LOOKUP,
    resource: RESOURCE_PATH.METADATA,
    id: RESOURCE_PATH.ARTICLE
  });
  const metaConfig = metadata?.data?.config;

  const { data, isLoading } = queryResult;
  const { Text } = Typography;
  const record = data?.data as IArticle;
  appendToAppTitle(record?.name);

  return (
    <Show
      isLoading={isLoading}
      headerProps={{
        //title: `Category - ${record?.category}`,
        title: record?.name,
        tags: <Tag color="gold">{stringExtensions.capitalize(record?.stateManager.state) ?? "Loading"}</Tag>,
        backIcon: true,
        extra: <>
          {record &&
            <CanAccess key={RESOURCE_PATH.ARTICLE} resource={RESOURCE_PATH.ARTICLE.toLowerCase()} action="edit" params={{ dataProviderName: DATAPROVIDER_UPDATE, id: record.id }}>
              <Space>
                {metaConfig?.editableStates?.includes(record.stateManager.state) && <EditButton></EditButton>}
                <StateButtons {...record as IBaseProps}
                  resourceToAct={RESOURCE_PATH.ARTICLE}
                  resourceToActId={record?.id || "0"}
                  resourceToNavigate={RESOURCE_PATH.WORKLIST}
                />
                {record && <RenderTimelineModal stateManager={record?.stateManager} />}
              </Space>
            </CanAccess>
          }
        </>
      }}
    >
      {record?.authorName &&
        <>
          <Text style={{ color: "GrayText" }}>By: </Text>
          <Space wrap split={<Divider type="vertical" />} >
            {arrayExtensions.validateArray(record?.authorName ?? []) ? record?.authorName?.map((e, i) => <ShowUserCard key={`author-${i}`} id={e}></ShowUserCard>) : "None"}
          </Space>
        </>
      }
      {record?.programIds && arrayExtensions.validateArray(record?.programIds) &&
        <div>
          <Text style={{ color: "GrayText" }}>Programs: </Text>
          <Space wrap split={<Divider type="vertical" />} >
            {record?.programIds?.map((e, i) => <LookupButton id={e || ""} resource={RESOURCE_PATH.PROGRAM}></LookupButton>)}
          </Space>
        </div>
      }

      {record?.engagementId &&
        <div>
          <Text style={{ color: "GrayText" }}>Engagement: </Text>
          <LookupButton id={record?.engagementId || ""} resource={RESOURCE_PATH.ENGAGEMENT}></LookupButton>
        </div>
      }
      {record?.publishedDate &&
        <div>
          <Text style={{ color: "GrayText" }}>Published on: </Text>
          <Text style={{ color: "GrayText" }}>{dayjs(record.publishedDate).format('LLLL')}</Text>
        </div>
      }
      {
        <div style={{ marginTop: 10 }}>
          <RenderSocialButtons socialElementId={record?.socialElementId} activeMode={metaConfig?.socialElement?.activeStates?.includes(record?.stateManager?.state) ?? true} />
        </div>
      }

      <Divider></Divider>

      <Alert
        message="Synopsis"
        description={record?.description}
        type="info"
        showIcon={false}
        style={{ marginBottom: 15 }}
      />
      {arrayExtensions.validateArray(record?.body ?? []) &&
        record?.body?.map((content, i) =>
          <div className="artcle" key={`art-${i}`}>
            {content.isImageAdded && content.alignment === "ImageAboveParagraph" &&
              <div className="img-cntnr">
                <img
                  key={`artimg-${i}`}
                  alt={record?.name}
                  src={record?.articleImages?.find(img => img.uid === content.paraImage)?.url || HELIUM_LOGO_PATH}
                />
                {content.imageTitle && <Typography.Text style={{ color: "GrayText" }}>{content.imageTitle}</Typography.Text>}
              </div>
            }
            <div className="container" key={`para-${i}`} data-color-mode={getAppTheme()}>
              <MDEditor.Markdown source={content.paragraph} />
            </div>
            {content.isImageAdded && content.alignment === "ImageBelowParagraph" &&
              <div className="img-cntnr">
                <img
                  key={`artimg-${i}`}
                  alt={record?.name}
                  src={record?.articleImages?.find(img => img.uid === content.paraImage)?.url || HELIUM_LOGO_PATH}
                />
                {content.imageTitle && <Typography.Text style={{ color: "GrayText" }}>{content.imageTitle}</Typography.Text>}
              </div>
            }
          </div>
        )
      }
      <Divider></Divider>
      {arrayExtensions.validateArray(record?.tagList) &&
        <>
          <Space wrap>
            <Typography.Title level={5}>Tags:</Typography.Title>
            <Tag color={"blue"}>{record?.category}</Tag>
            <Tag color={"green"}>{record?.contentType}</Tag>
            {arrayExtensions.validateArray(record?.tagList ?? []) ? record?.tagList?.map((e, i) => <Tag key={`tag-${i}`}>{e}</Tag>) : ""}
          </Space>
        </>
      }
    </Show>
  );
};

