import { Button, Divider, EditButton, Icons, ListButton, Show, Tabs } from "@pankod/refine-antd";
import { CanAccess, IResourceComponentsProps, useNavigation, useShow } from "@pankod/refine-core";
import { LocationDetails } from "components/manage/locationDetails";
import { LocationsList } from "components/manage/locationList";
import { ObjectMetadata } from "components/utils/objectMetadata";
import { IBaseProps, ICountry } from "interfaces";
import { DATAPROVIDER_READ, DATAPROVIDER_UPDATE, RESOURCE_PATH } from "scripts/site";

export const CountryShow: React.FC<IResourceComponentsProps> = () => {
    const { queryResult } = useShow<ICountry>({
        dataProviderName: DATAPROVIDER_READ
    });

    const { data, isLoading } = queryResult;
    const record = data?.data as ICountry;
    const { create } = useNavigation();

    return (
        <Show
            isLoading={isLoading}
            headerProps={{
                title: record?.id
            }}
            headerButtons={
                () => (
                    <>
                        {record &&
                            <>
                                <ListButton></ListButton>
                                <CanAccess key={`${RESOURCE_PATH.COUNTRY}-edit`} resource={RESOURCE_PATH.COUNTRY.toLowerCase()} action="edit" params={{ dataProviderName: DATAPROVIDER_UPDATE, id: record.id }}>
                                    <EditButton></EditButton>
                                </CanAccess>
                            </>
                        }
                    </>
                )
            }
        >
            <Tabs
                tabBarExtraContent={
                    <Button type="default"
                        onClick={() => { create(RESOURCE_PATH.LOCATION) }}>
                        <Icons.PlusSquareOutlined />Add a Location
                    </Button>
                }
                items={[
                    {
                        label: (<span><Icons.IdcardOutlined />Info</span>),
                        key: "info",
                        children: <>{record && <LocationDetails {...record} />}</>
                    },
                    {
                        label: (<span><Icons.PushpinOutlined />Locations</span>),
                        key: "workflow",
                        children: <>
                            {record && <LocationsList countryId={record.id} />}
                        </>
                    }
                ]}
            />
            <Divider></Divider>
            <div style={{ marginTop: 15 }}>
                <ObjectMetadata {...record as IBaseProps} ></ObjectMetadata>
            </div>
        </Show >
    );
};
