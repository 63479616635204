import { ILookup } from "@architecture-innovation-transformation/lib-common";
import { Col, Form, Input, Row, Select, Typography, useSelect } from "@pankod/refine-antd";
import { LookupButton } from "components/utils/lookupButton";
import { IBuilding, ILocationLookup } from "interfaces";

import { useState } from "react";
import { DATAPROVIDER_LOOKUP, RESOURCE_PATH, greyStyle } from "scripts/site";

// add a delegate to handle onChange event
export const LocationDropdowns: React.FC<{ record?: ILocationLookup, isLoading: boolean, onChange?: (record: ILocationLookup) => void }> =
    ({ record, isLoading, onChange }) => {

        const [countryId, setCountryId] = useState(record?.countryId ?? "");
        const [locationId, setLocationId] = useState(record?.locationId ?? "");

        const { selectProps: buildingProps, queryResult: buildingQuery } = useSelect<IBuilding>({
            dataProviderName: DATAPROVIDER_LOOKUP,
            resource: RESOURCE_PATH.BUILDING,
            optionLabel: "name",
            optionValue: "id",
            sort: [
                {
                    field: "name",
                    order: "asc"
                },
            ],
            fetchSize: 50,
            queryOptions: {
                //staleTime: STALE_DURATION,
            }
        });
        const [buildingId, setBuildingId] = useState(record?.buildingId ?? "");

        const { selectProps: zoneProps, queryResult: zoneQuery } = useSelect<ILookup>({
            dataProviderName: DATAPROVIDER_LOOKUP,
            resource: RESOURCE_PATH.ZONE,
            optionLabel: "name",
            optionValue: "id",
            filters: [{
                field: "buildingId",
                operator: "eq",
                value: buildingId
            }],
            sort: [
                {
                    field: "name",
                    order: "asc"
                },
            ],
            fetchSize: 20,
            queryOptions: {
                enabled: (buildingId !== undefined && buildingId !== ""),
                //staleTime: STALE_DURATION,
            }
        });

        const [isInitialLoad, setIsInitialLoad] = useState(true);
        if (isInitialLoad && !isLoading && record) {
            setIsInitialLoad(false);
            setCountryId(record.countryId);
            setLocationId(record.locationId);
            setBuildingId(record.buildingId);
        }

        return (<>
            <Form.Item name="countryId" hidden={true}>
                <Input />
            </Form.Item>
            <Form.Item name="locationId" hidden={true}>
                <Input />
            </Form.Item>
            <Typography.Text>Location: </Typography.Text>
            <LookupButton id={countryId} resource={RESOURCE_PATH.COUNTRY} noClick></LookupButton>
            <Typography.Text style={greyStyle}>{" / "}</Typography.Text>
            <LookupButton id={locationId} resource={RESOURCE_PATH.LOCATION} noClick></LookupButton>

            <Row gutter={[16, 16]} style={{ marginTop: 15 }}>
                <Col xl={12} xs={24}>
                    <Form.Item
                        label="Building"
                        name="buildingId"
                        rules={[
                            {
                                required: true,
                                whitespace: true
                            }
                        ]}
                    >
                        <Select
                            allowClear
                            placeholder="Select Building"
                            showSearch
                            loading={buildingQuery.isLoading}
                            //disabled={locationId === undefined || locationId === ""}
                            {...buildingProps}
                            onChange={(event: any) => {
                                setBuildingId(event);
                                const buildingObj = buildingQuery?.data?.data.find((item: any) => item.id === event);
                                const country = buildingObj?.countryId ?? "";
                                const location = buildingObj?.locationId ?? "";
                                setCountryId(country);
                                setLocationId(location);

                                onChange && onChange({
                                    countryId: country,
                                    locationId: location,
                                    buildingId: event,
                                    zoneId: ""
                                });
                            }}
                        />
                    </Form.Item>
                </Col>
                <Col xl={12} xs={24}>
                    <Form.Item
                        label="Zone"
                        name="zoneId"
                        rules={[
                            {
                                required: true,
                                whitespace: true,
                            }
                        ]}
                    >
                        <Select
                            allowClear
                            placeholder="Select Zone"
                            showSearch
                            loading={zoneQuery.isLoading}
                            disabled={buildingId === undefined || buildingId === ""}
                            {...zoneProps}
                            onChange={(event: any) => {
                                onChange && onChange({
                                    countryId: countryId,
                                    locationId: locationId,
                                    buildingId: buildingId,
                                    zoneId: event
                                });
                            }}
                        />
                    </Form.Item>
                </Col>
            </Row>
        </>
        );
    }