import { ILookup, stringExtensions } from "@architecture-innovation-transformation/lib-common";
import { Button, Form, FormProps, Icons, Input, Select, Space, useSelect } from "@pankod/refine-antd";
import { useOne } from "@pankod/refine-core";
import { RenderSpinner } from "components/common";
import { IOfferingMetadata } from "interfaces/article";
import { DATAPROVIDER_LOOKUP, RESOURCE_PATH, STALE_DURATION } from "scripts/site";

export const OfferingFilter: React.FC<{
    formProps: FormProps,
    onApplyFilter: () => void,
}> = (props) => {
    const { isLoading, data: metadata } = useOne<IOfferingMetadata>({
        dataProviderName: DATAPROVIDER_LOOKUP,
        resource: RESOURCE_PATH.METADATA,
        id: RESOURCE_PATH.OFFERING,
        queryOptions: {
            enabled: true,
            staleTime: STALE_DURATION
        }
    });

    const { selectProps: serviceLineProps } = useSelect<ILookup>({
        dataProviderName: DATAPROVIDER_LOOKUP,
        resource: RESOURCE_PATH.SERVICELINE,
        optionLabel: "name",
        optionValue: "id",
        sort: [
            {
                field: "name",
                order: "asc"
            },
        ],
        fetchSize: 50
    });

    const metaConfig = metadata?.data.config;
    return (
        isLoading ? <RenderSpinner message="Loading..." ></RenderSpinner> :
            <Form layout="vertical" {...props.formProps} autoComplete="off">
                <Form.Item
                    label={"Search"}
                    name="q"
                    tooltip={metaConfig?.search?.textMessages?.searchTextPlaceholder || "Search Offerings"}
                >
                    <Input
                        placeholder={metaConfig?.search?.textMessages?.searchTextPlaceholder || "Search Offerings"}
                        prefix={<Icons.SearchOutlined />}
                    />
                </Form.Item>
                <Form.Item
                    label="Offering Type"
                    name="offeringtype"
                >
                    <Select placeholder="Offering Type"
                        mode="multiple"
                        options={(metaConfig?.offeringtype || []).map((m, i) => ({ value: m, label: m }))}
                        allowClear>
                    </Select>
                </Form.Item>
                <Form.Item
                    label="Service Lines"
                    name="serviceLineIds"
                >
                    <Select
                        allowClear
                        mode="multiple"
                        placeholder="Service Lines"
                        {...serviceLineProps}
                    />
                </Form.Item>
                <Form.Item
                    label="Value Dimensions"
                    name="valueDimensions"
                >
                    <Select
                        placeholder="Value Dimensions"
                        mode="multiple"
                        options={(metaConfig?.valueDimensions || []).map((m, i) => ({ value: m, label: m }))}
                        allowClear
                    >
                    </Select>
                </Form.Item>
                <Form.Item
                    label="Licensing Model"
                    name="licensingmodel"
                >
                    <Select placeholder="Licensing Model"
                        mode="multiple"
                        options={(metaConfig?.licensingmodel || []).map((m, i) => ({ value: m, label: m }))}
                        allowClear>
                    </Select>
                </Form.Item>
                <Form.Item
                    label="Offering Status"
                    name="state"
                >
                    <Select
                        allowClear
                        mode="multiple"
                        placeholder="Offering status"
                    >
                        {metadata?.data?.states.map((m, i) => (
                            <Select.Option value={m.state} key={i}>{stringExtensions.capitalize(m.state)}</Select.Option>
                        ))}
                    </Select>
                </Form.Item>
                <Form.Item>
                    <Space>
                        <Button
                            htmlType="submit"
                            type="primary"
                            icon={<Icons.FilterOutlined />}
                            onClick={() => {
                                props.onApplyFilter();
                            }}
                        >
                            Apply
                        </Button>
                        <Button
                            icon={<Icons.ClearOutlined />}
                            htmlType="submit"
                            onClick={() => { props.formProps.form?.resetFields() }}
                        >
                            Clear
                        </Button>
                    </Space>
                </Form.Item>
            </Form>
    );
}