import { ConfigProvider, FloatButton, Layout, notificationProvider, theme } from "@pankod/refine-antd";
import "@pankod/refine-antd/dist/reset.css";
import { Refine } from "@pankod/refine-core";
import { aITracking } from "appInsightLoad";
import { AppError, AppFooter, AppHeader, AppLayout, AppTitle, RefineMenu } from "components/common";
import { heliumResources } from "components/utils/resources";
import hashRouterProvider from "hashRouter";
import { AuthNProvider, AuthZProvider } from "hooks";
import mermaid from "mermaid";
import { Login } from "pages/Auth/login";
import { useState } from "react";
import { validationMessages } from "scripts/messages";
import { dataProviders } from "scripts/site";
import { DEFAULT_THEME, getAppTheme, setAppTheme } from "scripts/theme";
import "styles/override.css";

function App() {
  const [currentTheme, setCurrentTheme] = useState(setAppTheme());

  return (
    <ConfigProvider
      form={{ validateMessages: { ...validationMessages } }}
      theme={{
        algorithm: currentTheme === DEFAULT_THEME ? theme.darkAlgorithm : theme.defaultAlgorithm,
        components: {
          Layout: {
            colorBgHeader: currentTheme === DEFAULT_THEME ? "#1f1f1f" : "#f9f9f9",
            colorBgTrigger: currentTheme === DEFAULT_THEME ? "#1f1f1f" : "#f9f9f9"
          }
        }
      }}
    >
      <Refine
        routerProvider={hashRouterProvider}
        // routerProvider={{
        //   ...hashRouterProvider,
        //   routes: [
        //     {
        //       element: <RoomQRPrint />,
        //       path: "/spaces/room/print",
        //       layout: false,
        //     },
        //   ],
        // }}
        dataProvider={dataProviders}
        authProvider={AuthNProvider}
        LoginPage={Login}
        Title={AppTitle}
        accessControlProvider={AuthZProvider}
        Footer={AppFooter}
        catchAll={AppError}
        Header={() => (
          <AppHeader theme={currentTheme} setTheme={setCurrentTheme} />
        )}
        Sider={RefineMenu}
        OffLayoutArea={() => <FloatButton.BackTop style={{ right: 50 }} />}
        resources={heliumResources}
        notificationProvider={notificationProvider}
        Layout={AppLayout}
        options={{
          disableTelemetry: true,
          warnWhenUnsavedChanges: true,
          syncWithLocation: true,
          reactQuery: { clientConfig: { defaultOptions: { queries: { retry: false } } } }
        }}
      >
      </Refine>
    </ConfigProvider>
  );
}

export const lay = aITracking(Layout);
mermaid.initialize({ startOnLoad: false, theme: getAppTheme(), securityLevel: "loose" })
export default App;