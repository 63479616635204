import { Tag } from "@pankod/refine-antd";
import React from "react";

export const ReservationLegendTags: React.FC<{ availability?: boolean }> = ({ availability }) => {
    return (<>
        <Tag color="green">Available</Tag>
        <Tag color="blue">My reservation</Tag>
        <Tag color="red">Reserved</Tag>
        {availability === true && <Tag color="default">Not matching criteria</Tag>}
    </>)
}