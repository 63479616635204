import { AntdLayout, Divider, Icons, Space, Typography } from "@pankod/refine-antd";
import { useGetIdentity } from "@pankod/refine-core";
import { IUser } from "interfaces";

export const AppFooter: React.FC = () => {
    const { data } = useGetIdentity();
    const user = data as IUser;

    return (
        <AntdLayout.Footer style={{ textAlign: 'center' }}>
            <Space wrap split={<Divider type="vertical" />} >
                <img src="/tcs-logo-2.png" alt="TCS" style={{ maxWidth: "21px" }} />
                <Typography.Text style={{ color: "GrayText" }}>TATA Consultancy Services</Typography.Text>
                <Typography.Text style={{ color: "GrayText" }}><span style={{ color: "limegreen" }}>A</span>rchitecture, <span style={{ color: "limegreen" }}>I</span>nnovation and <span style={{ color: "limegreen" }}>T</span>ransformation</Typography.Text>
                <Typography.Text style={{ color: "GrayText" }}>{process.env.REACT_APP_ApplicationTitle} <Icons.CopyrightOutlined /> {new Date().getFullYear()}</Typography.Text>
                {
                    user?.activeUsers && user?.activeUsers !== 0 &&
                    <Typography.Text style={{ color: "GrayText" }}>{user?.activeUsers} active users in last 7 days</Typography.Text>
                }
                {
                    process.env.REACT_APP_BuildId &&
                    <Typography.Text style={{ color: "GrayText" }}>Version: {process.env.REACT_APP_BuildId}</Typography.Text>
                }
            </Space>
        </AntdLayout.Footer>
    );
};