import { getCookieByName } from "./site";

// Helium Themes constants
export type HeliumTheme = 'light' | 'dark';
export const DEFAULT_THEME: HeliumTheme = "dark";
export const THEME_COOKIENAME = "theme";

export function setAppTheme(userTheme?: HeliumTheme): HeliumTheme {
    let theme = getCookieByName(THEME_COOKIENAME);
    // If user theme is available set the User Theme
    if(userTheme)
    {
        document.cookie = `${THEME_COOKIENAME}=${userTheme}`;
        return userTheme;
    }
    // Else return the cookie value
    else if (theme) {
        return theme as HeliumTheme;
    }
    // Else switch to default theme
    else {
        document.cookie = `${THEME_COOKIENAME}=${DEFAULT_THEME}`;
        return DEFAULT_THEME;
    }
}

export function getAppTheme(): HeliumTheme {
    let themeCookie = getCookieByName(THEME_COOKIENAME);
    if (themeCookie) {
        return themeCookie as HeliumTheme;
    }
    return DEFAULT_THEME;
}