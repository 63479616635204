import { stringExtensions } from "@architecture-innovation-transformation/lib-common";
import { Form, Input, Radio, Select } from "@pankod/refine-antd";
import { IAssetType, IAttachment } from "interfaces";
import { useState } from "react";
import { buildAttachmentUrl, RESOURCE_PATH } from "scripts/site";

export declare type AssetConfigProps = IAssetType & {
  fields?: IAttachment[],
  isCRRequired : boolean
};

export const EditAssetConfig: React.FC<AssetConfigProps> = (assetTypeObj: AssetConfigProps) => {
  const macIdUrl = buildAttachmentUrl(RESOURCE_PATH.METADATA, RESOURCE_PATH.ASSET, assetTypeObj.fields?.find(f => f.name === "macId")?.attachment);
  const crNumberUrl = buildAttachmentUrl(RESOURCE_PATH.METADATA, RESOURCE_PATH.ASSET, assetTypeObj.fields?.find(f => f.name === "crNumber")?.attachment);

  const [isCRRequired, setisCRRequired] = useState(assetTypeObj.isCRRequired);
    
  return (
    <>
      {
        (assetTypeObj?.makeModel ?? []).length > 0 ?
          <Form.Item
            label="Make/Model"
            name="makeModel"
            rules={[
              {
                required: true,
                type: "string",
              }
            ]}
          >
            {
              assetTypeObj?.makeModel?.length > 5
                ?
                <Select
                  showSearch
                  optionFilterProp="children"
                  filterOption={(input, option: any) =>
                    option?.children?.toLowerCase().indexOf(input.toLowerCase()) >= 0
                  }
                  filterSort={(optionA, optionB) =>
                    optionA?.children?.toLowerCase().localeCompare(optionB?.children?.toLowerCase())
                  }
                >
                  {assetTypeObj?.makeModel.sort().map((m, i) => (
                    <Select.Option value={m} key={i}>{m}</Select.Option>
                  ))}
                </Select>
                : <Radio.Group
                  optionType="button"
                  buttonStyle="solid"
                  options={assetTypeObj?.makeModel.sort()}
                />
            }
          </Form.Item>
          : <></>}
      {
        (assetTypeObj?.processor ?? []).length > 0 ?
          <Form.Item
            label="Processor"
            name="processor"
            rules={[
              {
                required: true,
                type: "string",
              }
            ]}
          >
            <Radio.Group
              optionType="button"
              buttonStyle="solid"
              options={assetTypeObj?.processor.sort()}
            />
          </Form.Item>
          : <></>
      }

      {
        (assetTypeObj?.ram ?? []).length > 0 ?
          <Form.Item
            label="RAM"
            name="ram"
            rules={[
              {
                required: true,
                type: "string",
              }
            ]}
          >
            <Radio.Group
              optionType="button"
              buttonStyle="solid"
              options={assetTypeObj?.ram}
            />
          </Form.Item>
          : <></>
      }

      {
        (assetTypeObj?.storage ?? []).length > 0 ?
          <Form.Item
            label="Storage"
            name="storage"
            rules={[
              {
                required: true,
                type: "string",
              }
            ]}
          >
            <Radio.Group
              optionType="button"
              buttonStyle="solid"
              options={assetTypeObj?.storage}
            />
          </Form.Item>
          : <></>
      }

      {
        (assetTypeObj?.resolution ?? []).length > 0 ?
          <Form.Item
            label="Resolution"
            name="resolution"
            rules={[
              {
                required: true,
                type: "string",
              }
            ]}
          >
            <Radio.Group
              optionType="button"
              buttonStyle="solid"
              options={assetTypeObj?.resolution}
            />
          </Form.Item>
          : <></>
      }

      {
        assetTypeObj?.macId === "" ?
          <Form.Item
            tooltip={macIdUrl &&
              <a href={macIdUrl} rel="noreferrer" target={"_blank"}>View detailed steps</a>
            }

            label="MAC ID"
            name="macId"
            rules={[
              {
                required: true,
                type: "string",
                whitespace: true,
                min: 5,
                max: 20,
              }
            ]}
          >
            <Input />
          </Form.Item>
          : <></>}
       {
        (assetTypeObj?.osImage ?? []).length > 0 ?
          <Form.Item
            label="OS Image Type"
            name="osImage"
            tooltip={<span>Select the type of OS Image running on your asset. <br/>Select Customer Provided if you are using a Customer SOE (Standard Operating Environment).</span>}
            rules={[
              {
                required: true,
                type: "string",
                validator(rule, value) {
                  if(!stringExtensions.stringEquals(value, "TCS"))
                  {
                    setisCRRequired(true);
                  }
                  else
                  {
                    setisCRRequired(false);
                  }
                return Promise.resolve();
              },
              }
            ]}
          >
            <Radio.Group
              optionType="button"
              buttonStyle="solid"
              options={assetTypeObj?.osImage}
            />
          </Form.Item>
          : <></>
      }     
      {
        assetTypeObj?.crNumber === "" ?
          <Form.Item
            label="CR Number"
            name="crNumber"
            hidden={!isCRRequired}
            tooltip={crNumberUrl &&
              <>
                <span>In the case of Customer Provided OS Image, <b>Security Control Exception</b> CR is recommended.</span>
                <br/>
                <a href={crNumberUrl} rel="noreferrer" target={"_blank"}>View detailed steps</a>
              </>
            }
            rules={[
              {
                required: false, //isCRRequired, -- Enable this once CR is required
                type: "string",
                whitespace: true,
                min: 5,
                max: 20,
              }
            ]}
          >
            <Input />
          </Form.Item>
          : <></>}
    </>
  );
};
