import { arrayExtensions } from "@architecture-innovation-transformation/lib-common";
import { AntdLayout, Grid, Menu, MenuProps } from "@pankod/refine-antd";
import {
    ITreeMenu, useAuthenticated, useNavigation, useTitle, useMenu
} from "@pankod/refine-core";
import { IAuthZ } from "interfaces";
import React, { CSSProperties, useState } from "react";
import { AAD_LOGOUT, allowedResources, useAuthZ } from "scripts/site";

export const RefineMenu: React.FC = () => {
    const [collapsed, setCollapsed] = useState<boolean>(false);
    const Title = useTitle();
    const { menuItems, selectedKey, defaultOpenKeys } = useMenu();
    const { push } = useNavigation();
    const breakpoint = Grid.useBreakpoint();
    const isMobile = !breakpoint.lg;
    const { isSuccess: isAuthenticated } = useAuthenticated();
    const { authZ, isLoading } = useAuthZ();
    const isFetched = !isLoading;

    type MenuItem = Required<MenuProps>['items'][number];

    const checkShowMenu = (meta: IAuthZ): boolean => {
        let result = true;
        if (meta && meta.ui) {
            // If showMenu is false, then don't show the menu item
            if (meta.ui.showMenu === false) {
                result = false;
            }
            // If showMenu is true and checkActionList exists, validate all the actions in the list against the allowedActions
            else if (meta.ui.showMenu === true && arrayExtensions.validateArray(meta.ui.checkActionList)) {
                result = meta.ui.checkActionList.every(action => meta.allowedActions.some(m => m === action));
            }
        }
        return result;
    }

    function getItem(
        label: React.ReactNode,
        key: React.Key,
        icon?: React.ReactNode,
        children?: MenuItem[],
        type?: 'group',
    ): MenuItem {
        return {
            key,
            icon,
            children,
            label,
            type,
        } as MenuItem;
    }

    let filteredMenu: MenuItem[] = [];

    const filterMenuItems = (items: ITreeMenu[], parentKey?: string): MenuItem[] => {
        const result: MenuItem[] = [];
        items?.forEach(mi => {
            let itemKey = mi.key || mi.name;

            if (parentKey)
                itemKey = [parentKey, itemKey].join("/");
            else
                itemKey = "/" + itemKey;

            if (mi?.children?.length > 0) {
                const childResult = filterMenuItems(mi.children, itemKey);
                if (childResult?.length > 0) {
                    result.push(getItem(mi.label, itemKey, mi.icon, childResult));
                }
            }
            else if (allowedResources.some(m => m === mi.name) || authZ?.some(m => m.id === mi.name && checkShowMenu(m))) {
                result.push(getItem(mi.label, itemKey, mi.icon));
            }
        });
        return result;
    }

    if (isAuthenticated && isFetched && arrayExtensions.validateArray(authZ)) {
        filteredMenu = filterMenuItems(menuItems);
    }

    return (
        <AntdLayout.Sider
            collapsible
            collapsed={collapsed}
            onCollapse={(collapsed: boolean): void => setCollapsed(collapsed)}
            collapsedWidth={isMobile ? 0 : 80}
            breakpoint="lg"
            style={isMobile ? antLayoutSiderMobile : antLayoutSider}
        >
            {Title ? <Title collapsed={collapsed} /> : <></>}
            <Menu theme="light"
                selectedKeys={[selectedKey]}
                defaultOpenKeys={arrayExtensions.validateArray(defaultOpenKeys) ? defaultOpenKeys : ['/home']}
                mode="inline"
                onClick={({ key }) => {
                    if (key === "logout") {
                        window.location.href = AAD_LOGOUT;
                        return;
                    }

                    if (!breakpoint.lg) {
                        setCollapsed(true);
                    }

                    push(key as string);
                }}
                items={filteredMenu}
            >
            </Menu>
        </AntdLayout.Sider>
    );
};

export const antLayoutSider: CSSProperties = {
    position: "relative",
};
export const antLayoutSiderMobile: CSSProperties = {
    position: "fixed",
    height: "100vh",
    zIndex: 999,
};

//Code adapted from: https://github.com/pankod/refine/blob/8b8da6091776e17cdfa683857176d57285908344/examples/customization/customSider/src/components/sider/index.tsx