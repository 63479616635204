import { Button, Col, Collapse, Divider, Form, FormProps, Icon, Icons, Input, InputNumber, RcFile, Row, Select, Upload, getValueFromEvent, message, useSelect } from "@pankod/refine-antd";
import { useApiUrl, useCreate, useOne } from "@pankod/refine-core";
import { RenderSpinner } from "components/common";
import { GenAISVG } from "components/svgs/genAISvg";
import { IBaseProps, IListData, ILookup } from "interfaces";
import { ICandidateMetadata } from "interfaces/resourcing";
import { useEffect, useState } from "react";
import { rowGutter, threeColumnLayout, twoColumnLaytout } from "scripts/layout";
import { AI_ACTION_FILEPARSER, DATAPROVIDER_AI, DATAPROVIDER_LOOKUP, DATAPROVIDER_UPLOAD, RESOURCE_PATH, SELECTION_LISTS, STALE_DURATION, validateRGSIDs } from "scripts/site";
import { ParsedResume } from "./parsedResume";

export declare type CandidateFormProps = {
    candidateType: string;
    formprops: FormProps;
    mode?: "create" | "edit";
    record?: any;
};

export const RequestForm: React.FC<CandidateFormProps> = ({ candidateType, formprops, mode = "create", record }) => {
    const { Panel } = Collapse;

    const { data: entityMetadata } = useOne<ICandidateMetadata>({
        dataProviderName: DATAPROVIDER_LOOKUP,
        resource: RESOURCE_PATH.METADATA,
        id: RESOURCE_PATH.CANDIDATE,
        queryOptions: {
            enabled: true,
            staleTime: STALE_DURATION
        }
    });

    const { data: skillsList } = useOne<IListData>({
        dataProviderName: DATAPROVIDER_LOOKUP,
        resource: RESOURCE_PATH.LIST,
        id: SELECTION_LISTS.TECHNICAL_EXPERTISE
    });

    const { selectProps: countryProps } = useSelect<ILookup>({
        dataProviderName: DATAPROVIDER_LOOKUP,
        resource: RESOURCE_PATH.COUNTRY,
        optionLabel: "name",
        optionValue: "id",
        sort: [
            {
                field: "name",
                order: "asc"
            }
        ],
        fetchSize: 100
    });

    const metaConfig = entityMetadata?.data?.config;
    const apiUrl = useApiUrl(DATAPROVIDER_UPLOAD);
    const [candidateId, setCandidateId] = useState("");

    const { refetch: checkCandidateId } = useOne<ILookup>({
        dataProviderName: DATAPROVIDER_LOOKUP,
        resource: RESOURCE_PATH.CANDIDATE,
        id: candidateId,
        queryOptions: {
            enabled: false,
        }
    });

    const [parsedResume, setParsedResume] = useState<any>();

    useEffect(() => {
        setParsedResume(record?.parsedResume ?? null);
    }, [record]);

    const { mutate, isLoading: parsedResumeLoading } = useCreate<IBaseProps>();
    const parseResume = (fileurl: string) => {
        // Save the preference to profile
        mutate({
            dataProviderName: DATAPROVIDER_AI,
            resource: RESOURCE_PATH.CANDIDATE,
            values: {
                aiAction: AI_ACTION_FILEPARSER,
                fileurl: fileurl
            },
            successNotification: false,
            errorNotification: false,
        },
            {
                onSuccess: (data) => {
                    if (data?.data) {
                        setParsedResume(data?.data);
                        formprops.form?.setFieldsValue({ "parsedResume": data?.data });

                    }
                },
                onError: () => {
                    setParsedResume(null);
                    formprops.form?.setFieldsValue({ "parsedResume": null });
                }
            }
        );
    }

    return (<>
        <Divider orientation="left"><><Icon component={GenAISVG}></Icon> Helium AI</></Divider>
        <Row gutter={rowGutter}>
            <Col className="gutter-row" {...threeColumnLayout}>
                <Form.Item
                    label="Upload Candidate Resume"
                    name="resume"
                    valuePropName="fileList"
                    getValueFromEvent={getValueFromEvent}
                    tooltip="Upload resume of candidate (Less than 5 MB)"
                    help="Helium AI will extract resume content."
                >
                    <Upload
                        name="file"
                        accept=".doc,.docx,application/msword,application/vnd.openxmlformats-officedocument.wordprocessingml.document,.ppt,.pptx,application/vnd.ms-powerpoint,application/vnd.openxmlformats-officedocument.presentationml.presentation,.pdf,application/pdf,"
                        action={`${apiUrl}/${RESOURCE_PATH.CANDIDATE}/document`}
                        maxCount={1}
                        beforeUpload={(file: RcFile) => {
                            const isLt5M = (file.size / 1024 / 1024) < 5;
                            if (!isLt5M) {
                                message.error(`${file.name} is greater than 5 MB!`);
                            }
                            return isLt5M;
                        }}
                        onChange={(info) => {
                            if (info.file.status === 'done') {
                                parseResume(info.file.response?.url || "");
                            }
                            else if (info.file.status === 'removed') {
                                setParsedResume(null);
                                formprops?.form?.setFieldsValue({ "parsedResume": null });
                            }
                        }}
                    >
                        <Button icon={<Icons.UploadOutlined />}>Upload</Button>
                    </Upload>
                </Form.Item>
            </Col>
            <Col className="gutter-row" {...twoColumnLaytout}>
                <Form.Item name="parsedResume" hidden={true}>
                    <Input />
                </Form.Item>
                {
                    parsedResumeLoading
                        ? <RenderSpinner message="Parsing resume content..." ></RenderSpinner>
                        : parsedResume && <Collapse expandIconPosition="end" defaultActiveKey={"ParsedResume"}>
                            <Panel header={"Parsed resume"} key="ParsedResume">
                                <ParsedResume {...parsedResume}></ParsedResume>
                            </Panel>
                        </Collapse>
                }</Col>
        </Row>
        <Divider orientation="left">Basic Details</Divider>
        <Row gutter={rowGutter}>
            <Col className="gutter-row" {...threeColumnLayout}>
                <Form.Item
                    label="Candidate Name"
                    name="name"
                    tooltip="Enter candidate name"
                    rules={[
                        {
                            required: true,
                            whitespace: true,
                        }
                    ]}
                >
                    <Input placeholder="Please provide name of the candidate" />
                </Form.Item>
            </Col>
            <Col className="gutter-row" {...threeColumnLayout}>
                {candidateType === "TCS Internal" ?
                    <>
                        <Form.Item
                            label="TCS Email ID"
                            name="tcsEmail"
                            tooltip="TCS Email ID of associate"
                            rules={[
                                {
                                    required: false,
                                    whitespace: true,
                                    type: "email"
                                }
                            ]}
                        >
                            <Input placeholder="Provide Email ID of associate" />
                        </Form.Item>
                        <Form.Item
                            label="Candidate ID"
                            name="id"
                            hidden={true}
                        >
                            <Input />
                        </Form.Item>
                    </>
                    :
                    <Form.Item
                        label="Email ID"
                        name="id"
                        tooltip="Email ID of candidate"
                        rules={[
                            {
                                required: true,
                                whitespace: true,
                                type: "email"
                            },
                            {
                                validator: async (_, value: string) => {
                                    if (!value) {
                                        return;
                                    } else if (mode === "edit") {
                                        return Promise.resolve();
                                    }
                                    else {
                                        const { data } = await checkCandidateId();
                                        if (data && data?.data?.id) {
                                            throw new Error("'Email ID' already exists and belongs to '" + data?.data?.name + "'. Kindly specify another email ID or edit existing candidate.");
                                        }
                                        return Promise.resolve();
                                    }
                                }
                            }
                        ]}
                    >
                        <Input disabled={mode === "edit"} placeholder="Provide Email ID of candidate" onChange={(event) => setCandidateId(event.target.value)} />
                    </Form.Item>
                }
            </Col>
            <Col className="gutter-row" {...threeColumnLayout}>
                <Form.Item
                    label="Contact Number"
                    name="phoneNumber"
                    tooltip="Enter candidate's contact number"
                    rules={[
                        {
                            required: true,
                            whitespace: true,
                            type: "string"
                        }
                    ]}
                >
                    <InputNumber placeholder="Provide candidates contact number" maxLength={12} controls={false} stringMode style={{ width: "100%" }} />
                </Form.Item>
            </Col>
        </Row>
        <Row gutter={rowGutter}>
            <Col className="gutter-row" {...threeColumnLayout}>
                <Form.Item
                    label="Skill Category"
                    name="skillCategory"
                    tooltip="Provide Skill Category"
                    rules={[
                        {
                            required: true,
                            whitespace: true,
                        }
                    ]}
                >
                    <Input placeholder="Provide candidates skill category" />
                </Form.Item>
            </Col>
            <Col className="gutter-row" {...threeColumnLayout}>
                <Form.Item
                    label="Skills"
                    name="skills"
                    tooltip="Add candidate's skills"
                    rules={[
                        {
                            required: true,
                            type: "array"
                        }
                    ]}
                >
                    <Select mode="tags"
                        placeholder="Type required skills and press enter. You can add as many as you want."
                        tokenSeparators={[',', ";"]}
                    >
                        {skillsList?.data.data.map((m, i) => (
                            <Select.Option value={m} key={`skls-${i}`}>{m}</Select.Option>
                        ))}
                    </Select>
                </Form.Item>
            </Col>
            <Col className="gutter-row" {...threeColumnLayout}>
                <Form.Item
                    label="Total Experience"
                    name="totalExperience"
                    tooltip="Enter candidate's total experience in years"
                    rules={[
                        {
                            required: true,
                            whitespace: true,
                            type: "number"
                        }
                    ]}
                >
                    <InputNumber addonAfter="Years" placeholder="Provide candidates total expereince in years" precision={2} min={0} max={40} controls={false} style={{ width: "100%" }} />
                </Form.Item>
            </Col>
        </Row>
        <Row gutter={rowGutter}>
            <Col className="gutter-row" {...threeColumnLayout}>
                <Form.Item
                    label="Country"
                    name="countryId"
                    tooltip="Where the candidate is presently located"
                    rules={[
                        {
                            required: true,
                            type: "string",
                        }
                    ]}
                >
                    <Select placeholder="Select Country"
                        showSearch
                        {...countryProps}
                    >
                    </Select>
                </Form.Item>
            </Col>
            <Col className="gutter-row" {...threeColumnLayout}>
                <Form.Item
                    label="State"
                    name="state"
                    tooltip="Where the candidate is presently located"
                    rules={[
                        {
                            required: false,
                            type: "string",
                        }
                    ]}
                >
                    <Input placeholder="Candidates state" />
                </Form.Item>
            </Col>
            <Col className="gutter-row" {...threeColumnLayout}>
                <Form.Item
                    label="City"
                    name="city"
                    tooltip="Where the candidate is presently located"
                    rules={[
                        {
                            required: false,
                            type: "string",
                        }
                    ]}
                >
                    <Input placeholder="Candidates city" />
                </Form.Item>
            </Col>
        </Row>
        <Row gutter={rowGutter}>
            <Col className="gutter-row" {...threeColumnLayout}>
                <Form.Item
                    label="Perferred Location"
                    name="preferredLocation"
                    tooltip="Candidates preferred location"
                    rules={[
                        {
                            required: true,
                            type: "string",
                        }
                    ]}
                >
                    <Input placeholder="Candidates preffered location" />
                </Form.Item>
            </Col>
            <Col className="gutter-row" {...twoColumnLaytout}>
                <Form.Item
                    label="Remarks"
                    name="remarks"
                    tooltip="Any additional information about candidate"
                    rules={[
                        {
                            required: false,
                            type: "string",
                        }
                    ]}
                >
                    <Input placeholder="Remarks - Any additional information about candidate" />
                </Form.Item>
            </Col>
        </Row>
        <Divider orientation="left">{candidateType} - Specific Details</Divider>
        {
            candidateType === "TCS Internal" &&
            <>
                <Row gutter={rowGutter}>
                    <Col className="gutter-row" {...threeColumnLayout}>
                        <Form.Item
                            label="Employee ID"
                            name="employeeId"
                            tooltip="Employee ID of TCS associate"
                            rules={[
                                {
                                    required: true,
                                },
                                {
                                    validator: async (_, value: string) => {
                                        if (!value) {
                                            return;
                                        } else if (mode === "edit") {
                                            return Promise.resolve();
                                        } else {
                                            setCandidateId(value);
                                            const { data } = await checkCandidateId();
                                            if (data && data?.data?.id) {
                                                throw new Error("'Employee ID' already exists and belongs to '" + data?.data?.name + "'. Kindly specify another employee ID or edit existing candidate.");
                                            }
                                            return Promise.resolve();
                                        }
                                    }
                                }
                            ]}
                        >
                            <InputNumber
                                disabled={mode !== "create"}
                                onChange={(value) => setCandidateId(value?.toString() || "")}
                                placeholder="Please provide employee ID of the candidate"
                                min={1} maxLength={7} controls={false}
                                style={{ width: '100%' }} />
                        </Form.Item>
                    </Col>
                    <Col className="gutter-row" {...threeColumnLayout}>
                        <Form.Item
                            label="Grade"
                            name="grade"
                            tooltip="Grade of TCS associate"
                            rules={[
                                {
                                    required: true,
                                    whitespace: true,
                                    type: "string"
                                }
                            ]}
                        >
                            <Input placeholder="TCS Grade of associate" />
                        </Form.Item>
                    </Col>
                    <Col className="gutter-row" {...threeColumnLayout}>
                        <Form.Item
                            label="TCS Experience"
                            name="tcsExperience"
                            tooltip="Number of years associate spend in TCS"
                            rules={[
                                {
                                    required: true,
                                    whitespace: true,
                                    type: "number"
                                }
                            ]}
                        >
                            <InputNumber addonAfter="Years" placeholder="Number of years associate spend in TCS" precision={2} min={0} max={40} controls={false} style={{ width: "100%" }} />
                        </Form.Item>
                    </Col>
                </Row>
                <Row gutter={rowGutter}>
                    <Col className="gutter-row" {...threeColumnLayout}>
                        <Form.Item
                            label="Experience Category"
                            name="experienceCategory"
                            tooltip="Experience Category of TCS associate"
                            rules={[
                                {
                                    required: false,
                                    whitespace: true,
                                }
                            ]}
                        >
                            <Select placeholder="Select Experience Category" showSearch>
                                {metaConfig?.experienceCategory.sort().map((m, i) => (
                                    <Select.Option value={m} key={`expct-${i}`}>{m}</Select.Option>
                                ))}
                            </Select>
                        </Form.Item>
                    </Col>
                    <Col className="gutter-row" {...threeColumnLayout}>
                        <Form.Item
                            label="Base Branch"
                            name="baseBranch"
                            tooltip="Base branch of TCS associate"
                            rules={[
                                {
                                    required: false,
                                    whitespace: true,
                                    type: "string"
                                }
                            ]}
                        >
                            <Input placeholder="Specify base branch of TCS associate" />
                        </Form.Item>
                    </Col>
                    <Col className="gutter-row" {...threeColumnLayout}>
                        <Form.Item
                            label="Depute Branch"
                            name="deputeBranch"
                            tooltip="Depute branch of TCS associate"
                            rules={[
                                {
                                    required: false,
                                    whitespace: true,
                                    type: "string"
                                }
                            ]}
                        >
                            <Input placeholder="Specify Depute branch of TCS associate" />
                        </Form.Item>
                    </Col>
                </Row>
                <Row gutter={rowGutter}>
                    <Col className="gutter-row" {...threeColumnLayout}>
                        <Form.Item
                            label="RMG SPOC"
                            name="rmgSpoc"
                            tooltip="RMG point of contact for TCS associate"
                            rules={[
                                {
                                    required: false,
                                    whitespace: true,
                                }
                            ]}
                        >
                            <Input placeholder="Name of RMG SPOC" />
                        </Form.Item>
                    </Col>
                    <Col className="gutter-row" {...threeColumnLayout}>
                        <Form.Item
                            label="RMG Status"
                            name="rmgStatus"
                            tooltip="Status of candidate as shared by RMG"
                            rules={[
                                {
                                    required: false,
                                    whitespace: true,
                                    type: "string"
                                }
                            ]}
                        >
                            <Input placeholder="Specify status of candidate as shared by RMG" />
                        </Form.Item>
                    </Col>
                    <Col className="gutter-row" {...threeColumnLayout}>
                    </Col>
                </Row>
            </>
        }
        {
            (candidateType === "BA" || candidateType === "External") &&
            <>
                <Row gutter={rowGutter}>
                    <Col className="gutter-row" {...threeColumnLayout}>
                        {candidateType === "BA" &&
                            <Form.Item
                                label="Rate Hourly (In USD)"
                                name="hourlyRate"
                                tooltip="Hourly Billing rate for candidate"
                                rules={[
                                    {
                                        required: true,
                                    }
                                ]}
                            >
                                <InputNumber
                                    placeholder="Provide Hourly Billing rate for candidate"
                                    min={0} precision={2} maxLength={7} controls={false}
                                    addonAfter={<Icons.DollarCircleTwoTone />}
                                    style={{ width: '100%' }} />
                            </Form.Item>
                        }
                        {candidateType === "External" &&
                            <Form.Item
                                label="Rate Yearly (In USD)"
                                name="yearlyRate"
                                tooltip="Yearly Billing rate for candidate"
                                rules={[
                                    {
                                        required: true
                                    }
                                ]}
                            >
                                <InputNumber
                                    placeholder="Provide Yearly Billing rate for candidate"
                                    min={0} precision={2} maxLength={10} controls={false}
                                    addonAfter={<Icons.DollarCircleTwoTone />}
                                    style={{ width: '100%' }} />
                            </Form.Item>
                        }
                    </Col>
                    <Col className="gutter-row" {...threeColumnLayout}>
                        <Form.Item
                            label="Work Authorization Type"
                            name="workAuthorizationType"
                            tooltip="Work Authorization category of candidate"
                            rules={[
                                {
                                    required: true,
                                    whitespace: true,
                                }
                            ]}
                        >
                            <Select placeholder="Select Work Authorization Type" showSearch>
                                {metaConfig?.workAuthorizationType.sort().map((m, i) => (
                                    <Select.Option value={m} key={`expct-${i}`}>{m}</Select.Option>
                                ))}
                            </Select>
                        </Form.Item>
                    </Col>
                    <Col className="gutter-row" {...threeColumnLayout}>
                        <Form.Item
                            label="Source"
                            name="profileSource"
                            tooltip="Source of candidate profile"
                            rules={[
                                {
                                    required: true,
                                    whitespace: true,
                                    type: "string"
                                }
                            ]}
                        >
                            <Input placeholder="Specify source of candidate profile" />
                        </Form.Item>
                    </Col>
                </Row>
                <Row gutter={rowGutter}>
                    <Col className="gutter-row" {...threeColumnLayout}>
                        <Form.Item
                            label="RGS ID"
                            name="rgsId"
                            rules={[
                                {
                                    required: true,
                                    whitespace: true,
                                    type: "number",
                                    message: `RGS ID should be 7 digits long numeric value starting with 8 or 9`,
                                    validator: async (_, value) => {
                                        if (value && validateRGSIDs([value])) {
                                            Promise.resolve();
                                        }
                                        else {
                                            throw new Error("Provide Valid RGS ID");
                                        }
                                    }
                                }
                            ]}
                        >
                            <InputNumber min={1} precision={0} placeholder="RGS ID" controls={false} style={{ width: "100%" }} />
                        </Form.Item>
                    </Col>
                    <Col className="gutter-row" {...threeColumnLayout}>
                        <Form.Item
                            label="Prior Microsoft ID"
                            name="priorMicrosoftID"
                            tooltip="Microsoft ID of candidate in case of rehire"
                            rules={[
                                {
                                    required: false,
                                    whitespace: true,
                                    type: "email"
                                }
                            ]}
                        >
                            <Input placeholder="Microsoft ID of candidate in case of rehire" />
                        </Form.Item>
                    </Col>
                    <Col className="gutter-row" {...threeColumnLayout}>
                        <Form.Item
                            label="Screening Availability"
                            name="screeningAvailability"
                            tooltip="Time slots when candidate is available for discussion"
                            rules={[
                                {
                                    required: false,
                                    whitespace: true,
                                    type: "string"
                                }
                            ]}
                        >
                            <Input placeholder="Specify availability time slots" />
                        </Form.Item>
                    </Col>
                </Row>
            </>
        }
    </>);
}