import { stringExtensions } from "@architecture-innovation-transformation/lib-common";
import { Avatar, Button, ButtonProps, Card, Divider, Popover, ShowButton, Typography } from "@pankod/refine-antd";
import { UserType } from "interfaces/enum";
import { HELIUM_LOGO_PATH, RESOURCE_PATH, useProfileLookup } from "scripts/site";
import { ShowUserBasics } from "./showUserBasics";

export declare type UserCardProps = ButtonProps & {
    id: string | undefined;
};

export const ShowUserCard: React.FC<UserCardProps> = ({
    id,
    ...rest
}) => {
    const { dataObj } = useProfileLookup(id);
    const imageUrl = dataObj?.userImages?.find(img => img.uid === dataObj?.userProfileImage)?.url;

    return (
        dataObj
            ? <Button
                type="link"
                size="small"
                style={{ padding: 0 }}
                {...rest}
                onClick={(e) => {
                    e.stopPropagation();
                }}
            >
                <Popover
                    content={
                        <Card size="small" >
                            <Card.Meta
                                avatar={<Avatar
                                    size="default"
                                    alt="Profile Image"
                                    src={imageUrl || HELIUM_LOGO_PATH}
                                />}
                                title={dataObj.name}
                            />
                            <Divider style={{ marginTop: 15, marginBottom: 10, fontWeight: "normal", fontSize: 14, color: dataObj.userType === UserType.Client ? "red" : "" }}>
                                {stringExtensions.capitalize(dataObj.userType)}
                            </Divider>
                            <ShowUserBasics {...dataObj} />
                            <ShowButton icon={<></>} type="link" resourceNameOrRouteName={RESOURCE_PATH.PEOPLE} size="small" recordItemId={id}>
                                View Full Profile
                            </ShowButton>
                        </Card>
                    }
                >
                    {dataObj.name ?? id}
                </Popover >
            </Button >
            : <Typography.Text copyable={!!id}>{id}</Typography.Text>
    );
};


