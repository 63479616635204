import { ButtonProps } from "@pankod/refine-antd";
import { ILookup } from "interfaces";
import { useResourceLookup } from "scripts/site";
import { RenderLink } from "./renderLink";

export declare type LookupProps = ButtonProps & {
    id: string;
    resource: string;
    noClick?: boolean;
    noTooltip?: boolean;
};

export const LookupButton: React.FC<LookupProps> = (props) => {

    const { dataObj } = useResourceLookup<ILookup>(props?.resource, props?.id);
    return (
        <RenderLink
            name={dataObj?.name}
            {...props}
        ></RenderLink>
    );
};