import { Divider, Modal, ModalProps, Show, Typography } from "@pankod/refine-antd";
import MDEditor from "@uiw/react-md-editor";
import { ObjectMetadata } from "components/utils/objectMetadata";
import dayjs from "dayjs";
import { IBaseProps } from "interfaces";
import { IIntakeActivity } from "interfaces/intake";
import { getAppTheme } from "scripts/theme";

type IntakeActivityProps = {
    modalProps: ModalProps;
    intakeActivity: IIntakeActivity | undefined;
};

export const IntakeActivityShow: React.FC<IntakeActivityProps> = ({
    modalProps,
    intakeActivity
}) => {
    const { Title, Text } = Typography;

    return (
        <Modal {...modalProps} width={"70%"}
            okButtonProps={{ style: { display: "none" } }}
            cancelText="Close"
         >
        <Show
            title="Activity Details"
            breadcrumb={null}
            goBack={true}
            headerProps={{
                extra: <>
                </>
            }}
        >
        <Title style={{ marginTop: 15 }} level={5}>Activity Name</Title>
            <div className="container" key="activityName" data-color-mode={getAppTheme()}>
                <Text>{intakeActivity?.name}</Text>
            </div>

            <Title style={{ marginTop: 15 }} level={5}>Description</Title>
            <div className="container" key="activityDesc" data-color-mode={getAppTheme()}>
                <MDEditor.Markdown source={intakeActivity?.description} />
            </div>
            
            <Title style={{ marginTop: 15 }} level={5}>Activity Date</Title>
            <div className="container" key="activityEffortSpent" data-color-mode={getAppTheme()}>
                <Text>{dayjs(intakeActivity?.activitydate).format("MMM DD, YYYY")}</Text>
            </div>
            <Title style={{ marginTop: 15 }} level={5}>Effort (in hours)</Title>
            <div className="container" key="activityEffortSpent" data-color-mode={getAppTheme()}>
                <Text>{intakeActivity?.effort}</Text>
            </div>
            <Divider></Divider>
            <div>
                <ObjectMetadata {...intakeActivity as IBaseProps}></ObjectMetadata>
            </div>
        </Show>
        </Modal>
    );
};
