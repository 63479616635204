import { Button, Descriptions, Drawer, ExportButton, Icons, List, Table, Typography, useTable } from "@pankod/refine-antd";
import { CanAccess, CrudFilters, CrudSorting, HttpError, IResourceComponentsProps, useExport, useGetIdentity, useOne, useUpdate } from "@pankod/refine-core";
import type { ColumnsType } from 'antd/es/table';
import { RenderMDFile, RenderWorkflowModal } from "components/common";
import { ManagePosition } from "components/resourcing/managePosition";
import { mapFiltersToFormFields } from "components/resourcing/mapFilters";
import { PositionFilter } from "components/resourcing/positionFilter";
import { RenderPositionActions } from "components/resourcing/renderPositionActions";
import { StatsTile } from "components/statistics/statsTile";
import { generateCrudFilters, generatePageSize, generateSortOrder } from "components/utils/generateCrudFilters";
import { GetListTableColumns, mergePreferences, preferencesData } from "components/utils/getListTableColumns";
import { ListColumnPicker } from "components/utils/listColumnPicker";
import { IBaseProps, IEntityListColumns, IState, IUser, IUserPreferences } from "interfaces";
import { IPosition, IPositionFilterVariables, IPositionMetadata } from "interfaces/resourcing";
import { useEffect, useState } from "react";
import { DATAPROVIDER_COUNT, DATAPROVIDER_EXPORT, DATAPROVIDER_LOOKUP, DATAPROVIDER_READ, DATAPROVIDER_UPDATE, MY_PROFILE, RESOURCE_PATH, STALE_DURATION, removeLocalSessionStorage } from "scripts/site";

export const PositionList: React.FC<IResourceComponentsProps> = () => {
    const { data: userData } = useGetIdentity();
    const userObj = userData as IUser;
    let savedFilters = userObj?.preferences?.entityListView?.find(x => x.id === RESOURCE_PATH.POSITION)?.filters || [];
    let savedPageSize = userObj?.preferences?.entityListView?.find(x => x.id === RESOURCE_PATH.POSITION)?.pageSize || 10;
    let savedSortOrder = userObj?.preferences?.entityListView?.find(x => x.id === RESOURCE_PATH.POSITION)?.sortOrder;
    let initialSortOrder: CrudSorting = (savedSortOrder && savedSortOrder.length > 0) ? savedSortOrder : [{
        field: "updatedAt",
        order: "desc"
    }];

    const { tableProps, tableQueryResult, searchFormProps, sorter, setSorter, setPageSize } = useTable<IPosition, HttpError, IPositionFilterVariables>({
        dataProviderName: DATAPROVIDER_READ,
        resource: RESOURCE_PATH.POSITION,
        initialFilter: savedFilters,
        initialSorter: initialSortOrder,
        initialPageSize: savedPageSize,
        onSearch: (params) => {
            const filters: CrudFilters = generateCrudFilters(params, "render");
            setSorter(generateSortOrder(params, initialSortOrder));
            setPageSize(generatePageSize(params));
            return filters;
        },
        queryOptions: {
            enabled: (savedFilters !== undefined)
        }
    });

    const { data: metadata } = useOne<IPositionMetadata>({
        dataProviderName: DATAPROVIDER_LOOKUP,
        resource: RESOURCE_PATH.METADATA,
        id: RESOURCE_PATH.POSITION,
        queryOptions: {
            enabled: true,
            staleTime: STALE_DURATION
        }
    });

    const totalRequests = tableQueryResult?.data?.total ?? 0;
    const helpFilePath = metadata?.data?.fields?.find(x => x.name === "help")?.attachment || "";
    const entityStates = metadata?.data?.states as IState[] ?? [];
    const metaConfig = metadata?.data?.config;
    const columnPreference = userObj?.preferences?.entityListView?.find(x => x.id === RESOURCE_PATH.POSITION)?.columns;
    const [filterOpen, switchFilterForm] = useState(false);
    const [helpOpen, switchHelpContent] = useState(false);
    const [refetchData, switchRefetchFlag] = useState(false);
    const [currentPosition, setCurrentPosition] = useState<IPosition>();
    const [reviewFormOpen, switchReviewForm] = useState(false);
    const [columnSelectionDrawer, switchColumnSelection] = useState(false);
    const [tableColumns, setTableColumns] = useState<IEntityListColumns>();

    const { triggerExport, isLoading: exportLoading } = useExport<any>({
        dataProviderName: DATAPROVIDER_EXPORT,
        resourceName: RESOURCE_PATH.POSITION,
        pageSize: metaConfig?.export.pageSize ?? 100,
    });

    useEffect(() => {
        if (refetchData) {
            tableQueryResult.refetch();
            switchRefetchFlag(false);
        }
    }, [refetchData, tableQueryResult]);

    useEffect(() => {
        if (metaConfig) {
            setTableColumns(mergePreferences(metaConfig.listColumns, columnPreference, RESOURCE_PATH.POSITION));
        }
    }, [metaConfig, columnPreference]);

    const refetchPositionsData = () => {
        switchRefetchFlag(true);
    }

    const onFormSubmit = () => {
        switchReviewForm(false);
    };

    const OnColumnSelection = () => {
        switchColumnSelection(false);
    }

    const getTableColumns = (): ColumnsType<IPosition> => {
        const columns: ColumnsType<IPosition> = GetListTableColumns(tableColumns, sorter, RESOURCE_PATH.POSITION);
        if (metadata?.data) {
            columns.push(
                {
                    title: "Actions",
                    dataIndex: "actions",
                    render: (_, record) => record && <RenderPositionActions record={record} metadata={metadata.data}
                        onClickManage={(e) => {
                            e?.stopPropagation();
                            setCurrentPosition(record);
                            switchReviewForm(true);
                        }} />,
                }
            );
        }
        return columns
    }

    const clearFilters = () => {
        //Switch to System Default 
        saveToProfile(preferencesData(userObj.preferences, RESOURCE_PATH.POSITION, undefined, [], [], 10), true);
    }

    const { mutate } = useUpdate<IBaseProps>();
    const saveToProfile = (userPreferences: IUserPreferences, clear: boolean = false) => {
        // Save the preference to profile
        mutate({
            dataProviderName: DATAPROVIDER_UPDATE,
            resource: RESOURCE_PATH.PROFILE,
            id: userObj.id,
            values: { preferences: userPreferences, _skipTimeStamp: true },
            successNotification: false,
            errorNotification: false
        },
            {
                onSuccess: () => {
                    //If clear, reset the form to facttory default
                    if (clear) {
                        searchFormProps.form?.setFieldsValue({});
                        searchFormProps.form?.submit();
                    }

                    // Remove the user Profile local data to ensure latest preferences are loaded
                    removeLocalSessionStorage(MY_PROFILE);
                },
                onError: () => {
                }
            }
        );
    }

    let isFiltered = savedFilters && savedFilters.length > 0 && savedFilters.some(fltr => fltr.value !== undefined)

    return (
        <List
            headerProps={{
                title: (tableQueryResult.isLoading ? "Loading" : totalRequests) + " Resourcing Position" + (totalRequests !== 1 ? "s" : ""),
                tags: <>
                    <Button key="filter" type="default"
                        icon={isFiltered ? <Icons.FilterFilled /> : <Icons.FilterOutlined />}
                        style={{ paddingRight: (isFiltered ? 4 : 15) }}
                        title={isFiltered ? "You are viewing a filtered list" : (metadata?.data?.config?.search?.textMessages?.buttonText || "Search & Filter")}
                        onClick={() => switchFilterForm(true)}>
                        Search
                        {isFiltered &&
                            <>
                                <Button
                                    type="text"
                                    danger
                                    title={"Clear Filters"}
                                    size="small"
                                    style={{ marginLeft: 5 }}
                                    onClick={(e) => {
                                        clearFilters();
                                        e.stopPropagation();
                                    }}
                                    icon={<Icons.CloseOutlined />}
                                >
                                </Button>
                            </>
                        }
                    </Button>
                </>
            }}

            headerButtons={() => (
                <>
                    {metaConfig?.export.enabled &&
                        <CanAccess key={RESOURCE_PATH.POSITION} resource={RESOURCE_PATH.POSITION} action="list" params={{ dataProviderName: DATAPROVIDER_EXPORT }}>
                            <ExportButton onClick={triggerExport} loading={exportLoading} />
                        </CanAccess>
                    }
                    <Button key="colmnopt" title="Column Options" icon={<Icons.ToolOutlined />} onClick={() => switchColumnSelection(true)}></Button>
                    {helpFilePath &&
                        <Button key="help"
                            title="Help & FAQs"
                            icon={<Icons.ReadOutlined />} onClick={() => switchHelpContent(true)}></Button>
                    }
                    {/* <PositionImport/> */}
                    {entityStates.length > 0 ? RenderWorkflowModal(entityStates) : <></>}
                </>
            )}
        >
            <Descriptions column={4}>
                <Descriptions.Item label="Total" labelStyle={{ fontSize: 24 }}>
                    <StatsTile
                        listProps={{
                            resource: RESOURCE_PATH.POSITION,
                            dataProviderName: DATAPROVIDER_COUNT,
                        }} />
                </Descriptions.Item>
                <Descriptions.Item label="Open" labelStyle={{ fontSize: 24 }}>
                    <StatsTile
                        listProps={{
                            resource: RESOURCE_PATH.POSITION,
                            dataProviderName: DATAPROVIDER_COUNT,
                            config: {
                                filters: [{
                                    field: "stateManager.state",
                                    operator: "eq",
                                    value: "open"
                                }]
                            }
                        }} />
                </Descriptions.Item>
                <Descriptions.Item label="On-Hold" labelStyle={{ fontSize: 24 }}>
                    <StatsTile
                        listProps={{
                            resource: RESOURCE_PATH.POSITION,
                            dataProviderName: DATAPROVIDER_COUNT,
                            config: {
                                filters: [{
                                    field: "stateManager.state",
                                    operator: "eq",
                                    value: "hold"
                                }]
                            }
                        }} />
                </Descriptions.Item>
                <Descriptions.Item label="Mapped" labelStyle={{ fontSize: 24 }}>
                    <StatsTile
                        listProps={{
                            resource: RESOURCE_PATH.POSITION,
                            dataProviderName: DATAPROVIDER_COUNT,
                            config: {
                                filters: [{
                                    field: "stateManager.state",
                                    operator: "eq",
                                    value: "mapped"
                                }]
                            }
                        }} />
                </Descriptions.Item>
            </Descriptions>
            <Table {...tableProps}
                rowKey="id"
                pagination={{
                    ...tableProps.pagination,
                    position: ["bottomRight"],
                    showTotal: (total => <Typography.Title level={4} style={{ marginRight: 10 }} >Total {total}</Typography.Title>)
                }}
                columns={getTableColumns()}
            />
            <Drawer
                title={metadata?.data?.config?.search?.textMessages?.drawerFormHeader || "Filter Requests"}
                placement="right"
                size="large"
                open={filterOpen}
                maskClosable={false}
                onClose={() => { switchFilterForm(false) }}
                forceRender={true}
            >
                {metaConfig &&
                    <PositionFilter
                        formProps={searchFormProps}
                        user={userObj}
                        onApplyFilter={() => switchFilterForm(false)}
                        defaultFilters={mapFiltersToFormFields(savedFilters, initialSortOrder, savedPageSize)}
                        listColumns={metaConfig.listColumns}
                    />
                }
            </Drawer>
            {(helpFilePath) &&
                <Drawer
                    title="FAQs"
                    placement="right"
                    size="large"
                    open={helpOpen}
                    onClose={() => { switchHelpContent(false) }}
                >
                    <RenderMDFile mdFilePath={helpFilePath} resourceName={RESOURCE_PATH.POSITION} />
                </Drawer>
            }
            <Drawer
                title="Manage Position"
                placement="right"
                size="large"
                open={reviewFormOpen}
                onClose={() => { switchReviewForm(false) }}
            >
                {currentPosition && metadata?.data &&
                    <ManagePosition position={currentPosition} positionMetadata={metadata.data} onFormSubmission={onFormSubmit} refetch={refetchPositionsData} />
                }
            </Drawer>
            <Drawer
                title="Column options"
                placement="right"
                size="default"
                open={columnSelectionDrawer}
                onClose={() => { switchColumnSelection(false) }}
            >
                <ListColumnPicker user={userObj} entityListColumns={tableColumns} onColumnSelection={OnColumnSelection} />
            </Drawer>
        </List>
    );
};
