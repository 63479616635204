import { buildAttachmentUrl, getGUID, RESOURCE_PATH, useUrlFromBlob } from "scripts/site";
import { RenderSpinner } from "./spinner";

export declare type audioProps = {
    audioFilePath: string;
    resourceName?: string;
};

export const RenderAudioPlayer: React.FC<audioProps> = ({
    audioFilePath
}) => {
    return (
        <audio id={getGUID()} preload="metadata" controls muted>
            <source src={audioFilePath} type="audio/mpeg" />
            Your browser does not support the audio element.
        </audio>
    );
};

export const RenderLocalAudioPlayer: React.FC<audioProps> = ({
    audioFilePath,
    resourceName
}) => {

    let audioFileUrl = buildAttachmentUrl(RESOURCE_PATH.METADATA, resourceName, audioFilePath);
    const { response: audioFile, isLoading } = useUrlFromBlob(audioFileUrl);

    return (
        isLoading ?
            <RenderSpinner message="Loading Audio Player..." ></RenderSpinner>
            :
            <audio id={getGUID()} preload="metadata" controls muted>
                <source src={audioFile} type="audio/mpeg" />
                Your browser does not support the audio element.
            </audio>
    );
};