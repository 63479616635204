import { Button, Drawer, Icons, Show, Tag } from "@pankod/refine-antd";
import {
  IResourceComponentsProps, useCan, useOne
} from "@pankod/refine-core";
import { OutreachForm, RenderMDFile } from "components/common";
import { IInfoPagesMetadata } from "interfaces";
import React, { useState } from "react";
import { DATAPROVIDER_LOOKUP, RESOURCE_PATH, STALE_DURATION } from "scripts/site";

export const About: React.FC<IResourceComponentsProps> = () => {

  const { data: metadata, isLoading: metadataLoading } = useOne<IInfoPagesMetadata>({
    dataProviderName: DATAPROVIDER_LOOKUP,
    resource: RESOURCE_PATH.METADATA,
    id: RESOURCE_PATH.ABOUT,
    queryOptions: {
      enabled: true,
      staleTime: STALE_DURATION
    }
  });

  const entityMetadata = metadata?.data;
  const aboutUrl = entityMetadata?.fields?.find(f => f.name === RESOURCE_PATH.ABOUT)?.attachment || null;
  const [outreachOpen, switchOutreach] = useState(false);
  const pageTitle = entityMetadata?.config?.pageHeading ? `${entityMetadata?.config?.pageHeading} ${process.env.REACT_APP_ApplicationTitle}` : "";

  const canListExpertOutreach = useCan({
    resource: RESOURCE_PATH.EXPERT_OUTREACH,
    action: "create"
  });

  return (
    <Show
      isLoading={metadataLoading}
      headerProps={{
        title: pageTitle,
        tags: <Tag>{process.env.REACT_APP_BuildId}</Tag>,
        extra: canListExpertOutreach.data?.can && entityMetadata?.config?.connectButtonText &&
          <Button key="help" onClick={() => switchOutreach(true)} icon={<Icons.ReadOutlined />}>{entityMetadata?.config?.connectButtonText}</Button>
      }}
    >
      {aboutUrl &&
        <RenderMDFile resourceName={RESOURCE_PATH.ABOUT} mdFilePath={aboutUrl} />
      }
      {canListExpertOutreach.data?.can &&
        <Drawer
          title={entityMetadata?.config?.drawerTitle}
          placement="right"
          size={entityMetadata?.config?.drawerFormSize}
          open={outreachOpen}
          onClose={() => { switchOutreach(false) }}
        >
          <OutreachForm categoryType={entityMetadata?.config?.connectCategory} onFormSubmit={() => switchOutreach(false)} />
        </Drawer>
      }
    </Show>
  );
};
