import { ILookup } from "@architecture-innovation-transformation/lib-common";
import { Button, Checkbox, Form, FormProps, Icons, Input, InputNumber, Select, useSelect } from "@pankod/refine-antd";
import { useOne } from "@pankod/refine-core";
import { RenderSpinner } from "components/common";
import dayjs from "dayjs";
import { IRoomMetadata } from "interfaces";
import { DATAPROVIDER_LOOKUP, RESOURCE_PATH, STALE_DURATION } from "scripts/site";

export const RoomFilter: React.FC<{ formProps: FormProps, availability?: boolean }> = (props) => {
    const { isLoading, data: metadata } = useOne<IRoomMetadata>({
        dataProviderName: DATAPROVIDER_LOOKUP,
        resource: RESOURCE_PATH.METADATA,
        id: RESOURCE_PATH.ROOM,
        queryOptions: {
            enabled: true,
            staleTime: STALE_DURATION
        }
    });

    const { selectProps: buildingProps } = useSelect<ILookup>({
        dataProviderName: DATAPROVIDER_LOOKUP,
        resource: RESOURCE_PATH.BUILDING,
        optionLabel: "name",
        optionValue: "id",
        sort: [
            {
                field: "name",
                order: "asc"
            },
        ],
        fetchSize: 100
    });
    const { selectProps: zoneProps } = useSelect<ILookup>({
        dataProviderName: DATAPROVIDER_LOOKUP,
        resource: RESOURCE_PATH.ZONE,
        optionLabel: "name",
        optionValue: "id",
        sort: [
            {
                field: "name",
                order: "asc"
            },
        ],
        fetchSize: 100
    });
    const metaConfig = metadata?.data?.config;

    return (
        isLoading ? <RenderSpinner message="Loading..." ></RenderSpinner> :
            <Form layout="vertical" {...props.formProps} autoComplete="off">
                <Form.Item
                    label={"Search"}
                    name="q"
                    tooltip={metaConfig?.search?.textMessages?.searchTextPlaceholder || "Search Rooms"}
                >
                    <Input
                        placeholder={metaConfig?.search?.textMessages?.searchTextPlaceholder || "Search Rooms"}
                        prefix={<Icons.SearchOutlined />}
                    />
                </Form.Item>
                <Form.Item
                    label="Building"
                    name="buildingId"
                >
                    <Select
                        allowClear
                        mode="multiple"
                        placeholder="Building"
                        {...buildingProps}
                    >
                    </Select>
                </Form.Item>
                <Form.Item
                    label="Zone"
                    name="zoneId"
                >
                    <Select
                        allowClear
                        mode="multiple"
                        placeholder="Zone"
                        {...zoneProps}
                    >
                    </Select>
                </Form.Item>
                <Form.Item
                    label="Occupancy"
                    name="occupancy"
                >
                    <InputNumber placeholder="No. of attendees" style={{ width: "100%" }} precision={0} min={0} />
                </Form.Item>

                {props.availability === true &&
                    <>
                        <Form.Item label="Duration" name="duration">
                            <InputNumber placeholder="Duration" max={1440} step={15} precision={0} min={0} addonAfter="Mins"></InputNumber>
                        </Form.Item>
                        <Form.Item name="date" label="Date" initialValue={dayjs().format("YYYY-MM-DD")}>
                            <Input disabled></Input>
                        </Form.Item>
                    </>
                }

                <Form.Item label="Private" name="privateRoom" valuePropName="checked">
                    <Checkbox />
                </Form.Item>
                <Form.Item label="Offline" name="offline" valuePropName="checked">
                    <Checkbox />
                </Form.Item>


                <Form.Item>
                    <Button
                        style={{ width: "100%" }}
                        htmlType="submit"
                        type="primary"
                    >
                        Apply Filter(s)
                    </Button>
                </Form.Item>
                <Form.Item>
                    <Button
                        style={{ width: "100%" }}
                        htmlType="submit"
                        onClick={() => { props.formProps.form?.resetFields() }}
                    >
                        Clear Filter(s)
                    </Button>
                </Form.Item>
            </Form>
    );
}