import { arrayExtensions } from "@architecture-innovation-transformation/lib-common";
import { Button, Drawer, EditButton, Icons, List, Modal, ShowButton, Space, Table, Tag, Tooltip, Typography, getDefaultSortOrder, useDrawerForm, useModal, useTable } from "@pankod/refine-antd";
import { CanAccess, CrudFilters, HttpError, IResourceComponentsProps, useList, useNavigation, useOne } from "@pankod/refine-core";
import { RenderSpinner } from "components/common";
import { EngagementSubMenu } from "components/engagement/subMenu";
import { CreateEnggProgram } from "components/engagementprogram/createEnggProgram";
import { EngagementProgramFilter } from "components/engagementprogram/engagementProgramFilter";
import { EngagementSolutionCount } from "components/engagementsolution/engagementSolutionCount";
import { ShowUserCard } from "components/profile/showUserCard";
import { ProgramSubMenu } from "components/program/subMenu";
import { displayRelativeDate } from "components/utils/displayRelativeDate";
import { LookupButton } from "components/utils/lookupButton";
import { IEngagementProgram, ILookup, IMetric, IMetricValue, IProgram } from "interfaces";
import { IEnggProgramFilterVariables, IEnggProgramMetadata } from "interfaces/article";
import { useState } from "react";
import { useLocation } from "react-router-dom";
import { DATAPROVIDER_CREATE, DATAPROVIDER_DISTINCT, DATAPROVIDER_LOOKUP, DATAPROVIDER_READ, DATAPROVIDER_UPDATE, RESOURCE_PATH, STALE_DURATION, THINK_GEN_AI_LOGO_BLACK, THINK_GEN_AI_LOGO_WHITE, arrayJoinString, getQueryStringParams } from "scripts/site";
import { getAppTheme } from "scripts/theme";

export const EngagementProgramList: React.FC<IResourceComponentsProps> = () => {
  const { show } = useNavigation();
  const [filterOpen, switchFilterForm] = useState(false);
  const { search } = useLocation();
  const { engagementId, programId } = getQueryStringParams(search);
  const mode: "Engagement" | "Program" | "None" = engagementId ? "Engagement" : (programId ? "Program" : "None");

  const listFilter: CrudFilters = [
    {
      field: "_arrayfields",
      operator: "eq",
      value: "programChampions"
    }
  ];

  const spocFilters: CrudFilters = [{
    field: "_fields",
    operator: "eq",
    value: "programSpoc"
  }];

  switch (mode) {
    case "Engagement":
      listFilter.push({
        field: "engagementId",
        operator: "eq",
        value: engagementId
      });
      spocFilters.push({
        field: "engagementId",
        operator: "eq",
        value: engagementId
      });
      break;

    case "Program":
      listFilter.push({
        field: "programId",
        operator: "eq",
        value: programId
      });
      spocFilters.push({
        field: "programId",
        operator: "eq",
        value: programId
      });
      break;
    default:
      break;
  }

  if (mode === "Program") {
    listFilter.push({
      field: "programId",
      operator: "eq",
      value: programId
    });
  }

  const { data: entityMetadata } = useOne<IEnggProgramMetadata>({
    dataProviderName: DATAPROVIDER_LOOKUP,
    resource: RESOURCE_PATH.METADATA,
    id: RESOURCE_PATH.ENGAGEMENTPROGRAM,
    queryOptions: {
      enabled: true,
      staleTime: STALE_DURATION
    }
  });
  const metaConfig = entityMetadata?.data?.config;

  const { tableProps, sorter, tableQueryResult, searchFormProps, filters } = useTable<IEngagementProgram, HttpError, IEnggProgramFilterVariables>({
    dataProviderName: DATAPROVIDER_READ,
    initialSorter: [
      {
        field: "updatedAt",
        order: "desc"
      },
    ],
    permanentFilter: listFilter,
    onSearch: (params) => {
      const filters: CrudFilters = [];
      const { programChampions, programSpoc, engagementId: enggId } = params;
      filters.push({
        field: "programSpoc",
        operator: "eq",
        value: programSpoc
      });
      filters.push({
        field: "programChampions ARRAY_CONTAINS_ANY",
        operator: "eq",
        value: programChampions
      });
      if (mode !== "Engagement") {
        filters.push({
          field: "engagementId",
          operator: "eq",
          value: enggId
        });
      }
      return filters;
    }
  });

  function rowClick(record: IEngagementProgram) {
    return {
      onClick: () => {
        show(RESOURCE_PATH.ENGAGEMENTPROGRAM, record.id);
      },
    };
  }
  const totalRequests = tableQueryResult?.data?.total ?? 0;
  let isFiltered = filters && filters.length > listFilter.length && filters.some(fltr => fltr.value !== undefined)

  const clearFilters = () => {
    searchFormProps.form?.resetFields();
    searchFormProps.form?.submit();
  }

  const { data: progName } = useOne<ILookup>({
    dataProviderName: DATAPROVIDER_LOOKUP,
    resource: RESOURCE_PATH.PROGRAM,
    id: programId,
    queryOptions: {
      enabled: mode === "Program" && !!programId
    },
  });


  const { data: enggName } = useOne<ILookup>({
    dataProviderName: DATAPROVIDER_LOOKUP,
    resource: RESOURCE_PATH.ENGAGEMENT,
    id: engagementId,
    queryOptions: {
      enabled: mode === "Engagement" && !!engagementId
    },
  });

  let pageTitle = "";
  let pageTag = "";

  switch (mode) {
    case "Program":
      pageTitle = (progName?.data?.name ?? "");
      pageTag = (tableQueryResult.isLoading ? "Loading" : totalRequests) + " Engagement" + (totalRequests !== 1 ? "s" : "");
      break;

    case "Engagement":
      pageTitle = (enggName?.data?.name ?? "");
      pageTag = (tableQueryResult.isLoading ? "Loading" : totalRequests) + " Program" + (totalRequests !== 1 ? "s" : "");
      break;

    default:
      pageTitle = (tableQueryResult.isLoading ? "Loading" : totalRequests) + " Engagement Program" + (totalRequests !== 1 ? "s" : "");
      break;
  }

  const {
    drawerProps,
    formProps,
    saveButtonProps,
    show: createModal,
  } = useDrawerForm<IEngagementProgram>({
    action: "create",
    resource: RESOURCE_PATH.ENGAGEMENTPROGRAM,
    dataProviderName: DATAPROVIDER_CREATE,
    redirect: false,
    onMutationSuccess: () => {
      tableQueryResult.refetch();
      //searchFormProps.form?.submit();
    }
  });


  const { data: existingEnggPrograms } = useList<IEngagementProgram>({
    dataProviderName: DATAPROVIDER_LOOKUP,
    resource: RESOURCE_PATH.ENGAGEMENTPROGRAM,
    config: {
      filters: [{
        field: "engagementId",
        operator: "eq",
        value: engagementId
      }]
    },
    queryOptions: {
      enabled: mode === "Engagement" && !!engagementId
    }
  });

  const { data: allProgramList } = useList<IProgram>({
    dataProviderName: DATAPROVIDER_LOOKUP,
    resource: RESOURCE_PATH.PROGRAM,
  });

  const existingProgramIds = existingEnggPrograms?.data?.map(item => item.programId) ?? [];
  const remainingPrograms = allProgramList?.data?.filter(mod => mod.active && !existingProgramIds.includes(mod.id));

  const { data: programRecord } = useOne<IProgram>({
    dataProviderName: DATAPROVIDER_READ,
    resource: RESOURCE_PATH.PROGRAM,
    id: programId,
    queryOptions: {
      enabled: !!programId
    },
  });

  const { show: emailPopup, modalProps } = useModal({
    modalProps: {
      title: "Copy email of all program SPOCs",
      destroyOnClose: true,
      footer: null,
    }
  });

  const { data: spocEmailsData, isLoading: emailsLoading } = useList({
    dataProviderName: DATAPROVIDER_DISTINCT,
    resource: RESOURCE_PATH.ENGAGEMENTPROGRAM,
    config: {
      filters: spocFilters
    },
  });
  const spocEmails = spocEmailsData?.data?.map((item: any) => item.programSpoc) ?? [];

  return (<>
    <List
      headerProps={{
        title: pageTitle,
        tags: <>
          {mode !== "None" && <Tag color={"default"}>
            {pageTag}
          </Tag>}
          <Button key="filter" type="default"
            icon={isFiltered ? <Icons.FilterFilled /> : <Icons.FilterOutlined />}
            style={{ paddingRight: (isFiltered ? 4 : 15) }}
            title={isFiltered ? "You are viewing a filtered list" : (entityMetadata?.data?.config?.search?.textMessages?.buttonText || "Search & Filter")}
            onClick={() => switchFilterForm(true)}>
            Search
            {isFiltered &&
              <>
                <Button
                  type="text"
                  danger
                  title={"Clear Filters"}
                  size="small"
                  style={{ marginLeft: 5 }}
                  onClick={(e) => {
                    clearFilters();
                    e.stopPropagation();
                  }}
                  icon={<Icons.CloseOutlined />}
                >
                </Button>
              </>}
          </Button>
        </>,
        extra: <>
          {mode === "Program" && <>
            <Button icon={<Icons.MailOutlined />} onClick={emailPopup}>
              Contact SPOCs
            </Button>
            <img src={getAppTheme() === "dark" ? THINK_GEN_AI_LOGO_WHITE : THINK_GEN_AI_LOGO_BLACK} alt="Think GenAI" style={{
              height: 30,
              width: 150,
            }}></img>
          </>}

          {mode === "Engagement" && <>
            {/* Need to pass the engagementId as a query parameter to build a dummy object for RBAC check */}
            <CanAccess key={RESOURCE_PATH.ENGAGEMENTPROGRAM} resource={RESOURCE_PATH.ENGAGEMENTPROGRAM.toLowerCase()} action="create"
              params={{ dataProviderName: DATAPROVIDER_CREATE, id: "1", engagementId: engagementId }}>
              {(remainingPrograms && remainingPrograms?.length > 0) ?
                <Button icon={<Icons.PlusOutlined />} onClick={() => createModal()}>
                  Program
                </Button>
                :
                <Tooltip title="No programs available to add" placement="bottom">
                  <Button disabled icon={<Icons.PlusOutlined />}>Program</Button>
                </Tooltip>}
            </CanAccess>
          </>}
        </>
      }}
    >
      {mode === "Program" &&
        <div style={{ marginBottom: 24 }}>
          <ProgramSubMenu programId={programId} selectedResource={RESOURCE_PATH.ENGAGEMENTPROGRAM}></ProgramSubMenu>
        </div>}

      {mode === "Engagement" &&
        <div style={{ marginBottom: 24 }}>
          <EngagementSubMenu engagementId={engagementId} selectedResource={RESOURCE_PATH.ENGAGEMENTPROGRAM}></EngagementSubMenu>
        </div>}

      <Table {...tableProps}
        rowKey="id"
        pagination={{
          ...tableProps.pagination,
          position: ["bottomRight"],
          showTotal: (total => <Typography.Title level={4} style={{ marginRight: 10 }}>Total {total}</Typography.Title>)
        }}
      >
        {mode !== "Program" &&
          <>
            <Table.Column
              dataIndex="id"
              key="id"
              title=""
              width={100}
              render={() => <img src={getAppTheme() === "dark" ? THINK_GEN_AI_LOGO_WHITE : THINK_GEN_AI_LOGO_BLACK} alt="Think GenAI"
                style={{
                  height: 20,
                  width: 100,
                }}></img>}
              onCell={rowClick}
              className="mouseHand" />

            <Table.Column
              dataIndex="programId"
              key="programId"
              title="Program"
              onCell={rowClick}
              className="mouseHand"
              render={(value: string) => <>
                {value &&
                  <LookupButton noTooltip id={value} resource={RESOURCE_PATH.PROGRAM} />}
              </>} />
          </>}
        {mode !== "Engagement" &&
          <Table.Column
            dataIndex="engagementId"
            key="engagementId"
            title="Engagement"
            onCell={rowClick}
            className="mouseHand"
            render={(value: string) => <>
              {value &&
                <LookupButton noTooltip id={value} resource={RESOURCE_PATH.ENGAGEMENT} />}
            </>} />}

        {programId && programRecord && arrayExtensions.validateArray(programRecord?.data?.keyResults) &&
          programRecord?.data?.keyResults.map((kr, i) => {
            return <Table.Column
              dataIndex={["keyResults"]}
              key={kr.id}
              title={kr.name}
              onCell={rowClick}
              className="mouseHand"
              render={(value: IMetricValue[]) => {
                if (value && arrayExtensions.validateArray(value)) {
                  const valueIndex = value.findIndex((v: IMetricValue) => v.id === kr.id);
                  return valueIndex >= 0 ? (value[valueIndex].value + "%") : "";
                }
                return "";
              }}
            />
          })
        }
        <Table.Column
          dataIndex="tasks"
          key="tasks"
          title="Tasks"
          onCell={rowClick}
          className="mouseHand"
          render={(value: IMetricValue[]) => <>
            {value && arrayExtensions.validateArray(value) &&
              <Typography.Text>{`${value.filter(v => v.value === true)?.length}/${value.length}`}</Typography.Text>
            }
          </>} />
        {programId && programRecord && arrayExtensions.validateArray(programRecord?.data?.journey) &&
          <Table.Column
            dataIndex="journey"
            key="journey"
            title="Maturity"
            onCell={rowClick}
            className="mouseHand"
            render={(value: IMetricValue[]) => {
              let result = "";
              if (value && arrayExtensions.validateArray(value)) {
                const groupedJourney = arrayExtensions.groupBy(programRecord?.data?.journey ?? [], "group");
                const groupKeys = Object.keys(groupedJourney);
                for (let i = 0; i < groupKeys.length; i++) {
                  if ((groupedJourney[groupKeys[i]] as IMetric[] ?? []).every(g => {
                    const item = value.find((v: IMetricValue) => v.id === g.id);
                    return (item && item.value === true)
                  }
                  )) {
                    result = groupKeys[i];
                  }
                  else {
                    break;
                  }
                }
              }
              return result;
            }}
          />
        }

        <Table.Column
          key="scenario"
          title="Scenarios"
          onCell={rowClick}
          className="mouseHand"
          render={(_, progRecord: IEngagementProgram) => <>
            {progRecord &&
              <EngagementSolutionCount engagementId={progRecord.engagementId} programId={progRecord.programId} />}
          </>} />

        <Table.Column
          dataIndex="programSpoc"
          key="programSpoc"
          title="Program SPOC"
          onCell={rowClick}
          className="mouseHand"
          render={(value: string) => <>
            {value &&
              <ShowUserCard id={value} />}
          </>} />

        <Table.Column
          dataIndex="updatedBy"
          key="updatedBy"
          title="Updated by"
          render={(value: string) => <>
            {value &&
              <ShowUserCard id={value} />}
          </>}
          onCell={rowClick}
          className="mouseHand" />

        <Table.Column
          dataIndex="updatedAt"
          key="updatedAt"
          title="Last Updated"
          render={displayRelativeDate}
          defaultSortOrder={getDefaultSortOrder("updatedAt", sorter)}
          sorter
          onCell={rowClick}
          className="mouseHand" />

        <Table.Column<IEngagementProgram>
          title="Actions"
          dataIndex="actions"
          render={(_, record) => (
            <Space>
              {/* Hidden button to let AuthZ module check access for it */}
              <ShowButton resourceNameOrRouteName={RESOURCE_PATH.ENGAGEMENTPROGRAM} hidden size="small" recordItemId={record.id} />
              <CanAccess key={`edit`} resource={RESOURCE_PATH.ENGAGEMENTPROGRAM.toLowerCase()} action="edit" params={{ dataProviderName: DATAPROVIDER_UPDATE, id: record.id }}>
                <EditButton resourceNameOrRouteName={RESOURCE_PATH.ENGAGEMENTPROGRAM} size="small" recordItemId={record.id} />
              </CanAccess>
              {/* <DeleteButton resourceNameOrRouteName={RESOURCE_PATH.ENGAGEMENTPROGRAM} dataProviderName={DATAPROVIDER_DELETE} size="small" recordItemId={record.id} /> */}
            </Space>
          )}
        />
      </Table>
      <Drawer
        title={metaConfig?.search?.textMessages?.drawerFormHeader || "Search & Filter"}
        placement="right"
        size="default"
        open={filterOpen}
        onClose={() => { switchFilterForm(false); }}
      >
        <EngagementProgramFilter formProps={searchFormProps}
          onApplyFilter={() => switchFilterForm(false)} />
      </Drawer>
    </List>
    <CreateEnggProgram
      mode={mode}
      drawerProps={drawerProps}
      engagementId={engagementId}
      formProps={formProps}
      saveButtonProps={saveButtonProps}
      programList={remainingPrograms ?? []} />
    <Modal {...modalProps}>
      {emailsLoading ? <RenderSpinner message="Loading emails ..." /> :
        <div>
          <Typography.Text copyable disabled>{spocEmails ? arrayJoinString(spocEmails, "; ") : "No emails identified"}</Typography.Text>
        </div>
      }
    </Modal>
  </>

  );
};