import { List, ListButton } from "@pankod/refine-antd";
import { IResourceComponentsProps } from "@pankod/refine-core";
import { LocationsList } from "components/manage/locationList";
import { RESOURCE_PATH } from "scripts/site";

export const LocationList: React.FC<IResourceComponentsProps> = () => {
    return (
        <List
            headerProps={{
                title: "Locations"
            }}
            headerButtons={() => (
                <>
                    <ListButton resourceNameOrRouteName={RESOURCE_PATH.COUNTRY}>Countries</ListButton>
                </>
            )}
        >
            <LocationsList />
        </List>
    );
};